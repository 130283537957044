import { useDefaultIdCodesUpdateMutation, useGetDefaultIdCodesQuery } from "../Services/BlockLifeSpanApi";
import { useGetBlockLifeSpanByPaginationQuery } from "../Services/blockLifespanTypesApi";
import { useFetchDataHook } from "./FetchData";

export const useBlockLifeSpanConfigHook = () => {
    const { pageData, loading, fetchData, model, refetching, isEmpty, isError } = useFetchDataHook(useGetBlockLifeSpanByPaginationQuery);

    const [updateDefaultCodesConfig] = useDefaultIdCodesUpdateMutation()
    const { data: getDefaultCodesConfig } = useGetDefaultIdCodesQuery()

    return { pageData, loading, updateDefaultCodesConfig, getDefaultCodesConfig, fetchData, isEmpty, isError, model, refetching };
}