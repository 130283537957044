import { DeliveryPath } from './../models/deliveryPath.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { Customer } from "../models/customer.model";
import { useCreateCustomersMutation, useGetCustomersByIdQuery, useGetCustomersByPaginationQuery, useUpdateCustomersByIdMutation } from "../Services/CustomersApi";
import { useFetchDataHook } from "./FetchData";
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { addCustomerValidationSchema } from '../Utils/validation'
import { useDeliveryPathHook } from './DeliveryPathHook';

type _Props = {
    id: string | undefined
}

export const useCustomerHook = (props: _Props) => {
    const { id } = props
    const { pageData, loading, fetchData, model, refetching } = useFetchDataHook(useGetCustomersByPaginationQuery);
    const [newDeliveryPath, setNewDeliveryPath] = useState()
    const { data: customersData } = useGetCustomersByIdQuery(id, { skip: !id });
    const [updateCustomerData, { error: updateCustomerDataError }] = useUpdateCustomersByIdMutation()
    const { pageData: deliveryPathData } = useDeliveryPathHook({ id: "" })
    const [deliveryPath, setDeliveryPath] = useState<DeliveryPath | undefined>(deliveryPathData?.data[0]);
    const [navigateTo, setNavigateTo] = useState<boolean>();
    const [createCustomerData, { error: createCustomerDataError }] = useCreateCustomersMutation()
    const { register, handleSubmit, reset, formState: { errors }, control } = useForm({ resolver: yupResolver(addCustomerValidationSchema) });
    const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();
    const [createSuccess, setCreateSuccess] = useState<boolean | undefined>();

    const onSubmit = (value: any) => {
        let updateCustomersDetails: Customer = customersData
            ? Object.assign({}, customersData)
            : ({} as any);

        if (updateCustomersDetails) {
            updateCustomersDetails.address1 = value.address1;
            updateCustomersDetails.address2 = value.address2;
            updateCustomersDetails.customer_name = value.customerName;
            updateCustomersDetails.customer_code = value.customer_code;
            updateCustomersDetails.city = value.city;
            updateCustomersDetails.prefecture = value.prefecture;
            updateCustomersDetails.country = value.country;
            updateCustomersDetails.contactPerson1 = value.contactPerson1;
            updateCustomersDetails.contactPerson2 = value.contactPerson2;
            updateCustomersDetails.email1 = value.email1;
            updateCustomersDetails.email2 = value.email2;
            updateCustomersDetails.phone = value.phone;
            if (deliveryPath) {
                updateCustomersDetails.deliveryPath = deliveryPath;
            }
            if (id) {
                updateCustomerData(updateCustomersDetails).then((res: any) => {
                    if (res) {
                        setNavigateTo(true)
                        setUpdateSuccess(true)
                        console.log("update success: ", res.data)
                    }
                    else {
                        setNavigateTo(false)
                        setUpdateSuccess(false)
                        console.log("update errors: ", res.data)
                    }
                })
            }
            else {
                createCustomerData(updateCustomersDetails).then((res: any) => {
                    if (res) {
                        setNavigateTo(true)
                        setCreateSuccess(true)
                        console.log("create success: ", res.data)
                    }
                    else {
                        setNavigateTo(false)
                        setCreateSuccess(false)
                        console.log("create errors: ", res.data)
                    }
                })
            }
        }
    }

    useEffect(() => {
        if (customersData) {
            reset(customersData);
        }
    }, [customersData])

    useEffect(() => {
        if (newDeliveryPath) {
            deliveryPathData?.data?.forEach((element: DeliveryPath) => {
                if (element.pathName === newDeliveryPath) {
                    setDeliveryPath(element);
                }
            });
        }
    }, [newDeliveryPath, deliveryPathData?.data])



    return {
        pageData,
        loading,
        fetchData,
        model,
        customersData,
        register,
        handleSubmit,
        reset,
        errors,
        onSubmit,
        refetching,
        id,
        createCustomerData,
        newDeliveryPath,
        setNewDeliveryPath,
        deliveryPathData,
        deliveryPath,
        navigateTo,
        setNavigateTo,
        createCustomerDataError,
        updateCustomerDataError,
        createSuccess,
        setCreateSuccess,
        updateSuccess,
        setUpdateSuccess,
        control
    };
}