import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../Components/Pagination";
import TableFormat from "../../Components/TableFormat";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import { useTransactionHook } from "../../Hooks/TransactionHooks";
import { Transaction } from "../../models/transaction.model";
import { formatDatecalender } from "../../Utils/datetime";
import { TableNavBar } from "../../Components/TableNavBar/TableNavBar";
import { FetchDataType } from "../../Hooks/FetchData";
import { useAppPermission } from "../../Hooks/AppPermissionHook";
import { Role } from "../../models/userRole.model";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import Button from "../../Components/base/button/button";
import Icon from "../../Components/base/icon/icon";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function TransactionScreen() {
  const { t } = useTranslation();

  const {
    control,
  } = useForm();
  const [blocks, setBlocks] = useState<number[]>([]);
  const [productName, setProductName] = useState<string[]>([]);
  const [companyName, setCompanyName] = useState<string[]>([]);
  const [backgroundColors, setBackgroundColors] = useState<string[]>([]);
  const [productNameFilter, setProductNameFilter] = useState<string | undefined>();
  const [fromDateFilter, setFromDateFilter] = useState<Date | undefined>();
  const [toDateFilter, setToDateFilter] = useState<Date | undefined>();
  const { hasPermission } = useAppPermission();

  const { pageData, loading, fetchData, isError, isEmpty } = useTransactionHook();

  useEffect(() => {
    if (pageData?.data) {
      setBlocks([]);
      setCompanyName([]);
      setProductName([]);
      pageData?.data?.forEach((transaction: Transaction) => {
        setBlocks((value) => [...value, transaction?.block]);
      });
      pageData?.data?.forEach((transaction: Transaction) => {
        setCompanyName((value) => [
          ...value,
          transaction?.company?.company_name,
        ]);
      });
      pageData?.data?.forEach((transaction: Transaction) => {
        setProductName((value) => [
          ...value,
          transaction?.products?.product_name,
        ]);
      });
    }
    setBackgroundColors([]);
    pageData?.data?.forEach(() => {
      let color = "#" + Math.floor(Math.random() * 16777215).toString(16);
      setBackgroundColors((value) => [...value, color]);
    });
  }, [pageData?.data]);

  const BarData = {
    labels: hasPermission([Role.SuperAdmin]) ? companyName : hasPermission([Role.CompanyAdmin]) ? productName : productName,
    datasets: [
      {
        label: t('blocks'),
        data: blocks,
        backgroundColor: backgroundColors,
        borderWidth: 0,
        cutout: "80%",
        animateScale: true,
      },
    ],
  };

  const BarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    rotation: -365 * Math.PI,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: t("totalBlocks"),
      },
    },
  };

  const columns = React.useMemo(
    () => hasPermission([Role.SuperAdmin]) ? [
      {
        Header: t("companyName"),
        accessor: "company_name",
        Cell: ({ row: { original } }: any) => (
          <div>
            <span className="mx-1 py-1.5 ca">
              {t(original?.company?.company_name)}
            </span>
          </div>
        ),
      },
      {
        Header: t("productName"),
        accessor: "product_name",
        Cell: ({ row: { original } }: any) => (
          <div>
            <span className="mx-1 py-1.5 ca">
              {t(original?.products?.product_name)}
            </span>
          </div>
        ),
      },
      {
        Header: t("block"),
        accessor: "block",
      },
      {
        Header: t("channelName"),
        accessor: "channelName",
      },
      {
        Header: t("date"),
        accessor: "date",
      },
      {
        Header: t("createdAt"),
        accessor: "created_at",
        Cell: ({ row: { original } }: any) => (
          <div>
            <span className="mx-1 py-1.5 ca">
              {formatDatecalender(original.created_at)}
            </span>
          </div>
        ),
      },
    ] : [
      {
        Header: t("productName"),
        accessor: "product_name",
        Cell: ({ row: { original } }: any) => (
          <div>
            <span className="mx-1 px-2 py-1.5 ca">
              {t(original?.products?.product_name)}
            </span>
          </div>
        ),
      },
      {
        Header: t("block"),
        accessor: "block",
      },
      {
        Header: t("channelName"),
        accessor: "channelName",
      },
      {
        Header: t("date"),
        accessor: "date",
      },
      {
        Header: t("createdAt"),
        accessor: "created_at",
        Cell: ({ row: { original } }: any) => (
          <div>
            <span className="mx-1 px-2 py-1.5 ca">
              {formatDatecalender(original.created_at)}
            </span>
          </div>
        ),
      },
    ], [t, hasPermission]);

  return (
    <>
      <div className="flex gap-x-3 h-full flex-col lg:flex-row overflow-y-auto no-scrollbar">
        <TableNavBar
          tableTitle={"transactions"}
          pageData={pageData}
          fetchData={fetchData}
          isError={isError}
          isLoading={loading}
          isEmpty={isEmpty}
        >
          <TableNavBar.SearchAndFilter>
            <>
              <input
                type="text"
                placeholder={t(`productName`)}
                id="price"
                value={productNameFilter}
                className="w-full h-8 px-2 pr-7 rounded-lg z-0 focus:outline-none border-TT-ash focus:border-TT-blue border text-sm"
                onChange={(event: any) => { setProductNameFilter(event.target.value) }}
              />
              {/* fromDate field */}
              <Controller
                control={control}
                name={"searchFromDate"}
                render={({ field: { onChange, value } }) => (
                  <div className="flex flex-col w-full">
                    <DatePicker
                      className="w-full h-8 px-2 pr-7 rounded-lg z-0 focus:outline-none border-TT-ash focus:border-TT-blue border text-sm"
                      onChange={(date: Date) => setFromDateFilter(date)}
                      shouldCloseOnSelect={true}
                      selected={fromDateFilter}
                      dateFormat={'yyyy-MM-dd'}
                      placeholderText={t("searchFromDate")}
                    />
                  </div>
                )}
              />
              {/* toDate field */}
              <Controller
                control={control}
                name={"searchToDate"}
                render={({ field: { onChange, value } }) => (
                  <div className="flex flex-col w-full">
                    <DatePicker
                      className="w-full h-8 px-2 pr-7 rounded-lg z-0 focus:outline-none border-TT-ash focus:border-TT-blue border text-sm"
                      onChange={(date: Date) => setToDateFilter(date)}
                      shouldCloseOnSelect={true}
                      selected={toDateFilter}
                      dateFormat={'yyyy-MM-dd'}
                      placeholderText={t("searchToDate")}
                    />
                  </div>
                )}
              />
              {/* search button  */}
              <Button
                onClick={() => {
                  toDateFilter?.setDate(toDateFilter.getDate() + 1)
                  fetchData({
                    pageSize: pageData?.page.pageSize,
                    pageIndex: pageData?.page.pageIndex,
                    totalElements: pageData?.page.totalElements,
                    search: {
                      searchFromDate: fromDateFilter?.toISOString(),
                      searchToDate: toDateFilter?.toISOString(),
                      product_name: productNameFilter,
                    },
                  } as FetchDataType);
                }}
                color="primary"
                className="py-1 flex flex-row whitespace-nowrap gap-x-1 items-center text-xs md:text-sm h-8"
                size="md"
              >
                <>
                  <Icon icon="SEARCH" size="x-small" />
                  {t("search")}
                </>
              </Button>
            </>
          </TableNavBar.SearchAndFilter>
          <TableNavBar.Pagination>
            {pageData && (
              <Pagination page={pageData.page} fetchData={fetchData} />
            )}
          </TableNavBar.Pagination>
          <TableNavBar.TableFormat>
            {pageData && (
              <TableFormat columns={columns} data={pageData?.data} />
            )}
          </TableNavBar.TableFormat>
        </TableNavBar>
        {pageData && (
          <section className="lg:max-w-lg md:mx-2 my-2 lg:-ml-3 md:mt-1 lg:mt-2 bg-white p-5 rounded-lg flex flex-col md:w-[calc(100%-1rem)] shadow">
            <div className="font-bold text-lg">{t("types")}</div>
            <div className="md:w-[400px] w-[350px] m-auto lg:h-3/4 h-screen">
              <Bar
                data={BarData}
                options={BarOptions}
              />
            </div>
          </section>
        )}
      </div>
    </>
  );
}

export default TransactionScreen;
