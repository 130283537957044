import Field from "../../Components/base/field/field";
import { useTranslation } from "react-i18next";
import Button from "../../Components/base/button/button";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { useAddUsersHook } from "../../Hooks/UserHook";
import RoleSelector from "../../Components/RoleSelector";
import { useAppPermission } from "../../Hooks/AppPermissionHook";
import { Role } from "../../models/userRole.model";
import { Controller } from "react-hook-form";
import { toastError, toastSuccess } from "../../Components/toast";
import { useAppDispatch } from "../../Store/hooks";
import { setNavbarProps } from "../../Services/utilsReducer";
import { PhoneNumberField } from "../../Components/base/PhoneInput/PhoneNumberInput";
import { Location as LocationModel } from "../../models/location.model";
import { Combobox, Transition } from "@headlessui/react";
import Icon from "../../Components/base/icon/icon";
import Spinner from "../../Components/Spinner";

function AddUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    handleSubmit,
    onSubmit,
    errors,
    register,
    userData,
    roles,
    locations,
    companies,
    setValue,
    control,
    locationsByCompany,
    createUserError,
    updateUserError,
    setSelectedRole,
    selectedRole,
    handleStatus,
    updateSuccess,
    navigateBack,
    createSuccess,
    companyData,
    selectedCompany,
    setCompanyQuery,
    addCompany,
    locationId,
    setLocationId,
    formatLocation,
  } = useAddUsersHook({ id: id });

  const { hasPermission } = useAppPermission();
  const dispatch = useAppDispatch();
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (createUserError as any) {
      toastError((createUserError as any)?.data?.message);
    }
    if (updateUserError as any) {
      toastError((updateUserError as any)?.data?.message);
    }
  }, [createUserError, updateUserError]);

  useEffect(() => {
    if (updateSuccess === true && navigateBack) {
      toastSuccess(t("updatedSuccessfully"));
      navigate(-1);
    } else if (updateSuccess === false && !navigateBack) {
      toastError(t("failed"));
    }
  }, [navigateBack, updateSuccess]);
  useEffect(() => {
    if (createSuccess === true && navigateBack) {
      toastSuccess(t("createdSuccessfully"));
      navigate(-1);
    } else if (createSuccess === false && !navigateBack) {
      toastError(t("failed"));
    }
  }, [navigateBack, createSuccess]);

  if (errors.password) {
    const content = (errors.password as any).message?.split(",");

    if (content && content?.length > 0) {
      let errorMessage = "<ul>";

      content.forEach((errorContent: any) => {
        if (errors.password) {
          errorMessage += "<li>" + t(errorContent) + "</li>";
        }
      });

      errorMessage += "</ul>";

      if (passwordError !== errorMessage) {
        setPasswordError(errorMessage);
      }
    }
  } else {
    if (passwordError) {
      setPasswordError("");
    }
  }

  useEffect(() => {
    dispatch(
      setNavbarProps({
        navBarTitle: id ? "editUser" : "addUser",
        navBarPrevPage: "/users",
        navBarUserAvatar: true,
      })
    );
  }, []);

  if ((id && !userData) || !companies || !locations) {
    return (
      <div
        className={`md:h-full h-screen flex justify-center items-center bg-white`}
      >
        <Spinner className="h-8 w-8" color="#2984FF" />
      </div>
    );
  }

  return (
    <section className="md:px-2 mt-[7.6rem] md:mt-2 h-full md:pb-20">
      <div className="px-5 pb-5 pt-4  bg-TT-background md:bg-white h-full w-screen md:w-full rounded-lg overflow-auto">
        <form id="addUser" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
            <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
              <label
                htmlFor="firstName"
                className="text-xs uppercase font-semibold"
              >
                {t(`firstName`)}
              </label>
              <Field
                {...register("firstName")}
                error={errors?.firstName && `${errors?.firstName?.message}`}
                type="text"
                defaultValue={userData?.firstName}
                placeholder={t(`firstName`)}
                id="firstName"
                className="px-2 h-12"
              ></Field>
            </div>
            <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
              <label
                htmlFor="lastName"
                className="text-xs uppercase font-semibold"
              >
                {t(`lastName`)}
              </label>
              <Field
                {...register("lastName")}
                error={errors?.lastName && `${errors?.lastName?.message}`}
                type="text"
                defaultValue={userData?.lastName}
                placeholder={t(`lastName`)}
                id="lastName"
                className="px-2 h-12"
              />
            </div>
            <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
              <label
                htmlFor="userName"
                className="text-xs uppercase font-semibold"
              >
                {t(`username`)}
              </label>
              <Field
                {...register("userName")}
                error={errors?.userName && `${errors?.userName?.message}`}
                type="text"
                defaultValue={userData?.username}
                placeholder={t(`username`)}
                id="userName"
                className="px-2 h-12"
                disabled={!!id}
              />
            </div>
            <div className="col-span-12 md:col-span-6 flex flex-col gap-y-0.5">
              <>
                <label
                  htmlFor="password"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`password`)}
                </label>
                <div className="relative">
                  <Field
                    {...register("password")}
                    type={showPassword ? "text" : "password"}
                    placeholder={t(`password`)}
                    id="password"
                    className="px-2 h-12 mt-1.5"
                  />
                  {
                    <span
                      className="absolute top-5 right-2 cursor-pointer text-TT-gray-light  "
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {!showPassword ? (
                        <Icon icon="EYE_SLASH" />
                      ) : (
                        <Icon icon="EYE" />
                      )}
                    </span>
                  }
                  {passwordError && (
                    <span
                      id="error_password"
                      data-testid="error_password"
                      className="text-red-500 text-sm"
                      dangerouslySetInnerHTML={{ __html: passwordError }}
                    ></span>
                  )}
                </div>
              </>
            </div>

            <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
              <label
                htmlFor="email"
                className="text-xs uppercase font-semibold"
              >
                {t(`email`)}
              </label>
              <Field
                {...register("email")}
                error={errors?.email && `${errors?.email?.message}`}
                type="text"
                defaultValue={userData?.email}
                placeholder={t(`email`)}
                id="email"
                className="px-2 h-12"
              />
            </div>
            <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
              <label
                htmlFor="phone"
                className="text-xs uppercase font-semibold"
              >
                {t(`phone`)}
              </label>
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange } }) => (
                  <PhoneNumberField
                    value={userData?.phone}
                    placeholder={t("enterphone")}
                    onChange={onChange}
                    error={errors?.phone?.message}
                  />
                )}
              />
              {(errors?.phone as any)?.message && (
                <span className="text-sm text-red-500 mt-2">
                  {t((errors?.phone as any)?.message)}
                </span>
              )}
            </div>
            <div className="col-span-12 flex flex-col gap-2">
              <label
                htmlFor="others"
                className="text-xs uppercase font-semibold"
              >
                {t(`others`)}
              </label>
              <Field
                {...register("others")}
                error={errors?.others && `${errors?.others?.message}`}
                type="textarea"
                rows={3}
                defaultValue={userData?.others}
                placeholder={t(`others`)}
                id="others"
                className="px-2 h-12"
              />
            </div>
            {roles && roles.length ? (
              <div className="col-span-12 flex flex-col gap-2">
                <label
                  htmlFor="Role"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`role`)}
                </label>
                <Controller
                  control={control}
                  name="roles"
                  defaultValue={[]}
                  render={({ field: { value, onChange } }) => (
                    <RoleSelector
                      data={roles}
                      onSelected={(selectedValue: any) => {
                        setSelectedRole(selectedValue.role);
                        onChange([selectedValue]);
                      }}
                      defaultRole={userData?.roles ?? []}
                    />
                  )}
                />
                <label htmlFor="roles_help" className="text-xs ">
                  {t(`roles_help`)}
                </label>
              </div>
            ) : null}

            {hasPermission([Role.SuperAdmin]) && (
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="company"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`company`)}
                </label>
                <Combobox value={selectedCompany} onChange={() => {}}>
                  <div className=" relative ">
                    <div>
                      <Combobox.Input
                        className="z-10 leading-5 relative flex flex-1 w-full rounded-lg p-input bg-white text-gray-700 md:text-base placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-TT-blue focus:border-transparent border border-gray-300 h-10 pl-2.5"
                        onChange={() => {}}
                        onSelect={(event: any) => {
                          setCompanyQuery(event.target.value);
                        }}
                        placeholder={t("companyName")}
                        autoComplete="off"
                      />
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      afterLeave={() => setCompanyQuery("")}
                    >
                      <Combobox.Options className="focus:outline-none mt-1 max-h-60 w-full  absolute overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm z-50">
                        {companyData && companyData?.length === 0 ? (
                          <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                            Nothing found.
                          </div>
                        ) : (
                          companyData?.map((company: any) => (
                            <Combobox.Option
                              key={company}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-5 pr-4 mx-1 rounded-lg ${
                                  active
                                    ? "bg-TT-blue text-white"
                                    : "text-gray-900"
                                }`
                              }
                              value={company}
                              onClick={() => addCompany(company)}
                            >
                              {({ selected, active }) => (
                                <span
                                  id="txt_selectedlocationType"
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {company}
                                </span>
                              )}
                            </Combobox.Option>
                          ))
                        )}
                      </Combobox.Options>
                    </Transition>
                  </div>
                </Combobox>
              </div>
            )}

            {hasPermission([Role.CompanyAdmin]) &&
              selectedRole === "sys_company_location_admin" &&
              (locations && locations.length > 0 ? (
                <LocationDropDown locations={locations} />
              ) : (
                <NoLocationAvailable />
              ))}

            {hasPermission([Role.SuperAdmin]) &&
              selectedRole === "sys_company_location_admin" &&
              (locationsByCompany && locationsByCompany.length > 0 ? (
                <LocationDropDown locations={locationsByCompany} />
              ) : (
                <NoLocationAvailable />
              ))}

            <div className="col-span-12 flex flex-col gap-2">
              <label
                htmlFor="status"
                className="text-xs uppercase font-semibold"
              >
                {t(`status`)}
              </label>
              <Button
                color="primary"
                className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
                form="addUser"
                onClick={() => {
                  handleStatus();
                }}
              >
                {userData?.status ? t("deactivate") : t("activate")}
              </Button>
              <label htmlFor="" className="text-xs ">
                {t(`status_help`)}
              </label>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-2 justify-center">
            <Button
              color="primary"
              className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
              form="addUser"
              submit
            >
              {id ? t("update") : t("addUser")}
            </Button>
          </div>
        </form>
      </div>
    </section>
  );

  function LocationDropDown({ locations }: { locations: LocationModel[] }) {
    return (
      <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
        <label htmlFor="location" className="text-xs uppercase font-semibold">
          {t(`location`)}
        </label>
        <Field
          {...register("location")}
          type="select"
          name="location"
          defaultValue={locationId}
          onChange={(event: any) => {
            setValue("location", event.target.value);
            setLocationId(event.target.value);
          }}
        >
          {locations?.map((location, index: any) => {
            return (
              <option key={index.toString()} value={location?.id}>
                {formatLocation(location)}
              </option>
            );
          })}
        </Field>
      </div>
    );
  }

  function NoLocationAvailable() {
    return (
      <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
        <label htmlFor="location" className="text-xs uppercase font-semibold">
          {t(`location`)}
        </label>
        <div className="pt-2">{t(`noLocationAvailable`)}</div>
      </div>
    );
  }
}

export default AddUser;
