import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Field from "../../Components/base/field/field";
import { Products } from "../../models/products.model";
import { useGetPackCodesQuery } from "../../Services/PackApi";
import { CodeTypes } from "../../models/codeTypes.model";
import { useGetProductByIdQuery } from "../../Services/productApi";
import { useEffect, useState } from "react";
import Button from "../../Components/base/button/button";
import { useGetItemCodesMutation } from "../../Services/qrCodeApi";
import { toastSuccess } from "../../Components/toast";

const PrintQR = ({ product }: { product: Products }) => {
  const { register, handleSubmit, reset } = useForm();
  const { t } = useTranslation();

  const [codeTypes, setCodeTypes] = useState<CodeTypes[]>([]);
  const [QRCodes, setQRCodes] = useState<any[]>([]);
  const [allowGenerate, setAllowGenerate] = useState<boolean>(false);
  const [noOfCodes, setNoOfCodes] = useState<Number>(0);

  const { data: productData, isSuccess: productDataSuccess } =
    useGetProductByIdQuery(product.id);
  const { data: packs, isSuccess: packsSuccess } = useGetPackCodesQuery();
  const [getItemCodes] = useGetItemCodesMutation();

  useEffect(() => {
    if (productData && packs) {
      packs.forEach((pack) => {
        if (pack.packName === productData.code?.packName) {
          setCodeTypes(pack.codes);
        }
      });
    }
  }, [productDataSuccess, packsSuccess, packs, productData]);

  useEffect(() => {
    if (
      noOfCodes &&
      noOfCodes > 0 &&
      noOfCodes !== null
    ) {
      setAllowGenerate(true);
    } else {
      setAllowGenerate(false);
    }
  }, [noOfCodes]);

  function onSubmit(values: any) {
    let args = {
      id: productData?.id,
      total: noOfCodes,
      met: values,
    };

    getItemCodes(args).then((result: any) => {
      if (result.data) {
        setQRCodes(result.data);
        toastSuccess(`${t("qrCodesGeneratedSuccessfully")}`);
      }
    });
  }

  if (QRCodes.length > 0) {
    return (
      <div className="flex flex-col items-center justify-center">
        {QRCodes.map((qrCode, index) => {
          return (
            <div key={index.toString()}>
              <div className="m-2 bg-white py-4">
                <img src={qrCode.qrcode} height={200} width={200} alt='' />
              </div>
              {index !== QRCodes.length - 1 && (
                <div className="w-full bg-black my-1" style={{ height: 1 }} />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id="generateCode">
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold">{product.product_name}</div>
            <div className="flex flex-row justify-center items-center gap-x-2">
              <div>{t("totalQRCodes")}</div>
              <div>
                <Field
                  type="number"
                  defaultValue={0}
                  min={0}
                  onChange={(value: any) =>
                    setNoOfCodes(Number(value.target?.value))
                  }
                  className="px-2 h-12 w-20"
                  name={"noOfCodes"}
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            {product?.code?.codes.map((c, index) => (
              <div className="mb-3" key={index}>
                <label
                  htmlFor={c.code}
                  className="text-xs uppercase font-semibold"
                >
                  {c.code}
                </label>
                <Field
                  {...register(c.code)}
                  type="text"
                  placeholder={c.code}
                  id={c.id}
                  className="px-2 h-12 w-full"
                />
              </div>
            ))}
          </div>
          <div className="flex flex-row justify-end items-center gap-x-5">
            <Button
              color="plain"
              className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
              onClick={() => {
                reset();
              }}
            >
              {t("clear")}
            </Button>
            <Button
              color="primary"
              type={"submit"}
              disabled={!allowGenerate}
              className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
              form="generateCode"
              submit
            >
              {t("generate")}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PrintQR;
