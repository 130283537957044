import { GoogleMap, InfoWindow, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../Components/Spinner';
import { useTraceLocationsHook } from '../../Hooks/TraceHooks';
import { useGetTraceDataQuery } from '../../Services/TraceApi';
import { useAppDispatch } from '../../Store/hooks';
import { setNavbarProps } from "../../Services/utilsReducer";

const MapView = () => {

  const { id, lat, lng, name } = useParams();
  const dispatch = useAppDispatch();

  const {
    data: trace,
    isSuccess: traceSuccess,
    isLoading: traceLoading,
  } = useGetTraceDataQuery(id, { skip: !id });

  const { coordinates, isSuccess: mapLinkSuccess } = useTraceLocationsHook({ trace: trace ? trace[0] : undefined })

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBFZVDMolm9CflfCz-odZfyyZ78nSf5SPY" //TODO get from server side.
  })

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker: any) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map: any) => {
    const bounds = new google.maps.LatLngBounds();
    coordinates.forEach(({ position }) => bounds.extend({ lat: parseFloat(position.lat), lng: parseFloat(position.lng) }));
    map?.fitBounds(bounds);
  };

  const handleSingleLoad = (map: any) => {
    const bounds = new google.maps.LatLngBounds();
    if (lat && lng) {
      bounds.extend({ lat: parseFloat(lat), lng: parseFloat(lng) })
    }
    map?.fitBounds(bounds);
  }

  const polyLineOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.6,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1
  };

  useEffect(() => {
    dispatch(
      setNavbarProps({
        navBarTitle: "locations",
        navBarUserAvatar: true,
        navBarPrevPage: "/trace/locations",
      })
    );
  }, []);

  // Single marker
  if (lat && lng && name) {
    return (
      <>
        <div className="h-full flex flex-col flex-1">
          <div className="h-full mt-[7.6rem] md:m-2 rounded-lg shadow md:w-auto w-screen flex justify-center items-center bg-white overflow-hidden">
            {
              (!isLoaded) ?
                <Spinner className="h-8 w-8" color="#2984FF" />
                :
                <GoogleMap
                  center={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
                  zoom={2}
                  onLoad={handleSingleLoad}
                  onClick={() => setActiveMarker(null)}
                  mapContainerClassName='md:h-full md:w-full h-screen w-screen'
                >
                  <Marker
                    key={1}
                    position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
                    onClick={() => handleActiveMarker(id)}
                  >
                    {activeMarker === id ? (
                      <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div>{name}</div>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                </GoogleMap>
            }
          </div>
        </div>
      </>
    )
  }

  // Multiple markers with path
  return (
    <div className="h-full flex flex-col flex-1">
      <div className="h-full mt-[7.6rem] md:m-2 rounded-lg shadow md:w-auto w-screen flex justify-center items-center bg-white overflow-hidden">
        {
          (!isLoaded || !mapLinkSuccess || !traceSuccess || traceLoading) ?
            <Spinner className="h-8 w-8" color="#2984FF" />
            :
            <GoogleMap
              zoom={3.5}
              onLoad={handleOnLoad}
              onClick={() => setActiveMarker(null)}
              mapContainerClassName='md:h-full md:w-full h-screen w-screen'
            >
              <>
                {coordinates?.map(({ id, name, position }) => (
                  <Marker
                    key={id}
                    position={{ lat: parseFloat(position.lat), lng: parseFloat(position.lng) }}
                    onClick={() => handleActiveMarker(id)}
                  >
                    {activeMarker === id ? (
                      <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div>{name}</div>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                ))}
                <Polyline
                  path={coordinates?.map(({ position }) => ({ lat: parseFloat(position.lat), lng: parseFloat(position.lng) }))}
                  options={polyLineOptions}
                />
              </>
            </GoogleMap>
        }
      </div>
    </div>
  )
}

export default (MapView)