import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import Pagination from "../../Components/Pagination";
import TableFormat from "../../Components/TableFormat";
import SearchAndFilter from "../../Components/TableNavBar/SearchAndFilter";
import { TableNavBar } from "../../Components/TableNavBar/TableNavBar";
import { useAppPermission } from "../../Hooks/AppPermissionHook";
import { FetchDataType, useFetchDataHook } from "../../Hooks/FetchData";
import { Role } from "../../models/userRole.model";
import {
  companiesApi,
  useDeleteCompanyByIdMutation,
  useGetCompaniesByPaginationQuery,
} from "../../Services/companiesApi";
import { useAppDispatch } from "../../Store/hooks";
import { Action, ActionType, ComponentType } from "../Action";

function CompanyTable() {

  const { t } = useTranslation();
  const { pageData, loading, fetchData, isError, isEmpty } = useFetchDataHook(
    useGetCompaniesByPaginationQuery
  );

  const { hasPermission } = useAppPermission();

  const [deleteCompany] = useDeleteCompanyByIdMutation();

  const [updateSearchModel, setUpdateSearchModel] = useState<any>();

  const dispatch = useAppDispatch();

  const fetchInactive = (active: boolean) => {
    fetchData({
      pageSize: pageData?.page.pageSize,
      pageIndex: 0,
      totalElements: 0,
      search: updateSearchModel,
      status: active
    } as FetchDataType);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: t("companyName"),
        accessor: "company_name",
        show: true,
        Cell: ({ row: { original } }: any) => (
          <div className="flex flex-row items-center my-2">
            {original?.images ? <img className="w-11 h-11 rounded-full flex flex-none items-center justify-center object-cover" src={original?.images.imageURL} alt={original?.company_name[0]} /> : <div className="w-11 h-11 rounded-full font-semibold bg-TT-blue text-white flex flex-none items-center justify-center uppercase">{original?.company_name[0]}</div>}
            <div className="flex flex-col pl-4">
              <span>{original?.company_name}</span>
              <span className="text-TT-gray-light lowercase">{original?.email1}</span>
            </div>
          </div>
        ),
      },
      {
        Header: t("gs1_prefix"),
        accessor: "gs1_prefix",
        show: true,
      },
      {
        Header: t("address"),
        accessor: "address1",
        show: true,
        Cell: ({ row: { original } }: any) => (
          <div className="flex flex-row items-center my-2 max-w-lg truncate">
            <span>{original?.address1 ? original?.address1 : original?.address2 ? original?.address2 : ""} {original?.city} {original?.country} {original?.pincode}</span>
          </div>
        ),
      },
      {
        Header: t("phone"),
        accessor: "phone",
        Cell: ({ row: { original } }: any) => (
          <PhoneInput
            inputStyle={{
              height: 45,
              width: "100%",
              borderRadius: 7,
              border: "#C0C0C0",
            }}
            buttonStyle={{
              backgroundColor: "white",
              borderTopLeftRadius: 7,
              borderBottomLeftRadius: 7,
              border: "#C0C0C0",
            }}
            value={original?.phone}
            onChange={() => { }}
            disableDropdown
            disableSearchIcon
            disabled
          />
        ),
      },
      {
        Header: t("action"),
        accessor: "",
        Cell: ({ row: { original } }: any) => (
          <Action
            id={original.id}
            types={
              hasPermission([Role.SuperAdmin])
                ? [ActionType.View, original.status ? ActionType.Delete : ActionType.Restore]
                : []
            }
            deleteFunc={deleteCompany}
            restoreFunc={() => dispatch(companiesApi.endpoints.restoreCompanyById.initiate(original.id)).unwrap()}
            invalidateFunc={() => dispatch(companiesApi.util.invalidateTags(['Companies']))}
            navigation={`${original.id}`}
            title="view History"
            componentType={ComponentType.Button}
          />
        ),
      },
    ], [t]);

  return (
    <TableNavBar
      tableTitle={"companiesList"}
      navLink={"add"}
      buttonTitle={"addNew"}
      pageData={pageData}
      fetchData={fetchData}
      isError={isError}
      isLoading={loading}
      isEmpty={isEmpty}
    >
      <TableNavBar.SearchAndFilter>
        <SearchAndFilter
          type="Companies"
          onSearch={(searchModel) => {
            setUpdateSearchModel(searchModel);
            fetchData({
              pageSize: pageData?.page.pageSize,
              pageIndex: pageData?.page.pageIndex,
              totalElements: pageData?.page.totalElements,
              search: searchModel,
            } as FetchDataType);
          }}
        />
      </TableNavBar.SearchAndFilter>
      <TableNavBar.Pagination>
        {pageData && <Pagination page={pageData.page} fetchData={fetchData} />}
      </TableNavBar.Pagination>
      <TableNavBar.TableFormat>
        {pageData && <TableFormat columns={columns} data={pageData?.data} _query={useGetCompaniesByPaginationQuery} fetchInactive={fetchInactive} />}
      </TableNavBar.TableFormat>
    </TableNavBar>
  );
}

export default CompanyTable;
