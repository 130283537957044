import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { groupScanValidationSchema } from "../../Utils/validation";
import ScanForm from './ScanForm'
import Button from '../../Components/base/button/button';
import { useTranslation } from 'react-i18next';
import { formatDate2, formatTime2 } from '../../Utils/datetime';
import QRScanner from './QRScanner';
import { useAppDispatch } from '../../Store/hooks';
import { productApi } from '../../Services/productApi';
import { toastError, toastSuccess } from '../../Components/toast';
import { Trace } from '../../models/trace.model';
import { useGetCurrentUserQuery } from '../../Services/userApi';
import { useCreateScanPreferenceDataMutation, useCreateTraceDataMutation, useGetScanPreferenceDataQuery } from '../../Services/TraceApi';
import { ScanPreference } from '../../models/scanPreference.model';
import ScanPreferenceComponent from './ScanPreferenceComponent';
import ScanPreferenceHeaderComponent from './ScanPreferenceHeaderComponent';
import Icon from '../../Components/base/icon/icon';

function Group() {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: currentUser } = useGetCurrentUserQuery();
  let trace: Trace = {} as any;

  const { register, reset, handleSubmit, formState: { errors }, getValues, setValue, watch } = useForm({ resolver: yupResolver(groupScanValidationSchema) });
  const [sendTrace] = useCreateTraceDataMutation();
  const { data: scanPreference } = useGetScanPreferenceDataQuery();
  const [createScanPreference] = useCreateScanPreferenceDataMutation();

  const [parentProduct, setParentProduct] = useState<any>();
  const [childProduct, setChildProduct] = useState<any[]>([]);
  const [isParentProduct, setIsParentProduct] = useState<boolean>(false);
  const [parentProductCode, setParentProductCode] = useState<any>();
  const [childProductCode, setChildProductCode] = useState<any[]>([]);

  function getDuplicate(jsonString: string) {
    let duplicateCount: number = 0;
    if (scanPreference?.length && scanPreference?.length > 0) {
      scanPreference?.forEach((pref: ScanPreference) => {
        if (jsonString === pref.scanPreference) {
          duplicateCount++;
        }
      });
      if (duplicateCount >= 1) {
        return false;
      } else {
        return true;
      }
    }
  }

  async function scanPreferenceData() {
    if (currentUser && (getValues().traceStatus || getValues().qrType || getValues().maskType || getValues().scanType || getValues().lifeSpan)) {
      let jsonString = JSON.stringify(getValues());
      if (scanPreference?.length && scanPreference?.length > 0) {
        if (getDuplicate(jsonString)) {
          let preference: ScanPreference = {} as any;
          preference.scanPreference = jsonString;
          preference.user = currentUser;
          await createScanPreference(preference);
        }
      } else if (!scanPreference?.length) {
        let preference: ScanPreference = {} as any;
        preference.scanPreference = jsonString;
        preference.user = currentUser;
        await createScanPreference(preference);
      }
    }
  }

  const handleUpdate = (result: any) => {
    if (result) {
      const scanData = JSON.parse(result.data);
      const gtin = scanData.pc;
      // Pack
      if (!gtin) {
        if (isParentProduct) {
          setParentProductCode(scanData.c);
          toastSuccess(t('packScannedSuccessfully'));
          return setParentProduct(scanData);
        }
      }

      //Product
      dispatch(productApi.endpoints.getProductByGtin.initiate(gtin)).then((value: any) => {
        if (isParentProduct) {
          setParentProductCode(scanData.c);
          toastSuccess(t('packScannedSuccessfully'));
          return setParentProduct(value.data);
        }
        if (!childProductCode.includes(scanData.c)) {
          setChildProductCode([...childProductCode, scanData.c]);
          toastSuccess(t('productScannedSuccessfully'));
          return setChildProduct([...childProduct, value.data]);

        } else {
          toastError(t('ProductAlreadyAdded'))
        }
      });
    }
  }

  const onSubmit = () => {
    trace.code = parentProductCode;
    trace.productCode = parentProduct?.gtin;
    trace.locationToken = currentUser?.location?.locationToken ?? "";
    trace.items = childProductCode;
    trace.traceStatus = getValues().traceStatus;
    trace.others = getValues().others;
    trace.subProductType = getValues().subProductType;
    trace.qrCodeType = getValues().qrType;
    trace.maskType = getValues().maskType;
    trace.scanType = getValues().scanType;
    trace.blockChain_lifespan = Number(getValues().lifeSpan);
    sendTrace(trace).then((res: any) => {
      if (res) {
        if (res.data) {
          toastSuccess(t('traceCreatedSuccessfully'));
          reset();
          setParentProductCode(undefined);
          setChildProductCode([]);
          setChildProduct([]);
          setParentProduct(undefined);
        }
        if (res.error) {
          toastError(res.error.data.message);
        }
      }
    });
  }

  const ScanResult = () => {

    return (
      <>
        {(parentProduct || childProductCode.length > 0) &&
          <>
            <div className="font-semibold text-center">{t(`scanned`)}</div>
            <div className="w-full p-2 px-5 shadow rounded-lg flex gap-x-4 flex-col">
              {parentProduct &&
                <ScanItemCard product={parentProduct} productCode={parentProductCode} />
              }
              {childProductCode.length > 0 &&
                <div className="w-full border-l border-TT-blue mt-3 ml-8 max-h-64 overflow-auto overflow-x-hidden">
                  <div className="p-5">
                    <div className='flex flex-col gap-y-5'>
                      {childProduct && childProduct.map((children: any, index: number) => (
                        <ScanItemCard product={children} productCode={childProductCode[index]} key={index} />
                      ))}
                    </div>
                  </div>
                </div>
              }
            </div>
          </>
        }
      </>
    )
  }

  return (
    <div className="flex flex-1">
      <div className="flex flex-col" style={{ flex: 2 }}>
        <div className="flex flex-1">
          <div className="w-screen md:w-full p-5 md:pb-24 lg:pb-0 order-2 md:order-1">
            <ScanForm watch={watch} errors={errors} register={register} handleSubmit={handleSubmit} onSubmit={onSubmit} />
            <div className='flex flex-row justify-between items-center'>
              <div className="flex items-center my-5 ">
                <input type="checkbox" id="pack" className="mr-2 w-5 h-5 accent-TT-blue" onChange={(e: any) => setIsParentProduct(e.target.checked)} />
                <label htmlFor="pack" className="text-xs md:text-sm font-semibold">
                  {t('ScanAsAPack')}
                </label>
              </div>
              <div className="flex flex-row-reverse mt-4">
                <Button color='primary' className="py-1 md:w-fit text-white h-8 md:h-fit w-full mx-0" onClick={() => { scanPreferenceData(); }}>{t('savePreference')}</Button>
              </div>
            </div>
            <div className='flex flex-col md:flex-row gap-x-10 gap-y-2 mt-5 justify-center'>
              <Button color='primary' form='scanForm' className="py-1 md:w-fit text-white h-8 md:h-fit w-full mx-0" submit>{t('send')}</Button>
            </div>
          </div>
        </div>
        <div className="flex flex-1 min-w-full">
          {scanPreference?.length && scanPreference?.length > 0 ?
            <div className="mx-4 min-w-full">
              <ScanPreferenceHeaderComponent />
              <ScanPreferenceComponent setValue={setValue} />
            </div>
            : <></>}
        </div>
      </div>
      <div className="w-full lg:w-2/6 bg-TT-blue/10 rounded-lg md:m-2 p-5 flex flex-1 items-center flex-col gap-5 order-1 md:order-2">
        <QRScanner handleUpdate={handleUpdate} />
        <div className="my-5">
          <ScanResult />
        </div>
      </div>
    </div>
  )
}

function ScanItemCard({ product, productCode }: { product: any, productCode: string }) {
  const { t } = useTranslation();

  return (
    <div className="flex gap-x-3 items-center">
      {product?.gtin ? <img src={product?.images[0]?.imageURL} alt={product?.gtin} className='rounded-full w-16 h-16 border' />
        : <Icon icon="ITEM_CART" className="w-16 h-16" />}
      <div className="flex flex-col font-semibold">
        {product?.gtin ? <span>{product?.product_name}</span> : <span>{t('pack')}</span>}
        <div className='flex gap-x-3 text-xs '>
          <span>{formatDate2(new Date().toISOString())}</span>
          <span>{formatTime2(new Date().toISOString())}</span>
        </div>
        <span className="text-gray-500">{productCode}</span>
      </div>
    </div>
  );
}

export default Group