import React from "react";
import { useTranslation } from "react-i18next";
import CustomPopover from "../../../Components/base/CustomPopover";
import Pagination from "../../../Components/Pagination";
import TableFormat from "../../../Components/TableFormat";
import SearchAndFilter from "../../../Components/TableNavBar/SearchAndFilter";
import { TableNavBar } from "../../../Components/TableNavBar/TableNavBar";
import { useBlockLifeSpanConfigHook } from "../../../Hooks/BlockLifeSpanConfigHooks";
import { useGetBlockLifeSpanByPaginationQuery } from "../../../Services/blockLifespanTypesApi";

function ConfigBlockLifespan() {

  const { t } = useTranslation();
  const {
    pageData,
    loading,
    updateDefaultCodesConfig,
    getDefaultCodesConfig,
    fetchData,
    isError,
    isEmpty
  } = useBlockLifeSpanConfigHook();

  const columns = React.useMemo(
    () => [
      {
        Header: t("prefix"),
        accessor: "prefix",
      },
      {
        Header: t("type"),
        accessor: "type",
      },
      {
        Header: t("lifeSpan"),
        accessor: "lifespan",
      },
      {
        Header: t("default"),
        accessor: "",
        Cell: ({ row: { original } }: any) => (
          <div
            className="w-5 h-5 rounded-full relative border-2 border-TT-blue"
            onClick={() => {
              updateDefaultCodesConfig({ blockLifespanType: original });
            }}
          >
            <div
              className={`absolute w-3 h-3 rounded-full  inset-0.5 ${
                original?.id ===
                  (getDefaultCodesConfig as any)?.blockLifespanType?.id &&
                "bg-TT-blue"
              }`}
            ></div>
          </div>
        ),
      },
    ], [getDefaultCodesConfig, t]);

  return (
    <TableNavBar
      tableTitle={"config"}
      pageData={pageData}
      fetchData={fetchData}
      isError={isError}
      isLoading={loading}
      isEmpty={isEmpty}
    >
      <TableNavBar.SearchAndFilter>
        <SearchAndFilter />
      </TableNavBar.SearchAndFilter>
      <TableNavBar.Pagination>
        {pageData && <Pagination page={pageData?.page} fetchData={fetchData} />}
      </TableNavBar.Pagination>
      <TableNavBar.TableFormat>
        {pageData && <TableFormat columns={columns} data={pageData?.data} _query={useGetBlockLifeSpanByPaginationQuery} />}
      </TableNavBar.TableFormat>
    </TableNavBar>
  );
}

export default ConfigBlockLifespan;
