import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changePreferenceLanguage } from "../Services/userReducer";

export const useChangeLanguageHook = () => {

    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    const changeLang = (language: string) => {
        dispatch(changePreferenceLanguage(language));
        i18n.changeLanguage(language)
    }
    return { changeLang };
} 