import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useTranslationHook } from './Hooks/Translations';
import LanguageDetector from 'i18next-browser-languagedetector'
import { Provider } from 'react-redux';
import ScrollToTop from './Components/ScrollToTop';
import SignIn from './Containers/SignIn';
import { persistor, store } from './Store';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'react-hot-toast';
import Track from './Containers/Track';
import Home from './Containers/Home';
import Location from './Containers/Location/Location';
import ApiToken from './Containers/ApiToken';
import Sidebar from './Containers/Sidebar';
import CompanyTable from './Containers/Company/CompanyTable';
import UsersTable from './Containers/Users/UsersTable';
import PageNotFound from './Components/PageNotFound';
import Explorer from './Containers/Explorer/Explorer';
import Status from './Containers/Explorer/Status';
import ChainCodes from './Containers/Explorer/ChainCodes';
import Channels from './Containers/Explorer/Channels';
import Blocks from './Containers/Explorer/Blocks';
import Network from './Containers/Explorer/Network';
import Profile from './Containers/Profile/Profile';
import ChangePassword from './Containers/Profile/ChangePassword';
import AddLocation from './Containers/Location/AddLocation';
import Customers from './Containers/Customers/Customers';
import AddCustomer from './Containers/Customers/AddCustomer';
import TransactionScreen from './Containers/Transaction/Transaction';
import Types from './Containers/Types/Types';
import LocationType from './Containers/Types/Location/LocationType';
import CompanyType from './Containers/Types/Company/CompanyType';
import Codes from './Containers/Types/Codes/Codes';
import BlockLifespan from './Containers/Types/BlockLifespan/BlockLifespan';
import AddLocationType from './Containers/Types/Location/AddLocationType';
import AddCompanyType from './Containers/Types/Company/AddCompanyType';
import AddCodes from './Containers/Types/Codes/AddCodes';
import AddBlockLifespan from './Containers/Types/BlockLifespan/AddBlockLifespan';
import Pricing from './Containers/Pricing/Pricing';
import Products from './Containers/Products/Products';
import Masking from './Containers/Products/Masking';
import ProductInfo from './Containers/Products/ProductInfo';
import EditProduct from './Containers/Products/EditProduct';
import Images from './Containers/Products/Images';
import Scan from './Containers/Scan/Scan';
import Individual from './Containers/Scan/Individual';
import Group from './Containers/Scan/Group';
import Trace from './Containers/Trace/Trace';
import VerificationList from './Containers/VerificationList/VerificationList';
import Config from './Containers/Config/Config';
import DeliveryPath from './Containers/Config/DeliveryPath/DeliveryPath';
import Code from './Containers/Config/Code/Code';
import Block from './Containers/Config/Block/Block';
import Email from './Containers/Config/Email/Email';
import APITokens from './Containers/Config/APITokens/APITokens';
import ConfigBlockLifespan from './Containers/Config/BlockLifespan/ConfigBlockLifespan';
import AuthVerify from './Containers/AuthVerify';
import EditCompany from './Containers/Company/EditCompany';
import AddPricing from './Containers/Pricing/AddPricing';
import AddDeliveryPath from './Containers/Config/DeliveryPath/AddDeliveryPath';
import AddCode from './Containers/Config/Code/AddCode';
import AddBlock from './Containers/Config/Block/AddBlock';
import AddEmail from './Containers/Config/Email/AddEmail';
import AddUser from './Containers/Users/AddUser';
import ProfileLocationEdit from './Containers/Profile/ProfileLocationEdit';
import ProductDeliveryPath from './Containers/Products/ProductDeliveryPath';
import ProductBlockConfig from './Containers/Products/ProductBlockConfig';
import ProductCode from './Containers/Products/ProductCode';
import BlockDetails from './Containers/Trace/BlockDetails';
import MapView from './Containers/Trace/MapView';
import Version from './Containers/Version';

function AppLoginLoader() {
  useTranslationHook(LanguageDetector)

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/t/:id" element={<Trace />} />
          <Route path="/" element={<SignIn />} />
          <Route element={<Sidebar />} >
            <Route path="company" element={<CompanyTable />} />
            <Route path="company/add" element={<EditCompany />} />
            <Route path="company/:id" element={<EditCompany />} />
            <Route path="users" element={<UsersTable />} />
            <Route path="users/add" element={<AddUser />} />
            <Route path="users/:id" element={<AddUser />} />
            <Route path="explorer" element={<Explorer />}>
              <Route path="status" element={<Status />} />
              <Route path="network" element={<Network />} />
              <Route path="chaincode" element={<ChainCodes />} />
              <Route path="channels" element={<Channels />} />
              <Route path="blocks" element={<Blocks />} />
            </Route>
            <Route path="profile" element={<Profile />} />
            <Route path="locations/:id" element={<ProfileLocationEdit />} />
            <Route path="changePassword" element={<ChangePassword />} />
            <Route path="location" element={<Location />} />
            <Route path="location/add" element={<AddLocation />} />
            <Route path="location/:id" element={<AddLocation />} />
            <Route path="customers" element={<Customers />} />
            <Route path="customers/add" element={<AddCustomer />} />
            <Route path="customers/:id" element={<AddCustomer />} />
            <Route path="transaction" element={<TransactionScreen />} />
            <Route path="products" element={<Products />} />
            <Route path="products" element={<EditProduct />} >
              <Route path="productInfo" element={<ProductInfo />} />
              <Route path=":id/productInfo" element={<ProductInfo />} />
              <Route path="images" element={<Images />} />
              <Route path=":id/images" element={<Images />} />
              <Route path="deliveryPath" element={<ProductDeliveryPath />} />
              <Route path=":id/deliveryPath" element={<ProductDeliveryPath />} />
              <Route path="blockConfig" element={<ProductBlockConfig />} />
              <Route path=":id/blockConfig" element={<ProductBlockConfig />} />
              <Route path="productCode" element={<ProductCode />} />
              <Route path=":id/productCode" element={<ProductCode />} />
              <Route path="masking" element={<Masking />} />
              <Route path=":id/masking" element={<Masking />} />
            </Route>


            <Route path="types" element={<Types />}>
              <Route path="locationType" element={<LocationType />} />
              <Route path="locationType/add" element={<AddLocationType />} />
              <Route path="locationType/:id" element={<AddLocationType />} />
              <Route path="companyType" element={<CompanyType />} />
              <Route path="companyType/add" element={<AddCompanyType />} />
              <Route path="companyType/:id" element={<AddCompanyType />} />
              <Route path="codes" element={<Codes />} />
              <Route path="codes/add" element={<AddCodes />} />
              <Route path="codes/:id" element={<AddCodes />} />
              <Route path="blockLifespan" element={<BlockLifespan />} />
              <Route path="blockLifespan/add" element={<AddBlockLifespan />} />
              <Route path="blockLifespan/:id" element={<AddBlockLifespan />} />
            </Route>


            <Route path="pricing" element={<Pricing />} />
            <Route path="pricing/add" element={<AddPricing />} />
            <Route path="pricing/:id" element={<AddPricing />} />
            <Route path="scan" element={<Scan />} >
              <Route path="individual" element={<Individual />} />
              <Route path="group" element={<Group />} />
            </Route>
            <Route path="trace/:id" element={<Trace />} />
            <Route path="trace/block/:blockId/:channel/:address" element={<BlockDetails />} />
            <Route path="trace/locations/:id" element={<MapView />} />
            <Route path="trace/location/:name/:lat/:lng" element={<MapView />} />
            <Route path="verificationList" element={<VerificationList />} />
            <Route path="config" element={<Config />} >
              <Route path="deliveryPath" element={<DeliveryPath />} />
              <Route path="deliveryPath/add" element={<AddDeliveryPath />} />
              <Route path="deliveryPath/:id" element={<AddDeliveryPath />} />
              <Route path="codes" element={<Code />} />
              <Route path="codes/add" element={<AddCode />} />
              <Route path="codes/:id" element={<AddCode />} />
              <Route path="block" element={<Block />} />
              <Route path="block/add" element={<AddBlock />} />
              <Route path="block/:id" element={<AddBlock />} />
              <Route path="email" element={<Email />} />
              <Route path="email/add" element={<AddEmail />} />
              <Route path="email/:id" element={<AddEmail />} />
              <Route path="APITokens" element={<APITokens />} />
              <Route path="blockLifespan" element={<ConfigBlockLifespan />} />
            </Route>
          </Route>
          <Route path="/t" element={<Track />} />
          <Route path="/home" element={<Home />} />
          <Route path="/location" element={<Location />} />
          <Route path="/apiToken" element={<ApiToken />} />
          <Route path="version" element={<Version />} />
        </Routes>
        <AuthVerify />
      </BrowserRouter>
    </>
  )
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Toaster />
        <AppLoginLoader />
      </PersistGate>
    </Provider>
  )
}

export default App
