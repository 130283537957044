import { useAppPermission } from './AppPermissionHook';
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from "../Services/loginApi";
import { changeToken, getToken } from "../Services/tokenReducer";
import { changeLogin, changePreferenceLanguage, getLanguage, getLogin } from "../Services/userReducer";
import { store } from "../Store";
import { loginFormValidationSchema } from "../Utils/validation";
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from "react-i18next";

export const useSignInHook = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation()

    const [navigateScreen, setNavigateScreen] = useState("");
    const [serverError, setServerError] = useState("");

    const loginData = useSelector(getLogin);
    const token = useSelector(getToken);
    let currentLanguage = getLanguage(store.getState());

    const [login, { isLoading: isLoggingIn }] = useLoginMutation();

    const { initialRoute } = useAppPermission()
    const { control, register, handleSubmit, formState: { errors }, watch } = useForm({ resolver: yupResolver(loginFormValidationSchema) })

    useEffect(() => {
        if (watch('email') && watch('password')) {
            setServerError('')
        }
    }, [watch, watch('email'), watch('password')])


    useEffect(() => {
        setNavigateScreen(initialRoute)
    },
        [initialRoute])

    const traceView = () => {
        setNavigateScreen('/t');
    }

    const changeLang = (language: string) => {
        dispatch(changePreferenceLanguage(language));
        i18n.changeLanguage(language)
    }

    const onSubmit = (values: any) => {
        if (serverError) {
            setServerError('');
        }

        const model = {} as any;
        model.username = values.email.toLowerCase();
        model.password = values.password;

        login(model).then((value: any) => {
            if (value.data) {
                dispatch(changeToken(value.data.token));
                dispatch(changeLogin(value.data));
            } else {
                setServerError('incorrectUsernameOrPassword')
            }
        })
    }

    const isLoggedIn = loginData && token;

    return { control, navigateScreen, traceView, currentLanguage, changeLang, handleSubmit, onSubmit, serverError, register, errors, isLoggedIn, isLoggingIn };
}


