import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import Icon from './base/icon/icon'
import { yupResolver } from '@hookform/resolvers/yup';
import { traceCodeValidationSchema } from "../Utils/validation";
import LanguageDropdown from './base/LanguageDropdown'
import { useAppSelector } from '../Store/hooks'
import { getLogin } from '../Services/userReducer'
import { useAppPermission } from '../Hooks/AppPermissionHook'
import Button from './base/button/button'
import logo from '../Assets/images/tt-logo.svg'
import QRScanModal from './QRScanModal'
import { getNavbarUserAvatar, getNavbarPrevPage, getNavbarTitle } from '../Services/utilsReducer'

function Navbar({  }: { title?: string, prevPage?: string, userAvatar?: boolean }) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isTablet, setTablet] = useState(false);
    const [showQRModal, setShowQRModal] = useState<boolean>(false)

    const currentUser = useAppSelector(getLogin)?.user;
    const title       = useAppSelector(getNavbarTitle);
    const prevPage    = useAppSelector(getNavbarPrevPage);
    const userAvatar  = useAppSelector(getNavbarUserAvatar);

    const { isUserLoggedIn } = useAppPermission()

    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(traceCodeValidationSchema) })

    
    useEffect(() => {
        setTablet(window.innerWidth >= 768 ? true : false);
    }, [window.innerWidth])


    const onSubmit = (values: any) => {
        navigate(`/trace/${values.traceCode}`)
    }

    const getInitials = () => {
        return `${currentUser?.firstName[0]}${currentUser?.lastName[0]}`
    }

    if (!isUserLoggedIn()) {
        return (
            <div className="justify-between items-center h-16 hidden md:flex py-4 bg-white px-7">
                <div><img src={logo} alt="TT" className='w-10 h-10 mx-auto' /></div>
                <Button color='primary' className="  py-1 md:w-fit h-12 md:h-fit w-full mx-0" onClick={() => { navigate("/") }}>
                    <div className="flex flex-row items-center justify-center gap-x-2 ">
                        <Icon icon='LEFT' size='x-small' />
                        {t('login')}
                    </div>
                </Button>

            </div>
        )
    }

    return (
        <>
            {isTablet &&
                <section className="justify-between items-center h-16 hidden md:flex py-4 bg-white px-5">
                    <div className="flex flex-row items-center text-lg gap-x-2">
                        {prevPage && <div className="flex items-center hover:bg-TT-background cursor-pointer p-1.5 rounded-full" onClick={() => { navigate(-1) }}>
                            <Icon icon='LEFT' />
                        </div>}
                        <span className="font-bold">{t(title)}</span>
                    </div>
                    <div className='flex items-center gap-x-5 lg:gap-x-8 w-fit shrink-0 grow-0'>
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="relative lg:w-96 w-60">
                                    <button type="submit" className="absolute top-2.5 right-2.5  cursor-pointer text-TT-gray-light z-20 " onClick={() => console.log("first")}><Icon icon='SEARCH' /></button>
                                    <input {...register('traceCode')} placeholder={t('SearchOrScanTrace')} type='text' className={`w-full h-10  rounded-lg z-0 focus:outline-none border-TT-ash focus:border-TT-blue border text-sm px-10 ${errors?.traceCode?.message && 'border-red-500 focus:outline-none ring-red-500'}`} />
                                    <div className="absolute left-2 top-2.5 h-fit  cursor-pointer text-TT-gray-light" onClick={() => setShowQRModal(true)}><Icon icon='CODE' /></div>
                                </div>
                            </form>
                        </div>
                        <div className="flex flex-row items-center gap-x-2">
                            <LanguageDropdown />
                        </div>
                        <div className="flex flex-row text-TT-gray-light items-center justify-start w-full cursor-pointer gap-x-2" >
                            <NavLink to='/profile' className=''>
                                <div className="w-11 h-11 rounded-full font-semibold bg-TT-blue text-white flex flex-none items-center justify-center uppercase ">{getInitials()}</div>
                            </NavLink>
                        </div>
                    </div>
                </section>
            }
            {!isTablet &&
                <div className="relative">
                    <div className={`block md:hidden w-screen h-fit bg-TT-background fixed top-0 z-30 ${prevPage && 'z-40'} p-5`}>
                        <div className={`flex ${userAvatar ? `justify-between` : 'justify-center gap-x-3'} items-center z-50`}>
                            <div className={`font-semibold ${prevPage && 'ml-0'}`} >{prevPage ?
                                <div onClick={() => navigate(-1)} className="absolute left-5 top-6">
                                    <Icon icon='LEFT' />
                                </div> : <Icon icon="MENU" />}</div>
                            <div className='font-semibold text-lg text-center'>{t(title)}</div>
                            {userAvatar && <NavLink to='/profile' ><div className='h-8 w-8 rounded-full bg-TT-blue flex justify-center items-center text-white font-bold uppercase'>{getInitials()}</div></NavLink>}
                        </div>
                        <div className='mt-3'>
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="relative w-full">
                                    <button type="submit" className="absolute top-2.5 right-2.5  cursor-pointer text-TT-gray-light z-20 " ><Icon icon='SEARCH' /></button>
                                    <input {...register('traceCode')} placeholder={t('SearchOrScanTrace')} type='text' className={`w-full h-10  rounded-lg z-0 focus:outline-none border-TT-ash focus:border-TT-blue border text-sm px-10 ${errors?.traceCode?.message && 'border-red-500 focus:outline-none ring-red-500'}`} />
                                    <div className="absolute left-2 top-2.5 h-fit  cursor-pointer text-TT-gray-light" onClick={() => setShowQRModal(true)}><Icon icon='CODE' /></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
            <QRScanModal isOpen={showQRModal} setIsOpen={setShowQRModal} />
        </>
    )
}

export default Navbar