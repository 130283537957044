import { allApis } from './allApi';
import { Customer } from '../models/customer.model';
import { PagedData } from '../models/pagedData.model';

export const customersApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getCustomers: builder.query<Customer[], void>({
            query: () => "api/customer/getAll/page",
            providesTags: ["Customer"],
        }),
        getCustomersByPagination: builder.query<PagedData<Customer[]>, any>({
            query: (args) => {
                return {
                    url: "api/customer/getAll/page",
                    params: args
                }
            },
            providesTags: ["Customer"],
        }),
        getCustomersById: builder.query<Customer, any>({
            query: (id: string) => `api/customer/${id}`,
            providesTags: ["Customer"],
        }),
        updateCustomersById: builder.mutation<Customer, Partial<Customer>>({
            query: (customer) => {
                return {
                    url: `api/customer/${customer.id}`,
                    method: "PUT",
                    body: customer,
                }
            },
            invalidatesTags: ['Customer']
        }),
        deleteCustomersById: builder.mutation<any, any>({
            query: (id) => {
                return {
                    url: `api/customer/${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ['Customer']
        }),
        restoreCustomerById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `api/customer/restore/${id}`,
                }
            },
            providesTags: ['Customer']
        }),
        createCustomers: builder.mutation<Customer, Partial<Customer>>({
            query: (customer) => {
                return {
                    url: `api/customer`,
                    method: "POST",
                    body: customer,
                }
            },
            invalidatesTags: ['Customer']
        }),
    }),

});

export const { useGetCustomersQuery, useGetCustomersByPaginationQuery, useGetCustomersByIdQuery, useUpdateCustomersByIdMutation, useDeleteCustomersByIdMutation, useCreateCustomersMutation, useRestoreCustomerByIdQuery } = customersApi;
