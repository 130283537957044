import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../Components/base/button/button";
import Field from "../../Components/base/field/field";
import Navbar from "../../Components/Navbar";
import { useProfileLocationEditHook } from "../../Hooks/ProfileLocationEditHook";
import { v4 } from "uuid";
import Icon from "../../Components/base/icon/icon";
import { useEffect } from "react";
import { toastError, toastSuccess } from "../../Components/toast";
import Spinner from "../../Components/Spinner";

const ProfileLocationEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate()

  const {
    locationData,
    locationDataLoading,
    handleSubmit,
    onSubmit,
    regenerateToken,
    register,
    errors,
    locationTypesLoading,
    locationTypeList,
    generatingToken,
    updateSuccess,
    navigateBack,
    tokenGenSuccess
  } = useProfileLocationEditHook({ id: id });

  useEffect(() => {
    if (updateSuccess === true && navigateBack) {
      toastSuccess(t("updatedSuccessfully"));
      navigate(-1);
    } else if (updateSuccess === false && !navigateBack) {
      toastError("updateFailed");
    } else if (tokenGenSuccess === true) {
      toastSuccess(t("updatedSuccessfully"));
    } else if (tokenGenSuccess === false) {
      toastError("updateFailed");
    }
  }, [navigateBack, updateSuccess, tokenGenSuccess]);

  if (locationDataLoading || locationTypesLoading || !locationData) {
    return (
      <div
        className={`md:h-full h-screen flex justify-center items-center bg-white`}
      >
        <Spinner className="h-8 w-8" color="#2984FF" />
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      <section className="bg-white mt-[7.6rem] m-0 md:m-2 rounded-lg h-full overflow-auto pb-24">
        <div className="w-screen md:w-full lg:w-3/5 p-5 md:pb-24 lg:pb-0">
          <form id="editLocation" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="locationType"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`locationType`)}
                </label>
                <Field
                  {...register("locationType")}
                  error={errors?.locationType && `${errors?.locationType?.message}`}
                  type="select"
                  defaultValue={locationData?.locationType?.id}
                  placeholder={t(`locationType`)}
                  id="locationType"
                  className="px-2 h-12"
                >
                  {locationTypeList &&
                    locationTypeList?.map((locationType, index) => (
                      <option value={locationType?.id} key={index.toString()}>
                        {locationType?.locationType}
                      </option>
                    ))}
                </Field>
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="locationKey"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`locationKey`)}
                </label>
                <Field
                  {...register("locationKey")}
                  error={errors?.locationKey && `${errors?.locationKey?.message}`}
                  type="text"
                  defaultValue={locationData?.locationKey}
                  placeholder={t(`locationKey`)}
                  id="locationKey"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="locationName"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`locationName`)}
                </label>
                <Field
                  {...register("locationName")}
                  error={errors?.locationName && `${errors?.locationName?.message}`}
                  type="text"
                  defaultValue={locationData?.location}
                  placeholder={t(`locationName`)}
                  id="locationName"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="locationToken"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`locationToken`)}
                </label>
                <div className="relative">
                  <Field
                    disabled
                    {...register("locationToken")}
                    error={errors?.locationToken && `${errors?.locationToken?.message}`}
                    type="text"
                    defaultValue={locationData?.locationToken}
                    placeholder={t(`locationToken`)}
                    id="locationToken"
                    className="px-2 h-12 pr-12"
                  />
                  <span
                    className={`absolute top-4 right-3  cursor-pointer text-TT-blue ${generatingToken ? 'animate-spin ease-in-out' : ''}`}
                    onClick={() => {
                      regenerateToken({ token: v4() });
                    }}
                  >
                    <Icon icon="RELOAD" />
                  </span>
                </div>
              </div>
              <div className="col-span-12 flex flex-col gap-2">
                <label
                  htmlFor="locationDetails"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`locationDetails`)}
                </label>
                <Field
                  {...register("locationDetails")}
                  error={errors?.locationDetails && `${errors?.locationDetails?.message}`}
                  type="textarea"
                  rows={3}
                  defaultValue={locationData?.locationInfo}
                  placeholder={t(`locationDetails`)}
                  id="locationDetails"
                  className="px-2 h-12"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-2 justify-center">
              <Button
                color="primary"
                className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
                form="editLocation"
                submit
              >
                {t("update")}
              </Button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ProfileLocationEdit;
