import { format, isEqual } from 'date-fns';
// import { formatToTimeZone } from 'date-fns-timezone'
import { isSameSecond, isAfter, isBefore } from 'date-fns'

export const sameOrBefore = (d1 = new Date(), d2 = new Date()) => {
  return isSameSecond(d1, d2) ? true : isBefore(d1, d2) ? true : false
}

export const sameOrAfter = (d1 = new Date(), d2 = new Date()) => {
  return isSameSecond(d1, d2) ? true : isAfter(d1, d2) ? true : false
}

export const isRangeBetween = (
  [r1a, r1b] = [new Date(), new Date()],
  [r2a, r2b] = [new Date(), new Date()],
  equalCounts = true,
) => {
  return equalCounts
    ? sameOrBefore(r2a, r1a) && sameOrAfter(r2b, r1b)
    : isBefore(r2a, r1a) && isAfter(r2b, r1b)
}

export const isBetween = (date: Date, from: Date, to: Date, isBeforeEqual = false, isAfterEqual = false) => {
  return (isBeforeEqual ? (isEqual(from, date) || isBefore(from, date)) : isBefore(from, date)) &&
    (isAfterEqual ? (isEqual(to, date) || isAfter(to, date)) : isAfter(to, date));
};

/// converting to toISOString
export function getUTCDateTime(timezoneUtc: string, date: Date) {
  const toUtc = format(date, "yyyy-MM-dd'T'HH:mm:ss") + timezoneUtc;
  return new Date(toUtc).toISOString();
}

/**
 * 
 * @param startDate  example "2021-10-23"
 * @param startTime  example "10:50" or "1:5"
 * @param endDate 
 * @param endTime 
 * @param timezone  "Asia/Tokyo"
 * @returns 
 */
//  export function durationCheck( timezone: string, startDate: string, startTime: string, endDate: string, endTime: string) {
//   startDate = format(new Date(startDate), "yyyy-MM-dd");
//   endDate = format(new Date(endDate), "yyyy-MM-dd");
//   const startTimeArray = startTime.split(":");
//   const startTimeUTC = getUTCDateTime(timezone, startDate, Number(startTimeArray[0]), Number(startTimeArray[1]));

//   const endTimeArray = endTime.split(":");
//   const endTimeUTC = getUTCDateTime(timezone, endDate, Number(endTimeArray[0]), Number(endTimeArray[1]));

//   return (endTimeUTC.getTime() - startTimeUTC.getTime()) / 60000;
// }

/**
 * 
 * @param dateTimeUTC  example "2021-12-08T02:59:55.333Z"
//  * @param timezone  "Asia/Tokyo"
 * @returns formattedDate Dec 08, 2021
 * 
 * https://github.com/prantlf/date-fns-timezone/blob/HEAD/docs/API.md#api-reference
 */

export function formatDate(dateTimeUTC?: string) {
  if (!dateTimeUTC) {
    return '';
  }
  return format(new Date(dateTimeUTC), "yyyy-MM-dd")
}
export function formatDatecalender(dateTimeUTC?: string) {
  if (!dateTimeUTC) {
    return '';
  }
  return format(new Date(dateTimeUTC), "yyyy-MM-dd")
}

export function formatDate2(dateTimeUTC?: string) {
  if (!dateTimeUTC) {
    return '';
  }
  return format(new Date(dateTimeUTC), "MMM dd, yyyy")

}

export function formatMonthDate(dateTimeUTC?: string) {
  if (!dateTimeUTC) {
    return '';
  }
  return format(new Date(dateTimeUTC), "MMM D")
}

/**
 * 
 * @param dateTimeUTC  example "2021-12-08T02:59:55.333Z"
 * @param timezone  "Asia/Tokyo"
 * @returns formattedTime 11:59 AM
 */

export function formatTime(dateTimeUTC?: string) {
  if (!dateTimeUTC) {
    return '';
  }
  return format(new Date(dateTimeUTC), "H:mm")
}

export function formatTime2(dateTimeUTC?: string) {
  if (!dateTimeUTC) {
    return '';
  }
  return format(new Date(dateTimeUTC), "hh:mm:ss a")
}

/**
 * 
 * @param dateTimeUTC  example "2021-12-08T02:59:55.333Z"
 * @param timezone  "Asia/Tokyo"
 * @returns formattedDateTime 08/12/2021 at 11:59 AM
 */

export function formatDateTime(dateTimeUTC: string) {
  return format(new Date(dateTimeUTC), "dd/MM/yyyy 'at' h:mm a")
}

export function formatDateTimeFull(dateTimeUTC: string) {
  return format(new Date(dateTimeUTC), "MMMM d, yyyy, h:mm:ss a")
}

export function secondsToTime(secs: number): { days: number, hours: number, minutes: number, seconds: number } {
  const days = Math.floor(secs / (3600 * 24))

  const divisor_for_hours = secs % (3600 * 24)
  const hours = Math.floor(divisor_for_hours / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  return {
    days,
    hours,
    minutes,
    seconds
  };
}
export function getTimeDifference(startDateTime: any, endDateTime: any) {
  const timeDiff = new Date(endDateTime).getTime() - new Date(startDateTime).getTime()
  return Math.round(timeDiff / 60000)
}

export function getUTCformat(fromDateTime: string, toDateTime: string) {
  const formate = fromDateTime.slice(0, 10);
  const startAt = fromDateTime.slice(11, 19);
  const endAt = toDateTime.slice(11, 19);
  return formate + ', ' + startAt + ' to ' + endAt;
}
export function getUTFormattedDate(dateTime: string) {
  const formate = dateTime.slice(0, 10);
  return formate;
}

export function getUTFormattedTime(dateTime: string) {
  const formate = dateTime.slice(11, 19);
  return formate;
}
