import { Pack } from '../models/pack.model';
import { allApis } from "./allApi";

export const packCodeApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getPackCodes: builder.query<Pack[], void>({
            query: () => "api/pack",
        }),
    }),

});

export const { useGetPackCodesQuery } = packCodeApi;