import { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Field from '../../../Components/base/field/field';
import Button from '../../../Components/base/button/button';
import { Combobox, Transition } from '@headlessui/react';
import Icon from '../../../Components/base/icon/icon';
import { useDeliveryPathHook } from '../../../Hooks/DeliveryPathHook';
import { toastError, toastSuccess } from '../../../Components/toast';
import Spinner from '../../../Components/Spinner';

function AddDeliveryPath() {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { deliveryPathData, locationTypeData, onSubmit, register, handleSubmit, reset,
        errors, locationTypeOption, locationTypeQuery, setLocationTypeQuery, locationType,
        addLocationType, removeLocationType, navigateTo, setNavigateTo, createSuccess, setCreateSuccess, updateSuccess, setUpdateSuccess,
        createDeliveryPathError, updateDeliveryPathError,
    } = useDeliveryPathHook({ id })

    let locationTypeDatum;
    if (locationTypeOption) {
        locationTypeDatum = locationTypeQuery === '' ? locationTypeOption : locationTypeOption.filter((locationType: any) =>
            locationType.toLowerCase().replace(/\s+/g, '').includes(locationTypeQuery.toLowerCase().replace(/\s+/g, ''))
        )
    }

    useEffect(() => {
        if (createDeliveryPathError as any) {
            toastError((createDeliveryPathError as any)?.data?.message);
        }
        if (updateDeliveryPathError as any) {
            toastError((updateDeliveryPathError as any)?.data?.message);
        }
    }, [createDeliveryPathError, updateDeliveryPathError])

    useEffect(() => {
        if (navigateTo && createSuccess === true) {
            toastSuccess(t("createdSuccessfully"));
            setNavigateTo(false);
            navigate('/config/deliveryPath');
        } else if (!navigateTo && createSuccess === false) {
            toastSuccess(t("failed"));
        }
    }, [navigateTo, createSuccess]);
    useEffect(() => {
        if (navigateTo && updateSuccess === true) {
            toastSuccess(t('updatedSuccessfully'));
            setNavigateTo(false);
            navigate('/config/deliveryPath')
        }
        else if (!navigateTo && updateSuccess === false) {
            toastSuccess(t('failed'));
        }
    }, [navigateTo, updateSuccess]);

    if (id && !deliveryPathData) {
        return (
            <div
                className={`md:h-full h-screen flex justify-center items-center bg-white`}
            >
                <Spinner className="h-8 w-8" color="#2984FF" />
            </div>
        );
    }

    return (
        <section className="md:md:h-[calc(100%-4.3rem)]  md:m-2 rounded-lg shadow bg-white w-screen md:w-auto overflow-auto">
            <div className="w-screen md:w-full lg:w-3/5 p-5 md:pb-24 lg:pb-0">
                <form id="addDeliveryPath" onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
                        <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                            <label htmlFor="pathName" className='text-xs font-semibold text-TT-gray uppercase'>{t(`pathName`)}</label>
                            <Field
                                {...register('pathName')} error={errors?.pathName && `${errors?.pathName?.message}`} type="text" defaultValue={deliveryPathData?.pathName}
                                placeholder={t(`enterPathName(reference name)`)} id="pathName" className='px-2 h-12'
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                            <label htmlFor="pathCode" className='text-xs font-semibold text-TT-gray uppercase'>{t(`pathCode`)}</label>
                            <Field
                                {...register('pathCode')} error={errors?.pathCode && `${errors?.pathCode?.message}`} type="text" defaultValue={deliveryPathData?.pathCode}
                                placeholder={t(`enterPathCode(Reference ID code)`)} id="pathCode" className='px-2 h-12'
                            />
                        </div>
                        <div className="col-span-12 flex flex-col gap-2">
                            <label htmlFor="locationType" className='text-xs font-semibold text-TT-gray uppercase'>{t(`locationType`)}</label>


                            <div className="flex gap-x-2">
                                {locationType && locationType?.map((item, index) => (
                                    <div className='flex gap-x-2 bg-TT-blue rounded-lg text-white px-2 items-center' key={index}>
                                        <div>{item}</div>
                                        <div className='hover:text-red-500 rounded-full p-0.5' onClick={() => removeLocationType(item)}><Icon icon='CLOSE' size='x-small' /></div>
                                    </div>
                                ))}
                            </div>
                            {locationTypeData && locationType &&
                                <div className="z-30 my-2 md:my-0 md:w-1/2 pr-4">
                                    <Combobox value={locationType} onChange={() => { }}>
                                        <div className="relative z-30 ">
                                            <div>
                                                <Combobox.Input
                                                    className="leading-5 relative flex flex-1 w-full rounded-lg p-input bg-white text-gray-700 md:text-base placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-TT-blue focus:border-transparent border border-gray-300 h-12 pl-2.5"
                                                    onChange={() => { }}
                                                    onSelect={(event: any) => { setLocationTypeQuery(event.target.value) }}
                                                    placeholder={t('locationType')}
                                                    autoComplete="off"
                                                />
                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center">
                                                </Combobox.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setLocationTypeQuery('')}
                                            >
                                                <Combobox.Options className="focus:outline-none absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                                                    {locationTypeDatum && locationTypeDatum?.length === 0 ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        locationTypeDatum?.map((locationType: any) => (
                                                            <Combobox.Option
                                                                key={locationType.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-5 pr-4 mx-1 rounded-lg ${active ? 'bg-TT-blue text-white' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={locationType}
                                                                onClick={() => addLocationType(locationType)}
                                                            >
                                                                {({ selected, active }) => (
                                                                    <span id="txt_selectedlocationType" className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`} >
                                                                        {locationType}
                                                                    </span>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>
                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>
                            }
                        </div>
                        <div className="md:col-span-6 col-span-12 flex flex-col gap-2">
                            <label htmlFor="displayOrder" className='text-xs font-semibold text-TT-gray uppercase'>{t(`displayOrder`)}</label>
                            <Field
                                {...register('order')} error={errors?.order && `${errors?.order?.message}`} type="text" defaultValue={deliveryPathData?.order}
                                placeholder={t(`enterDisplayOrder`)} id="displayOrder" className='px-2 h-12'
                            />
                        </div>
                        <div className="col-span-6 flex flex-col gap-2"></div>
                        <div className="md:col-span-6 col-span-12 flex gap-2 justify-between flex-row items-center">
                            <label htmlFor="status" className='text-xs font-semibold text-TT-gray uppercase'>{t(`status`)}</label>
                            <div className="flex gap-x-2 items-center">
                                <Field type='checkbox' id="status" {...register('inActiveUserLogin')} error={errors?.inActiveUserLogin && `${errors?.inActiveUserLogin?.message}`} defaultChecked={deliveryPathData?.status} />
                                <span>{t(`inactiveUserCan’tLogin`)}</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-2 justify-center'>
                        <Button color='primary' className="py-1 md:w-fit h-12 md:h-fit w-full mx-0" form='addDeliveryPath' submit>{!id ? t('addDeliveryPath') : t('update')}</Button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default AddDeliveryPath