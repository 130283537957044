import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import ErrorScreen from "../../Components/base/WentWrong"
import Spinner from "../../Components/Spinner"
import { useGetBlockDataQuery } from "../../Services/fabricApi"
import { useAppDispatch } from "../../Store/hooks"
import { setNavbarProps } from "../../Services/utilsReducer";

const BlockDetails = () => {
  const { t } = useTranslation()

  const { blockId, channel, address } = useParams()

  const { data: blockData, isLoading, isError } = useGetBlockDataQuery({ blockId, channel, address })
  const dispatch = useAppDispatch();


  useEffect(() => {
    dispatch(
      setNavbarProps({
        navBarTitle: "blockDetails",
        navBarUserAvatar: true,
        navBarPrevPage: "/",
      })
    );
  }, [dispatch]);

  return (
    <div className="h-full mt-[7.6rem] md:m-2 rounded-lg shadow bg-white md:w-auto w-screen p-5 flex flex-col flex-1 overflow-auto">
      {isLoading && <div className="h-full flex flex-1 justify-center items-center"><Spinner className="h-8 w-8" color="#2984FF" /></div>}
      {isError && <div className="h-full flex flex-1 justify-center items-center"><ErrorScreen /></div>}
      {(!isLoading && !isError && blockData) && <section className="flex flex-col gap-y-5">

        <div className="flex flex-col gap-y-2">
          <div className="text-lg font-semibold">{t('details')}</div>
          <div className="flex flex-row">
            <span className="w-80">{t('blockId')}</span>
            <span>{blockData?.blockChain_Hash}</span>
          </div>
          <div className="flex flex-row">
            <span className="w-80">{t('status')}</span>
            <div className="text-green-500">{t('success')}</div>
          </div>
          <div className="flex flex-row">
            <span className="w-80">{t('channel')}</span>
            <span>{blockData?.blockChain_channel}</span>
          </div>
          <div className="flex flex-row">
            <span className="w-80">{t('timeStamp')}</span>
            <span>{blockData?.timestamp.toString()}</span>
          </div>
          <div className="flex flex-row">
            <span className="w-80">{t('previousHash')}</span>
            <div>{blockData?.previous_hash}</div>
          </div>
          <div className="flex flex-row">
            <span className="w-80">{t('dataHash')}</span>
            <span>{blockData?.data_hash}</span>
          </div>
        </div>

        <div className="flex flex-col gap-y-2">
          <div className="text-lg font-semibold">{t('contentDetails')}</div>
          <div className="flex flex-row">
            <span className="w-80">{t('address')}</span>
            <span>{blockData?.data?.address}</span>
          </div>
          <div className="flex flex-row">
            <span className="w-80">{t('data')}</span>
            <pre>{JSON.stringify(blockData?.data?.data, null, 3)}</pre>
          </div>
        </div>

      </section>}
    </div>
  )
}
export default BlockDetails