import { useTranslation } from 'react-i18next';
import logo from '../Assets/images/SF-logo.png';

function Home() {
    const {t} = useTranslation();

    return (
        <div className="grid place-items-center">
            <img className="logo" src={logo} alt="angular logo" />
            {t('APP_NAME')} !    
        </div>
    );
}

export default Home;
