import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import Icon from "../Components/base/icon/icon";
import { yupResolver } from "@hookform/resolvers/yup";
import { traceCodeValidationSchema } from "../Utils/validation";
import { useNavigate } from "react-router-dom";
import QRScanModal from "../Components/QRScanModal";

const SignInSearch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const [isScanOpen, setIsScanOpen] = useState<boolean>(false);

  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(traceCodeValidationSchema) })

  function SearchButton() {
    return (
      <div
        className={`bg-TT-blue  flex flex-row px-4 py-2 rounded-full  flex-shrink gap-x-3 items-center justify-center text-white`}
      >
        <Icon icon="SEARCH" color={"white"}></Icon>
        <span className="font-semibold">{t('trace')}</span>
      </div>
    )
  }

  function SearchBar() {
    return (
      <div
        className={`md:w-96 w-72`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="items-center relative justify-center">
            <button type="submit"  className=" absolute top-2 right-4  cursor-pointer text-TT-gray-light z-20 ">
              <Icon icon="SEARCH" />
            </button>
            <input
              {...register("traceCode")}
              placeholder={t("SearchOrScanTrace")}
              type="text"
              className={`w-full h-10  rounded-lg z-0 focus:outline-none border-TT-ash focus:border-TT-blue border text-sm px-10 ${errors?.traceCode?.message &&
                "border-red-500 focus:outline-none ring-red-500"
                }`}
            />
            <div
              className="absolute top-2 left-2  cursor-pointer text-TT-gray-light"
              onClick={() => { setIsScanOpen(true) }}
            >
              <Icon icon="CODE" />
            </div>
          </div>
        </form>
      </div>
    )
  }

  function onSubmit(values: any) {
    navigate(`/t/${values.traceCode}`)
  }

  return (
    <div
      className="transition-all ease-linear p-3 relative"
      onMouseOver={() => setShowSearchBar(true)}
      onMouseLeave={() => setShowSearchBar(false)}
    >
      <Transition
        show={!showSearchBar}
        enter="transition-opacity duration-600"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-600"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {!showSearchBar && <SearchButton />}
      </Transition>
      <Transition
        show={showSearchBar}
        enter="transition-opacity duration-600"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-600"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {showSearchBar && <SearchBar />}
      </Transition>
      <QRScanModal isOpen={isScanOpen} setIsOpen={setIsScanOpen} />
    </div>
  );
};

export default SignInSearch;
