import { useGetVersionQuery } from "../Services/versionApi"
import OpenLogo from "../Assets/images/tt-logo.svg";

function Version() {
    const { data: apiVersion } = useGetVersionQuery({})
    return (
        <div className="flex items-center justify-center h-screen bg-white bg-[url('Assets/images/login-2in1-bg.svg')] bg-cover bg-center">
            <div className='flex flex-col gap-y-3 text-center items-center'>
                <img
                    src={OpenLogo}
                    className={`px-4 h-16`}
                    alt="TT"
                />
                <div className="text-center">
                    <div className="p-4 text-4xl font-semibold">Track & Trace Version</div>
                    <div><span className="font-light">Web version:</span> <span className="font-bold">{process.env.REACT_APP_VERSION}</span></div>
                    <div><span className="font-light">API version: </span> <span className="font-bold">{apiVersion}</span></div>
                </div>
            </div>

        </div>
    )
}

export default Version
