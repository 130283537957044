import { useEffect, useState } from "react";
import Field from "../base/field/field";
interface SearchBarProp {
  onSearch: (watch: string | undefined) => void;
  placeHolder?: string;
  clearFilters?: boolean;
}

export default function SearchBar({ onSearch, placeHolder, clearFilters }: SearchBarProp) {
  const [searchValue, setSearchValue] = useState<string | undefined>();

  useEffect(() => {
    if(searchValue) {
      setSearchValue('');
    }
  }, [clearFilters])
  
  function handleChange(event: any) {
    onSearch(event.target.value);
    setSearchValue(event.target.value);
  }

  return (
    <div className="relative w-full">
      <Field
        onChange={handleChange}
        type="text"
        placeholder={placeHolder ? placeHolder : "Search"}
        value={searchValue ?? ""}
        name="search"
        className="w-full h-8 px-2 pr-7 rounded-lg z-0 focus:outline-none border-TT-ash focus:border-TT-blue border text-sm"
      />
    </div>
  );
}
