import { allApis } from './allApi';
import { Block } from '../models/block.model';
import { PagedData } from '../models/pagedData.model';

export const blockConfigApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getBlockConfig: builder.query<Block[], void>({
            query: () => "api/block",
            providesTags: ["BlockConfig"],
        }),
        getBlockConfigByPagination: builder.query<PagedData<Block[]>, any>({
            query: search => {
                return {
                    url: "api/block/getAll/page",
                    params: search
                }
            },
            providesTags: ["BlockConfig"],
        }),
        getBlockConfigById: builder.query<Block, any>({
            query: (id: string) => `api/block/${id}`,
            providesTags: ["BlockConfig"],
        }),
        updateBlockConfigById: builder.mutation<Block, Partial<Block>>({
            query: (block) => {
                return {
                    url: `api/block/${block.id}`,
                    method: "PUT",
                    body: block,
                }
            },
            invalidatesTags: ['BlockConfig']
        }),
        createBlockConfig: builder.mutation<Block, Partial<Block>>({
            query: (block) => {
                return {
                    url: `api/block`,
                    method: "POST",
                    body: block,
                }
            },
            invalidatesTags: ['BlockConfig']
        }),
        deleteBlockById: builder.mutation<boolean, any>({
            query: (id) => {
                return {
                    url: `api/block/${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ['BlockConfig']
        }),
        restoreBlockById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `api/block/restore/${id}`,
                }
            },
            providesTags: ['BlockConfig']
        }),
        defaultBlockIdUpdate: builder.mutation<Block, Partial<Block>>({
            query: (id) => {
                return {
                    url: `api/block/default/${id}`,
                    method: "PUT",
                }
            },
            invalidatesTags: ['BlockConfig']
        }),
    }),

});

export const { useGetBlockConfigQuery, useGetBlockConfigByPaginationQuery, useRestoreBlockByIdQuery, useDeleteBlockByIdMutation, useGetBlockConfigByIdQuery, useUpdateBlockConfigByIdMutation, useCreateBlockConfigMutation, useDefaultBlockIdUpdateMutation } = blockConfigApi;
