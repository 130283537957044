import { useFetchDataHook } from "./FetchData";
import { useCreateCodeConfigMutation, useDefaultIdUpdateMutation, useDeleteCodeByIdMutation, useGetCodeConfigByIdQuery, useGetCodeConfigByPaginationQuery, useUpdateCodeConfigByIdMutation } from "../Services/CodeConfigApi";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import { addConfigCodesValidationSchema } from '../Utils/validation';
import { useEffect, useState } from "react";
import { Pack } from "../models/pack.model";
import { useGetAllCodeTypesQuery } from "../Services/codeTypeApi";

interface _Props {
    id: string | undefined
}

export const useCodeConfigHook = (props: _Props) => {
    const { id } = props
    const { pageData, loading, fetchData, model, refetching, isEmpty, isError } = useFetchDataHook(useGetCodeConfigByPaginationQuery);
    const [deleteCode] = useDeleteCodeByIdMutation();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(addConfigCodesValidationSchema) });
    const { data: codeTypeData, isSuccess: codeTypeSuccess } = useGetAllCodeTypesQuery({ id: "" })
    const { data: codeConfifData } = useGetCodeConfigByIdQuery(id, { skip: !id })
    const [updateCodeConfig, { error: updateCodeConfigError }] = useUpdateCodeConfigByIdMutation()
    const [createCodeConfig, { error: createCodeConfigError }] = useCreateCodeConfigMutation()
    const [defaultcodeConfig] = useDefaultIdUpdateMutation()
    const [codeTypeOptions, setCodeTypeOptions] = useState<string[]>([]);
    const [codeTypes, setCodeTypes] = useState<string[]>([]);
    const [result, setResult] = useState<any | undefined>([]);
    const [codeTypeQuery, setCodeTypeQuery] = useState('')
    const [navigateTo, setNavigateTo] = useState<boolean>()
    const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();
    const [createSuccess, setCreateSuccess] = useState<boolean | undefined>();


    const addCode = (item: any) => {
        if (item && !codeTypes.includes(item)) {
            setCodeTypes([...codeTypes, item]);
            setCodeTypeOptions(codeTypeOptions.filter(c => c !== item));
        }
    }

    const removeCode = (code: any) => {
        setCodeTypes(codeTypes.filter(c => c !== code));
        setCodeTypeOptions([...codeTypeOptions, code]);
    }
    const onSubmit = (value: any) => {
        let updateCodeConfigDetails: Pack = codeConfifData
            ? Object.assign({}, codeConfifData)
            : ({} as any);
        updateCodeConfigDetails.packName = value.codeName;
        updateCodeConfigDetails.order = value.order;
        updateCodeConfigDetails.codes = result;
        if (id) {
            updateCodeConfig(updateCodeConfigDetails).then((res: any) => {
                if (res.data) {
                    setNavigateTo(true)
                    setUpdateSuccess(true)
                    console.log("update success: ", res.data)
                }
                else {
                    setNavigateTo(false)
                    setUpdateSuccess(false)
                    console.log("update errors: ", res.data)
                }
            })
        }
        else if (!id) {
            createCodeConfig(updateCodeConfigDetails).then((res: any) => {
                if (res.data) {
                    setNavigateTo(true)
                    setCreateSuccess(true)
                    console.log("create success: ", res.data)
                }
                else {
                    setNavigateTo(false)
                    setCreateSuccess(false)
                    console.log("create errors: ", res.data)
                }
            })
        }
    }
    useEffect(() => {
        if (codeConfifData) {
            reset(codeConfifData)
            setCodeTypes([])
            codeConfifData?.codes.map((e) => {
                setCodeTypes((value) => [...value, e?.code])
            })
        }
    }, [codeConfifData])

    useEffect(() => {
        if (codeTypeData && codeTypeSuccess) {
            codeTypeData?.map((e) => {
                setCodeTypeOptions((value: any) => [...value, e.code])
            })
        }
    }, [codeTypeSuccess, codeTypeData])

    useEffect(() => {
        setResult([])
        if (codeTypes && codeTypeData) {
            codeTypes.forEach((value) =>
                setResult((val1: any) => [...val1, codeTypeData.find((val) =>
                    value === val.code
                )])
            )
        }
    }, [codeTypeSuccess, codeTypes, codeTypeData])




    return {
        pageData, loading, fetchData, model, isEmpty, isError,
        refetching, deleteCode, codeConfifData, register,
        handleSubmit, reset, errors, onSubmit,
        addCode, removeCode, codeTypeOptions, setCodeTypeOptions,
        codeTypeQuery, setCodeTypeQuery, defaultcodeConfig, codeTypes,
        setCodeTypes, codeTypeData, navigateTo, setNavigateTo, updateSuccess, setUpdateSuccess,
        createCodeConfigError, updateCodeConfigError, createSuccess, setCreateSuccess
    };
}