import { allApis } from './allApi';
import { Email } from '../models/email.model';
import { PagedData } from '../models/pagedData.model';

export const emailConfigApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getEmailConfig: builder.query<Email[], void>({
            query: () => "api/email/getAll/page",
            providesTags: ["EmailConfig"],
        }),
        getEmailConfigByPagination: builder.query<PagedData<Email[]>, any>({
            query: search => {
                return {
                    url: "api/email/getAll/page",
                    params: search
                }
            },
            providesTags: ["EmailConfig"],
        }),
        getEmailConfigById: builder.query<Email, any>({
            query: (id: string) => `api/email/${id}`,
            providesTags: ["EmailConfig"],
        }),
        updateEmailConfigById: builder.mutation<Email, Partial<Email>>({
            query: (email) => {
                return {
                    url: `api/email/${email.id}`,
                    method: "PUT",
                    body: email,
                }
            },
            invalidatesTags: ['EmailConfig']
        }),
        createEmailConfig: builder.mutation<Email, Partial<Email>>({
            query: (email) => {
                return {
                    url: `api/email`,
                    method: "POST",
                    body: email,
                }
            },
            invalidatesTags: ['EmailConfig']
        }),
        deleteEmailById: builder.mutation<boolean, any>({
            query: (id) => {
                return {
                    url: `api/email/${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ['EmailConfig']
        }),
        restoreEmailById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `api/email/restore/${id}`,
                }
            },
            providesTags: ['EmailConfig']
        }),
        defaultEmailIdUpdate: builder.mutation<Email, Partial<Email>>({
            query: (id) => {
                return {
                    url: `api/email/default/${id}`,
                    method: "PUT",
                }
            },
            invalidatesTags: ['EmailConfig']
        }),
    }),

});

export const {
    useGetEmailConfigQuery,
    useGetEmailConfigByPaginationQuery,
    useDeleteEmailByIdMutation,
    useGetEmailConfigByIdQuery,
    useUpdateEmailConfigByIdMutation,
    useCreateEmailConfigMutation,
    useDefaultEmailIdUpdateMutation,
    useRestoreEmailByIdQuery,
} = emailConfigApi;
