import { CommonEntity } from "./commonEntity.model";
import { User } from "./user.model";

export enum Role {
    CompanyAdmin = "sys_company_admin",
    SuperAdmin = "sys_super_admin",
    CompanyLocationAdmin = "sys_company_location_admin",
    CompanySupportUser = "company_support_user",
}

export interface UserRole extends CommonEntity {
    role: Role;
    user: User;
}