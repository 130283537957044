import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Company } from '../models/company.model';
import { useCreateCompanyMutation, useUpdateCompanyMutation } from '../Services/companiesApi';
import { useGetCompanyByIdQuery } from '../Services/companyApi';
import { useCompanyTypesQuery } from '../Services/companyTypesApi';
import { editCompanyValidationSchema } from "../Utils/validation";

export const useEditCompanyHook = ({ id }: { id?: string }) => {

  const { register, handleSubmit, reset, setValue, formState: { errors }, control } = useForm({ resolver: yupResolver(editCompanyValidationSchema) })

  const [companyType, setCompanyType] = useState<string>()
  const [navigateBack, setNavigateBack] = useState<boolean>(false)
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>()
  const [createSuccess, setCreateSuccess] = useState<boolean | undefined>()

  const { data: companyTypes, isLoading: companyTypesLoading } = useCompanyTypesQuery()
  const { data: companyDetails, isLoading: companyDetailsLoading, refetch } = useGetCompanyByIdQuery(id ? id : '', { skip: !id })

  const [editCompany] = useUpdateCompanyMutation();
  const [createCompany] = useCreateCompanyMutation();

  const onSubmit = (values: any) => {
    let updateCompany: Company = companyDetails
      ? Object.assign({}, companyDetails)
      : ({} as Company);

    if (updateCompany) {
      updateCompany.company_name = values.companyName;
      updateCompany.company_name_local = values.companyNameJapanese;
      updateCompany.gs1_prefix = values.gs1_prefix;
      updateCompany.address1 = values.address1;
      updateCompany.address2 = values.address2;
      updateCompany.city = values.city;
      updateCompany.prefecture = values.prefecture;
      updateCompany.country = values.country;
      updateCompany.contactPerson1 = values.contactPerson1;
      updateCompany.contactPerson2 = values.contactPerson2;
      updateCompany.email1 = values.email1;
      updateCompany.email2 = values.email2;
      updateCompany.phone = values.phone;
      updateCompany.profile = values.profile;
      updateCompany.pincode = values.pincode;
      companyTypes?.forEach(item => {
        if(values.companyType.trim() == item.companyType.trim()) {
          updateCompany.companyType = item;
        }
      });
      if (id) {
        editCompany({ id: id, company: updateCompany }).then((result: any) => {
          if (result.data) {
            setNavigateBack(true)
            setUpdateSuccess(true)
          } else {
            setNavigateBack(false);
            setUpdateSuccess(false);
          }
        });
      } else {
        createCompany({ company: updateCompany }).then((result: any) => {
          if (result.data) {
            setNavigateBack(true);
            setCreateSuccess(true);
          } else {
            setNavigateBack(false);
            setCreateSuccess(false);
          }
        });
      }
    }
  }

  useEffect(() => {
    if (companyDetails) {
      reset(companyDetails);
      setCompanyType(companyDetails.companyType.companyType);
      setValue('companyType', companyDetails.companyType.companyType);
    }
  }, [companyDetails])


  return {
    register,
    handleSubmit,
    reset,
    setValue,
    errors,
    companyDetails,
    companyDetailsLoading,
    companyTypesLoading,
    companyTypes,
    setCompanyType,
    onSubmit,
    companyType,
    createSuccess,
    updateSuccess,
    navigateBack,
    refetch,
    control,
  }
}