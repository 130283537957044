import { allApis } from './allApi';
import { Company } from '../models/company.model';
import { PagedData } from '../models/pagedData.model';

export const companiesApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getCompanies: builder.query<Company[], void>({
            query: () => "api/company",
            providesTags: ["Companies"],
        }),
        getCompaniesByPagination: builder.query<PagedData<Company[]>, any>({
            query: (args) => {
                return {
                    url: "api/company/getAll/page/",
                    params: args
                }
            },
            providesTags: ["Companies"],
        }),
        createCompany: builder.mutation<Company, any>({
            query: (args) => {
                const { company } = args
                return {
                    url: `api/company`,
                    method: "POST",
                    body: company,
                }
            },
            invalidatesTags: ['Companies']
        }),
        updateCompany: builder.mutation<Company, any>({
            query: (args) => {
                const { id, company } = args
                return {
                    url: `api/company/${id}`,
                    method: "PUT",
                    body: company,
                }
            },
            invalidatesTags: ['Companies', 'Company']
        }),
        deleteCompanyById: builder.mutation<any, any>({
            query: (id) => {
                return {
                    url: `api/company/${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ['Companies']
        }),
        restoreCompanyById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `api/company/restore/${id}`,
                }
            },
            providesTags: ['Companies']
        }),
    }),

});

export const { useGetCompaniesQuery, useGetCompaniesByPaginationQuery, useRestoreCompanyByIdQuery, useDeleteCompanyByIdMutation, useUpdateCompanyMutation, useCreateCompanyMutation } = companiesApi;
