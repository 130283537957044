import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Button from "../../Components/base/button/button"
import Field from "../../Components/base/field/field"
import Modal from "../../Components/Modal"
import { toastError, toastSuccess } from "../../Components/toast"
import { QRCode } from "../../models/qrcode.model"
import { qrCodeApi } from "../../Services/qrCodeApi"
import { useAppDispatch } from "../../Store/hooks"

const PackProductPrint = () => {

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { register, handleSubmit, watch, setValue } = useForm()

  const [allowGenerate, setAllowGenerate] = useState(false)
  const [showPackPrintModal, setShowPackPrintModal] = useState<boolean>(false)
  const [packCodeData, setPackCodeData] = useState<QRCode[]>([])

  useEffect(() => {
    if (watch("noOfCodes") && watch("noOfCodes") > 0 && watch("noOfCodes") !== null) {
      setAllowGenerate(true)
    }
    else {
      setAllowGenerate(false)
    }
  }, [watch("noOfCodes"), watch])

  function onSubmit(values: any) {
    if (values?.noOfCodes) {
      dispatch(qrCodeApi.endpoints.getPackCodes.initiate(values.noOfCodes)).then((result) => {
        if (result.data) {
          toastSuccess(`${t("qrCodesGeneratedSuccessfully")}`)
          setValue("noOfCodes", 0)
          setPackCodeData(result.data)
          setShowPackPrintModal(true)
        } else {
          toastError(`${t("somethingWentWrong")}`)
          setValue("noOfCodes", 0)
          setAllowGenerate(false);
        }
        dispatch(qrCodeApi.util.resetApiState())
      })

    }
  }

  return (
    <>
      <div className="bg-white p-5 rounded-lg md:h-full h-auto">
        <div className='font-bold text-lg mb-3'>{t('qrCodePackPrint')}</div>
        <div className="px-2">
          <form id="productsForm" onSubmit={handleSubmit(onSubmit)}>
            <Field
              {...register('noOfCodes')} type="number"
              defaultValue={0} min={0}
              placeholder={t(`noOfCodes`)} id="noOfCodes" className='px-2 h-12 md:ml-1 mt-1'
            />
            <div className="mt-5 flex justify-center">
              <Button disabled={!allowGenerate} color='primary' className="py-0.5 md:w-fit text-white h-12 md:h-fit w-full mx-0" form="productsForm" submit>{t('generate')}</Button>
            </div>
          </form>
        </div>
      </div>
      {packCodeData && <Modal isOpen={showPackPrintModal} setIsOpen={setShowPackPrintModal} title={t("printPackCode")} onDismiss={() => {
        setPackCodeData([])
      }
      }>
        <PackPrintModal QRCodes={packCodeData} />
      </Modal>}
    </>
  )
}

export default PackProductPrint

const PackPrintModal = ({ QRCodes }: { QRCodes: QRCode[] }) => {

  return (
    <div className="flex flex-col items-center justify-center">
      {
        QRCodes.map((qrCode, index) => {
          return (
            <div key={index.toString()}>
              <div className="m-2 bg-white py-4">
                <img src={qrCode.qrcode} height={200} width={200} alt='' />
              </div>
              {index !== QRCodes.length - 1 && <div className="w-full bg-black my-1" style={{ height: 1 }} />}
            </div>
          )
        })
      }

    </div>
  )
}

