// eslint-disable-next-line import/no-anonymous-default-export
export default {
  about: 'About',
  action: 'Action',
  activate: 'Activate',
  add: 'Add',
  types: 'Types',
  CodeType: 'Code Type',
  addBlock: 'Add Block',
  addBlockConfig: 'Add Block Config',
  addBlockLifespan: 'Add Block Lifespan',
  addCode: 'Add Code',
  addCodes: 'Add Codes',
  addCodeTypes: 'Add Code Types',
  addCompany: 'Add Company',
  addCompanyType: 'Add Company Type',
  addCustomer: 'Add Customer',
  addDeliveryPath: 'Add delivery path',
  addEmail: 'Add Email',
  addedBy: 'Added By',
  'addedBy :': 'Added By :',
  addInfo: 'Add Info',
  addPath: 'Add Path',
  addedLocation: 'Added Location',
  addedUser: 'Added User',
  additionalNo: 'Additional Number',
  additionalUniqueNumber: 'Additional Unique Number',
  addLocation: 'Add Location',
  addLocationTypes: 'Add Location Types',
  addNew: 'Add New',
  addNewLocation: 'Add New Location',
  addMasking: 'Add Masking',
  addPrefix: 'Add Prefix',
  addPack: 'Add Pack',
  addProduct: 'Add Product',
  address: 'Address',
  address1: 'Address 1',
  companyTypeList: 'Company Type List',
  address2: 'Address 2',
  addressAndDetails: 'Address and Details',
  addressDetails: 'Address Details',
  traceCode: 'Trace Code',
  traceCreatedSuccessfully: 'Trace created successfully',
  trackView: 'Track View',
  track: 'Track',
  addUser: 'Add User',
  advancedTrack: 'Advanced Track',
  all: 'All',
  APP_NAME: 'Trace & Track',
  areYouSure: 'Are you sure?',
  aroundMultipleLocations: 'around multiple locations',
  attachments: 'Attachments',
  back: 'Back',
  block: 'Block',
  blockContentDetails: 'Block Content Details',
  blockDetails: 'Block details',
  blockId: 'Block ID',
  blockNo: 'Block number',
  blocksStatus: 'Blocks Status',
  blocksStatistics: 'Blocks Statistics',
  bottleCode: 'Bottle Code',
  box: 'Box',
  cancel: 'Cancel',
  cancelAll: 'Cancel All',
  cancelled: 'Cancelled',
  channel: 'Channel',
  city: 'City',
  clear: 'Clear',
  code: 'Code',
  codeExample: 'Example',
  codeExample_help: 'Code example for user understanding',
  codeName: 'Code Name',
  codeName_help: 'Code Name ex. GTIN, JAN, MD, etc.',
  codeNames: 'Codes',
  codePattern: 'Pattern',
  codePattern_help: 'Code Pattern, number of digits.',
  codes: 'Codes',
  companies: 'Companies',
  companiesList: 'Companies List',
  companyDetails: 'Company Details',
  company_help: 'Choose company the user belongs',
  companyLocation: 'Company Location',
  companyProduct: 'Company Products',
  companyProfile: 'Company Profile',
  companyUser: 'Company User',
  config: 'Config',
  confirmPassword: 'Confirm Password',
  contact: 'Contact',
  contactPerson: 'Contact Person',
  contactPerson1: 'Contact Person 1',
  contactPerson2: 'Contact Person 2',
  contacts: 'Contacts',
  container: 'Container',
  contentsOfBox: 'Contents of Box',
  contentsOfContainer: 'Contents of Container',
  contentsOfPack: 'Contents of Pack',
  country: 'Country',
  create: 'Create',
  createBlockAtAllLocations: 'Create block at all locations',
  createdAt: 'Created At',
  createdSuccessfully: 'Created successfully',
  customer: 'Customer',
  customer_code: 'Customer Code',
  customerName: 'Customer Name',
  customers: 'Customers',
  customersList: 'Customer List',
  dashboard: 'Dashboard',
  data: 'Data',
  date: 'Date',
  dragAndDropImage: 'Drag and drop image',
  data_hash: 'Data_hash',
  deletedSuccessfully: 'Deleted Successfully',
  restoredSuccessfully: 'Restored Successfully',
  restoreFailed: 'Restore Failed',
  deleteFailed: 'Delete Failed',
  deleteCompany: 'Delete Company',
  deleteCustomer: 'Delete Customer',
  deleteDeliveryPath: 'Delete DeliveryPath',
  deletedLocation: 'Deleted Location',
  deletedUser: 'Deleted User',
  deleteLocation: 'Delete Location',
  deleteLocationType: 'Delete Location Type',
  deletePack: 'Delete Pack',
  deleteProduct: 'Delete Product',
  deleteUser: 'Delete User',
  delivery: 'Delivery',
  deliveryPath: 'Delivery Path',
  details: 'Details',
  enterAddress1: 'Enter Address 1',
  enterAddress2: 'Enter Address 2',
  enterImageURL: 'Enter Image URL',
  enterBlockLifespanType: 'Enter Block Lifespan Type',
  enterBlock: 'Enter Block',
  enterDisplayOrder: 'Enter Display Order',
  enterDescription: 'Enter Description',
  enterEmail1: 'Enter Email 1',
  enterEmail2: 'Enter Email 2',
  enterType: 'Enter Type',
  enterLifespan: 'Enter Lifespan',
  enterPrefix: 'Enter Prefix',
  'enterPathName(reference name)': 'Enter Path Name (reference name)',
  'enterPathCode(Reference ID code)': 'Enter Path Code (Reference ID code)',
  enterphone: 'Enter Phone',
  enterProductDescription: 'Enter Product Description',
  enterCity: 'Enter City',
  enterCode: 'Enter Code',
  enterCountry: 'Enter Country',
  enterCompanyName: 'Enter Company Name',
  enterCompanyInformation: 'Enter Company Information',
  enterPrefecture: 'Enter Prefecture',
  enterPreferredDeliveryPath: 'Enter Preferred Delivery Path',
  enterContactPerson1: 'Enter Contact Person 1',
  enterContactPerson2: 'Enter Contact Person 2',
  entername: 'Enter name',
  edit: 'Edit',
  editCodeType: 'Edit Code',
  editCompany: 'Edit Company',
  editCompanyType: 'Edit Company Type',
  editCustomer: 'Edit Customer',
  editDeliveryPath: 'Edit Delivery Path',
  editedLocation: 'Edited Location',
  editedUser: 'Edited User',
  editIssue: 'Edit Issue',
  editLocation: 'Edit Location',
  editPack: 'Edit Pack',
  editProduct: 'Edit Product',
  editUser: 'Edit User',
  enterNewPassword: 'Enter new password',
  EnterUniqueGTINWithCompanyPrefix: 'Enter Unique GTIN with Company Prefix',
  fromDate: 'From Date',
  print: 'Print',
  printPackCode: 'Print Pack Code',
  download: 'Download',
  viewInactiveList: ' View Inactive List',
  viewActiveList: 'View Active List',
  viewLocation: 'View Location',
  selectColumns: 'Select Columns',
  referenceIdentificationCode: 'Reference Identification Code',
  displayOrder: 'Display Order',
  email: 'Email',
  email1: 'Email 1',
  email2: 'Email 2',
  error404: '404 Error ',
  filter: 'Filter',
  filterByCompany: 'Filter By Company',
  filterByFirstName: 'Filter By First name',
  filterByLastName: 'Filter By Last name ',
  firstName: 'First Name',
  generate: 'Generate',
  goBack: 'Go Back',
  group: 'Group',
  gs1_prefix: 'GS1 Prefix',
  enterGs1_prefix: 'Enter GS1 Prefix',
  gtin: 'GTIN',
  home: 'Home',
  high: 'High',
  in: 'In',
  individual: 'Individual',
  'inactiveUserCan’tLogin': 'Inactive user can’t login',
  ingredient: 'Ingredient',
  issue: 'Issue',
  issueCode: 'Issue Code',
  issues: 'Issues',
  item: 'Item',
  items: 'Items',
  itemsNavigate: 'Items Navigate',
  key: 'Key',
  language: 'Language',
  lastName: 'Last Name',
  latitude: 'Latitude',
  location: 'Location',
  location_help: 'Choose location if the user is the location manager',
  locationInfo: 'Location Info',
  locationKey: 'Location Key',
  locationProfile: 'Location Profile',
  locations: 'Locations',
  locationToken: 'Location Token',
  locationType: 'Location Type',
  locationTypeKey: 'Location Type Key',
  longitude: 'Longitude',
  logout: 'Logout',
  login: 'Login',
  low: 'Low',
  rememberMe: 'Remember Me',
  masking: 'Masking',
  maskType: 'Mask Type',
  mayTheForce: 'May The Force',
  msg_invalid: 'Invalid',
  msg_required: 'Required Field',
  manufacturingDate: 'Manufacturing Date',
  name: 'Name',
  none: 'None',
  noBlockData: 'No Block Data',
  public: 'Public',
  client: 'Client',
  noOfCodes: 'Number of Codes',
  notUpdated: 'Not Updated',
  offlineMessage: 'Without connection we can not load anything!',
  order: 'Order',
  others: 'Others',
  others_help: 'Additional Information',
  out: 'Out',
  prefix: 'Prefix',
  previous_hash: 'Previous_Hash',
  blockDetail: 'Block Detail',
  pack: 'Pack',
  packName: 'Pack Name',
  packType: 'PackType',
  password: 'Password',
  password_help: 'Reset password for user',
  pathCode: 'Path Code',
  peers: 'Peers',
  peerType: 'Peer Type',
  phone: 'Phone',
  pincode: 'Pincode',
  prefecture: 'Prefecture',
  progress: 'Progress',
  reset: 'Reset',
  reporter: 'Reporter',
  reenterNewPassword: 'Re-enter new password',
  scanned: 'Scanned',
  savePreference: 'Save Preference',
  select: 'Select',
  savedScanPreferences: 'Saved Scan Preferences',
  send: 'Send',
  status: 'Status',
  currentStatus: 'Current Status',
  latlong: 'Latitude & Longitude',
  yetToBeUpdated: 'Yet to be updated',
  title: 'Title',
  trace: 'Trace',
  traceBack: 'TraceBack',
  traceStatus: 'Trace Status',
  track01: 'Track01',
  trackProduct: 'Track Product',
  trackProductsWithQRCodes: 'Track products with QR codes',
  transaction: 'Transaction',
  transfer: 'Transfer',
  shipped: 'Shipped',
  inTransit: 'InTransit',
  delivered:'Delivered',
  unpack: 'Unpack',
  updateBrowser: 'You are using an old browser, please update it and reload the page.',
  updatedAt: 'Updated At',
  updatedSuccessfully: 'Updated successfully',
  updateFailed: 'Update Failed',
  upload: 'Upload',
  uploaded: 'Uploaded',
  uploadFile: 'Upload File',
  userCompany: 'User Company',
  username: 'Username',
  username_help: 'Username for user to login',
  changePassword: 'Change Password',
  printQR: 'Print QR',
  totalBlocks: 'Total Blocks',
  totalQRCodes: 'Total QR Codes',
  users: 'Users',
  unsigned: 'Unsigned',
  version: 'Version',
  wagyuBeef: 'Wagyu Beef',
  yes: 'Yes',
  yourProfile: 'Your Profile',
  toDate: 'To Date',
  trackPack: 'Track Pack',
  trackNow: 'Track Now',
  trackContainer: 'Track Container',
  trackCode: 'Track Code',
  trackBox: 'Track Box',
  traceAndTrack: 'Trace & Track',
  timestamp: 'Timestamp',
  theSelectedCustomerIs: 'The selected customer is',
  show: 'Show',
  success: 'Success',
  status_help: `InActive user can\`t login`,
  snapshot: 'Snapshot',
  sfftApp: 'Trace & Track',
  scanType: 'Scan Type',
  save: 'Save',
  saved: 'Saved',
  save_btn: 'Save',
  saveAndScan: 'Save & Scan',
  ScanAsAPack: 'Scan As A Pack',
  savePassword: 'Save Password',
  roles_help: 'Choose either Company Admin or Location Manager ',
  role: 'Role',
  revert_btn: 'Revert',
  result: 'Result',
  remove: 'Remove',
  requestUrl: 'Request URL',
  qrScan: 'QR Scan',
  qrType: 'QR Type',
  path: 'Path',
  qrCodePackPrint: 'QR Code Pack Print',
  qrCodesGeneratedSuccessfully: 'QR codes generated Successfully',
  publicKey: 'Public Key',
  profile: 'Profile',
  verificationList: 'Verification List',
  referenceName: 'Reference Name',
  blockLifespan: 'Block Lifespan',
  blockLifespanList: 'Block Lifespan List',
  companyType: 'Company Type',
  companyTypes: 'Company Types',
  uploadAll: 'Upload all',
  removeAll: 'Remove all',
  products: 'Products',
  ProductAlreadyAdded: 'Product Already Added',
  locationName: 'Location Name',
  searchForTrace: 'Search For Trace',
  scanTrace: 'Scan Trace',
  SearchOrScanTrace: 'Search Or Scan Trace',
  locationTypeList: 'Location Type List',
  deliveryPathList: 'Delivery Path List',
  pathName: 'Path Name',
  new: 'New',
  filterByGTIN: 'Filter by GTIN',
  filterByAddedBy: 'Filter by added by',
  filterByCity: 'Filter By City',
  filterByCode: 'Filter By Code',
  filterByCountry: 'Filter by Country',
  filterByCustomerCode: 'Filter By Customer Code',
  filterByName: 'Filter by Name',
  filterByNumber: 'Filter by number',
  filterByPrefecture: 'Filter by prefecture',
  filterByProductName: 'Filter By Product Name',
  filterByProductNumber: 'Filter by Product Number',
  filterByTitle: 'Filter by title',
  filterCompanyCode: 'Filter By Company Code',
  filterCompanyName: 'Filter by Company Name',
  filterIssueById: 'Filter Issue by ID',
  filterIssueByReporter: 'Filter Issue by Reporter',
  filterIssueByRepoter: 'Filter Issue by Reporter',
  filterUserByCompany: 'Filter User by Company',
  filterByLocationKey: 'Filter Location by Key',
  filterByLocationName: 'Filter Location By Name',
  '(clickOrDropImage)': '(Click or Drop Image)',
  verificationCompaniesList: 'Verification Companies List',
  locationMetaCode: 'Location Meta Code',
  locationList: 'Location List',
  preview: 'Preview',
  userList: 'User List',
  productList: 'Product List',
  companyInformation: 'Company Information',
  info: 'Info',
  images: 'Images',
  imageFromURL: 'Image From URL',
  imageURLFromLocalStorage: 'Image URL From Local Storage',
  blockConfig: 'Block Config',
  blockLifespanType: 'Block Lifespan Type',
  selectDeliveryPaths: 'Select Delivery Paths',
  addImageFromURL: 'Add Image from URL',
  addImageFromLocal: 'Add Image from Local',
  allergy: 'Allergy',
  nutrient: 'Nutrient',
  nutrients: 'Nutrients',
  nutritions: 'Nutritions',
  ingredients: 'Ingredients',
  ingredientsAdded: 'Ingredients Added',
  preferredDeliveryPath: 'Preferred Delivery Path',
  type: 'Type',
  default: 'Default',
  templateList: 'Template List',
  templateName: 'Template Name',
  description: 'Description',
  companyTypeLlist: 'Company Type List',
  codesList: 'Codes List',
  allergicTo: 'Allergic to...',
  companyLogo: 'Company Logo',
  companyInfo: 'Company Info',
  'company_name_local(Japanese)': 'Company Name (Japanese)',
  'enterCompanyName(Japanese)': 'Enter Company Name (Japanese)',
  enterCodeName: 'Enter Code Name',
  companyName: 'Company Name',
  imageDrop: 'Image Drop',
  editCompanies: 'Edit companies',
  'editCompany ': 'Edit Company',
  company: 'Company',
  editCustomers: 'Edit Customers',
  editEmail: 'Edit Email',
  user: 'User ',
  editIssues: 'Edit Issues',
  editProducts: 'Edit Products',
  locationTypes: 'Location Types',
  editLocationType: 'Edit Location Type',
  blockLifeSpan: 'Block Lifespan',
  blockLifeSpanList: 'Block Lifespan List',
  lifeSpan: 'Lifespan',
  inMonths: 'in Months',
  editBlockLifeSpanType: 'Edit Block Lifespan Types',
  productName: 'Product Name ',
  mainMenu: 'Main Menu',
  options: 'Options',
  verify: 'Verify',
  product: 'Product',
  packScannedSuccessfully: 'Pack Scanned Successfully',
  productScannedSuccessfully: 'Product Scanned Successfully',
  preferred_sno: 'Serial Number',
  productDescription: 'Product Description',
  productUpdatedSuccessfully: 'Product Updated Successfully',
  productUpdateFailed: 'Product Update Failed',
  editLocations: 'Edit Locations',
  metaCode: 'Meta Code',
  metadata: 'Metadata',
  locationDetails: 'Location Details',
  customerList: 'Customer List',
  customerInfo: 'Customer Info',
  packs: 'Packs',
  payLoadList: 'PayLoad List',
  productCode: 'Product Code',
  template: 'Template',
  scan: 'Scan',
  selectCamera: 'Select Camera',
  productDetails: 'Product Details',
  packagedLocation: 'Packaged Location',
  packagedOn: 'Packaged On',
  packagedDate: 'Packaged Date',
  APITokens: 'API Tokens',
  APITokenList: 'API Token List',
  token: 'Token',
  createToken: 'Create Token',
  expire: 'Expire',
  explorer: 'Explorer',
  extendValidity: 'Extend Validity',
  noCameraFound: 'No Camera Found',
  noDataAvailable: 'No Data Available',
  noImage: 'No Image',
  codeList: 'Code List',
  codeTypes: 'Code Types',
  blockChainStatus: 'Blockchain Status',
  selectChannel: 'Select Channel',
  blocks: 'Blocks',
  transactions: 'Transactions',
  nodes: 'Nodes',
  chainCodes: 'Chaincodes',
  peerName: 'Peer Name',
  channels: 'Channels',
  id: 'Id',
  channelName: 'Channel Name',
  timeStamp: 'Timestamp',
  network: 'Network',
  from: 'From',
  to: 'To',
  search: 'Search',
  noOfTx: 'Number of Tx',
  dataHash: 'Data Hash',
  blockHash: 'Block Hash',
  previousHash: 'Previous Hash',
  size: 'Size(KB)',
  creator: 'Creator',
  txId: 'Tx Id',
  chainCode: 'Chaincode',
  packedAt: 'Packed At',
  packedOn: 'Packed On',
  costStructure: 'Cost Structure',
  channelType: 'Channel Type',
  price: 'Price',
  tax: 'Tax',
  discount: 'Discount',
  pricing: 'Pricing',
  pricingList: 'Pricing List',
  editPricing: 'Edit Pricing',
  deletePrice: 'Delete Price',
  pleaseCheckAt: 'Please Check at :',
  usernameOrEmailIsRequired: 'Username or email is required',
  incorrectUsernameOrPassword: 'Incorrect username or password',
  signIn: 'Sign In',
  usernameOrEmail: 'Username or email',
  somethingWentWrong: 'Something went wrong!',
  required: 'Required',
  enterValidEmail: 'Enter valid Email',
  emailCanBeMax50CharactersLong: "Email can be maximum 50 characters long",
  enterCompanyProfile: 'Enter company profile',
  failed: 'Failed',
  enteredPasswordsAreDifferent: 'Entered passwords are different',
  urlMustBeLessThan250Characters: 'URL Must Be Less Than 250 Characters',
  companyInformationMustBeLessThan250Characters: 'Company Information Must Be Less Than 250 Characters',
  sessionExpired: 'Session Expired',
  error: 'Error',
  processed: 'Processed',
  thisBrowserDoesNotSupportHTML5: 'This browser does not support HTML5.',
  pleaseUploadAValidExcelFile: 'Please upload a valid Excel file.',
  loginToYourAccount: 'Login to your account',
  welcomeBack: 'Welcome back',
  pleaseEnterYourDetails: 'Please enter your details',
  traceNotFound: 'Trace not found',
  contentDetails: 'Content Details',
  months: 'Months',
  addPricing: 'Add Pricing',
  searchFromDate: 'Search From Date',
  searchToDate: 'Search To Date',
  greaterThanOrEqualTo8Characters: "Greater than or equal to 8 characters",
  lessThanOrEqualTo25Characters: "Less than or equal to 25 characters",
  blankSpacesAreNotAllowed: "Blank spaces are not allowed",
  atleastOneNumber: "Atleast one number",
  atleastOneUpperCaseLetter: "Atleast one uppercase letter",
  atleastOneLowerCaseLetter: "Atleast one lower case letter",
  atleastOneSpecialSymbol: "Atleast one special symbol",
  of: "of",
  fileSizeIsTooLargeUploadUnder1MB: 'File size is too large upload under 1 mb',
  unsupportedFileFormat: 'Unsupported File Format',
  onlyJpegJpgAndPngImagesWillBeAccepted: 'Only *.jpeg, *.jpg and *.png images will be accepted',
  enterValidCountryCodeAndPhoneNo: "Enter valid Country Code and Phone Number",
  usedForInternalReferencePurpose: "Used For Internal Reference Purpose",
  enterPincode: "Enter Pincode",
  noLocationAvailable: "No Location Available",
}

