import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useGetCurrentUserQuery, useUpdateCurrentUserMutation } from "../Services/userApi";
import { profileValidationSchema } from "../Utils/validation";

export interface ProfileInitial {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    others: string;
    reEnterPassword?: string;
    password?: string;
}

export default function useProfileHooks() {
    const [updateCurrentUser, { error: updateCurrentUserError }] = useUpdateCurrentUserMutation();
    const { data: currentUser } = useGetCurrentUserQuery();
    const [navigateScreen, setNavigateScreen] = useState(false);
    const [changePasswordStatus, setChangePasswordStatus] = useState<boolean | null>()

    const {
        control,
        register,
        reset,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
    } = useForm<ProfileInitial>({resolver: yupResolver(profileValidationSchema)});

    let updateCUser: any = currentUser
        ? Object.assign({}, currentUser)
        : ({} as any);

    useEffect(() => {
        if (currentUser) {
            reset(currentUser);
        }
    }, [currentUser]);

    const changePassword = () => {
        if (updateCUser && getValues().reEnterPassword) {
            updateCUser.password = getValues().reEnterPassword;
            updateCurrentUser(updateCUser)
                .then((result: any) => {
                    if (result.data) {
                        setChangePasswordStatus(true)
                        setNavigateScreen(true);
                    }
                    else {
                        setChangePasswordStatus(false)
                        setNavigateScreen(false);
                        console.log(result.error);
                    }
                })
        }
    };

    const updateProfile = () => {
        if (updateCUser) {
            updateCUser.firstName = getValues().firstName;
            updateCUser.lastName = getValues().lastName;
            updateCUser.email = getValues().email;
            updateCUser.phone = getValues().phone;
            updateCUser.others = getValues().others;
            updateCurrentUser(updateCUser)
                .then(() => {
                }
                )
                .catch((err: any) => {
                    console.log(err);
                }
                );
        }
    }

    return {
        control,
        register,
        handleSubmit,
        changePassword,
        errors,
        setValue,
        updateCurrentUser,
        getValues,
        navigateScreen,
        updateProfile,
        updateCurrentUserError,
        changePasswordStatus
    };
}
