import { ScanPreference } from "../models/scanPreference.model";
import { Trace } from "../models/trace.model";
import { allApis } from "./allApi";

export const traceApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getTraceData: builder.query<Trace[], any>({
            query: (code: string) => `api/trace/i/${code}`,
        }),
        createTraceData: builder.mutation<Trace, any>({
            query: (trace) => ({
                url: `api/trace`,
                method: 'POST',
                body: trace
            })
        }),
        getScanPreferenceData: builder.query<ScanPreference[], void>({
            query: () => `api/scanPreference`,
            providesTags: ['ScanPreference']
        }),
        createScanPreferenceData: builder.mutation<ScanPreference, any>({
            query: (scanPreference) => ({
                url: `api/scanPreference`,
                method: 'POST',
                body: scanPreference
            }),
            invalidatesTags: ['ScanPreference']
        }),
        deleteScanPreferenceById: builder.mutation<any, any>({
            query: (id) => {
                return {
                    url: `api/scanPreference/${id}`,
                    method: "GET",
                }
            },
            invalidatesTags: ['ScanPreference']
        }),
    }),
});

export const { useGetTraceDataQuery, useCreateTraceDataMutation, useGetScanPreferenceDataQuery, useCreateScanPreferenceDataMutation, useDeleteScanPreferenceByIdMutation } = traceApi;
