import { allApis } from './allApi';
import { Company } from '../models/company.model';

export const companyApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getCompanyByUserId: builder.query<Company, string>({
            query: (userId) => "api/company/user/" + userId,
            providesTags: ['Company']
        }),
        getCompanyById: builder.query<Company, string>({
            query: (id) => "api/company/" + id,
            providesTags: ['Company']
        }),
        uploadCompanyImagefromUrl: builder.mutation<any, any>({
            query: (args) => {
                const { imageUrl, id } = args;
                return {
                    url: `api/company/uploadURL/${id}`,
                    method: "POST",
                    body: { imageURL: imageUrl }
                }
            }
        }),
        uploadCompanyImagefromFile: builder.mutation<any, Object>({
            query: ({ id, file }: { id: string, file: File }) => {
                let fileData: FormData = new FormData();
                fileData.append('file', file, file.name);
                return {
                    url: `api/company/file/upload/${id}`,
                    method: "POST",
                    body: fileData
                }
            },
            invalidatesTags: ['Company']
        }),
        deleteCompanyImage: builder.mutation<any, any>({
            query: (args) => {
                const { companyId, imageId } = args;
                return {
                    url: `api/company/file/delete/${companyId}/${imageId}`,
                    method: "GET",
                }
            }
        }),
    }),
});

export const { useGetCompanyByUserIdQuery, useGetCompanyByIdQuery, useUploadCompanyImagefromUrlMutation, useDeleteCompanyImageMutation, useUploadCompanyImagefromFileMutation } = companyApi;
