import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../Components/Pagination";
import TableFormat from "../../Components/TableFormat";
import SearchAndFilter from "../../Components/TableNavBar/SearchAndFilter";
import { TableNavBar } from "../../Components/TableNavBar/TableNavBar";
import { useAppPermission } from "../../Hooks/AppPermissionHook";
import { useFetchDataHook } from "../../Hooks/FetchData";
import { Role } from "../../models/userRole.model";
import {
  pricingApi,
  useDeletePricingByIdMutation,
  useGetPricingByPaginationQuery,
} from "../../Services/PricingApi";
import { useAppDispatch } from "../../Store/hooks";
import { Action, ActionType, ComponentType } from "../Action";

function Pricing() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { pageData, loading, fetchData, isError, isEmpty } = useFetchDataHook(
    useGetPricingByPaginationQuery
  );

  const { hasPermission } = useAppPermission();

  const [deletePricing] = useDeletePricingByIdMutation();

  function currencyFormat(num: number) {
    if (num > 999) {
      return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
    return num;
  }

  const data = useMemo(() => {
    const displayData: any[] = [];

    pageData?.data?.forEach((company: any) => {
      const companyData = { ...company };

      displayData.push({
        blockLifespan: companyData?.blockLifeSpanType.type,
        companyName: companyData?.company?.company_name,
        price: companyData?.price,
        discount: companyData?.discount,
        tax: companyData?.tax,
        id: companyData?.id,
      });
    });

    return displayData;
  }, [pageData]);

  const columns = React.useMemo(
    () =>
      hasPermission([Role.SuperAdmin])
        ? [
          {
            Header: t("blockLifespan"),
            accessor: "blockLifespan",
          },
          {
            Header: t("company"),
            accessor: "companyName",
          },
          {
            Header: t("price"),
            accessor: "price",
            Cell: ({ row: { original } }: any) => (
              <div>
                <span>
                  {currencyFormat(original.price)}
                </span>
              </div>
            ),
          },
          {
            Header: t("discount"),
            accessor: "discount",
          },
          {
            Header: t("tax"),
            accessor: "tax",
          },
          {
            Header: t("action"),
            accessor: "",
            Cell: ({ row: { original } }: any) => (
              <Action
                id={original.id}
                types={[ActionType.View, original.status ? ActionType.Delete : ActionType.Restore]}
                deleteFunc={deletePricing}
                restoreFunc={() => dispatch(pricingApi.endpoints.restorePricingById.initiate(original.id)).unwrap()}
                invalidateFunc={() => dispatch(pricingApi.util.invalidateTags(['Pricing']))}
                navigation={`${original.id}`}
                title="view History"
                componentType={ComponentType.Button}
              />
            ),
          },
        ]
        : [
          {
            Header: t("channelType"),
            accessor: "channelType",
          },
          {
            Header: t("company"),
            accessor: "companyName",
          },
          {
            Header: t("price"),
            accessor: "price",
            Cell: ({ row: { original } }: any) => (
              <div>
                <span>
                  {currencyFormat(original.price)}
                </span>
              </div>
            ),
          },
          {
            Header: t("discount"),
            accessor: "discount",
          },
          {
            Header: t("tax"),
            accessor: "tax",
          },
        ],
    [t, deletePricing, dispatch, hasPermission]
  );

  return (
    <TableNavBar
      tableTitle={"pricing"}
      navLink={"add"}
      buttonTitle={"addNew"}
      pageData={pageData}
      fetchData={fetchData}
      isError={isError}
      isLoading={loading}
      isEmpty={isEmpty}
    >
      <TableNavBar.SearchAndFilter>
        <SearchAndFilter />
      </TableNavBar.SearchAndFilter>
      <TableNavBar.Pagination>
        {pageData && <Pagination page={pageData.page} fetchData={fetchData} />}
      </TableNavBar.Pagination>
      <TableNavBar.TableFormat>
        {pageData && <TableFormat columns={columns} data={data} _query={useGetPricingByPaginationQuery} />}
      </TableNavBar.TableFormat>
    </TableNavBar>
  );
}

export default Pricing;
