import React from "react";
import { useTranslation } from "react-i18next";
import Field from "../../Components/base/field/field";
import Icon from "../../Components/base/icon/icon";
import Select from "../../Components/base/Select";
import Pagination from "../../Components/Pagination";
import TableFormat from "../../Components/TableFormat";
import { Pagination as pagination } from "../../models/pagination.model";

function Network() {
  const { t } = useTranslation();
  const numbers = [5, 10, 50, 100, 200, 500, 1000];

  const pageData = {
    data: [
      {
        id: 1,
        peerName: "peer0.org1.example",
        requestUrl: "http://localhost:8080/api/v1/",
        peerType: "Peer",
        MSPID: "Org1MSP",
        high: 1,
        low: 1,
        unsigned: "false",
      },
      {
        id: 2,
        peerName: "peer1.org1.example.com:8051",
        requestUrl: "http://localhost:8080/api/v1/",
        peerType: "Peer",
        MSPID: "Org1MSP",
        high: 5,
        low: 0,
        unsigned: "true",
      },
      {
        id: 3,
        peerName: "peer0.org1.example.com:7051",
        requestUrl: "http://localhost:8080/api/v1/",
        peerType: "ORDERER",
        MSPID: "Org1MSP",
        high: 3,
        low: 1,
        unsigned: "false",
      },
      {
        id: 4,
        peerName: "peer0.org2.example",
        requestUrl: "http://localhost:8080/api/v1/",
        peerType: "Peer",
        MSPID: "Org1MSP",
        high: 7,
        low: 2,
        unsigned: "false",
      },
      {
        id: 5,
        peerName: "peer1.org1.example",
        requestUrl: "http://localhost:8080/api/v1/",
        peerType: "ORDERER",
        MSPID: "Org1MSP",
        high: 1,
        low: 0,
        unsigned: "true",
      },
    ],
    page: {} as pagination
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("peerName"),
        accessor: "peerName",
      },
      {
        Header: t("requestUrl"),
        accessor: "requestUrl",
      },
      {
        Header: t("peerType"),
        accessor: "peerType",
      },
      {
        Header: t("MSPID"),
        accessor: "MSPID",
      },
      {
        Header: t("high"),
        accessor: "high",
      },
      {
        Header: t("low"),
        accessor: "low",
      },
      {
        Header: t("unsigned"),
        accessor: "unsigned",
      },
    ], [t]);

  return (
    <section className="rounded-lg shadow bg-white h-full md:h-[calc(100%-3.3rem)]">
      <div className="flex justify-between p-3 border-b md:pb-5 flex-wrap">
        <div className="flex justify-between w-full md:justify-start md:w-auto gap-x-2">
          <div className="relative w-full">
            <Field
              type="text"
              placeholder="Search"
              name="search"
              className="w-full h-8 px-2 pr-7 rounded-lg z-0 focus:outline-none border-TT-ash focus:border-TT-blue border text-sm"
            />
            <div className="absolute top-1.5 right-2  cursor-pointer">
              <Icon icon="SEARCH" />
            </div>
          </div>
          <div className="h-8 w-fit px-2 border border-TT-ash rounded-lg flex gap-x-2 items-center">
            <div className="flex flex-none">
              <Icon icon="FILTER" />
            </div>
            <span className="font-semibold">{t("filter")}</span>
          </div>
        </div>
        <div className="flex gap-x-3">
          <div className="flex justify-center md:justify-start mt-2 md:mt-0 flex-wrap gap-y-2 md:gap-y-0 items-center gap-x-3">
            <div className="flex items-center gap-x-3 flex-none">
              <span className="text-xs md:text-sm">{t("show")}</span>
              <Select items={numbers} />
            </div>
            {pageData && <Pagination page={pageData.page} fetchData={()=>{}}/>}
          </div>
        </div>
      </div>
      <section className="overflow-y-auto">
        {pageData && <TableFormat columns={columns} data={pageData?.data} />}
      </section>
    </section>
  );
}

export default Network;
