import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import OpenLogo from "../Assets/images/tt-logo.svg";
import Icon from "../Components/base/icon/icon";
import Navbar from "../Components/Navbar";
import { useAppPermission } from "../Hooks/AppPermissionHook";
import { useSignOutHook } from "../Hooks/SignOutHook";
import { Role } from "../models/userRole.model";

interface MenuType {
    title: string
    icon: string
    url: string
}

const Menus: MenuType[] = [
    { title: "companies", icon: "COMPANY", url: "company" },
    { title: "users", icon: "USERS", url: "users" },
    { title: "types", icon: "GRID", url: "types/locationType" },
    { title: "location", icon: "LOCATION", url: "location" },
    { title: "products", icon: "BLOCK", url: "products" },
    { title: "config", icon: "GLOBE", url: "config/deliveryPath" },
    { title: "customers", icon: "USERS", url: "customers" },
    { title: "scan", icon: "SEARCH", url: "scan/individual" },
    { title: "explorer", icon: "EXPLORE", url: "explorer/status" },
    { title: "pricing", icon: "CURRENCY", url: "pricing" },
    { title: "transaction", icon: "SORT", url: "transaction" },
    { title: "verificationList", icon: "TICK", url: "verificationList" },
    { title: "trace", icon: "SEARCH", url: "trace" },
];

const Sidebar = () => {

    const { t } = useTranslation();
    const isMobile = window.innerWidth <= 768;
    const [isTablet, setTablet] = useState(false);

    const [open, setOpen] = useState<boolean>(isMobile ? false : true);

    const { tabs } = useSidebarTabs()

    const { signOutCurrentUser } = useSignOutHook()

    const style = {
        navLink: `flex rounded-lg  p-2 py-3 cursor-pointer hover:text-white text-TT-gray-light text-sm items-center gap-x-4 hover:bg-TT-blue font-semibold ${!open && 'justify-start'} `,
        active: `flex rounded-lg p-2 py-3 cursor-pointer hover:text-white text-sm items-center gap-x-4 bg-TT-blue text-white font-bold ${!open && 'justify-start'} `,
    }


    useEffect(() => {
        if (isMobile) {
            document.addEventListener('mousedown', () => setOpen(false));
            return () => document.removeEventListener('mousedown', () => setOpen(false))
        }
    }, [open, isMobile]);

    useEffect(() => {
        setTablet(window.innerWidth >= 768 ? true : false);
    }, [window.innerWidth])
    
    return (
        <div className="flex">
            {/* desktop menu */}
            {isTablet &&
                <div className="hidden md:flex w-full md:overflow-hidden h-screen">
                    <aside className={` ${open ? "lg:w-60 md:w-56" : "lg:w-20 md:w-20 w-16"} bg-white lg:h-full pt-2 relative shadow-md group transition-all ease-linear duration-100`}>
                        <div className={`absolute cursor-pointer -right-3 lg:inset-y-[50vh] inset-y-1/4 h-6 w-6  flex items-center justify-center bg-TT-blue lg:bg-white group-hover:bg-TT-blue hover:text-black rounded-full  ${!open && "rotate-180"} shadow-md lg:opacity-0 group-hover:opacity-100 duration-200 transition-all`}
                            onClick={() => setOpen(!open)}>
                            <Icon icon="LEFT" size="x-small" className="text-white" />
                        </div>
                        <div className="">
                            <div>
                                <div className="flex flex-row text-TT-gray-light items-center justify-start w-full cursor-pointer translate-x-1 " >
                                    <img
                                        src={OpenLogo}
                                        className={`duration-200 flex px-4 lg:h-20 lg:w-20 md:h-16 md:w-16 `}
                                        alt="TT"
                                    />
                                    {open && <span className="font-semibold text-lg transition ease-in-out duration-200 truncate text-ellipsis">{t('APP_NAME')}</span>}
                                </div>
                                <ul className="p-3 pt-3 flex flex-col gap-1.5 h-[80vh] overflow-auto no-scrollbar">
                                    {tabs.map((Menu: any, index: any) => (
                                        <NavLink to={`/${Menu.url}`}
                                            key={index}
                                            className={(navData: any) => navData.isActive ? style.active : style.navLink}
                                        >
                                            <div className="flex flex-row flex-none items-start justify-start gap-3 px-3">
                                                <Icon icon={Menu.icon} />
                                                <span className={`${!open && "hidden"} origin-left duration-200 capitalize truncate`}>
                                                    {t(Menu.title)}
                                                </span>
                                            </div>
                                        </NavLink>
                                    ))}
                                </ul>
                            </div>
                            <div className="flex flex-row flex-none  px-5 mb-5  justify-center" onClick={() => { signOutCurrentUser() }}>
                                <div className="hover:bg-TT-blue p-3 rounded-lg hover:text-white flex items-center gap-3">
                                    <Icon icon='LOGOUT' />
                                    <span className={`${!open && "hidden"} origin-left duration-200 capitalize truncate`}>
                                        {t('logout')}
                                    </span>
                                </div>
                            </div>

                        </div>
                    </aside>
                    <aside className="w-[calc(100%-5rem)] pl-0.5 bg-gray-200">
                        <div className="h-full flex flex-col flex-1">
                            <Navbar />
                            <Outlet />
                        </div>
                    </aside>
                </div>
            }
            {/* mobile menu */}
            {!isTablet &&
                <div className="flex md:hidden">
                    <aside className={` ${open ? "left-0" : "left-[-65%]"} w-[65%] z-40 fixed shadow text-white text-4xl top-0  cursor-pointer group transition-all ease-linear duration-200 bg-white  h-screen`}>
                        <div className={`${open ? 'absolute cursor-pointer -right-12' : 'fixed cursor-pointer left-5'} top-7 h-6 w-6 `}

                            onClick={() => setOpen(!open)}>
                            <Icon icon={!open ? "MENU" : "ADD_BLACK"} size='small' className={`text-black ${open ? 'rotate-45' : 'opacity-0'} `} />
                        </div>
                        <div className="flex flex-col justify-between h-full overflow-y-auto overflow-x-hidden no-scrollbar">
                            <div>
                                <div className="flex flex-row text-TT-gray-light items-center justify-start w-full cursor-pointer translate-x-1 mt-5" >
                                    <img
                                        src={OpenLogo}
                                        className={`duration-200 flex px-4 h-12`}
                                        alt="TT"
                                    />
                                    {open && <span className="font-semibold text-lg transition ease-in-out duration-200 text-black">{t('APP_NAME')}</span>}
                                </div>
                                <ul className="p-3 pt-3 flex flex-col gap-2  ">
                                    {tabs.map((Menu, index) => (
                                        <NavLink to={`/${Menu.url}`}
                                            key={index}
                                            className={(navData: any) => navData.isActive ? style.active : style.navLink}
                                            onClick={() => setOpen(!open)}
                                        >
                                            <div className="flex flex-row flex-none gap-3 px-3">
                                                <Icon icon={Menu.icon} />
                                                <span className={`origin-left duration-200 capitalize truncate`}>
                                                    {t(Menu.title)}
                                                </span>
                                            </div>
                                        </NavLink>
                                    ))}
                                    <div className="flex rounded-lg  p-2 py-3 cursor-pointer hover:text-white text-TT-gray-light text-sm items-center gap-x-4 hover:bg-TT-blue font-semibold false " onClick={() => { signOutCurrentUser() }}>
                                        <div className="hover:bg-TT-blue px-3 rounded-lg hover:text-white flex items-center gap-3">
                                            <Icon icon='LOGOUT' />
                                            <span className={`${!open && "hidden"} origin-left duration-200 capitalize truncate`}>
                                                {t('logout')}
                                            </span>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <div className="border-t-2 py-7 px-4 hidden md:flex w-full">
                                <NavLink to='/profile' >
                                    <div className="flex flex-row text-TT-gray-light items-center justify-start w-full cursor-pointer translate-x-1 gap-x-3" >
                                        <div className="w-10 h-10 rounded-md font-semibold bg-TT-blue text-white flex flex-none items-center justify-center ">SA</div>
                                        {open &&
                                            <div className={`flex flex-col gap-y-0 ${open && 'transition ease-in-out delay-150'}`}>
                                                <span className="font-semibold">Super Admin</span>
                                                <span className="font-semibold text-sm text-gray-500">superadm@gmail.com</span>
                                            </div>
                                        }
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </aside>
                    <aside className="flex-1 bg-gray-200">
                        <div className="w-full h-full">
                            <div className="h-full flex flex-col flex-1">
                                <Outlet />
                            </div>
                        </div>
                    </aside>
                </div >
            }
        </div >
    );
};


export default Sidebar;

const useSidebarTabs = () => {
    const [tabs, setTabs] = useState<MenuType[]>([])
    const { hasPermission } = useAppPermission();

    const superAdminTabs = [
        "companies",
        "users",
        "types",
        "explorer",
        "pricing",
        "transaction",
    ]

    const companyAdminTabs = [
        "users",
        "location",
        "products",
        "config",
        "customers",
        "explorer",
        "transaction",
        "verificationList",
    ]

    const companyLocationAdminTabs = [
        "products",
        "scan",
        "explorer",
        "transaction",
    ]

    useEffect(() => {
        if (hasPermission([Role.SuperAdmin])) {
            addTabs(superAdminTabs);
        } else if (hasPermission([Role.CompanyAdmin])) {
            addTabs(companyAdminTabs);
        } else if (hasPermission([Role.CompanyLocationAdmin])) {
            addTabs(companyLocationAdminTabs);
        }
    }, [])

    function addTabs(list: string[]) {
        setTabs([]);
        setTabs(Menus.filter(value => list.includes(value.title)))
    }

    return { tabs }
}