import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import Icon from "../../Components/base/icon/icon";
import Spinner from "../../Components/Spinner";
import { Trace } from "../../models/trace.model";
import { useGetLocationByIdQuery } from "../../Services/locationApi";
import { formatDate2, formatTime2 } from "../../Utils/datetime";

interface TraceListItemProps {
    id: string;
    travel: any;
    showLocation: boolean;
    showMetaData: boolean;
    accId?: string;
    trace?: Trace
    ascending?: boolean;
    arrow?: boolean;
}

function TraceListItem({ id, travel, showLocation, showMetaData, accId, trace, ascending, arrow = false }: TraceListItemProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: location, isLoading: locationLoading } = useGetLocationByIdQuery(id, { skip: !id });


    const collapse = (accId: any, e: any) => {
        e.stopPropagation();
        if ((document.getElementById(`panel_` + accId) as | HTMLInputElement | any)?.classList.contains("min-h-[21rem")) {
            (document?.getElementById(`panel_` + accId) as | HTMLInputElement | any)?.classList.remove("min-h-[21rem");
            (document?.getElementById(`panel_` + accId) as | HTMLInputElement | any)?.classList.add("h-14");
            (document?.getElementById(accId) as HTMLInputElement | any)?.classList.remove("-rotate-360");
            (document?.getElementById(accId) as HTMLInputElement | any)?.classList.add("-rotate-90");
        } else {
            (document?.getElementById(`panel_` + accId) as | HTMLInputElement | any)?.classList.remove("h-14");
            (document?.getElementById(`panel_` + accId) as | HTMLInputElement | any)?.classList.add("min-h-[21rem");
            (document?.getElementById(accId) as HTMLInputElement | any)?.classList.remove("-rotate-90");
            (document?.getElementById(accId) as HTMLInputElement | any)?.classList.add("-rotate-360");
        }
    };

    if (locationLoading) {
        return <Spinner className="h-8 w-8" color="#2984FF" />;
    }
    return (
        <div className="flex gap-x-1 md:gap-x-3 last:mb-10">
            {!arrow &&
                <div className="flex flex-col items-center pt-5">
                    <div
                        className={`bg-TT-blue w-5 h-5 rounded-full text-white flex items-center justify-center text-center p-2 duration-500  ${ascending ? '' : 'rotate-180'}`}
                        onClick={(e) => collapse(accId, e)} id={accId}
                    >
                        <div onClick={(e) => collapse(accId, e)} > <Icon icon="DOWN" size="x-small" className={`flex-none`} /> </div>
                    </div>
                    <div className="h-full border-TT-blue border bg-TT-blue w-0 min-h-full"></div>
                </div>
            }
            {arrow &&
                <div className="flex flex-col items-center pt-5">
                    <div
                        className={`bg-TT-blue w-5 h-5 rounded-full text-white flex items-center justify-center text-center p-2 duration-500  ${ascending ? '' : 'rotate-180'}`}
                        onClick={(e) => collapse(accId, e)} id={accId}
                    >
                        <div className="bg-reg-500" onClick={(e) => collapse(accId, e)} > <Icon icon="DOWN" size="x-small" className={`flex-none`} /> </div>
                    </div>
                </div>}
            <div
                className="w-full p-3 md:p-5 shadow-md rounded-lg flex flex-col overflow-hidden -rotate-360 transition-[height] ease-out duration-500 border mb-2 min-h-[21rem"
                id={"panel_" + accId}
            >
                <div className="flex flex-row justify-between font-semibold items-center">
                    <div className="flex flex-col gap-x-3">
                        {showLocation && location?.location}
                        <div className="text-TT-gray-light mt-2">
                            {location?.locationType.locationType}
                        </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <span>{formatDate2(travel?.created_at.toString())}</span>
                        <div className="text-TT-gray-light mt-2">
                            <span>{formatTime2(travel?.created_at.toString())}</span>
                        </div>
                    </div>
                </div>

                <div className="h-0.5 bg-gray-200" />
                <div className="flex mt-2">
                    {showLocation && (location?.latitude && location.longitude) ?
                        <div className="flex flex-col justify-center w-4/5 flex-wrap">
                            <div className="font-semibold">{t('locationInfo')}</div>
                            <div className="text-TT-gray-light">{location?.locationInfo}</div>
                            <br />
                            <div className="flex flex-col">
                                <span className="font-semibold">{t('latlong')}</span>
                                <NavLink to={`/trace/location/${location?.location}/${location?.latitude.trim()}/${location?.longitude.trim()}`}
                                    className="text-TT-blue underline underline-offset-2 ">
                                    {`${location?.latitude}, ${location?.longitude}`}
                                </NavLink>
                            </div>
                        </div> : <div className="flex flex-col w-4/5"></div>}
                    {(travel?.blockChain_Hash && travel?.blockChain_channel) &&
                        <div className="flex flex-1 mt-5 ml-9">
                            <div
                                className="bg-TT-blue/20 h-fit rounded-xl w-28 flex justify-end p-2 flex-col text-center font-semibold relative shadow cursor-pointer"
                                onClick={() => {
                                    if (trace && trace.address && travel.blockChain_Hash && travel.blockChain_channel) {
                                        navigate(`/trace/block/${travel.blockChain_Hash}/${travel.blockChain_channel}/${travel.address}`)
                                    }
                                }}
                            >
                                <div className="flex justify-center absolute -top-4 inset-x-1/2">
                                    <div className="w-fit bg-TT-blue/80 border border-TT-blue text-white p-2 rounded-full">
                                        <Icon icon="BLOCK" size="x-small" />
                                    </div>
                                </div>
                                <div className="text-sky-700 mt-3 text-3xl font-bold">
                                    {travel?.blockChain_Hash}
                                </div>
                                <span className="text-sky-700 font-bold">
                                    {travel?.blockChain_channel}
                                </span>
                                <span className="font-bold">Block</span>
                            </div>
                        </div>
                    }
                </div>
                {showMetaData && (
                    <div className="grid grid-cols-3 font-semibold w-full pt-5 pb-5 flex-wrap">
                        {travel?.metadata &&
                            Object.keys(travel?.metadata)?.map((key: any, index: number) => {
                                return (
                                    <div className="flex flex-col mt-3 items-start" key={index}>
                                        {travel?.metadata[key] &&
                                            <div className="flex flex-col col-span-1">
                                                <span>{key}</span>
                                                <span className="text-TT-gray-light">{travel?.metadata[key]}</span>
                                            </div>}
                                    </div>
                                );
                            })}
                    </div>
                )}
                {travel?.traceStatus && (
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-col col-span-2">
                            <div className="font-semibold">
                                {t('status')}
                            </div>
                            <div className="text xs text-green-500">
                                {travel?.traceStatus !== 'others' ? t(travel?.traceStatus) : travel?.others ? travel?.others : t(travel?.traceStatus)}
                            </div>
                        </div>
                        {travel?.scanType && <div className="flex flex-col col-span-2">
                            <div
                                className={`text xs flex gap-x-1 items-center ${travel?.scanType === "IN"
                                    ? "text-green-500"
                                    : travel?.scanType === "OUT" ? "text-red-500"
                                        : "text-black"}`}
                            >
                                {travel?.scanType}{" "}
                                {travel?.scanType === "IN" ? (
                                    <Icon className="rotate-180" icon="LOGOUT" />
                                ) : travel?.scanType === "OUT" ? (
                                    <Icon icon="LOGOUT" />
                                ) : <Icon icon="TRANSFER" />}
                            </div>
                        </div>}
                    </div>
                )}
            </div>
        </div>
    );
}

export default TraceListItem;