import { useFieldArray, useForm } from "react-hook-form";
import Field from "../../Components/base/field/field";
import { useTranslation } from "react-i18next";
import Button from "../../Components/base/button/button";
import Icon from "../../Components/base/icon/icon";
import {
  useCreateProductsMutation,
  useGetProductByIdQuery,
  useUpdateProductByIdMutation,
} from "../../Services/productApi";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetDeliveryPathsQuery } from "../../Services/DeliveryPathApi";
import { Products } from "../../models/products.model";
import { DeliveryPath } from "../../models/deliveryPath.model";
import { toastError, toastSuccess } from "../../Components/toast";

function ProductDeliveryPath() {
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues
  } = useForm();
  const { fields, append, remove, update: updateNewFieldsArray } = useFieldArray({
    control,
    name: "deliveryPath",
  });
  const { data: product } = useGetProductByIdQuery(id);
  const { data: allDeliveryPaths } = useGetDeliveryPathsQuery();
  const [updateDeliveryPaths, { error: updateDeliveryPathsError }] = useUpdateProductByIdMutation()
  const [navigateTo, setNavigateTo] = useState<boolean>()
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();

  useEffect(() => {
    if (updateDeliveryPathsError as any) {
      toastError((updateDeliveryPathsError as any)?.data?.message);
    }
  }, [updateDeliveryPathsError])

  useEffect(() => {
    if (product) {
      reset({
        deliveryPath: product?.product_multiDeliveryPath
      })
    }
  }, [product]);

  const onSubmit = () => {
    let updateProductDeliveryPath: Products = product
      ? Object.assign({}, product)
      : ({} as any);

    updateProductDeliveryPath.product_multiDeliveryPath = getValues().deliveryPath as DeliveryPath[];

    if((getValues().deliveryPath as DeliveryPath[]).length > 0) {
      updateProductDeliveryPath.deliveryPathCheck = true;
    } else {
      updateProductDeliveryPath.deliveryPathCheck = false;
    }

    if (id) {
      updateDeliveryPaths(updateProductDeliveryPath).then((res: any) => {
        if (res.data) {
          setNavigateTo(true)
          setUpdateSuccess(true)
          console.log("update success", res.data)
        }
        else {
          setNavigateTo(false)
          setUpdateSuccess(false)
          console.log("update failed", res.error)
        }
      })
    }
  };

  useEffect(() => {
    if (navigateTo && updateSuccess === true) {
      toastSuccess(t('updatedSuccessfully'));
      setNavigateTo(false);
    }
    else if (!navigateTo && updateSuccess === false) {
      toastSuccess(t('failed'));
    }
  }, [navigateTo, updateSuccess]);

  return (
    <section className="md:h-[calc(100%-3.5rem)] h-screen rounded-lg shadow bg-white w-screen md:w-auto overflow-auto">
      <div className="w-full p-2 md:p-0 md:pl-5 md:py-5 md:mb-20">
        <form id="addProductInfo" onSubmit={handleSubmit(onSubmit)}>
          <div className="font-semibold flex gap-x-3 text-xs text-TT-gray uppercase">
            {t(`deliveryPath`)}{" "}
            <span
              className="ml-3 text-TT-blue flex gap-x-2 items-center text-xs cursor-pointer"
              onClick={() => append({ deliveryPath: {} })}
            >
              <Icon icon="ADD_BLACK" size="x-small" /> {t("addPath")}
            </span>
          </div>

          {
            fields.map((deliveryPath: any, fieldIndex) => {
              return (
                deliveryPath?.deliveryPath?.pathName
                  ? <AlreadyDeliveryPath deliveryPath={deliveryPath} index={fieldIndex} key={fieldIndex} />
                  : <AddNewDeliveryPath deliveryPath={deliveryPath} index={fieldIndex} key={fieldIndex} />
              )
            })
          }
          <div className="flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-2 justify-center">
            <Button
              color="primary"
              className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
              form="addProductInfo"
              submit
            >
              {t("addDeliveryPath")}
            </Button>
          </div>
        </form>
      </div>
    </section>
  );

  function AlreadyDeliveryPath({ deliveryPath, index }: { deliveryPath: any, index: number }) {
    return (
      <>
        <div className="flex flex-col items-start mt-3">
          <div className="flex-col flex gap-x-3">
            <div className="flex-col flex py-1 gap-3">
              <Field
                key={index}
                {...register(`deliveryPath[${index}].pathName`)}
                type="text"
                defaultValue={deliveryPath?.deliveryPath?.pathName}
                placeholder={t(`pathCode`)}
                id="pathCode"
                className="px-2 h-12"
              />
              <div className="flex items-center gap-2 flex-wrap">
                {deliveryPath.deliveryPath?.path?.map(
                  (pathLocationType: any, index: any) => (
                    <span
                      key={index.toString()}
                      className="bg-TT-blue text-white px-2 rounded-md py-1 h-fit md:h-fit w-fit mx-0"
                    >
                      {pathLocationType?.locationType}
                    </span>
                  ))}
                <div className="flex-none">
                  <Icon
                    icon="CLOSE"
                    size="small"
                    onClick={() => {
                      remove(index)
                    }}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </>
    )
  }

  function AddNewDeliveryPath({ deliveryPath, index }: { deliveryPath: any, index: number }) {
    return (
      <>
        <div className="flex flex-col items-start mt-3">
          <div className="flex-col flex gap-x-3 ">
            <Field
              key={index}
              {...register(`deliveryPath[${index}].pathName`)}
              type="select"
              onChange={(e: any) => {
                const selectedDeliveryPath = allDeliveryPaths?.find((val) => val.pathName === e.target.value)
                updateNewFieldsArray(index, { deliveryPath: selectedDeliveryPath })
              }}
              placeholder={t(`pathCode`)}
              id="pathCode"
              className="px-2 h-12"
            >
              <option value="">{t("select")}</option>
              {allDeliveryPaths?.map((path: any, index) => (
                <>
                  {
                    <option key={index.toString()} id={path.id}>
                      {path?.pathName}
                    </option>
                  }
                </>
              ))}
            </Field>
          </div>
        </div>
      </>
    )
  }

}

export default ProductDeliveryPath;
