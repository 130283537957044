import React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Button from "../../Components/base/button/button";
import Field from "../../Components/base/field/field";
import { toastError, toastSuccess } from "../../Components/toast";
import { useFetchDataHook } from "../../Hooks/FetchData";
import { Block } from "../../models/block.model";
import { BlockLifespanTypes } from "../../models/blockLifespanTypes.model";
import { Products } from "../../models/products.model";
import { useGetBlockConfigQuery } from "../../Services/BlockConfigApi";
import { useBlockLifespanTypesQuery } from "../../Services/blockLifespanTypesApi";
import { useGetProductByIdQuery, useUpdateProductByIdMutation } from "../../Services/productApi";
import { addProductBlockConfigValidationSchema } from "../../Utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";


function ProductBlockConfig() {
    const { id } = useParams()
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(addProductBlockConfigValidationSchema) });
    const { pageData } = useFetchDataHook(useBlockLifespanTypesQuery);
    const { data: productData } = useGetProductByIdQuery(id);
    const { data: blockListData } = useGetBlockConfigQuery();
    const [updateBlockConfig, { error: updateBlockConfigError }] = useUpdateProductByIdMutation()
    const [isChecked, setIsChecked] = useState<boolean>(true);
    const [newBlock, setNewBlock] = useState<Block | undefined>()
    const [newBlockLifeSpan, setNewBlockLifeSpan] = useState<BlockLifespanTypes | undefined>()
    const [selectedBlock, setSelectedBlock] = useState<string>()
    const [selectedBlockLifeConfig, setSelectedBlockLifeConfig] = useState<string>()
    const [navigateTo, setNavigateTo] = useState<boolean>()
    const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();
    const [blockLifeSpanTypes, setBlockLifeSpanTypes] = useState<BlockLifespanTypes[]>([]);


    const onSubmit = () => {
        let updateProductBlockConfig: Products = productData
            ? Object.assign({}, productData)
            : ({} as any);

        updateProductBlockConfig.block = Object.assign({}, productData?.block)
        updateProductBlockConfig.blockLifespanTypes = Object.assign({}, productData?.blockLifespanTypes)
        updateProductBlockConfig.blockCheck = isChecked
        if (newBlock) {
            updateProductBlockConfig.block = newBlock
        }
        if (newBlockLifeSpan) {
            updateProductBlockConfig.blockLifespanTypes = newBlockLifeSpan
        }
        if (id) {
            updateBlockConfig(updateProductBlockConfig).then((res: any) => {
                if (res.data) {
                    setNavigateTo(true)
                    setUpdateSuccess(true)
                    console.log("update success", res.data)
                }
                else {
                    setNavigateTo(false)
                    setUpdateSuccess(false)
                    console.log("update failed", res.error)
                }
            })
        }
    }

    useEffect(() => {
        if (updateBlockConfigError as any) {
            toastError((updateBlockConfigError as any)?.data?.message);
        }
    }, [updateBlockConfigError])


    useEffect(() => {
        if (selectedBlock) {
            blockListData?.forEach((element: any) => {
                if (element.name === selectedBlock) {
                    setNewBlock(element)
                }
            })
        }
    }, [selectedBlock, blockListData])

    useEffect(() => {
        if (selectedBlockLifeConfig) {
            pageData?.forEach((element: any) => {
                if (element.type === selectedBlockLifeConfig) {
                    setNewBlockLifeSpan(element)
                }
            })
        }
    }, [selectedBlockLifeConfig, pageData])

    useEffect(() => {
        if (navigateTo && updateSuccess === true) {
            toastSuccess(t('updatedSuccessfully'));
            setNavigateTo(false);
        }
        else if (!navigateTo && updateSuccess === false) {
            toastSuccess(t('failed'));
        }
    }, [navigateTo, updateSuccess]);


    useEffect(() => {
        if (productData) {
            reset(productData);
            setIsChecked(productData?.blockCheck);
        }
    }, [productData])

    useEffect(() => {
        if (pageData && pageData.length) {
            const temp = Object.assign([], pageData);
            setBlockLifeSpanTypes(temp.sort((type1: BlockLifespanTypes, type2: BlockLifespanTypes) => type1.lifespan - type2.lifespan));
        }
    }, [pageData])


    return (
        <section className="md:h-[calc(100%-3.5rem)] h-screen rounded-lg shadow bg-white w-screen md:w-auto overflow-auto">
            <div className="w-full p-2 md:p-0 md:pl-5 md:py-5">
                <form id="addProductBlockConfig" onSubmit={handleSubmit(onSubmit)}>
                    <>
                        <label htmlFor="blockLifespan" className="text-xs font-semibold text-TT-gray uppercase">
                            {t(`blockLifespan`)}
                        </label>
                        <div className="h-3" />
                        <div className="md:w-1/4">
                            <Field
                                {...register("blockLifeSpan")}
                                type="select"
                                id="blockLifeSpan"
                                error={errors?.blockLifeSpan && `${errors?.blockLifeSpan?.message}`}
                                className="px-2 h-12 w-full md:w-1/4"
                                onChange={
                                    (value: any) => {
                                        setSelectedBlockLifeConfig(value.target.value)
                                    }
                                }
                            >
                                <option defaultValue={productData?.blockLifespanTypes?.type}>{productData?.blockLifespanTypes?.type}</option>
                                {React.Children.toArray(blockLifeSpanTypes.map((type: BlockLifespanTypes) => (
                                    <>
                                        {productData?.blockLifespanTypes.type !== type.type &&
                                            <option id={type.id}>
                                                {type?.type}
                                            </option>
                                        }
                                    </>
                                )))
                                }
                            </Field>
                        </div>
                        <div className="h-5" />
                        {/* Block Data */}
                        <div className="flex items-center my-2 ">
                            <input
                                type="checkbox"
                                id="remember_me"
                                className="mr-2 w-5 h-5 accent-TT-blue"
                                defaultChecked={productData?.blockCheck}
                                onChange={(e: any) => {
                                    setIsChecked(e.target.checked);
                                }}
                            />
                            <label
                                htmlFor="remember_me"
                                className="text-xs md:text-sm font-semibold"
                            >
                                {t("createBlockAtAllLocations")}
                            </label>
                        </div>
                        {!isChecked && (
                            <>
                                {(blockListData?.length && blockListData?.length > 0) ? (
                                    <div className="my-2">
                                        <label htmlFor="block" className="text-xs font-semibold text-TT-gray uppercase">
                                            {t(`block`)}
                                        </label>
                                        <div className="h-3" />
                                        <div className="flex flex-row gap-x-3">
                                            <div className="md:w-1/4">
                                                <Field
                                                    {...register("enterBlock")}
                                                    type="select"
                                                    id="enterBlock"
                                                    error={errors?.enterBlock && `${errors?.enterBlock?.message}`}
                                                    className="px-2 h-12 w-full md:w-1/4"
                                                    onChange={
                                                        (value: any) => {
                                                            setSelectedBlock(value.target.value)
                                                        }
                                                    }
                                                >
                                                    <option defaultValue={productData?.block?.name}>{productData?.block?.name}</option>
                                                    {React.Children.toArray(blockListData?.map((type) => (
                                                        <>
                                                            {productData?.block?.name !== type?.name &&
                                                                <option id={type.id}>
                                                                    {type?.name}
                                                                </option>
                                                            }
                                                        </>
                                                    )))}
                                                </Field>
                                            </div>
                                            <div className="w-2" />
                                            {productData?.block?.locationTypes.map(
                                                (locationType: any, index: number) => (
                                                    <span key={index} className="bg-TT-blue text-white px-2 rounded-md py-1 md:w-fit h-12 md:h-fit w-full mx-0">
                                                        {locationType?.locationType}
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    </div>) : <div className="mt-10"><span>{t('noBlockData')}!</span></div>}
                            </>
                        )}

                        <div className="flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-2 justify-center">
                            <Button
                                color="primary"
                                className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
                                form="addProductBlockConfig"
                                submit
                            >
                                {t("addBlockConfig")}
                            </Button>
                        </div>
                    </>
                </form>
            </div>
        </section>
    )
}
export default ProductBlockConfig