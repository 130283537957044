import { useTranslation } from "react-i18next";
import Button from "../../Components/base/button/button";
import Field from "../../Components/base/field/field";
import { useNavigate, useParams } from "react-router-dom";
import { usePricingHook } from "../../Hooks/PricingHook";
import { toastError, toastSuccess } from "../../Components/toast";
import { useEffect } from "react";
import { setNavbarProps } from "../../Services/utilsReducer";
import { useAppDispatch } from "../../Store/hooks";
import Spinner from "../../Components/Spinner";

function AddPricing() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    companiesList,
    pricing,
    blockLifeSpanType,
    addPricingError,
    updatePricingError,
    createSuccess,
    updateSuccess,
    navigateTo,
    setNavigateTo,
  } = usePricingHook({ id });

  useEffect(() => {
    if (addPricingError as any) {
      toastError((addPricingError as any)?.data?.message);
    }
    if (updatePricingError as any) {
      toastError((updatePricingError as any)?.data?.message);
    }
  }, [addPricingError, updatePricingError]);

  useEffect(() => {
    if (navigateTo && createSuccess === true) {
      toastSuccess(t("createdSuccessfully"));
      setNavigateTo(false);
      navigate(-1);
    } else if (!navigateTo && createSuccess === false) {
      toastSuccess(t("failed"));
    }
  }, [navigateTo, createSuccess]);

  useEffect(() => {
    if (navigateTo && updateSuccess === true) {
      toastSuccess(t("updatedSuccessfully"));
      setNavigateTo(false);
      navigate(-1);
    } else if (!navigateTo && updateSuccess === false) {
      toastSuccess(t("failed"));
    }
  }, [navigateTo, updateSuccess]);

  useEffect(() => {
    dispatch(setNavbarProps({
      navBarTitle: id ? "editPricing" : "addPricing",
      navBarPrevPage: "/pricing",
      navBarUserAvatar: true,
    }));
  }, [])

  if(id && !pricing) {
    return (
      <div
        className={`md:h-full h-screen flex justify-center items-center bg-white`}
      >
        <Spinner className="h-8 w-8" color="#2984FF" />
      </div>
    );
  }

  return (
    <>
      <section className="md:px-2 mt-[7.6rem] md:mt-2 h-full md:pb-20">
        <div className="px-5 pb-5 pt-4  bg-TT-background md:bg-white h-full w-screen md:w-full rounded-lg overflow-auto">
          <form
            id="addPricing"
            className="md:w-full lg:w-3/5"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="company"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`company`)}
                </label>
                <Field
                  type="select"
                  className="px-2 h-12"
                  error={errors?.company && `${errors?.company?.message}`}
                  {...register("company")}
                >
                  {pricing?.company?.company_name ? (
                    <option value="DEFAULT">
                      {pricing?.company?.company_name}
                    </option>
                  ) : (
                    <option></option>
                  )}
                  {companiesList?.map((item: any, index: number) => (
                    <option value={item.company_name} key={index}>
                      {t(item.company_name)}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="channelType"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`blockLifespan`)} ({t("months")})
                </label>
                <Field
                  {...register("blockLifeSpan")}
                  type="select"
                  className="px-2 h-12"
                  error={
                    errors?.blockLifeSpan && `${errors?.blockLifeSpan?.message}`
                  }
                >
                  {pricing?.blockLifeSpanType?.type ? (
                    <option value="DEFAULT">
                      {pricing?.blockLifeSpanType?.type}
                    </option>
                  ) : (
                    <option></option>
                  )}
                  {blockLifeSpanType?.data?.map((item: any, index: number) => (
                    <option value={item.id} key={index}>
                      {t(item.type)}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="price"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`price`)}
                </label>
                <Field
                  {...register("price")}
                  error={errors?.price && `${errors?.price?.message}`}
                  type="number"
                  min={0}
                  placeholder={t(`price`)}
                  id="price"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="discount"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`discount`)} {"%"}
                </label>
                <Field
                  {...register("discount")}
                  error={errors?.discount && `${errors?.discount?.message}`}
                  type="number"
                  min={0}
                  placeholder={t(`discount`)}
                  id="discount"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="tax"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`tax`)} {"%"}
                </label>
                <Field
                  {...register("tax")}
                  error={errors?.tax && `${errors?.tax?.message}`}
                  type="number"
                  min={0}
                  placeholder={t(`tax`)}
                  id="tax"
                  className="px-2 h-12"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-2 justify-center">
              <Button
                color="primary"
                className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
                form="addPricing"
                submit
              >
                {id ? t("update") : t("addPricing")}
              </Button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default AddPricing;
