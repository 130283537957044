import { TraceCommonEntity } from './TraceCommonEntity.model';

export enum BlockChainType {
  sawtooth = 'sawtooth',
  fabric = 'fabric'
}

export enum MaskType {
  companies = 'companies',
  locations = 'locations',
  all = 'all',
  none = 'none'
}

export enum QRCodeType {
  container = 'container',
  box = 'box',
  pack = 'pack',
  product = 'product',
  ingredient = 'ingredient'
}

export interface Trace extends TraceCommonEntity {
  locationToken: string;

  locationKey: string;

  locationId: string;

  locationAddress: string;

  code: string;

  code01: string;

  code02: string;

  code03: string;

  productCode: string;

  productName: string;

  maskType: MaskType;

  companyCode: string;

  items: string[];

  scanType: string;

  qrCodeType: QRCodeType;

  subProductType: string;

  metadata: any;

  blockChainType: BlockChainType;

  blockChain_lifespan: number;

  coordinates: number[];

  traceStatus: string;
  
  others: string;

  traceCurrentStatus: string;

  travels?: any[];

  items_down?: Trace[];

  items_up?: Trace[];

  address?: string
}