import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from "react-hook-form"
import { useGetLocationByIdQuery, useUpdateLocationByIdMutation } from "../Services/locationApi"
import { useLocationTypesQuery } from "../Services/locationTypesApi";
import { editProfileLocationValidationSchema } from '../Utils/validation';
import { Location } from "../models/location.model";

export const useProfileLocationEditHook = ({ id }: { id?: string }) => {

  const [navigateBack, setNavigateBack] = useState<boolean>()
  const [updateSuccess, setUpdateSuccess] = useState<boolean | null>()
  const [generatingToken, setGeneratingToken] = useState<boolean>(false)
  const [tokenGenSuccess, setTokenGenSuccess] = useState<boolean | null>()

  const { data: locationData, isLoading: locationDataLoading, refetch: refetchLocationData } = useGetLocationByIdQuery(id, { skip: !id })
  const { data: locationTypeList, isLoading: locationTypesLoading } = useLocationTypesQuery(true);
  const [updateLocationData] = useUpdateLocationByIdMutation()

  const { handleSubmit, register, formState: { errors }, setValue } = useForm({ resolver: yupResolver(editProfileLocationValidationSchema) })

  function onSubmit(values: any) {
    let updateLocationDetails: Location = locationData
      ? Object.assign({}, locationData)
      : ({} as any);

    if (updateLocationDetails) {
      updateLocationDetails.locationType = values.locationType;
      updateLocationDetails.location = values.locationName;
      updateLocationDetails.locationKey = values.locationKey;
      updateLocationDetails.locationInfo = values.locationDetails;

      if (id) {
        updateLocationData(updateLocationDetails).then((res: any) => {
          if (res.data) {
            setUpdateSuccess(true)
            setNavigateBack(true)
          } else {
            setUpdateSuccess(false)
            setNavigateBack(false)
          }
        })
      }
    }
  }

  async function regenerateToken({ token }: { token: string }) {
    let updateLocationDetails: Location = locationData
      ? Object.assign({}, locationData)
      : ({} as any);

    updateLocationDetails.locationToken = token;
    setGeneratingToken(true);
    await updateLocationData(updateLocationDetails).then((res: any) => {
      if (res.data) {
        setGeneratingToken(false);
        setTokenGenSuccess(true);
        refetchLocationData()
        setValue("locationToken", res?.data?.locationToken)
      } else {
        setGeneratingToken(false);
        setTokenGenSuccess(true);
      }
    })
  }

  return {
    locationData,
    locationDataLoading,
    handleSubmit,
    register,
    errors,
    onSubmit,
    locationTypeList,
    locationTypesLoading,
    navigateBack,
    updateSuccess,
    regenerateToken,
    generatingToken,
    tokenGenSuccess
  }
}