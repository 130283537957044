import { createSlice } from "@reduxjs/toolkit";
import { Login } from '../models/login.model';
import { RootState } from '../Store';

interface UserState {
    login: Login | null;
    language: string | null;
}

const initialStateValue: UserState = {
    login: null,
    language: 'en'
};

export const userSlice = createSlice({
    name: "user",
    initialState: initialStateValue,
    reducers: {
        changePreferenceLanguage: (state, action) => {
            state.language = action.payload;
        },
        changeToInitial: (state) => {
            state.login = null;
            state.language = 'en';
        },
        changeLogin: (state, action) => {
            state.login = action.payload;
        }
    }
});

export const getLogin = (state: RootState): Login => state.user.login;
export const getLanguage = (state: RootState): string => state.user.language;
export const { changePreferenceLanguage, changeToInitial, changeLogin } = userSlice.actions;
export default userSlice.reducer;
