import { BlockLifespanTypes } from "../models/blockLifespanTypes.model";
import { PagedData } from "../models/pagedData.model";
import { allApis } from "./allApi";

export const blockLifespanTypesApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        blockLifespanTypes: builder.query<PagedData<BlockLifespanTypes[]>, any>({
            query: () => "api/blockLifespanTypes",
            providesTags: ['BlockLifespanTypes']
        }),
        getBlockLifeSpanByPagination: builder.query<PagedData<BlockLifespanTypes[]>, any>({
            query: (args) => {
                return {
                    url: "api/blockLifespanTypes/getAll/page/",
                    params: args
                }
            },
            providesTags: ['BlockLifespanTypes']
        }),
        getBlockLifespanTypesById: builder.query<BlockLifespanTypes, any>({
            query: (id: string) => `api/blockLifespanTypes/${id}`,
        }),
        updateBlockLifespanTypesById: builder.mutation<BlockLifespanTypes[], any>({
            query: (blockLifespanTypes) => {
                return {
                    url: `api/blockLifespanTypes/${blockLifespanTypes.id}`,
                    method: "PUT",
                    body: blockLifespanTypes,
                }
            },
            invalidatesTags: ['BlockLifespanTypes']
        }),
        createBlockLifespanTypes: builder.mutation<BlockLifespanTypes[], Partial<BlockLifespanTypes>>({
            query: (blockLifespanTypes) => {
                return {
                    url: `api/blockLifespanTypes`,
                    method: "POST",
                    body: blockLifespanTypes,
                }
            },
            invalidatesTags: ['BlockLifespanTypes']
        }),
        deleteBlockLifespanTypesById: builder.mutation<any, any>({
            query: (id) => {
                return {
                    url: `api/blockLifespanTypes/${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ['BlockLifespanTypes']
        }),
        restoreBlockLifespanTypesById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `api/blockLifespanTypes/restore/${id}`,
                }
            },
            providesTags: ['BlockLifespanTypes']
        }),
    })
});

export const { useBlockLifespanTypesQuery, useGetBlockLifeSpanByPaginationQuery, useGetBlockLifespanTypesByIdQuery, useCreateBlockLifespanTypesMutation, useUpdateBlockLifespanTypesByIdMutation, useDeleteBlockLifespanTypesByIdMutation } = blockLifespanTypesApi;