import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

type Props = {
    value?: string;
    disabled?: boolean;
    placeholder: string;
    error?: string | any;
    onChange: (value: string) => void;
};
export function PhoneNumberField({
    value,
    placeholder,
    error,
    onChange,
}: Props) {
    return (

        <div className="relative rounded-lg z-30 focus:outline-none focus:ring-1 focus:ring-orange-300 focus:border-transparent border border-gray-300">
            <PhoneInput
                inputStyle={{
                    height: 45,
                    width: "100%",
                    borderRadius: 7,
                    border: error ? '1px solid red' : "#C0C0C0",
                }}
                buttonStyle={{
                    backgroundColor: "white",
                    borderTopLeftRadius: 7,
                    borderBottomLeftRadius: 7,
                    border: error ? '1px solid red' : "#C0C0C0",
                }}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
            {error && <ErrorIcon />}
        </div>
    )
}

const ErrorIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        fill="currentColor"
        className="absolute right-2 -mt-6 text-red-500"
        viewBox="0 0 1792 1792"
    >
        <path d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z" />
    </svg>
)