import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Icon from "../../Components/base/icon/icon"
import Spinner from "../../Components/Spinner"
import { useProfileCompanyHook } from "../../Hooks/ProfileCompanyHook"

const ProfileCompany = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const { companyData, companyName, isLoading, locations, products, users } = useProfileCompanyHook()

  if (isLoading || !companyData) {
    return (
      <div
        className={`md:h-full h-screen flex justify-center items-center bg-white`}
      >
        <Spinner className="h-8 w-8" color="#2984FF" />
      </div>
    );
  }

  return (
    <div className="h-full">
      <section>
        <div className="font-semibold flex gap-x-3 items-center">
          <span className='text-lg font-semibold'>{t('companyDetails')}</span>
          <span className='text-TT-blue hover:cursor-pointer'><Icon icon="EDIT" onClick={() => navigate(`/company/${companyData?.id}`)} /></span>
        </div>
        <div className="lg:w-1/2 mt-5">
          <div className="grid md:grid-cols-3 grid-cols-2">
            <div className="col-span-1">{t(`companyName`)}</div>
            <div className="md:col-span-2 col-span-1">{companyName}</div>
          </div>
          <div className="col-span-1 flex flex-col gap-3 mt-3 lg:w-1/2">
            <div className="grid grid-cols-3 gap-2 lg:gap-2 rounded-lg">
              <div className="flex flex-col cols-span-1 bg-TT-blue rounded-md items-center justify-start h-fit p-2 text-white py-4">
                <span className='text-3xl font-bold'>{products?.page?.totalElements}</span>
                <Icon icon="BLOCK" size='medium' />
                <span className='font-semibold'>{t('products')}</span>
              </div>
              <div className="flex flex-col cols-span-1 bg-TT-blue rounded-md items-center justify-start h-fit p-2 text-white py-4">
                <span className='text-3xl font-bold'>{locations?.page?.totalElements}</span>
                <Icon icon="LOCATION_WHITE" size='medium' />
                <span className='font-semibold'>{t('locations')}</span>
              </div>
              <div className="flex flex-col cols-span-1 bg-TT-blue rounded-md items-center justify-start h-fit p-2 text-white py-4">
                <span className='text-3xl font-bold'>{users?.page?.totalElements}</span>
                <Icon icon="USERS" size='medium' />
                <span className='font-semibold'>{t('users')}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='mt-5'>
        <div className="flex gap-x-3 items-center">
          <Icon icon="ADDRESS" size='medium' />
          <span className='font-semibold text-lg'>{t(`address`)}</span>
        </div>
        <div className="grid grid-cols-3 lg:w-1/2 mt-5 gap-y-2">
          <div className="col-span-1">{t(`address1`)}</div>
          <div className="col-span-2">{companyData?.address1}</div>
          <div className="col-span-1">{t(`address2`)}</div>
          <div className="col-span-2">{companyData?.address2}</div>
          <div className="col-span-1">{t(`city`)}</div>
          <div className="col-span-2">{companyData?.city}</div>
          <div className="col-span-1">{t(`prefecture`)}</div>
          <div className="col-span-2">{companyData?.prefecture}</div>
          <div className="col-span-1">{t(`country`)}</div>
          <div className="col-span-2">{companyData?.country}</div>
        </div>
      </section>
      <section className='mt-5'>
        <div className="flex gap-x-3 items-center">
          <Icon icon="ADDRESS" size='medium' />
          <span className='font-semibold text-lg'>{t(`contact`)}</span>
        </div>
        <div className="grid grid-cols-3 lg:w-1/2 mt-5 gap-y-2 mb-10">
          <div className="col-span-1">{t(`email`)}</div>
          <div className="col-span-2">{companyData?.email1}</div>
          <div className="col-span-1">{t(`phone`)}</div>
          <div className="col-span-2">{companyData?.phone}</div>
          {companyData?.contactPerson1 ?
            <>
              <div className="col-span-1">{t(`contactPerson1`)}</div>
              <div className="col-span-2">{companyData?.contactPerson1}</div>
            </> : null}
          {companyData?.contactPerson2 ? <>
            <div className="col-span-1">{t(`contactPerson2`)}</div>
            <div className="col-span-2">{companyData?.contactPerson2}</div>
          </> : null}
        </div>
      </section>
    </div>
  )
}


export default ProfileCompany