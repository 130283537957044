import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TrialVersion from "../../Components/base/TrialVersion";
import Pagination from "../../Components/Pagination";
import TableFormat from "../../Components/TableFormat";
import SearchAndFilter from "../../Components/TableNavBar/SearchAndFilter";
import { TableNavBar } from "../../Components/TableNavBar/TableNavBar";
import { useVerificationListHook } from "../../Hooks/VerificationListHooks";
import { setNavbarProps } from "../../Services/utilsReducer";
import { useGetVerificationListByPaginationQuery } from "../../Services/VerificationApi";
import { useAppDispatch } from "../../Store/hooks";
import { Action, ActionType, ComponentType } from "../Action";

function VerificationList() {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pageData, loading, fetchData, isEmpty, isError } = useVerificationListHook();
  const isTrial = true;

  const columns = React.useMemo(
    () => [
      {
        Header: t("gs1_prefix"),
        accessor: "gs1_prefix",
      },
      {
        Header: t("companyName"),
        accessor: "company_name",
      },
      {
        Header: t("city"),
        accessor: "city",
      },
      {
        Header: t("prefecture"),
        accessor: "prefecture",
      },
      {
        Header: t("country"),
        accessor: "country",
      },
      {
        Header: t("phone"),
        accessor: "phone",
      },
      {
        Header: t("action"),
        accessor: "",
        Cell: ({ row: { original } }: any) => (
          <Action
            id={original.id}
            types={[ActionType.View, ActionType.Delete]}
            deleteFunc={() => { }}
            navigation="verificationList"
            title="view History"
            componentType={ComponentType.Button}
          />
        ),
      },
    ], [t]);

  useEffect(() => {
    dispatch(setNavbarProps({
      navBarTitle: 'Verification List',
      navBarPrevPage: "",
      navBarUserAvatar: true,
    }));
  }, [])

  if (isTrial) {
    return <TrialVersion />
  }

  return (
    <TableNavBar
      tableTitle={"verificationList"}
      pageData={pageData}
      fetchData={fetchData}
      isEmpty={isEmpty}
      isError={isError}
      isLoading={loading}
    >
      <TableNavBar.SearchAndFilter>
        <SearchAndFilter />
      </TableNavBar.SearchAndFilter>
      <TableNavBar.Pagination>
        {pageData && <Pagination page={pageData.page} fetchData={fetchData} />}
      </TableNavBar.Pagination>
      <TableNavBar.TableFormat>
        {pageData && <TableFormat columns={columns} data={pageData?.data} _query={useGetVerificationListByPaginationQuery} />}
      </TableNavBar.TableFormat>
    </TableNavBar>
  );
}

export default VerificationList;
