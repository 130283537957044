import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Components/base/button/button';
import Field from '../../Components/base/field/field';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { toastError, toastSuccess } from '../../Components/toast';
import { useProductsHook } from '../../Hooks/ProductsHook';


function Masking() {
    const { id } = useParams()
    const { t } = useTranslation();
    const MaskingDataList = ['none', 'public', 'client']
    const {
        productData, onSubmit, register, watch, setValue, handleSubmit, navigateTo, setNavigateTo, updateSuccess, setUpdateSuccess, UpdateMaskingDataError
    } = useProductsHook({ id })

    useEffect(() => {
        if (UpdateMaskingDataError as any) {
            toastError((UpdateMaskingDataError as any)?.data?.message);
        }
    }, [UpdateMaskingDataError])

    useEffect(() => {
        if (navigateTo && updateSuccess === true) {
            toastSuccess(t('updatedSuccessfully'));
            setNavigateTo(false);
        }
        else if (!navigateTo && updateSuccess === false) {
            toastSuccess(t('failed'));
        }
    }, [navigateTo, updateSuccess]);

    useEffect(() => {
      if(watch('view_items') === 'none') {
        setValue('access_navigate', 'none');
      }
    }, [watch, watch('view_items'), setValue])
    
    return (
        <section className="md:h-[calc(100%-3.5rem)] h-screen rounded-lg shadow bg-white w-screen md:w-auto overflow-auto">
            <div className="w-screen md:w-full lg:w-3/5 p-5 md:pb-24 lg:pb-0">
                <form id="addMasking" onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
                        <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                            <label htmlFor="company" className='text-xs uppercase font-semibold'>{t(`company`)}</label>
                            <Field {...register('view_company')} type="select" className='px-2 h-12 w-full md:w-1/4'>
                                <option value={productData?.view_company} defaultValue={productData?.view_company}>{productData && t(productData.view_company)}</option>
                                {React.Children.toArray(MaskingDataList?.map((MaskingData: string) => (
                                    <>
                                        {productData?.view_company.toLowerCase() !== MaskingData.toLowerCase() &&
                                            <option value={MaskingData}>
                                                {t(MaskingData)}
                                            </option>
                                        }
                                    </>
                                )))}
                            </Field>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                            <label htmlFor="location" className='text-xs uppercase font-semibold'>{t(`location`)}</label>
                            <Field {...register('view_location')} type="select" className='px-2 h-12 w-full md:w-1/4'>
                                {<option value={productData?.view_location} defaultValue={productData?.view_location}>{productData && t(productData.view_location)}</option>}
                                {React.Children.toArray(MaskingDataList?.map((MaskingData: string) => (
                                    <>
                                        {productData?.view_location.toLowerCase() !== MaskingData.toLowerCase() &&
                                            <option value={MaskingData}>
                                                {t(MaskingData)}
                                            </option>}
                                    </>
                                )))}
                            </Field>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                            <label htmlFor="metadata" className='text-xs uppercase font-semibold'>{t(`metadata`)}</label>
                            <Field {...register('view_metadata')} type="select" className='px-2 h-12 w-full md:w-1/4'>
                                {<option value={productData?.view_metadata} defaultValue={productData?.view_metadata}>{productData && t(productData?.view_metadata)}</option>}
                                {React.Children.toArray(MaskingDataList?.map((MaskingData: string) => (
                                    <>
                                        {productData?.view_metadata.toLowerCase() !== MaskingData.toLowerCase() &&
                                            <option value={MaskingData}>
                                                {t(MaskingData)}
                                            </option>}
                                    </>
                                )))}
                            </Field>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                            <label htmlFor="items" className='text-xs uppercase font-semibold'>{t(`items`)}</label>
                            <Field {...register('view_items')} type="select" className='px-2 h-12 w-full md:w-1/4'>
                                {<option value={productData?.view_items} defaultValue={productData?.view_items}>{productData && t(productData?.view_items)}</option>}
                                {React.Children.toArray(MaskingDataList?.map((MaskingData: string) => (
                                    <>
                                        {productData?.view_items.toLowerCase() !== MaskingData.toLowerCase() &&
                                            <option value={MaskingData}>
                                                {t(MaskingData)}
                                            </option>}
                                    </>
                                )))}
                            </Field>
                        </div>
                        {watch('view_items') !== 'none' && <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                            <label htmlFor="itemsNavigate" className='text-xs uppercase font-semibold'>{t(`itemsNavigate`)}</label>
                            <Field {...register('access_navigate')} type="select" className='px-2 h-12 w-full md:w-1/4'>
                                {<option value={productData?.access_navigate} defaultValue={productData?.access_navigate}>{productData && t(productData?.access_navigate)}</option>}
                                {React.Children.toArray(MaskingDataList?.map((MaskingData: string) => (
                                    <>
                                        {productData?.access_navigate.toLowerCase() !== MaskingData.toLowerCase() &&
                                            <option value={MaskingData}>
                                                {t(MaskingData)}
                                            </option>}
                                    </>
                                )))}
                            </Field>
                        </div>}
                    </div>
                    <div className="flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-2 justify-center">
                        <Button
                            color="primary"
                            className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
                            form="addMasking"
                            submit
                        >
                            {t("addMasking")}
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Masking