import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Field from "../../../Components/base/field/field";
import Button from "../../../Components/base/button/button";
import Icon from "../../../Components/base/icon/icon";
import { Combobox, Transition } from "@headlessui/react";
import { useBlockConfigHook } from "../../../Hooks/BlockConfigHooks";
import { toastError, toastSuccess } from "../../../Components/toast";

function AddBlock() {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        errors,
        getBlockData,
        onSubmit,
        locationType,
        setLocationType,
        locationTypeData,
        addLocationType,
        removeLocationType,
        locationTypeQuery,
        setLocationTypeQuery,
        locationTypeOption,
        codeTypeOptions,
        codeTypeQuery,
        codeTypes,
        setCodeTypeQuery,
        navigateTo,
        setNavigateTo,
        updateSuccess,
        removeCode,
        addCode,
        createBlockDataError,
        updateBlockDataError,
        createSuccess,
    } = useBlockConfigHook({ id });

    let locationTypeDatum;
    if (locationTypeOption) {
        locationTypeDatum =
            locationTypeQuery === ""
                ? locationTypeOption
                : locationTypeOption.filter((locationType: any) =>
                    locationType
                        .toLowerCase()
                        .replace(/\s+/g, "")
                        .includes(locationTypeQuery.toLowerCase().replace(/\s+/g, ""))
                );
    }

    let filteredCodes;
    if (codeTypeOptions) {
        filteredCodes =
            codeTypeQuery === ""
                ? codeTypeOptions
                : codeTypeOptions.filter((codeTypes: any) =>
                    codeTypes
                        .toLowerCase()
                        .replace(/\s+/g, "")
                        .includes(codeTypeQuery.toLowerCase().replace(/\s+/g, ""))
                );
    }

    useEffect(() => {
        if (createBlockDataError as any) {
            toastError((createBlockDataError as any)?.data?.message);
        }
        if (updateBlockDataError as any) {
            toastError((updateBlockDataError as any)?.data?.message);
        }
    }, [createBlockDataError, updateBlockDataError])
    useEffect(() => {
        if (navigateTo && updateSuccess === true) {
            toastSuccess(t("updatedSuccessfully"));
            setNavigateTo(false);
            navigate(-1);
        } else if (!navigateTo && updateSuccess === false) {
            toastSuccess(t("failed"));
        }
    }, [navigateTo, updateSuccess]);
    useEffect(() => {
        if (navigateTo && createSuccess === true) {
            toastSuccess(t("createdSuccessfully"));
            setNavigateTo(false);
            navigate(-1);
        } else if (!navigateTo && createSuccess === false) {
            toastSuccess(t("failed"));
        }
    }, [navigateTo, createSuccess]);

    return (
        <section className="md:h-[calc(100%-4.3rem)]  md:m-2 rounded-lg shadow bg-white w-screen md:w-auto overflow-auto">
            <div className="px-5 pb-5 pt-4  bg-TT-background md:bg-white h-full w-screen md:w-full rounded-lg overflow-auto">
                <form id="addDeliveryPath" className='w-full lg:w-3/5' onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
                        <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                            <label
                                htmlFor="name"
                                className="text-xs font-semibold text-TT-gray uppercase"
                            >
                                {t(`name`)}
                            </label>
                            <Field
                                {...register("name")}
                                error={errors?.name && `${errors?.name?.message}`}
                                type="text"
                                defaultValue={getBlockData?.name}
                                placeholder={t(`entername`)}
                                id="name"
                                className="px-2 h-12"
                            />
                        </div>
                        {/* locationtype */}
                        <div className="col-span-12 flex flex-col gap-2 ">
                            <label
                                htmlFor="locationType"
                                className="text-xs font-semibold text-TT-gray uppercase"
                            >
                                {t(`locationType`)}
                            </label>
                            <div className="flex gap-x-2 ">
                                {locationType &&
                                    locationType?.map((item, index) => (
                                        <div
                                            className="flex gap-x-2 bg-TT-blue rounded-lg text-white px-2 items-center"
                                            key={index}
                                        >
                                            <div>{item}</div>
                                            <div
                                                className="hover:text-red-500 rounded-full p-0.5"
                                                onClick={() => removeLocationType(item)}
                                            >
                                                <Icon icon="CLOSE" size="x-small" />
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            {locationTypeData && locationType && (
                                <div className="my-2 md:my-0 md:w-1/2 pr-3.5">
                                    <Combobox value={locationType} onChange={() => { }}>
                                        <div className=" relative ">
                                            <div>
                                                <Combobox.Input
                                                    className="z-10 leading-5 relative flex flex-1 w-full rounded-lg p-input bg-white text-gray-700 md:text-base placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-TT-blue focus:border-transparent border border-gray-300 h-12 pl-2.5"
                                                    onChange={() => { }}
                                                    onSelect={(event: any) => {
                                                        setLocationTypeQuery(event.target.value);
                                                    }}
                                                    placeholder={t("locationType")}
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setLocationTypeQuery("")}
                                            >
                                                <Combobox.Options className="focus:outline-none mt-1 max-h-60 w-full  absolute overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm z-50">
                                                    {locationTypeDatum &&
                                                        locationTypeDatum?.length === 0 ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        locationTypeDatum?.map((locationType: any) => (
                                                            <Combobox.Option
                                                                key={locationType.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-5 pr-4 mx-1 rounded-lg ${active
                                                                        ? "bg-TT-blue text-white"
                                                                        : "text-gray-900"
                                                                    }`
                                                                }
                                                                value={locationType}
                                                                onClick={() => addLocationType(locationType)}
                                                            >
                                                                {({ selected, active }) => (
                                                                    <span
                                                                        id="txt_selectedlocationType"
                                                                        className={`block truncate ${selected ? "font-medium" : "font-normal"
                                                                            }`}
                                                                    >
                                                                        {locationType}
                                                                    </span>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>
                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>
                            )}
                        </div>

                        {/* codetype */}
                        <div className="col-span-12 flex flex-col gap-2">
                            <label
                                htmlFor="locationType"
                                className="text-xs font-semibold text-TT-gray uppercase"
                            >
                                {t(`CodeType`)}
                            </label>
                            <div className="flex gap-x-2 ">
                                {codeTypes.map((item, index) => (
                                    <div
                                        className="flex gap-x-2 bg-TT-blue rounded-lg text-white px-2 items-center"
                                        key={index}
                                    >
                                        <div>{item}</div>
                                        <div
                                            className="hover:text-red-500 rounded-full p-0.5"
                                            onClick={() => removeCode(item)}
                                        >
                                            <Icon icon="CLOSE" size="x-small" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {
                                <div className="z-30 my-2 md:my-0 md:w-1/2 pr-3.5">
                                    <Combobox value={""} onChange={() => { }}>
                                        <div className="relative ">
                                            <div>
                                                <Combobox.Input
                                                    className="z-10 leading-5 relative flex flex-1 w-full rounded-lg p-input bg-white text-gray-700 md:text-base placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-TT-blue focus:border-transparent border border-gray-300 h-12 pl-2.5"
                                                    onChange={() => { }}
                                                    onSelect={(event: any) => {
                                                        setCodeTypeQuery(event.target.value);
                                                    }}
                                                    placeholder={t("CodeType")}
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setCodeTypeQuery("")}
                                            >
                                                <Combobox.Options className="focus:outline-none absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                                                    {filteredCodes && filteredCodes?.length === 0 ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredCodes?.map((codeTypeList) => (
                                                            <Combobox.Option
                                                                key={codeTypeList}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-5 pr-4 mx-1 rounded-lg ${active
                                                                        ? "bg-TT-blue text-white"
                                                                        : "text-gray-900"
                                                                    }`
                                                                }
                                                                value={codeTypeList}
                                                                onClick={() => addCode(codeTypeList)}
                                                            >
                                                                {({ selected, active }) => (
                                                                    <span
                                                                        id="txt_selectedlocationType"
                                                                        className={`block truncate ${selected ? "font-medium" : "font-normal"
                                                                            }`}
                                                                    >
                                                                        {codeTypeList}
                                                                    </span>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>
                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>
                            }
                        </div>

                        <div className="md:col-span-6 col-span-12 flex flex-col gap-2">
                            <label
                                htmlFor="displayOrder"
                                className="text-xs font-semibold text-TT-gray uppercase"
                            >
                                {t(`code`)}
                            </label>
                            <div className="flex flex-row gap-x-3">
                                <span className="flex gap-x-2 bg-TT-blue rounded-lg text-white px-2 items-center">
                                    {t("code")}
                                </span>
                                <span className="flex gap-x-2 bg-TT-blue rounded-lg text-white px-2 items-center">
                                    {t("productCode")}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-2 justify-center">
                        <Button
                            color="primary"
                            className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
                            form="addDeliveryPath"
                            submit
                        >
                            {id ? `${t("update")}` : `${t("addBlock")}`}
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AddBlock;
