import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Icon from "../Components/base/icon/icon";
import { toastError, toastSuccess } from "../Components/toast";
import { productApi } from "../Services/productApi";
import { useAppDispatch } from "../Store/hooks";

export enum ActionType {
  "Edit",
  "Delete",
  "View",
  "QR",
  "Restore",
}
export enum ComponentType {
  "Icon",
  "Button",
}
interface IAction {
  id: string;
  types: ActionType[];
  deleteFunc?: any;
  restoreFunc?: any;
  invalidateFunc?: any;
  QRFunc?: Function;
  mode?: string;
  title?: string;
  success?: any;
  navigation?: string;
  componentType?: ComponentType;
}

export function Action({
  id,
  types,
  deleteFunc,
  restoreFunc,
  invalidateFunc,
  mode = "",
  title = "",
  success,
  navigation = "view",
  componentType = ComponentType.Icon,
  QRFunc,
}: IAction) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deleteSuccess, setDeleteSuccess] = useState<boolean | undefined>();
  const [restoreSuccess, setRestoreSuccess] = useState<boolean | undefined>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (deleteSuccess === true) {
        toastSuccess(t("deletedSuccessfully"));
    } else if (deleteSuccess === false) {
      toastError("deleteFailed");
    }
  }, [deleteSuccess, t]);
  
  useEffect(() => {
    if (restoreSuccess === true) {
        toastSuccess(t("restoredSuccessfully"));
    } else if (restoreSuccess === false) {
      toastError("restoreFailed");
    }
  }, [restoreSuccess, t]);

  return (
    <div className="flex gap-x-3">
      {types.includes(ActionType.View) && id && (
        <Icon
          icon="EDIT"
          onClick={() => navigate(navigation)}
          className="cursor-pointer"
        />
      )}
      {types.includes(ActionType.Delete) && id && (
        <Icon
          icon="DELETE"
          onClick={() => {
            deleteFunc(id).then((res: any) => {
              if(typeof(res.data) == "object") {
                setDeleteSuccess(true);
              } else {
                setDeleteSuccess(res.data);
              }
            });
          }}
          className="cursor-pointer"
        />
      )}
      {types.includes(ActionType.Restore) && id && (
        <Icon
          icon="RESTORE"
          onClick={() => {
            restoreFunc().then((res: any) => {
              setRestoreSuccess(true);
              invalidateFunc();
            }).catch(()=>{
              setRestoreSuccess(false);
            });
          }}
          className="cursor-pointer"
        />
      )}
      {types.includes(ActionType.QR) && id && (
        <Icon
          icon="CODE"
          onClick={() => QRFunc && QRFunc(id)}
          className="cursor-pointer"
        />
      )}
    </div>
  );
}
