import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../Components/base/button/button";
import Pagination from "../../../Components/Pagination";
import TableFormat from "../../../Components/TableFormat";
import { Action, ActionType, ComponentType } from "../../Action";
import { useApiTokenHook } from "../../../Hooks/ApiToken";
import { formatDatecalender } from "../../../Utils/datetime";
import { TableNavBar } from "../../../Components/TableNavBar/TableNavBar";
import SearchAndFilter from "../../../Components/TableNavBar/SearchAndFilter";

function APITokens() {
  const { t } = useTranslation();

  const { pageData, updateApiTokenValidity, create, deleteApiToken, fetchData, isEmpty, isError, loading } =
    useApiTokenHook();

  const columns = React.useMemo(
    () => [
      {
        Header: t("token"),
        accessor: "token",
      },
      {
        Header: t("createdAt"),
        accessor: "createdAt",
        Cell: ({ row: { original } }: any) => (
          <div className="flex gap-x-2 items-center">
            {formatDatecalender(original.createdAt)}{" "}
          </div>
        ),
      },
      {
        Header: t("expire"),
        accessor: "expire",
        Cell: ({ row: { original } }: any) => (
          <div className="flex gap-x-2 items-center">
            {formatDatecalender(original.expire)}{" "}
            <Button
              onClick={() => updateApiTokenValidity(original)}
              color="primary"
              className="py-1 flex whitespace-nowrap gap-x-1 items-center text-xs md:text-sm"
              size="sm"
            >
              {t("extendValidity")}
            </Button>{" "}
          </div>
        ),
      },
      {
        Header: t("action"),
        accessor: "",
        Cell: ({ row: { original } }: any) => (
          <Action
            id={original.id}
            types={[ActionType.Delete]}
            deleteFunc={() => deleteApiToken(original)}
            navigation="editProduct/productInfo"
            title="view History"
            componentType={ComponentType.Button}
          />
        ),
      },
    ], [t]);

  return (
    <TableNavBar
      tableTitle={"config"}
      isClick={true}
      navClick={() => { create() }}
      buttonTitle={"createToken"}
      pageData={pageData}
      fetchData={fetchData}
      isError={isError}
      isLoading={loading}
      isEmpty={isEmpty}
    >
      <TableNavBar.SearchAndFilter>
        <SearchAndFilter />
      </TableNavBar.SearchAndFilter>
      <TableNavBar.Pagination>
        {pageData && <Pagination page={pageData?.page} fetchData={fetchData} />}
      </TableNavBar.Pagination>
      <TableNavBar.TableFormat>
        {pageData && <TableFormat columns={columns} data={pageData?.data} />}
      </TableNavBar.TableFormat>
    </TableNavBar>
  );
}

export default APITokens;
