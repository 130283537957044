import Field from "../../Components/base/field/field";
import { useTranslation } from "react-i18next";
import Button from "../../Components/base/button/button";
import { useNavigate, useParams } from "react-router-dom";
import { useLocationHook } from "../../Hooks/LocationHook";
import { Fragment, useEffect, useState } from "react";
import { toastError, toastSuccess } from "../../Components/toast";
import { useAppDispatch } from "../../Store/hooks";
import { setNavbarProps } from "../../Services/utilsReducer";
import { PhoneNumberField } from "../../Components/base/PhoneInput/PhoneNumberInput";
import { Controller } from "react-hook-form";
import { Combobox, Transition } from "@headlessui/react";
import Spinner from "../../Components/Spinner";

function AddLocation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    locationData,
    navigateTo,
    setNavigateTo,
    updateSuccess,
    createLocationError,
    updateLocationError,
    createSuccess,
    control,
    selectedLocationType,
    setLocationTypeQuery,
    locationTypeDatum,
    addLocationType,
  } = useLocationHook({ id });

  useEffect(() => {
    dispatch(
      setNavbarProps({
        navBarTitle: id ? "editLocation" : "addLocation",
        navBarPrevPage: "/location",
        navBarUserAvatar: true,
      })
    );
  }, []);

  useEffect(() => {
    if (createLocationError as any) {
      toastError((createLocationError as any)?.data?.message);
    }
    if (updateLocationError as any) {
      toastError((updateLocationError as any)?.data?.message);
    }
  }, [createLocationError, updateLocationError]);

  useEffect(() => {
    if (navigateTo && updateSuccess === true) {
      toastSuccess(t("updatedSuccessfully"));
      setNavigateTo(false);
      navigate("/location");
    } else if (!navigateTo && updateSuccess === false) {
      toastError(t("failed"));
    }
  }, [navigateTo, updateSuccess]);

  useEffect(() => {
    if (navigateTo && createSuccess === true) {
      toastSuccess(t("createdSuccessfully"));
      setNavigateTo(false);
      navigate("/location");
    } else if (!navigateTo && createSuccess === false) {
      toastError(t("failed"));
    }
  }, [navigateTo, createSuccess]);

  if (id && !locationData) {
    return (
      <div
        className={`md:h-full h-screen flex justify-center items-center bg-white`}
      >
        <Spinner className="h-8 w-8" color="#2984FF" />
      </div>
    );
  }

  return (
    <>
      <section className="md:px-2 mt-[7.6rem] md:mt-2 h-full md:pb-20">
        <div className="px-5 pb-5 pt-4  bg-TT-background md:bg-white h-full w-screen md:w-full rounded-lg overflow-auto">
          <form
            id="addLocation"
            className="md:w-full lg:w-3/5"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="locationType"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`locationType`)}
                </label>

                <div className="h-12 w-full">
                  <Combobox value={selectedLocationType} onChange={() => {}}>
                    <div className=" relative ">
                      <div>
                        <Combobox.Input
                          className="z-10 leading-5 relative flex flex-1 w-full rounded-lg p-input bg-white text-gray-700 md:text-base placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-TT-blue focus:border-transparent border border-gray-300 h-12 pl-2.5"
                          onChange={() => {}}
                          onSelect={(event: any) => {
                            console.log("target", event.target.value)
                            setLocationTypeQuery(event.target.value);
                          }}
                          placeholder={t("locationType")}
                          autoComplete="off"
                        />
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setLocationTypeQuery("")}
                      >
                        <Combobox.Options className="focus:outline-none mt-1 max-h-60 w-full  absolute overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm z-50">
                          {locationTypeDatum &&
                          locationTypeDatum?.length === 0 ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                              Nothing found.
                            </div>
                          ) : (
                            locationTypeDatum?.map((locationType: any) => (
                              <Combobox.Option
                                key={locationType}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-5 pr-4 mx-1 rounded-lg ${
                                    active
                                      ? "bg-TT-blue text-white"
                                      : "text-gray-900"
                                  }`
                                }
                                value={locationType}
                                onClick={() => addLocationType(locationType)}
                              >
                                {({ selected, active }) => (
                                  <span
                                    id="txt_selectedlocationType"
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    {locationType}
                                  </span>
                                )}
                              </Combobox.Option>
                            ))
                          )}
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </Combobox>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="metaCode"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`metaCode`)}
                </label>
                <Field
                  {...register("metaCode")}
                  error={errors?.metaCode && `${errors?.metaCode?.message}`}
                  type="text"
                  defaultValue={locationData?.locationMetaCode}
                  placeholder={t(`metaCode`)}
                  id="metaCode"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="locationKey"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`locationKey`)}
                </label>
                <Field
                  {...register("locationKey")}
                  error={
                    errors?.locationKey && `${errors?.locationKey?.message}`
                  }
                  type="text"
                  defaultValue={locationData?.locationKey}
                  placeholder={t(`locationKey`)}
                  id="locationKey"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="locationName"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`locationName`)}
                </label>
                <Field
                  {...register("locationName")}
                  error={
                    errors?.locationName && `${errors?.locationName?.message}`
                  }
                  type="text"
                  defaultValue={locationData?.location}
                  placeholder={t(`locationName`)}
                  id="locationName"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="latitude"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`latitude`)}
                </label>
                <Field
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  {...register("latitude")}
                  error={errors?.latitude && `${errors?.latitude?.message}`}
                  type="text"
                  defaultValue={locationData?.latitude}
                  placeholder={t(`latitude`)}
                  id="latitude"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="longitude"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`longitude`)}
                </label>
                <Field
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  {...register("longitude")}
                  error={errors?.longitude && `${errors?.longitude?.message}`}
                  type="text"
                  defaultValue={locationData?.longitude}
                  placeholder={t(`longitude`)}
                  id="longitude"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 flex flex-col gap-2">
                <label
                  htmlFor="locationdetails"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`locationDetails`)}
                </label>
                <Field
                  {...register("locationdetails")}
                  error={
                    errors?.locationdetails &&
                    `${errors?.locationdetails?.message}`
                  }
                  type="textarea"
                  rows={3}
                  defaultValue={locationData?.locationInfo}
                  placeholder={t(`locationDetails`)}
                  id="locationdetails"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="country"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`country`)}
                </label>
                <Field
                  {...register("country")}
                  error={errors?.country && `${errors?.country?.message}`}
                  type="text"
                  defaultValue={locationData?.country}
                  placeholder={t(`country`)}
                  id="country"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="pincode"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`pincode`)}
                </label>
                <Field
                  {...register("pincode")}
                  error={errors?.pincode && `${errors?.pincode?.message}`}
                  type="text"
                  defaultValue={locationData?.pincode}
                  placeholder={t(`pincode`)}
                  id="pincode"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="phone"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`phone`)}
                </label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <PhoneNumberField
                      value={locationData?.phone}
                      placeholder={t("enterphone")}
                      onChange={onChange}
                      error={errors?.phone?.message}
                    />
                  )}
                />
                {(errors?.phone as any)?.message && (
                  <span className="text-sm text-red-500 mt-2">
                    {t((errors?.phone as any)?.message)}
                  </span>
                )}
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="email"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`email`)}
                </label>
                <Field
                  {...register("email")}
                  error={errors?.email && `${errors?.email?.message}`}
                  type="text"
                  defaultValue={locationData?.email}
                  placeholder={t(`email`)}
                  id="email"
                  className="px-2 h-12"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-2 justify-center">
                <Button
                  color="primary"
                  className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
                  form="addLocation"
                  submit
                >
                {id ? `${t("update")}` : `${t("addLocation")}`}
              </Button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default AddLocation;
