import { yupResolver } from '@hookform/resolvers/yup';
import { useFetchDataHook } from "./FetchData";
import { useCreateBlockConfigMutation, useDefaultBlockIdUpdateMutation, useDeleteBlockByIdMutation, useGetBlockConfigByIdQuery, useGetBlockConfigByPaginationQuery, useUpdateBlockConfigByIdMutation } from "../Services/BlockConfigApi";
import { useForm } from "react-hook-form";
import { addConfigBlockValidationSchema } from '../Utils/validation';
import { useEffect, useState } from 'react';
import { useLocationTypesQuery } from '../Services/locationTypesApi';
import { useGetAllCodeTypesQuery } from '../Services/codeTypeApi';
import { Block } from '../models/block.model';

interface _Props {
    id: string | undefined
}
export const useBlockConfigHook = (props: _Props) => {
    const { id } = props
    const { pageData, loading, fetchData, model, refetching, isEmpty, isError } = useFetchDataHook(useGetBlockConfigByPaginationQuery);
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(addConfigBlockValidationSchema) });
    const { data: getBlockData } = useGetBlockConfigByIdQuery(id, { skip: !id })
    const [deleteBlock] = useDeleteBlockByIdMutation();
    const [updateBlockData, { error: updateBlockDataError }] = useUpdateBlockConfigByIdMutation()
    const [createBlockData, { error: createBlockDataError }] = useCreateBlockConfigMutation()
    const [defaultBlockIdUpdate] = useDefaultBlockIdUpdateMutation()
    const { data: locationTypeData, isSuccess: locationTypeSuccess } = useLocationTypesQuery({ id })
    const [locationTypeOption, setLocationTypeOption] = useState<string[]>([])
    const [locationType, setLocationType] = useState<string[]>([]);
    const [locationTypeResult, setLocationTypeResult] = useState<any | undefined>([]);
    const [locationTypeQuery, setLocationTypeQuery] = useState('')

    const { data: codeTypeData, isSuccess: codeTypeSuccess } = useGetAllCodeTypesQuery({ id: "" })
    const [codeTypeOptions, setCodeTypeOptions] = useState<string[]>([]);
    const [codeTypes, setCodeTypes] = useState<string[]>([]);
    const [codeTypeResult, setCodeTypeResult] = useState<any | undefined>([]);
    const [codeTypeQuery, setCodeTypeQuery] = useState('')
    const [navigateTo, setNavigateTo] = useState<boolean>()
    const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();
    const [createSuccess, setCreateSuccess] = useState<boolean | undefined>();

    const addLocationType = (item: any) => {
        if (item && !locationType.includes(item)) {
            setLocationType([...locationType, item]);
            setLocationTypeOption(locationTypeOption.filter(c => c !== item));
        }
    }

    const removeLocationType = (location: any) => {
        setLocationType(locationType.filter(c => c !== location));
        setLocationTypeOption([...locationTypeOption, location]);
    }

    const addCode = (item: any) => {
        if (item && !codeTypes.includes(item)) {
            setCodeTypes([...codeTypes, item]);
            setCodeTypeOptions(codeTypeOptions.filter(c => c !== item));
        }
    }

    const removeCode = (code: any) => {
        setCodeTypes(codeTypes.filter(c => c !== code));
        setCodeTypeOptions([...codeTypeOptions, code]);
    }

    const onSubmit = (value: any) => {
        let updateBlockConfigDetails: Block = getBlockData
            ? Object.assign({}, getBlockData)
            : ({} as any);

        updateBlockConfigDetails.name = value.name;
        updateBlockConfigDetails.locationTypes = locationTypeResult;
        updateBlockConfigDetails.codes = codeTypeResult

        if (id) {
            updateBlockData(updateBlockConfigDetails).then((res: any) => {
                if (res.data) {
                    setNavigateTo(true)
                    setUpdateSuccess(true)
                    console.log("update success: ", res.data)
                }
                else {
                    setNavigateTo(false)
                    setUpdateSuccess(false)
                    console.log("update errors: ", res.data)
                }
            })
        }
        else if (!id) {
            createBlockData(updateBlockConfigDetails).then((res: any) => {
                if (res.data) {
                    setNavigateTo(true)
                    setCreateSuccess(true)
                    console.log("create success: ", res.data)
                }
                else {
                    setNavigateTo(false)
                    setCreateSuccess(false)
                    console.log("create errors: ", res.data)
                }
            })
        }
    }

    useEffect(() => {
        if (getBlockData) {
            reset(getBlockData)
            setLocationType([])
            getBlockData?.locationTypes?.map((values) => {
                setLocationType((value) => [...value, values.locationType]);
            })
        }
    }, [getBlockData])

    useEffect(() => {
        if (locationTypeData && locationTypeSuccess) {
            locationTypeData?.map((e) => {
                setLocationTypeOption((value) => [...value, e.locationType]);
            })
        }
    }, [locationTypeSuccess, locationTypeData])

    useEffect(() => {
        setLocationTypeResult([])
        if (locationType && locationTypeData) {
            locationType.forEach((value) =>
                setLocationTypeResult((val1: any) => [...val1, locationTypeData.find((val) =>
                    value === val.locationType
                )])
            )
        }
    }, [locationTypeSuccess, locationType, locationTypeData])


    useEffect(() => {
        if (getBlockData) {
            reset(getBlockData)
            setCodeTypes([])
            getBlockData?.codes.map((e) => {
                setCodeTypes((value) => [...value, e?.code])
            })
        }
    }, [getBlockData])

    useEffect(() => {
        if (codeTypeData && codeTypeSuccess) {
            codeTypeData?.map((e) => {
                setCodeTypeOptions((value: any) => [...value, e.code])
            })
        }
    }, [codeTypeSuccess, codeTypeData])

    useEffect(() => {
        setCodeTypeResult([])
        if (codeTypes && codeTypeData) {
            codeTypes.forEach((value) =>
                setCodeTypeResult((val1: any) => [...val1, codeTypeData.find((val) =>
                    value === val.code
                )])
            )
        }
    }, [codeTypeSuccess, codeTypes, codeTypeData])

    return {
        pageData, loading, fetchData, model,
        refetching, deleteBlock, register, handleSubmit,
        reset, errors, getBlockData, onSubmit, locationType, setLocationType,
        locationTypeData, addLocationType, removeLocationType, locationTypeQuery, setLocationTypeQuery, locationTypeOption,
        codeTypes, setCodeTypes, codeTypeData, addCode, removeCode, codeTypeQuery, setCodeTypeQuery, codeTypeOptions, navigateTo,
        setNavigateTo, updateSuccess, defaultBlockIdUpdate, isEmpty, isError, createBlockDataError, updateBlockDataError, createSuccess, setCreateSuccess,
    };
}