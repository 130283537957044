import { allApis } from './allApi';
import { VerificationCompany } from '../models/verificationCompany.model';

export const verificationApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getVerificationList: builder.query<VerificationCompany[], void>({
            query: () => "api/company/all/verification",
            providesTags: ["VerificationCompany"],
        }),
        getVerificationListByPagination: builder.query<VerificationCompany[], any>({
            query: (args) => {
                return {
                    url: "api/company/all/verification",
                    params: args
                }
            },
            providesTags: ["VerificationCompany"],
        }),
    }),

});

export const { useGetVerificationListQuery, useGetVerificationListByPaginationQuery } = verificationApi;
