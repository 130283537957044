import { Children, HTMLAttributes, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { FetchDataType } from "../../Hooks/FetchData";
import { setNavbarProps } from "../../Services/utilsReducer";
import { useAppDispatch } from "../../Store/hooks";
import Button from "../base/button/button";
import Icon from "../base/icon/icon";
import NoDataAvailable from "../base/NoDataAvailable";
import Select from "../base/Select";
import ErrorScreen from "../base/WentWrong";
import Spinner from "../Spinner";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  tableTitle?: string;
  navLink?: string;
  buttonTitle?: string;
  fetchData?: (args: FetchDataType) => void;
  pageData?: any;
  isLoading?: boolean;
  isEmpty?: boolean;
  isError?: boolean;
  isClick?: boolean;
  navClick?: Function;
}

export function TableNavBar({
  children,
  navLink,
  tableTitle,
  buttonTitle,
  fetchData,
  pageData,
  isLoading = false,
  isEmpty = false,
  isError = false,
  isClick = false,
  navClick
}: Props) {

  const { t } = useTranslation();
  const numbers = [5, 10, 15, 50, 100, 200, 500, 1000];
  const location = useLocation();
  const dispatch = useAppDispatch();
  
  const URL = location.pathname.split("/");
  const tabLinks = URL.includes("config") || URL.includes("types");
  let _searchAndFilter, _pagination, _tableFormatView, _tableFiler;

  useEffect(() => {
    dispatch(setNavbarProps({
      navBarTitle: tableTitle,
      navBarPrevPage: "",
      navBarUserAvatar: true,
    }));

  }, [tableTitle])
  
  function onPageChange(pageSize: number) {
    fetchData &&
      fetchData({
        pageSize,
        pageIndex: pageData?.page.pageIndex,
        totalElements: pageData?.page.totalElements,
      } as FetchDataType);
  }

  Children.forEach(children, (child: any) => {
    if (child?.type === TableSearchAndFilterBar) {
      return (_searchAndFilter = child);
    }

    if (child?.type === TablePagination) {
      return (_pagination = child);
    }

    if (child?.type === TableFormatView) {
      return (_tableFormatView = child);
    }

    if (child?.type === TableFiler) {
      return (_tableFiler = child);
    }
  });

  return (
    <>
      {isLoading && (
        <div className={`${!tabLinks ? 'mt-[7.6rem] md:h-full h-screen' : 'md:h-[calc(100%-4.3rem)] h-full'} md:m-2 rounded-lg shadow md:w-auto min-w-full flex justify-center items-center bg-white`}>
          <Spinner className="h-8 w-8" color="#2984FF" />
        </div>
      )}
      {!pageData?.page?.pageSize && (
        <>
          {isEmpty && (
            <div className={`${!tabLinks ? 'mt-[7.6rem] h-full' : 'md:h-[calc(100%-4.3rem)] h-full'} md:m-2 rounded-lg md:w-auto w-screen flex justify-center items-center bg-white`}>
              <NoDataAvailable />
            </div>
          )}
          {isError && (
            <div className={`${!tabLinks ? 'mt-[7.6rem] h-full' : 'md:h-[calc(100%-4.3rem)] h-full'} md:m-2 rounded-lg md:w-auto w-screen flex justify-center items-center bg-white`}>
              <ErrorScreen />
            </div>
          )}
        </>
      )}
      {pageData?.page?.pageSize && (
        <section className={`${!tabLinks ? 'mt-[7.6rem] h-full' : 'md:h-[calc(100%-4.3rem)] h-full'} md:m-2 rounded-lg shadow bg-white w-screen md:w-auto overflow-auto`}>
          <div className="p-0.5">
          <div className="flex justify-between p-3 border-b flex-wrap gap-2">
            {_tableFiler ? _tableFiler : _searchAndFilter}
            <div className="flex gap-x-3">
              <div className="flex justify-center md:justify-start mt-2 md:mt-0 flex-wrap gap-y-2 md:gap-y-0 items-center gap-x-3">

                {!isClick && navLink ? (
                  <NavLink to={navLink}>
                    <Button
                      color="primary"
                      className="py-1 flex whitespace-nowrap gap-x-1 items-center text-xs md:text-sm"
                      size="sm"
                    >
                      <>
                        <Icon icon="ADD_BLACK" size="x-small" />
                        {t(buttonTitle ?? "add")}
                      </>
                    </Button>
                  </NavLink>
                ) : isClick && navClick && (<>
                  <Button
                    onClick={() => navClick()}
                    color="primary"
                    className="py-1 flex whitespace-nowrap gap-x-1 items-center text-xs md:text-sm"
                    size="sm"
                  >
                    <>
                      <Icon icon="ADD_BLACK" size="x-small" />
                      {t(buttonTitle ?? "add")}
                    </>
                    </Button></>)}
                <div className="flex items-center gap-x-3 flex-none">
                  <Select
                    items={numbers}
                    onSelect={onPageChange}
                    label={pageData?.page?.pageSize?.toString()}
                  />
                    {pageData?.page?.totalElements && <span className="text-xs md:text-sm">{`${t('of')} ${pageData?.page?.totalElements}`}</span>}
                </div>
                {_pagination}
              </div>
            </div>
          </div>
          <section className="h-fit overflow-hidden">
            {!isLoading && !isError && isEmpty && (
                <div className="flex-1 overflow-x-scroll">
                  {_tableFormatView}
                  <NoDataAvailable />
                </div>
            )}
            <div className="flex-1 overflow-x-scroll">
              {!isEmpty && !isError && _tableFormatView}
            </div>
          </section>
          </div>
        </section>
      )}
    </>
  );
}

const TableSearchAndFilterBar = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-col md:flex-row justify-center w-full md:justify-start md:w-auto md:gap-x-2 gap-y-2">
    {children}
  </div>
);
const TablePagination = ({ children }: { children: ReactNode }) => (
  <>{children}</>
);
const TableFormatView = ({ children }: { children: ReactNode }) => (
  <>{children}</>
);
const TableFiler = ({ children }: { children: ReactNode }) => <>{children}</>;

TableNavBar.SearchAndFilter = TableSearchAndFilterBar;
TableNavBar.Pagination = TablePagination;
TableNavBar.TableFormat = TableFormatView;
TableNavBar.Filter = TableFiler;
