import * as yup from "yup";
import { testImage } from "./functions";
import "yup-phone";

const emailSchema = yup
  .string()
  .email("enterValidEmail")
  .transform((curr, orig) => (orig === null ? "" : curr))
  .required("required")
  .max(50, "emailCanBeMax50CharactersLong");

const passwordvalidation = yup
  .string()
  .trim()
  .required("required")
  .transform((curr, orig) => (orig === null ? "" : curr))
  .test("password check", ``, function (value) {
    const message = [];
    if (value) {
      if (value.length < 8) {
        message.push("greaterThanOrEqualTo8Characters");
      }

      if (value.length > 25) {
        message.push("lessThanOrEqualTo25Characters");
      }

      if (value.match(/(\s)/g)) {
        message.push("blankSpacesAreNotAllowed");
      }

      if (!value.match(/[0-9]/g)) {
        message.push("atleastOneNumber");
      }

      if (!value.match(/[A-Z]/g)) {
        message.push("atleastOneUpperCaseLetter");
      }

      if (!value.match(/[a-z]/g)) {
        message.push("atleastOneLowerCaseLetter");
      }

      if (!value.match(/[!@#$%^&*]/g)) {
        message.push("atleastOneSpecialSymbol");
      }
    } else {
      return true;
    }
    if (message.length > 0) {
      return this.createError({ message: message.toString() });
    } else {
      return true;
    }
  });

export const loginFormValidationSchema = yup.object().shape({
  email: yup.string().required("usernameOrEmailIsRequired"),
  password: yup.string().required("required"),
});
export const profileValidationSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email().required("required"),
  phone: yup
    .string()
    .transform((value) => "+" + value)
    .phone(null, true, "enterValidCountryCodeAndPhoneNo")
    .required("required"),
  others: yup.string().required("required"),
});
export const changePasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required("required")
    .transform((curr, orig) => (orig === null ? "" : curr)),
  reEnterPassword: yup
    .string()
    .required("required")
    .when("password", {
      is: (password) => (password && password.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("password")], "enteredPasswordsAreDifferent"),
    })
    .transform((curr, orig) => (orig === null ? "" : curr)),
});
export const blocksDateValidationSchema = yup.object().shape({
  fromDate: yup.string().required("required"),
  toDate: yup.string().required("required"),
});
export const traceCodeValidationSchema = yup.object().shape({
  traceCode: yup.string().required("required"),
});
export const addLocationValidationSchema = yup.object().shape({
  locationType: yup.string().required("required"),
  metaCode: yup.string().required("required"),
  locationKey: yup.string().required("required"),
  locationName: yup.string().required("required"),
  latitude: yup.string().required("required"),
  longitude: yup.string().required("required"),
  locationdetails: yup.string().required("required"),
  country: yup.string().required("required"),
  pincode: yup.string().required("required"),
  phone: yup
    .string()
    .transform((value) => "+" + value)
    .phone(null, true, "enterValidCountryCodeAndPhoneNo")
    .required("required"),
  email: emailSchema,
});
export const addCustomerValidationSchema = yup.object().shape({
  customerName: yup.string().required("required"),
  customer_code: yup.string().required("required"),
  address1: yup.string().required("required"),
  address2: yup.string().required("required"),
  city: yup.string().required("required"),
  prefecture: yup.string().required("required"),
  country: yup.string().required("required"),
  contactPerson1: yup.string().required("required"),
  contactPerson2: yup.string().required("required"),
  email1: emailSchema,
  email2: emailSchema,
  phone: yup
    .string()
    .transform((value) => "+" + value)
    .phone(null, true, "enterValidCountryCodeAndPhoneNo")
    .required("required"),
  deliveryPaths: yup.string().required("required"),
});
export const addLocationTypeValidationSchema = yup.object().shape({
  locationType: yup.string().required("required").trim(),
  locationTypeKey: yup.string().required("required").trim(),
});
export const addCompanyTypeValidationSchema = yup.object().shape({
  companyType: yup.string().required("required").trim(),
});
export const addCodesValidationSchema = yup.object().shape({
  codeName: yup.string().required("required").trim(),
  codePattern: yup.string().required("required").trim(),
  codeExample: yup.string().required("required").trim(),
});
export const addBlockLifespanValidationSchema = yup.object().shape({
  type: yup.string().required("required").trim(),
  lifeSpan: yup.string().required("required"),
  prefix: yup
    .string()
    .required("required")
    .test("Prefix Check", function (value) {
      if (value.length === 1 && !/^[a-zA-Z\s.,]+$/.test(value)) {
        return this.createError({ message: "Prefix must be an alphabet only" });
      } else if (value.length > 1 && !/^[a-z0-9]+$/i.test(value)) {
        return this.createError({
          message: "Prefix must be an alpha-numeric only",
        });
      } else {
        return true;
      }
    }),
});
export const addProductInfoValidationSchema = yup.object().shape({
  GTIN: yup.string().required("required"),
  additionalNo: yup.string().required("required"),
  productName: yup.string().required("required"),
  productDescription: yup.string().required("required"),
  companyInformation: yup
    .string()
    .required("required")
    .max(250, "companyInformationMustBeLessThan250Characters"),
});
// export const addProductOthersValidationSchema = yup.object().shape({
//   blockLifeSpan: yup.string().required("required"),
//   enterBlock: yup.string().required("required"),
//   enterCode: yup.string().required("required"),
// });
export const addProductCodeValidationSchema = yup.object().shape({
  enterCode: yup.string().required("required"),
});
export const addProductBlockConfigValidationSchema = yup.object().shape({
  blockLifeSpan: yup.string().required("required"),
  // enterBlock: yup.string().required("required"),
});
export const addPricingValidationSchema = yup.object().shape({
  company: yup.string().required("required"),
  blockLifeSpan: yup.string().required("required"),
  price: yup.string().required("required"),
  discount: yup.string().required("required"),
  tax: yup.string().required("required"),
});
export const addConfigDeliveryPathValidationSchema = yup.object().shape({
  pathName: yup.string().required("required"),
  pathCode: yup.string().required("required"),
  order: yup.string().required("required"),
  inActiveUserLogin: yup.string().required("required"),
});
export const addConfigCodesValidationSchema = yup.object().shape({
  codeName: yup.string().required("required"),
  order: yup.string().required("required"),
  inActiveUserLogin: yup.string().required("required"),
});
export const addConfigBlockValidationSchema = yup.object().shape({
  name: yup.string().required("required"),
});
export const addConfigBlockLifespanValidationSchema = yup.object().shape({
  type: yup.string().required("required"),
  lifespan: yup.string().required("required"),
  prefix: yup.string().required("required"),
});
export const editCompanyValidationSchema = yup.object().shape({
  companyName: yup.string().required("required"),
  gs1_prefix: yup.string().required("required"),
  country: yup.string().required("required"),
  contactPerson1: yup.string().required("required"),
  email1: emailSchema,
  phone: yup
    .string()
    .transform((value) => "+" + value)
    .phone(null, true, "enterValidCountryCodeAndPhoneNo")
    .required("required"),
});
const SUPPORTED_FORMATS = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];

function get_url_extension(url) {
  return url.split(/[#?]/)[0].split(".").pop().trim();
}

export const editCompanyImageValidationSchema = yup.object().shape({
  imageUrl: yup
    .string()
    .test("valid-image-url", "Must use valid image URL", (value) =>
      testImage(value, 1000).then((status) => status === "success")
    )
    .max(250, "urlMustBeLessThan250Characters")
    .test("fileFormat", "unsupportedFileFormat", (value) => {
      return SUPPORTED_FORMATS.indexOf(get_url_extension(value)) !== -1;
    }),
});
export const addUserValidationSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  userName: yup.string().required("required"),
  password: passwordvalidation,
  email: emailSchema,
  phone: yup
    .string()
    .transform((value) => "+" + value)
    .phone(null, true, "enterValidCountryCodeAndPhoneNo")
    .required("required"),
});
export const editUserValidationSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: emailSchema,
  password: passwordvalidation,
  phone: yup
    .string()
    .transform((value) => "+" + value)
    .phone(null, true, "enterValidCountryCodeAndPhoneNo")
    .required("required"),
});
export const editProfileLocationValidationSchema = yup.object().shape({
  locationType: yup.string().required("required"),
  locationKey: yup.string().required("required"),
  locationName: yup.string().required("required"),
  locationDetails: yup.string().required("required"),
});
export const groupScanValidationSchema = yup.object().shape({
  // traceStatus: yup.string().required("required"),
  qrType: yup.string().required("required"),
  // maskType: yup.string().required("required"),
  scanType: yup.string().required("required"),
  // lifeSpan: yup.string().required("required"),
});
export const transactionFilterValidationSchema = yup.object().shape({
  productName: yup.string().required("required"),
});
