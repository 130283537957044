import { useEffect } from 'react';
import { useFlexLayout, usePagination, useTable } from 'react-table';
import Icon from '../Components/base/icon/icon';
import { PaginationReactTable } from '../models/paginationReactTable.model';

function TableFormat({
    columns, data, defaultColumn, fetchData, loading, page: pageData, tab
}: { columns: any, data: any, defaultColumn: any, fetchData?: any, loading: boolean, page: PaginationReactTable, tab?: string }) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize },
    } = useTable({
        columns, data, defaultColumn, manualPagination: true, pageCount: pageData.totalPages, initialState: {
            pageIndex: 0, pageSize: pageData.pageSize
        }
    }, useFlexLayout, usePagination);

    useEffect(() => {
        fetchData({ pageIndex, pageSize })
    }, [fetchData, pageIndex, pageSize])

    return (
        <>
            {loading && 
                <>
                    <div className='flex justify-center items-center'>
                        Loading....
                    </div>
                </>
            }
            {!loading &&
                <>
                    <table {...getTableProps()} className="w-full">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()} className="h-12 border-b items-center font-bold text-sm px-3">
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()} className="flex justify-center">{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row: any, i: any) => {
                                prepareRow(row)
                                return (
                                    <tr 
                                        {...row.getRowProps()} 
                                        className="h-2.6 border-b items-center font-semibold text-sm px-3 gap-2 hover:shadow-md"
                                    >
                                        {row.cells.map((cell: any) => {
                                            return (
                                                <td {...cell.getCellProps()} className="truncate capitalize">{cell.render('Cell')}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                    <div className="flex justify-between px-10 items-center text-BeeMG-dark-gray text-sm h-12 ">
                        <div>
                            <td colSpan={10000}>{page.length} of ~ {pageData.totalElements}{' '}{tab}</td>
                        </div>
                        <div className="pagination flex gap-x-2">
                            <button 
                                onClick={() => previousPage()} disabled={!canPreviousPage} 
                                className="w-6 h-6 border rounded-full bg-white hover:bg-BeeMG-yellow hover:shadow-md shadow-sm text-gray-500 font-bold flex items-center justify-center"
                            >
                                <Icon icon="LEFT" size="x-small" />
                            </button>
                            <div>Page{' '}<strong>{pageIndex + 1} of {pageOptions.length}</strong>{' '}</div>{' '}
                            <button 
                                onClick={() => nextPage()} disabled={!canNextPage} 
                                className="w-6 h-6 border rounded-full bg-white hover:bg-BeeMG-yellow hover:shadow-md shadow-sm text-gray-500 font-bold flex items-center justify-center"
                            >
                                <Icon icon="RIGHT" size="x-small" />
                            </button>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default TableFormat;
