import { CompanyType } from './../models/companyType.model';
import { useCreateCompanyTypesMutation, useGetCompanyTypesByIdQuery, useGetCompanyTypesByPaginationQuery, useUpdateCompanyTypesByIdMutation } from "../Services/companyTypesApi";
import { useFetchDataHook } from "./FetchData";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addCompanyTypeValidationSchema } from '../Utils/validation';
import { useEffect, useState } from 'react';

interface _Props {
    id: string | undefined
}

export const useCompanyTypesHook = (props: _Props) => {
    const { id } = props
    const { pageData, loading, fetchData, model, refetching } = useFetchDataHook(useGetCompanyTypesByPaginationQuery);
    const { data: companyTypesData } = useGetCompanyTypesByIdQuery(id, { skip: !id })
    const [updateCompanyTypesData, { error: updateCompanyTypesDataError }] = useUpdateCompanyTypesByIdMutation()
    const [createCompanyTypesData, { error: createCompanyTypesDataError }] = useCreateCompanyTypesMutation()
    const [navigateTo, setNavigateTo] = useState<boolean>()
    const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();
    const [createSuccess, setCreateSuccess] = useState<boolean | undefined>();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(addCompanyTypeValidationSchema) })
    const onSubmit = (value: any) => {
        let updateCompanyTypesDetails: CompanyType = companyTypesData
            ? Object.assign({}, companyTypesData)
            : ({} as any);

        if (updateCompanyTypesDetails) {
            updateCompanyTypesDetails.companyType = value.companyType
            if (id) {
                updateCompanyTypesData(updateCompanyTypesDetails).then((res: any) => {
                    if (res) {
                        setNavigateTo(true)
                        setUpdateSuccess(true)
                        console.log("update success: ", res.data)
                    }
                    else {
                        setNavigateTo(false)
                        setUpdateSuccess(false)
                        console.log("update errors: ", res.data)
                    }
                })
            }
            else {
                createCompanyTypesData(updateCompanyTypesDetails).then((res: any) => {
                    if (res) {
                        setNavigateTo(true)
                        setCreateSuccess(true)
                        console.log("create success: ", res.data)
                    }
                    else {
                        setNavigateTo(false)
                        setCreateSuccess(false)
                        console.log("create errors: ", res.data)
                    }
                })
            }
        }
    }
    useEffect(() => {
        if (companyTypesData) {
            reset(companyTypesData)
        }
    }, [companyTypesData])


    return { pageData, loading, fetchData, model, refetching, register, handleSubmit, errors, onSubmit, companyTypesData, navigateTo, setNavigateTo, createCompanyTypesDataError, updateCompanyTypesDataError, createSuccess, updateSuccess };
}