import { useTranslation } from 'react-i18next';
import ERRORSTATE from '../../Assets/images/error-state-image.svg'

const ErrorScreen = () => {

  const { t } = useTranslation()

  return (
    <div className='h-full flex flex-col flex-1 bg-white justify-center items-center rounded-lg m-2'>
      <img
        src={ERRORSTATE}
        className={`flex h-2/5 w-2/5`}
        alt={t("somethingWentWrong")}
      />
      <span>
        {t("somethingWentWrong")}
      </span>
    </div>
  )
}

export default ErrorScreen;