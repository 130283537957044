import React from 'react'
import { NavLink } from 'react-router-dom'
import OpenLogo from "../Assets/images/tt-logo.svg";
function PageNotFound() {
    return (
        <div className="flex items-center justify-center h-screen bg-white bg-[url('Assets/images/login-2in1-bg.svg')] bg-cover bg-center">
            <div className='flex flex-col gap-y-3 text-center items-center'>
                <NavLink to='/company' >
                    <img
                        src={OpenLogo}
                        className={`px-4 h-16`}
                        alt="TT"
                    />
                </NavLink>
                <span className='text-xl md:text-3xl text-TT-gray font-semibold'>404 | Page Not Found</span>
            </div>

        </div>
    )
}

export default PageNotFound