import React from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../../Components/Pagination";
import TableFormat from "../../../Components/TableFormat";
import SearchAndFilter from "../../../Components/TableNavBar/SearchAndFilter";
import { TableNavBar } from "../../../Components/TableNavBar/TableNavBar";
import { useFetchDataHook } from "../../../Hooks/FetchData";
import {
  companyTypesApi,
  useDeleteCompanyTypesByIdMutation,
  useGetCompanyTypesByPaginationQuery,
} from "../../../Services/companyTypesApi";
import { useAppDispatch } from "../../../Store/hooks";
import { Action, ActionType, ComponentType } from "../../Action";

function CompanyType() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { pageData, loading, fetchData, isError, isEmpty } = useFetchDataHook(
    useGetCompanyTypesByPaginationQuery
  );
  const [deleteCompanyTypesData] = useDeleteCompanyTypesByIdMutation();

  const columns = React.useMemo(
    () => [
      {
        Header: t("companyType"),
        accessor: "companyType",
      },
      {
        Header: t("action"),
        accessor: "",
        Cell: ({ row: { original } }: any) => (
          <Action
            id={original.id}
            types={[ActionType.View, original.status ? ActionType.Delete : ActionType.Restore]}
            deleteFunc={deleteCompanyTypesData}
            restoreFunc={()=>dispatch(companyTypesApi.endpoints.restoreCompanyTypesById.initiate(original.id)).unwrap()}
            invalidateFunc={()=>dispatch(companyTypesApi.util.invalidateTags(['CompanyType']))}
            navigation={`${original.id}`}
            title="view History"
            componentType={ComponentType.Button}
          />
        ),
      },
    ], [t]);

  return (
    <TableNavBar
      tableTitle={"types"}
      navLink={"add"}
      buttonTitle={"addNew"}
      pageData={pageData}
      fetchData={fetchData}
      isError={isError}
      isLoading={loading}
      isEmpty={isEmpty}
    >
      <TableNavBar.SearchAndFilter>
        <SearchAndFilter />
      </TableNavBar.SearchAndFilter>
      <TableNavBar.Pagination>
        {pageData && <Pagination page={pageData.page} fetchData={fetchData} />}
      </TableNavBar.Pagination>
      <TableNavBar.TableFormat>
        {pageData && <TableFormat columns={columns} data={pageData?.data} _query={useGetCompanyTypesByPaginationQuery} />}
      </TableNavBar.TableFormat>
    </TableNavBar>
  );
}

export default CompanyType;
