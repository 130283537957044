import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../Components/base/button/button';
import Field from '../../../Components/base/field/field';
import Spinner from '../../../Components/Spinner';
import { toastError, toastSuccess } from '../../../Components/toast';
import { useBlockLifespanTypesHook } from '../../../Hooks/BlockLifeSpanTypesHooks';

function AddBlockLifespan() {
    const { id } = useParams()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { register, handleSubmit, errors, onSubmit, blockLifeSpanTypesData, navigateTo, setNavigateTo, createBlockLifespanTypesError, updateBlockLifespanTypesError, createSuccess, updateSuccess } = useBlockLifespanTypesHook({ id })

    useEffect(() => {
        if (createBlockLifespanTypesError as any) {
            toastError((createBlockLifespanTypesError as any)?.data?.message);
        }
        if (updateBlockLifespanTypesError as any) {
            toastError((updateBlockLifespanTypesError as any)?.data?.message);
        }
    }, [createBlockLifespanTypesError, updateBlockLifespanTypesError])

    useEffect(() => {
        if (navigateTo && createSuccess === true) {
            toastSuccess(t("createdSuccessfully"));
            setNavigateTo(false);
            navigate('/types/blockLifespan');
        } else if (!navigateTo && createSuccess === false) {
            toastSuccess(t("failed"));
        }
    }, [navigateTo, createSuccess]);

    useEffect(() => {
          if (navigateTo && updateSuccess === true) {
              toastSuccess(t('updatedSuccessfully'));
              setNavigateTo(false);
              navigate('/types/blockLifespan')
        }
          else if (!navigateTo && updateSuccess === false) {
              toastSuccess(t('failed'));
          }
      }, [navigateTo, updateSuccess]);

    if(id && !blockLifeSpanTypesData) {
        return (
            <div
              className={`md:h-full h-screen flex justify-center items-center bg-white`}
            >
              <Spinner className="h-8 w-8" color="#2984FF" />
            </div>
          );
    }

    return (
        <div className="md:md:h-[calc(100%-4.3rem)]  md:m-2 rounded-lg shadow bg-white w-screen md:w-auto overflow-auto">
            <div className="w-full lg:w-1/4 p-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
                        <div className="col-span-12 flex flex-col gap-2">
                            <label htmlFor="type" className='text-xs uppercase font-semibold'>{t(`type`)}</label>
                            <Field
                                {...register('type')} error={errors?.type && `${errors?.type?.message}`} type='text' defaultValue={blockLifeSpanTypesData?.type}
                                placeholder={t(`type`)} id="type" className='px-2 h-12'
                            />
                        </div>
                        <div className="col-span-12 flex flex-col gap-2">
                            <label htmlFor="lifeSpan" className='text-xs uppercase font-semibold'>{t(`lifeSpan`)}</label>
                            <Field
                                {...register('lifeSpan')} error={errors?.lifeSpan && `${errors?.lifeSpan?.message}`} type="text" defaultValue={blockLifeSpanTypesData?.lifespan}
                                placeholder={t(`lifeSpan`)} id="lifeSpan" className='px-2 h-12'
                            />
                            <p className="text-xs text-TT-gray">{t(`inMonths`)}</p>
                        </div>
                        <div className="col-span-12 flex flex-col gap-2">
                            <label htmlFor="prefix" className='text-xs uppercase font-semibold'>{t(`prefix`)}</label>
                            <Field
                                {...register('prefix')} error={errors?.prefix && `${errors?.prefix?.message}`} type="text" defaultValue={blockLifeSpanTypesData?.prefix}
                                placeholder={t(`prefix`)} id="prefix" className='px-2 h-12'
                            />
                            <div className='flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-5 justify-center'>
                                <Button color='primary' className="py-1 md:w-fit text-white h-12 md:h-fit w-full mx-0" submit>{id ? t('update') : t('addBlockLifespan')}</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddBlockLifespan