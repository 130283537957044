import { Listbox, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Icon from './icon/icon'

interface ISelect{
    items: any[],
    size?: 'sm' | 'md',
    label?: string,
    onSelect?: (item: any)=> void,
}

function Select({ items, size='sm', label, onSelect }: ISelect) {

    const { t } = useTranslation();
    const [selected, setSelected] = useState(label ? label : items[0]);

    return (
        <div className="">
            <Listbox value={selected} onChange={(item)=>{
                setSelected(item);
                if(onSelect) {
                    onSelect(item)
                };
            }}>
                <div className="relative">
                    <Listbox.Button className={`relative w-full cursor-default rounded-lg bg-white pl-3 md:pr-10 pr-6 text-left border border-TT-ash focus:outline-none focus-visible:border-TT-blue focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-TT-blue sm:text-sm ${size==='sm' ? 'py-1' : 'py-3.5'}`}>
                        <span className="block truncate text-xs md:text-sm">{t(selected)}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-2 pr-1">
                            <Icon icon="INCREMENT" />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-black ring-opacity-5 focus:outline-none sm:text-sm w-fit z-40">
                            {items?.map((item: any, itemId: any) => (
                                <Listbox.Option
                                    key={itemId}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-TT-blue/10' : 'text-gray-900'
                                        }`
                                    }
                                    value={item}
                                >
                                    {({ selected }) => (
                                        <>
                                            <span
                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                    }`}
                                            >
                                                {t(item)}
                                            </span>
                                            {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-TT-blue">
                                                    <Icon icon="TICK" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}

export default Select