import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toastError } from "../Components/toast";
import { useSignOutHook } from "../Hooks/SignOutHook";
import { getLogin } from "../Services/userReducer";
import { useAppSelector } from "../Store/hooks";

const AuthVerify = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const loginData = useAppSelector(getLogin);
  const { signOutCurrentUser } = useSignOutHook()

  //checking for token expiration 
  useEffect(() => {
    let interval: NodeJS.Timer;
    checkTokenExpiration()
    interval = setInterval(() => {
      checkTokenExpiration()
    }, 60000)

    return () => {
      clearInterval(interval);
    }
  }, [loginData])

  useEffect(() => {
    if (!loginData && !location.pathname.includes("anonymousTrack")) {
      navigate('/signIn')
    }
  }, [loginData])

  function checkTokenExpiration() {
    if (loginData && (new Date(loginData?.expire.toString()).getTime() < new Date().getTime())) {
      toastError(t("sessionExpired"))
      signOutCurrentUser()
      navigate('/signIn')
    }
  }

  return <></>
}

export default AuthVerify;
