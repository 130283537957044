import React, { useEffect, useState } from "react";
import Icon from "./base/icon/icon";
import { Pagination as pagination } from "../models/pagination.model";

type _Page = {
  page: pagination;
  fetchData: ({ pageSize, pageIndex }: any) => void;
};

function Pagination({ page, fetchData }: _Page) {
  const totalPages = Math.ceil(page?.totalElements / page?.pageSize);

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const showPages = Math.min(totalPages, 4);
  const startPage = Math.max(1, currentIndex - Math.floor(showPages / 2));
  const endPage = Math.min(totalPages, startPage + showPages);
  const pages = Array.from({ length: endPage + 1 - startPage }, (_, i) =>
    i + startPage
  );

  useEffect(() => {
    if (page.pageIndex) {
      setCurrentIndex(Number(page.pageIndex) + 1);
    }
  }, [page.pageIndex]);

  const showLeftArrow = currentIndex > 1;
  const showRightArrow = currentIndex < totalPages;

  const click = (pageIndex: number) => {
    if (pageIndex !== currentIndex) {
      fetchData({ pageSize: page.pageSize, pageIndex: pageIndex - 1 });
    }
  };

  const style = {
    active: `w-8 h-8 flex items-center justify-center bg-TT-blue rounded-md text-white`,
    inactive: `w-8 h-8 flex items-center justify-center hover:bg-TT-blue/50 rounded-md hover:text-white`,
  };

  const arrowStyle = {
    active: `w-8 h-8 flex items-center justify-center hover:bg-TT-blue rounded-md hover:text-white`,
    inactive: `w-8 h-8 flex items-center justify-center hover:bg-TT-blue/50 rounded-md hover:text-white`,
  };

  return (
    <section className="flex gap-x-1 items-center text-sm md:text-base ">
      <div
        className={showLeftArrow ? arrowStyle.active : arrowStyle.inactive}
        onClick={() => showLeftArrow && click(currentIndex - 1)}
      >
        <Icon icon="LEFT" size="x-small" />
      </div>
      {pages.map((x, i) => (
        <div
          key={i.toString()}
          className={currentIndex === x ? style.active : style.inactive}
          onClick={() => click(x)}
        > {x} </div>
      ))}
      <div
        className={showRightArrow ? arrowStyle.active : arrowStyle.inactive}
        onClick={() => showRightArrow && click(currentIndex + 1)}
      >
        <Icon icon="RIGHT" size="x-small" />
      </div>
    </section>
  );
}

export default Pagination;