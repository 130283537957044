import { useNavigate } from "react-router-dom";
import QRScanner from "../Containers/Scan/QRScanner";
import Modal from "./Modal";

interface QRScanModalProp {
  isOpen: boolean
  setIsOpen: Function;
}

const QRScanModal = (props: QRScanModalProp) => {
  const { isOpen, setIsOpen } = props;

  const navigate = useNavigate()

  const handleUpdate = (result: any) => {
    if (result) {
      setIsOpen(false);
      const gtin = JSON.parse(result.data).c;
      navigate(`/trace/${gtin}`)
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={"scanTrace"} >
        <div className="flex flex-col gap-5 items-center">
          {isOpen && <QRScanner handleUpdate={handleUpdate} defaultScanMode />}
        </div>
      </Modal>
    </>
  )
}

export default QRScanModal;