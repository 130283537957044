import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../Store";

const initialStateValue = "";

export const tokenSlice = createSlice({
    name: "token",
    initialState: { 
        value: initialStateValue
    },
    reducers: {
        changeToken: (state, action) => {
            state.value = action.payload;
        },
        logoutToken: (state) => {
            state.value = initialStateValue;
        },
    }
});

export const getToken = (state: RootState): boolean => state.token?.value

export const { changeToken, logoutToken } = tokenSlice.actions;
export default tokenSlice.reducer;
