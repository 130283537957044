import React from 'react'
import { useTranslation } from 'react-i18next';
import Icon from '../../Components/base/icon/icon'
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Status() {

  const { t } = useTranslation();

  const DoughnutData = {
    labels: ['Nissan', 'Nippon', 'BMW', 'Pepsi', 'FirstQA', 'Sakura Fresh'],
    datasets: [
      {
        data: [30, 20, 20, 10, 10, 10],
        backgroundColor: ['#165DFF', '#50CD89', '#FFC700', '#7239EA', '#0FC6C2', '#B5BDCB'],
        borderWidth: 0,
        cutout: '80%',
        animateScale: true,
        hoverOffset: 10,

      },
    ],
  };

  const DoughnutOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
        }
      },
      title: {
        display: true,
        text: t('totalBlocks'),
      },
    },
  };

  const BarData = {
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    datasets: [
      {
        label: 'blocks',
        data: [1000, 600, 850, 480, 620, 450, 657, 650, 730, 430, 700, 620],
        backgroundColor: '#165DFF',
        borderWidth: 0,
        animateScale: true,
        response: true,

      },
    ],
  };

  const BarOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          usePointStyle: true,
        }
      },
      title: {
        display: true,
        text: '5,276 B',
      },
    },
  };


  return (
    <section className="grid md:grid-cols-2 grid-cols-1 gap-2 w-full" >
      <div className="col-span-1 flex flex-col gap-3">
        <div className="grid lg:grid-cols-4 grid-cols-2 lg:p-4 p-3 gap-2 lg:gap-5 bg-white rounded-lg">
          <div className="flex flex-col cols-span-1 bg-TT-blue rounded-md items-center justify-center text-white py-5">
            <span className='text-5xl font-bold flex-none'>20</span>
            <Icon icon="BLOCK" size='large' />
            <span className='font-semibold'>{t('blocks')}</span>
          </div>
          <div className="flex flex-col cols-span-1 bg-TT-blue rounded-md items-center justify-center text-white py-5">
            <span className='text-5xl font-bold'>18</span>
            <Icon icon="TRANSACTION" size='large' />
            <span className='font-semibold'>{t('transactions')}</span>
          </div>
          <div className="flex flex-col cols-span-1 bg-TT-blue rounded-md items-center justify-center text-white py-5">
            <span className='text-5xl font-bold'>05</span>
            <Icon icon="USERS" size='large' />
            <span className='font-semibold'>{t('nodes')}</span>
          </div>
          <div className="flex flex-col cols-span-1 bg-TT-blue rounded-md items-center justify-center text-white py-5">
            <span className='text-5xl font-bold'>169</span>
            <Icon icon="CHAIN_CODE" size='large' />
            <span className='font-semibold'>{t('chainCodes')}</span>
          </div>
        </div>
        <div className="flex flex-col p-3 bg-white rounded-lg h-full">
          <div className="font-bold">{t('blocksStatus')}</div>
          <div className='lg:w-[400px] m-auto'>
            <Doughnut data={DoughnutData} options={DoughnutOptions} />
          </div>
        </div>
      </div>
      <div className="col-span-1 flex flex-col">
        <div className="flex gap-y-3 flex-col h-full">
          <div className='bg-white p-4 rounded-lg '>
            <div className="font-bold">{t('peers')}</div>
            {[...Array(7)].map((el, index) => <div key={index.toString()} className="odd:bg-TT-ash md:odd:bg-white p-1 rounded-lg">peer0.org1.example.com:7051</div>)}
          </div>
          <div className='bg-white p-4 rounded-lg h-full'>
            <div className="font-bold">{t('blocksStatistics')}</div>
            <div className='lg:w-[600px] m-auto'>
              <Bar data={BarData} options={BarOptions} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Status