import { allApis } from "./allApi";

export const versionApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getVersion: builder.query<any, any>({
            query: () => "version",
            providesTags: ['Version']
        }),
    }),

});

export const { useGetVersionQuery } = versionApi;