import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet, useParams } from 'react-router-dom'
import { setNavbarProps } from '../../Services/utilsReducer'
import { useAppDispatch } from '../../Store/hooks'

function EditProduct() {
    const { id } = useParams()
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(setNavbarProps({
        navBarTitle: id ? 'editProduct' : 'addProduct',
        navBarPrevPage: "/products",
        navBarUserAvatar: true,
      }));
    }, [])
    
    const style = {
        navLink: `w-fit p-1 px-2 font-semibold border-transparent hover:border-TT-blue border-b-2 focus:outline-none`,
        active: `w-fit p-1 px-2 font-bold border-b-2 border-TT-blue`,
    }
    const exploreOn = [
        { title: 'info', url: 'productInfo' },
        { title: 'images', url: 'images' },
        { title: 'deliveryPath', url: 'deliveryPath' },
        { title: 'blockConfig', url: 'blockConfig' },
        { title: 'code', url: 'productCode' },
        { title: 'masking', url: 'masking' },
    ]
    const { t } = useTranslation();

    return (
        <>
            <section className='h-full mt-[7.6rem] md:mt-0 md:m-2 md:w-auto w-screen'>
                <div className="flex justify-start lg:px-3 px-2 py-1 lg:py-2 gap-x-3 items-center no-scrollbar overflow-x-auto">
                    {id && exploreOn.map((item, index) => (

                        <NavLink to={id ? `${id}/${item.url}` : `${item.url}`}
                            key={index}
                            className={(navData: any) => navData.isActive ? style.active : style.navLink}
                        >
                            <div className="flex gap-x-2 items-center whitespace-nowrap">
                                {t(item.title)}
                            </div>
                        </NavLink>
                    ))}
                    {!id && <NavLink to={'productInfo'}
                        className={(navData: any) => navData.isActive ? style.active : style.navLink}
                    >
                        <div className="flex gap-x-2 items-center whitespace-nowrap">
                            {t('info')}
                        </div>
                    </NavLink>}
                </div>
                <Outlet />
            </section>
        </>
    )
}

export default EditProduct