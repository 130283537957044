import { useEffect, useState } from 'react';
import { useFetchDataHook } from './FetchData';
import { getLogin } from './../Services/userReducer';
import { useAppSelector } from './../Store/hooks';
import { useGetCompanyByUserIdQuery } from "../Services/companyApi"
import { useGetLocationByPaginationQuery } from '../Services/locationApi';
import { useGetUsersByPaginationQuery } from '../Services/userApi';
import { useGetProductsByPaginationQuery } from '../Services/productApi';
import { useGetPreferenceByUserQuery } from '../Services/preferenceApi';

export const useProfileCompanyHook = () => {
  const currentUser = useAppSelector(getLogin)?.user

  const { data: companyData, isLoading: companyLoading } = useGetCompanyByUserIdQuery(currentUser?.id, { skip: !currentUser?.id })
  const { pageData: locations, loading: locationsLoading } = useFetchDataHook(useGetLocationByPaginationQuery);
  const { pageData: users, loading: usersLoading } = useFetchDataHook(useGetUsersByPaginationQuery);
  const { pageData: products, loading: productsLoading } = useFetchDataHook(useGetProductsByPaginationQuery);
  const { data: preference, isLoading: preferenceLoading } = useGetPreferenceByUserQuery()

  const [companyName, setCompanyName] = useState<string>("")

  useEffect(() => {
    if (preference && companyData) {
      if (preference?.language !== 'en' && companyData.company_name_local) {
        setCompanyName(companyData.company_name_local)
      } else {
        setCompanyName(companyData.company_name)
      }
    }
  }, [preference, companyData])

  const isLoading = companyLoading || locationsLoading || usersLoading || productsLoading || preferenceLoading;

  return { companyData, companyName, locations, users, products, isLoading }

}