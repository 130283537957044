import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Button from '../../Components/base/button/button';
import Field from '../../Components/base/field/field';
import { toastError } from '../../Components/toast';
import jsQR from "jsqr";

interface ScannerProp {
    handleUpdate?: any
    defaultScanMode?: boolean
}

function QRScanner({ handleUpdate, defaultScanMode = false }: ScannerProp) {

    const { t } = useTranslation();
    const [QRShow, setQRShow] = useState(defaultScanMode);

    const video = document.getElementById('video') as HTMLVideoElement;
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;

    const delayBetweenScan = 1000;

    const [qrCode, setQrCode] = useState<any>();
    const [videoDevicesAvailable, setVideoDevicesAvailable] = useState<MediaDeviceInfo[]>([]);
    const [stream, setStream] = useState<MediaStream | undefined>();

    const startVideo = async () => {
        const mediaDeviceInfoList = await navigator.mediaDevices.enumerateDevices();
        let videoDevices = mediaDeviceInfoList.filter(deviceInfo => deviceInfo.kind === 'videoinput')
        setVideoDevicesAvailable(videoDevices);
        if (videoDevices.length === 0) {
            throw toastError(t('noCameraFound'))
        }
    }
    useEffect(() => {
        if (videoDevicesAvailable.length > 0) {
            setSelectedDevice(0);
        }
    }, [videoDevicesAvailable])


    const stopVideo = (): void => {
        if (stream) {
            stream.getVideoTracks()[0].stop();
        }
    }

    const setSelectedDevice = (index: number) => {
        const device = videoDevicesAvailable[index];
        if (device) {
            navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {
                    deviceId: device.deviceId,
                    autoGainControl: true,
                    noiseSuppression: true
                }
            }).then(mediaStream => {
                stopVideo();
                setStream(mediaStream);
                if (video) {
                    video.srcObject = mediaStream;
                    processImage();
                }
            }).catch((error: any) => {
                console.log(error);
            });
        }
    }

    const processImage = () => {

        if (video && canvas) {
            const width = canvas.width;
            const height = canvas.height;
            const context = canvas.getContext('2d') as CanvasRenderingContext2D;
            context.drawImage(video, 0, 0, width, height);
            const imageData = context.getImageData(0, 0, width, height);

            const qrcode = jsQR(imageData.data, width, height, { inversionAttempts: 'dontInvert' });

            if (qrcode && qrcode.data.length !== 0) {
                setQrCode(qrcode);
                setTimeout(() => {
                    processImage();
                }, delayBetweenScan);
            }
            else {
                setTimeout(() => {
                    processImage();
                }, 100);
            }
        }
    }

    useEffect(() => {
        if (QRShow) {
            startVideo();
        } else {
            stopVideo();
        }
    }, [QRShow])

    useEffect(() => {
        handleUpdate(qrCode);
        stopVideo();
    }, [qrCode])


    return (
        <>
            {QRShow ? (
                <div className="w-52 h-52 border-4 mt-5 relative">
                    <hr className="absolute shadow-xl border-0 shadow-TT-blue w-full h-5 scan z-50" />
                    <video id="video" autoPlay muted playsInline width='680' height='800' className=" object-cover w-52 h-52 pb-2" />
                    <canvas id="canvas" hidden className='hidden'></canvas>
                </div>
            ) : (
                <div className="w-52 h-52 border-4 mt-5" />
            )}
            <div>
                {!defaultScanMode &&
                    <Button color={QRShow ? "primary" : "plain"}
                        className="py-1 flex whitespace-nowrap gap-x-1 items-center text-xs md:text-sm"
                        size="sm"
                        onClick={() => { setQRShow(!QRShow) }}
                    >
                        {t("scan")}
                    </Button>
                }
            </div>
            {videoDevicesAvailable.length > 1 &&
                <Field name="videoDevices" type="select" onChange={(e: any) => setSelectedDevice(e.target.value)} >
                    {videoDevicesAvailable && videoDevicesAvailable.map((device: MediaDeviceInfo, index: number) => {
                        return <option key={index} value={index}>{device.label}</option>
                    })}
                </Field>
            }
        </>
    )
}

export default QRScanner


