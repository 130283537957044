import { useTranslation } from 'react-i18next';

const ScanPreferenceHeaderComponent = () => {
    const { t } = useTranslation();

    return (
        <div>
            <label className="text-lg font-semibold">{t('savedScanPreferences')}</label>
            <div className="flex flex-row my-2 ">
                <label className="flex flex-1 text-md font-semibold">{t(`traceStatus`)}</label>
                <label className="flex flex-1 text-md font-semibold">{t(`qrType`)}</label>
                {/* <label className="flex flex-1 text-md font-semibold">{t(`maskType`)}</label> */}
                <label className="flex flex-1 text-md font-semibold">{t(`scanType`)}</label>
                <label className="flex flex-1 text-md font-semibold">{t(`lifeSpan`)}</label>
                <label className="flex flex-1 text-md font-semibold">{t('select')}</label>
                <label className="flex flex-1 text-md font-semibold">{t('remove')}</label>
            </div>
        </div>
    )
}

export default ScanPreferenceHeaderComponent