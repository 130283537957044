import { useState, useEffect } from 'react';
import { Role, UserRole } from '../models/userRole.model';
import { getLogin } from '../Services/userReducer';
import { useAppSelector } from '../Store/hooks';

export const useAppPermission = () => {
  const loginData = useAppSelector(getLogin)
  const [initialRoute, setInitialRoute] = useState<string>("")

  useEffect(() => {
    if (loginData) {
      getInitialRoute()
    }
  }, [loginData])

  function hasPermission(roles: Role[]) {
    function isUserActive(): boolean {
      return !!(loginData?.user?.username && loginData.token && loginData.active);
    }

    function getCurrentUserRoles(): UserRole[] {
      return loginData?.user.roles;
    }

    return (isUserActive() && getCurrentUserRoles() && getCurrentUserRoles().some(userRole => roles.some(role => role === userRole.role)))
  }

  const getInitialRoute = () => {
    if (hasPermission([Role.SuperAdmin])) {
      setInitialRoute('/company')
    }
    else if (hasPermission([Role.CompanyAdmin])) {
      setInitialRoute('/users')
    }
    else if (hasPermission([Role.CompanyLocationAdmin])) {
      setInitialRoute('/products')
    }
  }

  const isUserLoggedIn = (): boolean => !!(loginData?.user?.username && loginData.token && loginData.active)

  return { hasPermission, initialRoute, isUserLoggedIn }
}

