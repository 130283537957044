import React from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '../../Components/base/button/button';
import { useAppPermission } from '../../Hooks/AppPermissionHook';
import { useGetProductByGtinQuery } from '../../Services/productApi';
import { formatDate2, formatTime2 } from '../../Utils/datetime';
import TracePack from '../../Assets/images/trace-pack.svg';
import { useTranslation } from 'react-i18next';
import { useSignInHook } from '../../Hooks/SignIn';

function ItemsDown({ productCode, traceCode, isNavigable, createdAt }: { productCode: string, traceCode: string, isNavigable: boolean, createdAt: Date }) {

  const navigate = useNavigate();
  const { isUserLoggedIn } = useAppPermission();
  const { t } = useTranslation();
  const { data: product } = useGetProductByGtinQuery(productCode, { skip: !productCode });
  const { isLoggedIn } = useSignInHook();

  const canShow = (key: string | undefined): boolean => {
    if (key === "None") {
      return false;
    }
    if (key === "Client") {
      return isUserLoggedIn();
    }
    return true;
  };

  return (
    <div className={`items-center rounded-lg hover:shadow-xl shadow-lg p-5 gap-y-5 flex flex-col text-center border min-w-[12rem]`}>
      <div className="font-bold flex-none text-center">{product?.product_name}</div>
      <div className="w-full flex justify-center">
        {(product?.images?.length && product?.images[0]?.imageURL) ? <img src={product?.images[0]?.imageURL} alt={product?.product_name} className='w-32 h-32 ' />
          : <img src={TracePack} alt={product?.product_name} className='w-32 h-32' />}
      </div>
      <div className="mt-2 font-semibold">
        <p>{formatDate2(createdAt?.toString())}</p>
        <p>{formatTime2(createdAt?.toString())}</p>
      </div>
      {canShow(product?.access_navigate) && <Button
        color="plain"
        className="py-1 md:w-fit h-12 md:h-fit w-full mx-0 cursor-pointer"
        onClick={() => { if (isLoggedIn) { navigate(`/trace/` + traceCode); } else { navigate(`/t/` + traceCode); } }}
      >
        {t('track')}
      </Button>}
    </div>
  )
}

export default ItemsDown