import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../Components/base/button/button';
import Field from '../../../Components/base/field/field';
import Spinner from '../../../Components/Spinner';
import { toastError, toastSuccess } from '../../../Components/toast';
import { useLocationTypesHook } from '../../../Hooks/LocationTypesHooks';

function AddLocationType() {
  const { id } = useParams()
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { register, handleSubmit, errors, locationDataById, onSubmit, navigateTo, setNavigateTo, createLocationTypesError, updateLocationTypesError, createSuccess, updateSuccess } = useLocationTypesHook({ id })

  useEffect(() => {
    if (createLocationTypesError as any) {
      toastError((createLocationTypesError as any)?.data?.message);
    }
    if (updateLocationTypesError as any) {
      toastError((updateLocationTypesError as any)?.data?.message);
    }
  }, [createLocationTypesError, updateLocationTypesError])

  useEffect(() => {
    if (navigateTo && createSuccess === true) {
      toastSuccess(t("createdSuccessfully"));
      setNavigateTo(false);
      navigate('/types/locationType');
    } else if (!navigateTo && createSuccess === false) {
      toastSuccess(t("failed"));
    }
  }, [navigateTo, createSuccess]);

  useEffect(() => {
    if (navigateTo && updateSuccess === true) {
      toastSuccess(t('updatedSuccessfully'));
      setNavigateTo(false);
      navigate('/types/locationType')
    }
    else if (!navigateTo && updateSuccess === false) {
      toastSuccess(t('failed'));
    }
  }, [navigateTo, updateSuccess]);

  if(id && !locationDataById) {
    return (
      <div
        className={`md:h-full h-screen flex justify-center items-center bg-white`}
      >
        <Spinner className="h-8 w-8" color="#2984FF" />
      </div>
    );
  }

  return (
    <div className="md:md:h-[calc(100%-4.3rem)]  md:m-2 rounded-lg shadow bg-white w-screen md:w-auto overflow-auto">
      <div className="w-full lg:w-1/4 p-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
            <div className="col-span-12 flex flex-col gap-2">
              <label htmlFor="locationType" className='text-xs uppercase font-semibold'>{t(`locationType`)}</label>
              <Field
                {...register('locationType')} error={errors?.locationType && `${errors?.locationType?.message}`} type='text' defaultValue={locationDataById?.locationType}
                placeholder={t(`locationType`)} id="locationType" className='px-2 h-12'
              />
            </div>
            <div className="col-span-12 flex flex-col gap-2">
              <label htmlFor="locationTypeKey" className='text-xs uppercase font-semibold'>{t(`locationTypeKey`)}</label>
              <Field
                {...register('locationTypeKey')} error={errors?.locationTypeKey && `${errors?.locationTypeKey?.message}`} type="text" defaultValue={locationDataById?.locationTypeKey}
                placeholder={t(`locationTypeKey`)} id="locationTypeKey" className='px-2 h-12'
              />
          <div className='flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-5 justify-center'>
                <Button color='primary' className="py-1 md:w-fit text-white h-12 md:h-fit w-full mx-0" submit>{id ? t('update') : t('addLocationTypes')}</Button>
          </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddLocationType