import { LocationType } from './../models/locationType.model';
import { useForm } from "react-hook-form";
import { useCreateLocationTypesMutation, useGetLocationTypesByIdQuery, useGetLocationTypesByPaginationQuery, useUpdateLocationTypesByIdMutation } from "../Services/locationTypesApi";
import { useFetchDataHook } from "./FetchData";
import { addLocationTypeValidationSchema } from '../Utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';

type _Props = {
    id: string | undefined
}
export const useLocationTypesHook = (props: _Props) => {
    const { id } = props
    const { pageData, loading, fetchData, model, refetching } = useFetchDataHook(useGetLocationTypesByPaginationQuery);
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(addLocationTypeValidationSchema) })
    const { data: locationDataById } = useGetLocationTypesByIdQuery({ id }, { skip: !id })
    const [updateLocationTypes, { error: updateLocationTypesError }] = useUpdateLocationTypesByIdMutation()
    const [createLocationTypes, { error: createLocationTypesError }] = useCreateLocationTypesMutation()
    const [navigateTo, setNavigateTo] = useState<boolean>()
    const [createSuccess, setCreateSuccess] = useState<boolean>()
    const [updateSuccess, setUpdateSuccess] = useState<boolean>()
    const onSubmit = (value: any) => {
        let updateLocationTypesDetails: LocationType = locationDataById
            ? Object.assign({}, locationDataById)
            : ({} as any);
        if (updateLocationTypesDetails) {
            updateLocationTypesDetails.locationType = value.locationType
            updateLocationTypesDetails.locationTypeKey = value.locationTypeKey
            if (id) {
                updateLocationTypes(updateLocationTypesDetails).then((res: any) => {
                    if (res) {
                        setNavigateTo(true)
                        setUpdateSuccess(true)
                        console.log("update success: ", res.data)
                    }
                    else {
                        setNavigateTo(false)
                        setUpdateSuccess(false)
                        console.log("update errors: ", res.data)
                    }
                })
            }
            else if (!id) {
                createLocationTypes(updateLocationTypesDetails).then((res: any) => {
                    if (res) {
                        setNavigateTo(true)
                        setCreateSuccess(true)
                        console.log("create success: ", res.data)
                    }
                    else {
                        setNavigateTo(false)
                        setCreateSuccess(false)
                        console.log("create errors: ", res.data)
                    }
                })
            }
        }
    }

    useEffect(() => {
        if (locationDataById) {
            reset(locationDataById)
        }
    }, [locationDataById])

    return { pageData, loading, fetchData, model, refetching, register, handleSubmit, errors, locationDataById, onSubmit, navigateTo, setNavigateTo, createLocationTypesError, updateLocationTypesError, createSuccess, setCreateSuccess, updateSuccess, setUpdateSuccess };
}