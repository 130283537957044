import { Menu, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { useChangeLanguageHook } from '../../Hooks/ChangeLanguageHook';
import Icon from './icon/icon'

function LanguageDropdown() {
    const items = [{ lang: 'en', langDisplay: 'English' }, { lang: 'ja', langDisplay: '日本語' }]

    const { changeLang } = useChangeLanguageHook();

    return (
        <div className="">
            <Menu as="div" className="relative inline-block text-left top-1">
                <div>
                    <Menu.Button className="flex items-center gap-x-2">
                        <div><Icon icon="GLOBE" /></div>
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 mt-2 w-fit origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                        <div className="px-1 py-1 ">
                            {items.map((item: any, index) => (
                                <Menu.Item key={index.toString()}>
                                    {({ active }) => (
                                        <button
                                            className={`${active ? 'bg-TT-blue text-white' : 'text-TT-gray'
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            onClick={() => changeLang(item.lang)}
                                        >
                                            <span className="ml-2 uppercase">{item.langDisplay}</span>
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

export default LanguageDropdown