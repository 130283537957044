import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Pagination from "../../Components/Pagination";
import TableFormat from "../../Components/TableFormat";
import SearchAndFilter from "../../Components/TableNavBar/SearchAndFilter";
import { TableNavBar } from "../../Components/TableNavBar/TableNavBar";
import { FetchDataType } from "../../Hooks/FetchData";
import { useLocationHook } from "../../Hooks/LocationHook";
import { locationApi, useDeleteLocationByIdMutation, useGetLocationByPaginationQuery } from "../../Services/locationApi";
import { useAppDispatch } from "../../Store/hooks";
import { Action, ActionType, ComponentType } from "../Action";

function Location() {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { pageData, loading, fetchData, isError, isEmpty } = useLocationHook({
    id,
  });
  const [deleteLocation] = useDeleteLocationByIdMutation();

  const [updateSearchModel, setUpdateSearchModel] = useState<any>();

  const fetchInactive = (active: boolean) => {
    fetchData({
      pageSize: pageData?.page.pageSize,
      pageIndex: 0,
      totalElements: 0,
      search: updateSearchModel,
      status: active
    } as FetchDataType);
  }
  
  const columns = React.useMemo(
    () => [
      {
        Header: t("key"),
        accessor: "locationKey",
      },
      {
        Header: t("name"),
        accessor: "location",
      },
      {
        Header: t("addressDetails"),
        accessor: "locationInfo",
      },
      {
        Header: t("pincode"),
        accessor: "pincode",
      },
      {
        Header: t("phone"),
        accessor: "phone",
      },
      {
        Header: t("action"),
        accessor: "",
        Cell: ({ row: { original } }: any) => (
          <Action
            id={original.id}
            types={[ActionType.View, original.status ? ActionType.Delete : ActionType.Restore]}
            deleteFunc={deleteLocation}
            restoreFunc={()=>dispatch(locationApi.endpoints.restoreLocationById.initiate(original.id)).unwrap()}
            invalidateFunc={()=>dispatch(locationApi.util.invalidateTags(['Location']))}
            navigation={`${original.id}`}
            title="view History"
            componentType={ComponentType.Button}
          />
        ),
      },
    ], [t]);

  return (
    <div className="h-screen md:overflow-hidden">
      <TableNavBar
        tableTitle={"location"}
        navLink={"add"}
        buttonTitle={"addNew"}
        pageData={pageData}
        fetchData={fetchData}
        isError={isError}
        isLoading={loading}
        isEmpty={isEmpty}
      >
        <TableNavBar.SearchAndFilter>
          <SearchAndFilter
            type="Locations"
            onSearch={(searchModel) => {
              setUpdateSearchModel(searchModel);
              fetchData({
                pageSize: pageData?.page.pageSize,
                pageIndex: pageData?.page.pageIndex,
                totalElements: pageData?.page.totalElements,
                search: searchModel,
              } as FetchDataType);
            }}
          />
        </TableNavBar.SearchAndFilter>
        <TableNavBar.Pagination>
          {pageData && (
            <Pagination page={pageData.page} fetchData={fetchData} />
          )}
        </TableNavBar.Pagination>
        <TableNavBar.TableFormat>
          {pageData && <TableFormat columns={columns} data={pageData?.data} _query={useGetLocationByPaginationQuery} fetchInactive={fetchInactive} />}
        </TableNavBar.TableFormat>
      </TableNavBar>
    </div>
  );
}

export default Location;
