import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "../../Components/base/button/button";
import Field from "../../Components/base/field/field";
import Select from "../../Components/base/Select";
import Pagination from "../../Components/Pagination";
import TableFormat from "../../Components/TableFormat";
import { yupResolver } from "@hookform/resolvers/yup";
import { blocksDateValidationSchema } from "../../Utils/validation";
import { Pagination as pagination } from "../../models/pagination.model";

function Blocks() {
  const { t } = useTranslation();
  const numbers = [5, 10, 50, 100, 200, 500, 1000];
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(blocksDateValidationSchema) });

  const onSubmit = (values: any) => {
    // API Call
  };

  const pageData = {
    data: [
      {
        id: 1,
        channelName: "a202011",
        blockNumber: 5,
        productCode: 50,
        txId: 50,
        timestamp: "2022-05-31T09:05:13.000Z",
      },
      {
        id: 2,
        channelName: "a202011",
        blockNumber: 5,
        productCode: 50,
        txId: 50,
        timestamp: "2022-05-31T09:05:13.000Z",
      },
      {
        id: 3,
        channelName: "a202011",
        blockNumber: 5,
        productCode: 50,
        txId: 50,
        timestamp: "2022-05-31T09:05:13.000Z",
      },
      {
        id: 4,
        channelName: "a202011",
        blockNumber: 5,
        productCode: 50,
        txId: 50,
        timestamp: "2022-05-31T09:05:13.000Z",
      },
      {
        id: 5,
        channelName: "a202011",
        blockNumber: 5,
        productCode: 50,
        txId: 50,
        timestamp: "2022-05-31T09:05:13.000Z",
      },
    ],
    page: {} as pagination,
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("channelName"),
        accessor: "channelName",
      },
      {
        Header: t("blockNumber"),
        accessor: "blockNumber",
      },
      {
        Header: t("productCode"),
        accessor: "productCode",
      },
      {
        Header: t("txId"),
        accessor: "txId",
      },
      {
        Header: t("timestamp"),
        accessor: "timestamp",
      },
    ], [t]);

  return (
    <section className="rounded-lg shadow bg-white h-full md:h-[calc(100%-3.3rem)]">
      <div className="flex justify-between p-3 border-b md:pb-5 flex-wrap">
        <div className="flex gap-x-2 flex-wrap justify-around">
          <form onSubmit={handleSubmit(onSubmit)} id="blockDate">
            <div className="flex flex-col md:flex-row gap-2 items-center">
              <Field
                {...register("fromDate")}
                error={errors?.fromDate && t(`${errors?.fromDate?.message}`)}
                type="date"
                placeholder={t("fromDate")}
                name="fromDate"
                className="appearance-none	 w-full h-8 px-2 pr-7 rounded-lg z-0 focus:outline-none border-TT-ash focus:border-TT-blue border text-sm"
              />
              <Field
                {...register("toDate")}
                error={errors?.toDate && t(`${errors?.toDate?.message}`)}
                type="date"
                placeholder={t("toDate")}
                name="toDate"
                className="w-full h-8 px-2 pr-7 rounded-lg z-0 focus:outline-none border-TT-ash focus:border-TT-blue border text-sm"
              />
            </div>
          </form>
          <div className="flex gap-x-2 mt-2 md:mt-0 h-fit ">
            <Button
              color="primary"
              className="md:py-1.5 py-1 mb-1 text-xs md:text-sm"
              size="sm"
              form="blockDate"
              submit
            >
              {t("search")}
            </Button>
            <Button
              className="md:py-1.5 py-1 mb-1 focus:ring-0 text-xs md:text-sm"
              size="sm"
              color="plain"
              onClick={() => reset()}
            >
              {t("clear")}
            </Button>
          </div>
        </div>

        <div className="flex gap-x-3">
          <div className="flex justify-center md:justify-start mt-2 md:mt-0 flex-wrap gap-y-2 md:gap-y-0 items-center gap-x-3">
            <div className="flex items-center gap-x-3 flex-none">
              <span className="text-xs md:text-sm">{t("show")}</span>
              <Select items={numbers} />
            </div>
            {pageData && <Pagination page={pageData.page} fetchData={()=>{}}/>}
          </div>
        </div>
      </div>
      <section className="overflow-y-auto">
        {pageData && <TableFormat columns={columns} data={pageData?.data} />}
      </section>
    </section>
  );
}

export default Blocks;
