import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useGetAllTransactionsQuery } from "../Services/transactionApi";
import { transactionFilterValidationSchema } from "../Utils/validation";
import { useFetchDataHook } from "./FetchData";

export const useTransactionHook = () => {
    const { pageData, loading, fetchData, model, isEmpty, isError } = useFetchDataHook(useGetAllTransactionsQuery);
    const { control, register, formState: { errors }, handleSubmit } = useForm({ resolver: yupResolver(transactionFilterValidationSchema) });


    return { pageData, loading, fetchData, model, isEmpty, isError, control, register, errors, handleSubmit };
}