import { useEffect, useState } from 'react'
import Field from '../../Components/base/field/field';
import { useTranslation } from 'react-i18next';
import Button from '../../Components/base/button/button';
import Icon from '../../Components/base/icon/icon';
import useProfileHooks from '../../Hooks/ProfileHooks';
import { toastError, toastSuccess } from '../../Components/toast';
import { useNavigate } from 'react-router-dom';
import { setNavbarProps } from "../../Services/utilsReducer";
import { useAppDispatch } from '../../Store/hooks';

function ChangePassword() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { changePassword, handleSubmit, register, errors, navigateScreen, changePasswordStatus, updateCurrentUserError } = useProfileHooks()

  useEffect(() => {
    if ((changePasswordStatus !== null || undefined) && navigateScreen) {
      toastSuccess("success")
      navigate(-1);
    } else if (updateCurrentUserError || changePasswordStatus === false) {
      toastError("failed")
    }
  }, [changePasswordStatus, updateCurrentUserError, navigateScreen])

  useEffect(() => {
    dispatch(
      setNavbarProps({
        navBarTitle: "changePassword",
        navBarPrevPage: "/profile",
        navBarUserAvatar: true,
      })
    );
  }, []);

  return (
    <div className='h-full w-full md:bg-white'>
      <section className="md:px-5 mt-[7.6rem] md:mt-2">
        <div className="px-2 md:px-0 bg-TT-background md:bg-white h-full w-screen md:w-full flex justify-center">
          <div className="w-full lg:w-1/4 mt-5">
            <form onSubmit={handleSubmit(changePassword)}>
              <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
                <div className="col-span-12 flex flex-col gap-2">
                  <label htmlFor="password" className='text-xs uppercase font-semibold'>{t(`enterNewPassword`)}</label>
                  <div className="relative">
                    <Field
                      {...register('password')} error={errors?.password && `${errors?.password?.message}`} type={showPassword ? 'text' : 'password'}
                      placeholder={t(`password`)} className='px-2 h-12'
                    />
                    {!errors?.password && <div className="absolute top-4 right-4  cursor-pointer text-TT-gray-light " onClick={() => { setShowPassword(!showPassword) }}>{!showPassword ? <Icon icon="EYE_SLASH" /> : <Icon icon="EYE" />}</div>}
                  </div>
                </div>
                <div className="col-span-12 flex flex-col gap-2">
                  <label htmlFor="confirmPassword" className='text-xs uppercase font-semibold'>{t(`reenterNewPassword`)}</label>
                  <Field
                    {...register('reEnterPassword')} error={errors?.reEnterPassword && `${errors?.reEnterPassword?.message}`} type="password"
                    placeholder={t(`confirmPassword`)} className='px-2 h-12'
                  />
                </div>
              </div>
              <div className='flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-5 justify-center'>
                <Button color='primary' submit className="py-1 md:w-fit text-white h-12 md:h-fit w-full mx-0" >{t('save')}</Button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ChangePassword