import { Preference } from './../models/preference.model';
import { allApis } from './allApi';

export const preferenceApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getPreferenceByUser: builder.query<Preference, void>({
            query: () => "api/preference/user",
        }),
    }),

});

export const { useGetPreferenceByUserQuery } = preferenceApi;
