import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Location } from "../models/location.model";
import { useCreateLocationsMutation, useGetLocationByIdQuery, useGetLocationByPaginationQuery, useUpdateLocationByIdMutation } from "../Services/locationApi";
import { useLocationTypesQuery } from "../Services/locationTypesApi";
import { useFetchDataHook } from "./FetchData";
import { yupResolver } from '@hookform/resolvers/yup';
import { addLocationValidationSchema } from '../Utils/validation';

type _Props = {
    id: string | undefined
}
export const useLocationHook = (props: _Props) => {
    const { id } = props
    const { pageData, loading, fetchData, isEmpty, isError } = useFetchDataHook(useGetLocationByPaginationQuery);
    const [updateLocationData, { error: updateLocationError }] = useUpdateLocationByIdMutation()
    const { data: locationData } = useGetLocationByIdQuery(id, { skip: !id });
    const [createLocationData, { error: createLocationError }] = useCreateLocationsMutation()
    const { data: locationTypeList } = useLocationTypesQuery(true);
    const { register, handleSubmit, reset, formState: { errors }, control, setValue } = useForm({ resolver: yupResolver(addLocationValidationSchema) });
    const [navigateTo, setNavigateTo] = useState<boolean>()
    const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();
    const [createSuccess, setCreateSuccess] = useState<boolean | undefined>();
    const [locationTypeQuery, setLocationTypeQuery] = useState("");
    const [locationTypeOption, setLocationTypeOption] = useState<string[]>([]);
    const [selectedLocationType, setSelectedLocationType] = useState<string | undefined>();

    useEffect(() => {
        if (locationTypeList) {
            locationTypeList?.map((e) => {
                setLocationTypeOption((value) => [...value, e.locationType]);
            });
        }
    }, [locationTypeList]);

    useEffect(() => {
        if (locationData?.locationType) {
            setSelectedLocationType(locationData?.locationType?.locationType);
        }
    }, [locationData]);

    let locationTypeDatum;
    if (locationTypeOption) {
        locationTypeDatum =
            locationTypeQuery === ""
                ? locationTypeOption
                : locationTypeOption.filter((locationType: any) =>
                    locationType
                        .toLowerCase()
                        .replace(/\s+/g, "")
                        .includes(locationTypeQuery.toLowerCase().replace(/\s+/g, ""))
                );
    }

    function addLocationType(locationType: string) {
        setSelectedLocationType(locationType);
        setValue("locationType", locationType);
    }

    const onSubmit = (value: any) => {
        let updateLocationDetails: Location = locationData
            ? Object.assign({}, locationData)
            : ({} as any);
        if (updateLocationDetails) {
            locationTypeList?.forEach(item => {
                if (item.locationType === value.locationType) {
                    updateLocationDetails.locationType = item;
                }
            })
            updateLocationDetails.locationMetaCode = value.metaCode;
            updateLocationDetails.locationKey = value.locationKey;
            updateLocationDetails.location = value.locationName;
            updateLocationDetails.latitude = value.latitude;
            updateLocationDetails.longitude = value.longitude;
            updateLocationDetails.locationInfo = value.locationdetails;
            updateLocationDetails.country = value.country;
            updateLocationDetails.pincode = value.pincode;
            updateLocationDetails.phone = value.phone;
            updateLocationDetails.phone = value.phone;
            updateLocationDetails.email = value.email;
            if (id) {
                updateLocationData(updateLocationDetails).then((res: any) => {
                    if (res.data) {
                        setNavigateTo(true)
                        setUpdateSuccess(true)
                        console.log("update success: ", res.data)
                    } else {
                        setNavigateTo(false)
                        setUpdateSuccess(false)
                        console.log("update errors: ", res.error)
                    }
                })
            } else {
                createLocationData(updateLocationDetails).then((res: any) => {
                    if (res.data) {
                        setNavigateTo(true)
                        setCreateSuccess(true)
                        console.log("create success: ", res.data)
                    } else {
                        setNavigateTo(false)
                        setCreateSuccess(false)
                        console.log("create errors: ", res.error)
                    }
                })
            }
        }
    }

    useEffect(() => {
        if (locationData) {
            reset(locationData);
            setValue("locationType", locationData?.locationType?.locationType)
        }
    }, [locationData])

    return {
        pageData,
        loading,
        id,
        register,
        handleSubmit,
        errors,
        onSubmit,
        updateLocationData,
        locationData,
        locationTypeList,
        navigateTo,
        setNavigateTo,
        fetchData,
        isEmpty,
        isError,
        updateSuccess,
        createLocationError,
        updateLocationError,
        createSuccess,
        setCreateSuccess,
        control,
        setValue,
        selectedLocationType,
        setLocationTypeQuery,
        locationTypeDatum,
        addLocationType,
    }
}