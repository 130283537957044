import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TrialVersion from "../../Components/base/TrialVersion";
import Pagination from "../../Components/Pagination";
import TableFormat from "../../Components/TableFormat";
import SearchAndFilter from "../../Components/TableNavBar/SearchAndFilter";
import { TableNavBar } from "../../Components/TableNavBar/TableNavBar";
import { FetchDataType, useFetchDataHook } from "../../Hooks/FetchData";
import {
  customersApi,
  useDeleteCustomersByIdMutation,
  useGetCustomersByPaginationQuery,
} from "../../Services/CustomersApi";
import { setNavbarProps } from "../../Services/utilsReducer";
import { useAppDispatch } from "../../Store/hooks";
import { Action, ActionType, ComponentType } from "../Action";

function Customers() {
  const { t } = useTranslation();
  const isTrial = true;

  const dispatch = useAppDispatch();

  const { pageData, loading, fetchData, isError, isEmpty } = useFetchDataHook(
    useGetCustomersByPaginationQuery
  );

  const [deleteCustomer] = useDeleteCustomersByIdMutation();

  const [updateSearchModel, setUpdateSearchModel] = useState<any>();

  const fetchInactive = (active: boolean) => {
    fetchData({
      pageSize: pageData?.page.pageSize,
      pageIndex: 0,
      totalElements: 0,
      search: updateSearchModel,
      status: active
    } as FetchDataType);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: t("customer_code"),
        accessor: "customer_code",
      },
      {
        Header: t("customerName"),
        accessor: "customer_name",
      },
      {
        Header: t("city"),
        accessor: "city",
      },
      {
        Header: t("prefecture"),
        accessor: "prefecture",
      },
      {
        Header: t("country"),
        accessor: "country",
      },
      {
        Header: t("phone"),
        accessor: "phone",
      },
      {
        Header: t("action"),
        accessor: "",
        Cell: ({ row: { original } }: any) => (
          <Action
            id={original.id}
            types={[ActionType.View, original.status ? ActionType.Delete : ActionType.Restore]}
            deleteFunc={deleteCustomer}
            restoreFunc={() => dispatch(customersApi.endpoints.restoreCustomerById.initiate(original.id)).unwrap()}
            invalidateFunc={() => dispatch(customersApi.util.invalidateTags(['Customer']))}
            navigation={`${original.id}`}
            title="view History"
            componentType={ComponentType.Button}
          />
        ),
      },
    ], [t]);

  useEffect(() => {
    dispatch(setNavbarProps({
      navBarTitle: 'Customers',
      navBarPrevPage: "",
      navBarUserAvatar: true,
    }));
  }, [])

  if (isTrial) {
    return <TrialVersion />
  }

  return (
    <TableNavBar
      tableTitle={"customers"}
      navLink={"add"}
      buttonTitle={"addNew"}
      pageData={pageData}
      fetchData={fetchData}
      isError={isError}
      isLoading={loading}
      isEmpty={isEmpty}
    >
      <TableNavBar.SearchAndFilter>
        <SearchAndFilter
          type={"Customers"}
          onSearch={(searchModel) => {
            setUpdateSearchModel(searchModel);
            fetchData({
              pageSize: pageData?.page.pageSize,
              pageIndex: pageData?.page.pageIndex,
              totalElements: pageData?.page.totalElements,
              search: searchModel,
            } as FetchDataType);
          }}
        />
      </TableNavBar.SearchAndFilter>
      <TableNavBar.Pagination>
        {pageData && <Pagination page={pageData.page} fetchData={fetchData} />}
      </TableNavBar.Pagination>
      <TableNavBar.TableFormat>
        {pageData && <TableFormat columns={columns} data={pageData?.data} _query={useGetCustomersByPaginationQuery} fetchInactive={fetchInactive} />}
      </TableNavBar.TableFormat>
    </TableNavBar>
  );
}

export default Customers;
