import React from 'react';

interface Props {
    children: React.ReactNode;
}

interface DropdownItemProps
    extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    children: React.ReactNode;
}

const useToggle = () => {
    const [show, setShow] = React.useState<boolean>(false)
    const ref = React.useRef<HTMLDivElement>(null)

    const toggle = React.useCallback(() => {
        setShow(prevState => !prevState)
    }, [])

    // We need to check
    // close dropdown when you click outside
    // React.useEffect(() => {
    //     const handleOutsideClick = (event: any) => {
    //         if (!ref.current?.contains(event.target)) {
    //             if (!show) return
    //             console.log("show2", show)
    //             setShow(false)
    //             console.log("show3", show)
    //         }
    //     }
    //     window.addEventListener('click', handleOutsideClick)
    //     return () => window.removeEventListener('click', handleOutsideClick)
    // }, [show, ref])

    // close dropdown when you click on "ESC" key
    React.useEffect(() => {
        const handleEscape = (event: any) => {
            if (!show) return

            if (event.key === 'Escape') {
                setShow(false)
            }
        }
        document.addEventListener('keyup', handleEscape)
        return () => document.removeEventListener('keyup', handleEscape)
    }, [show])

    return {
        show,
        toggle,
        ref,
    }
}

const style = {
    menu: `fixed bg-white float-left pt-2 px-0 text-left border border-gray-300 mt-0.5 mb-0 mx-0 rounded-xl z-50`,
    item: `block w-full py-1 px-8 mb-2 text-sm font-normal clear-both whitespace-nowrap border-0 hover:bg-TT-blue hover:text-white hover:rounded-md cursor-pointer`,
};

export function Dropdown({ children }: Props) {
    const { show, toggle } = useToggle();
    /* First child contains the dropdown toggle */
    // @ts-ignore
    const dropdownToggle = children[0];

    /* Second child contains the dropdown menu */
    // @ts-ignore
    const dropdownMenu = children[1];
    return (

        <>
            <button
                onClick={toggle}
                type="button"
                className="text-gray-400 font-light flex items-center"
                aria-expanded="true"
                aria-haspopup="true"
            >
                {dropdownToggle}
            </button>
            {show && <>{dropdownMenu}</>}
        </>
    );
}

export function DropdownToggle({ children }: Props) {
    return <>{children}</>;
}

export function DropdownMenu({ children }: Props) {
    return (
        <div className="relative">
            <div
                style={{ transform: 'translate3d(0px, 3px, 0px)' }}
                className={style.menu}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
            >
                {children}
            </div>
        </div>
    );
}

/* You can wrap the a tag with Link if you are using either Create-React-App, Next.js or Gatsby */
export function DropdownItem({ children }: DropdownItemProps) {
    return (
        <a tabIndex={0} className={style.item} role="menuitem">
            {children}
        </a>
    );
}

export function DropdownDivider() {
    return <hr className="my-2 text-gray-300" />;
}