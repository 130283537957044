import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet } from 'react-router-dom'
import { setNavbarProps } from '../../Services/utilsReducer';
import { useAppDispatch } from '../../Store/hooks';

function Config() {
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(setNavbarProps({
        navBarTitle: 'config',
        navBarPrevPage: "",
        navBarUserAvatar: true,
      }));
    }, [])
    
    const style = {
        navLink: `w-fit p-1 px-2 font-semibold border-transparent hover:border-TT-blue border-b-2 focus:outline-none`,
        active: `w-fit p-1 px-2 font-bold border-b-2 border-TT-blue`,
    }
    const exploreOn = [
        { title: 'deliveryPath', url: 'config/deliveryPath' },
        { title: 'codes', url: 'config/codes' },
        { title: 'block', url: 'config/block' },
        { title: 'email', url: 'config/email' },
        { title: 'APITokens', url: 'config/APITokens' },
        { title: 'blockLifespan', url: 'config/blockLifespan' },
    ]
    const { t } = useTranslation();


    return (
        <section className='overflow-hidden mt-[7.6rem] md:mt-0 h-screen'>
            <div className="flex justify-start lg:px-3 px-2 py-1 lg:py-2 gap-x-3 items-center w-screen overflow-auto no-scrollbar md:w-auto" >
                {exploreOn.map((item, index) => (
                    <NavLink to={`/${item.url}`}
                        key={index}
                        className={(navData: any) => navData.isActive ? style.active : style.navLink}
                    >
                        <div className="flex gap-x-2 items-center whitespace-nowrap">
                            {t(item.title)}
                        </div>
                    </NavLink>
                ))}
            </div>
            <Outlet />
        </section>
    )
}

export default Config