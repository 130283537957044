import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../Components/base/button/button";
import Field from "../../Components/base/field/field";
import { useGetCodeConfigQuery } from "../../Services/CodeConfigApi";
import { useCreateProductsMutation, useGetProductByIdQuery, useUpdateProductByIdMutation } from "../../Services/productApi";
import { Products } from "../../models/products.model";
import { useEffect, useState } from "react";
import { Pack } from "../../models/pack.model";
import { toastError, toastSuccess } from "../../Components/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { addProductCodeValidationSchema } from "../../Utils/validation";

function ProductCode() {

    const { id } = useParams()
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(addProductCodeValidationSchema) });
    const { data: productData } = useGetProductByIdQuery(id);
    const { data: codeData } = useGetCodeConfigQuery();
    const [updateCode, { error: updateCodeError }] = useUpdateProductByIdMutation()
    const [newCode, setNewCode] = useState<Pack | undefined>()
    const [selectedCode, setSelectedCode] = useState<string>()
    const [navigateTo, setNavigateTo] = useState<boolean>()
    const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();

    const onSubmit = () => {
        let updateProductCode: Products = productData
            ? Object.assign({}, productData)
            : ({} as any);

        updateProductCode.code = Object.assign({}, productData?.code)
        if (newCode) {
            updateProductCode.code = newCode
        }
        if (id) {
            updateCode(updateProductCode).then((res: any) => {
                if (res.data) {
                    setNavigateTo(true)
                    setUpdateSuccess(true)
                    console.log("update success", res.data)
                }
                else {
                    setNavigateTo(false)
                    setUpdateSuccess(false)
                    console.log("update failed", res.error)
                }
            })
        }
    }

    useEffect(() => {
        if (updateCodeError as any) {
            toastError((updateCodeError as any)?.data?.message);
        }
    }, [updateCodeError])

    useEffect(() => {
        if (selectedCode) {
            codeData?.forEach((element: any) => {
                if (element.packName === selectedCode) {
                    setNewCode(element)
                }
            })
        }
    }, [selectedCode, codeData])

    useEffect(() => {
        if (navigateTo && updateSuccess === true) {
            toastSuccess(t('updatedSuccessfully'));
            setNavigateTo(false);
        }
        else if (!navigateTo && updateSuccess === false) {
            toastSuccess(t('failed'));
        }
    }, [navigateTo, updateSuccess]);

    useEffect(() => {
        if (productData) {
            reset(productData);
        }
    }, [productData])

    return (
        <section className="md:h-[calc(100%-3.5rem)] h-screen rounded-lg shadow bg-white w-screen md:w-auto overflow-auto">
            <div className="w-full p-2 md:p-0 md:pl-5 md:py-5">
                <form id="addProductCode" onSubmit={handleSubmit(onSubmit)}>
                    <>
                        <div className="my-2">
                            <label htmlFor="code" className="text-xs font-semibold text-TT-gray uppercase">
                                {t(`code`)}
                            </label>
                            <div className="h-3" />
                            <div className="md:w-1/4">
                                <Field
                                    {...register("enterCode")}
                                    type="select"
                                    placeholder={t(`enterCode`)}
                                    id="enterCode"
                                    error={errors?.enterCode && `${errors?.enterCode?.message}`}
                                    className="px-2 h-12 w-full md:w-1/4"
                                    onChange={
                                        (value: any) => {
                                            setSelectedCode(value.target.value)
                                        }
                                    }>
                                    <option>select</option>
                                    <option defaultValue={productData?.code?.packName}> {productData?.code?.packName}</option>
                                    {codeData?.map((code: any, index: number) => {
                                        return (
                                            productData?.code?.packName !== code?.packName &&
                                            <option key={index}>{code?.packName}</option>
                                        );
                                    })}
                                </Field>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-2 justify-center">
                            <Button
                                color="primary"
                                className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
                                form="addProductCode"
                                submit
                            >
                                {t("addCode")}
                            </Button>
                        </div>
                    </>
                </form>
            </div>
        </section>
    )
}
export default ProductCode;