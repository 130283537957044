import { fabricApi } from './../Services/fabricApi';
import { rolesApi } from './../Services/rolesApi';
import { allApis } from '../Services/allApi';
import AsyncStorage from '@react-native-async-storage/async-storage';
import userReducer from '../Services/userReducer';
import tokenReducer from '../Services/tokenReducer';
import { apiTokenApi } from './../Services/apiTokenApi';
import { preferenceApi } from './../Services/preferenceApi';
import { locationApi } from './../Services/locationApi';
import { productApi } from './../Services/productApi';
import { companyApi } from './../Services/companyApi';
import { combineReducers } from 'redux'
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { loginApi } from '../Services/loginApi';
import { userApi } from '../Services/userApi';
import { transactionApi } from '../Services/transactionApi';
import { locationTypesApi } from '../Services/locationTypesApi';
import { companyTypesApi } from '../Services/companyTypesApi';
import { codeTypeApi } from '../Services/codeTypeApi';
import { blockLifespanTypesApi } from '../Services/blockLifespanTypesApi';
import { customersApi } from '../Services/CustomersApi';
import { verificationApi } from '../Services/VerificationApi';
import { deliveryPathApi } from '../Services/DeliveryPathApi';
import { codeConfigApi } from '../Services/CodeConfigApi';
import { blockConfigApi } from '../Services/BlockConfigApi';
import { emailConfigApi } from '../Services/EmailConfigApi';
import { apiTokenConfigApi } from '../Services/APITokenConfigApi';
import { pricingApi } from '../Services/PricingApi';
import { companiesApi } from '../Services/companiesApi';
import { qrCodeApi } from '../Services/qrCodeApi';
import utilsReducer from '../Services/utilsReducer';

const reducers = combineReducers({
  [allApis.reducerPath]: allApis.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
  [preferenceApi.reducerPath]: preferenceApi.reducer,
  [apiTokenApi.reducerPath]: apiTokenApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
  [locationTypesApi.reducerPath]: locationTypesApi.reducer,
  [companyTypesApi.reducerPath]: companyTypesApi.reducer,
  [codeTypeApi.reducerPath]: codeTypeApi.reducer,
  [blockLifespanTypesApi.reducerPath]: blockLifespanTypesApi.reducer,
  [customersApi.reducerPath]: customersApi.reducer,
  [verificationApi.reducerPath]: verificationApi.reducer,
  [deliveryPathApi.reducerPath]: deliveryPathApi.reducer,
  [codeConfigApi.reducerPath]: codeConfigApi.reducer,
  [blockConfigApi.reducerPath]: blockConfigApi.reducer,
  [emailConfigApi.reducerPath]: emailConfigApi.reducer,
  [apiTokenConfigApi.reducerPath]: apiTokenConfigApi.reducer,
  [pricingApi.reducerPath]: pricingApi.reducer,
  [companiesApi.reducerPath]: companiesApi.reducer,
  [qrCodeApi.reducerPath]: qrCodeApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [fabricApi.reducerPath]: fabricApi.reducer,
  user: userReducer,
  token: tokenReducer,
  utils: utilsReducer,
})

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['user', 'token'],
  transforms: [
    encryptTransform({
      secretKey: 'my-super-secret-key', // To Do
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(allApis.middleware)
      .concat(userApi.middleware)
      .concat(loginApi.middleware)
      .concat(companyApi.middleware)
      .concat(productApi.middleware)
      .concat(locationApi.middleware)
      .concat(preferenceApi.middleware)
      .concat(apiTokenApi.middleware)
      .concat(transactionApi.middleware)
      .concat(locationTypesApi.middleware)
      .concat(companyTypesApi.middleware)
      .concat(codeTypeApi.middleware)
      .concat(blockLifespanTypesApi.middleware)
      .concat(customersApi.middleware)
      .concat(verificationApi.middleware)
      .concat(deliveryPathApi.middleware)
      .concat(codeConfigApi.middleware)
      .concat(blockConfigApi.middleware)
      .concat(emailConfigApi.middleware)
      .concat(apiTokenConfigApi.middleware)
      .concat(pricingApi.middleware)
      .concat(companiesApi.middleware)
      .concat(qrCodeApi.middleware)
      .concat(rolesApi.middleware)
      .concat(fabricApi.middleware);

    return middlewares
  },
})

const persistor = persistStore(store)
setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export { store, persistor }
