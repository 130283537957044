import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../Components/Pagination";
import TableFormat from "../../Components/TableFormat";
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from "chart.js";
import { FetchDataType, useFetchDataHook } from "../../Hooks/FetchData";
import {
  productApi,
  useDeleteProductByIdMutation,
  useGetProductsByPaginationQuery,
  useUpdateProductFromUploadMutation,
} from "../../Services/productApi";
import { Action, ActionType, ComponentType } from "../Action";
import { Products as ProductModel } from "../../models/products.model";
import Modal from "../../Components/Modal";
import PrintQR from "./PrintQR";
import PackProductPrint from "./PackPrint";
import { TableNavBar } from "../../Components/TableNavBar/TableNavBar";
import * as XLSX from "xlsx";
import { Images } from "../../models/images.model";
import { toastError, toastSuccess } from "../../Components/toast";
import SearchAndFilter from "../../Components/TableNavBar/SearchAndFilter";
import { useAppPermission } from "../../Hooks/AppPermissionHook";
import { Role } from "../../models/userRole.model";
import { useAppDispatch } from "../../Store/hooks";

ChartJS.register(ArcElement, Title, Tooltip, Legend);

function Products() {
  const { t } = useTranslation();
  const { pageData, loading, fetchData, isError, isEmpty } = useFetchDataHook(
    useGetProductsByPaginationQuery
  );

  const { hasPermission } = useAppPermission();
  const [deleteProduct] = useDeleteProductByIdMutation();
  const [updateProduct] = useUpdateProductFromUploadMutation();
  const dispatch = useAppDispatch();

  const [showProductDesc, setShowProductDesc] = useState<boolean>(false);
  const [productDesc, setProductDesc] = useState<ProductModel>();
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
  const [successCount, setSuccessCount] = useState<number>(0);
  const [failedCount, setFailedCount] = useState<number>(0);
  const [processCount, setProcessCount] = useState<number>(0);
  const [fileProcessing, setFileProcessing] = useState<boolean>(false);

  function Upload() {
    const fileUpload = document.getElementById("fileUpload") as
      | HTMLInputElement
      | any;
    const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
    if (regex.test(fileUpload?.value?.toLowerCase())) {
      if (typeof FileReader !== "undefined") {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = (e) => {
            processExcel(reader.result);
          };
          reader.readAsBinaryString(fileUpload.files[0]);
        }
      } else {
        toastError(t("thisBrowserDoesNotSupportHTML5"));
      }
    } else {
      toastError(t("pleaseUploadAValidExcelFile"));
    }
  }

  function uploadCSV(p: any) {
    let product: ProductModel = {} as any;
    product.product_name = p.PRODUCT_NAME;
    product.productDescription = p.PRODUCT_DESCRIPTION;
    product.gtin = p.GTIN;

    if (p.IMAGES) {
      let imgs = p.IMAGES as string;
      if (imgs.startsWith("[")) {
        imgs = imgs.substring(1);
      }
      if (imgs.endsWith("]")) {
        imgs = imgs.substring(0, imgs.length - 1);
      }
      const imgsArr = imgs.split(",");
      product.images = [];
      imgsArr.forEach((img) => {
        product.images.push({ imageURL: img.trim() } as Images);
      });
    }

    updateProduct(product).then((res: any) => {
      if (res.data) {
        toastSuccess(`${t("productUpdatedSuccessfully")}`);
        setSuccessCount((preValue: number) => preValue + 1);
      } else {
        toastError(`${t("productUpdateFailed")}`);
        setFailedCount((preValue: number) => preValue + 1);
      }
    });
  }

  function processExcel(data: any) {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    const totalProcessed = excelRows.length;
    setProcessCount(totalProcessed);
    setSuccessCount(0);
    setFailedCount(0);
    setFileProcessing(true)
    excelRows.map((e: any) => {
      uploadCSV(e);
    });
  }

  const handleProductClick = (product: ProductModel) => {
    if (product !== productDesc) {
      setProductDesc(product);
    }
    !showProductDesc && setShowProductDesc(true);
  };

  const [updateSearchModel, setUpdateSearchModel] = useState<any>();

  const fetchInactive = (active: boolean) => {
    fetchData({
      pageSize: pageData?.page.pageSize,
      pageIndex: 0,
      totalElements: 0,
      search: updateSearchModel,
      status: active
    } as FetchDataType);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: t("productName"),
        accessor: "product_name",
        Cell: ({ row: { original } }: any) => (
          <div className="flex flex-row items-center my-2 max-w-sm">
            {original?.images.length > 0 ? <img className="w-11 h-11 rounded-full flex flex-none items-center justify-center object-cover" src={original?.images[0].imageURL} alt={original?.product_name[0]} /> : <div className="w-11 h-11 rounded-full font-semibold bg-TT-blue text-white flex flex-none items-center justify-center uppercase">{original?.product_name[0]}</div>}
            <div className="flex flex-col pl-4 truncate">
              <div>{original?.product_name}</div>
              <div className="text-TT-gray-light">{original?.productDescription}</div>
            </div>
          </div>
        ),
      },
      {
        Header: t("GTIN"),
        accessor: "gtin",
      },
      {
        Header: t("preferred_sno"),
        accessor: "preferred_sno",
      },
      {
        Header: t("action"),
        accessor: "",
        Cell: ({ row: { original } }: any) => (
          <Action
            id={original.id}
            types={
              hasPermission([Role.CompanyAdmin])
                ? [ActionType.View, original.status ? ActionType.Delete : ActionType.Restore, ActionType.QR]
                : [ActionType.QR]
            }
            deleteFunc={deleteProduct}
            restoreFunc={() => dispatch(productApi.endpoints.restoreProductById.initiate(original.id)).unwrap()}
            invalidateFunc={() => dispatch(productApi.util.invalidateTags(['Products']))}
            navigation={`${original.id}/productInfo`}
            title="view History"
            componentType={ComponentType.Button}
            QRFunc={() => setShowPrintModal(true)}
          />
        ),
      },
    ], [t]);

  return (
    <>
      <div className="flex h-full flex-col lg:flex-row overflow-y-auto no-scrollbar">
        <TableNavBar
          tableTitle={"products"}
          navLink={hasPermission([Role.CompanyLocationAdmin]) ? "" : "/products/productInfo"}
          buttonTitle={hasPermission([Role.CompanyLocationAdmin]) ? "" : "addNew"}
          pageData={pageData}
          fetchData={fetchData}
          isError={isError}
          isLoading={loading}
          isEmpty={isEmpty}
        >
          <TableNavBar.SearchAndFilter>
            <SearchAndFilter
              type={"Products"}
              onSearch={(searchModel) => {
                setUpdateSearchModel(searchModel);
                fetchData({
                  pageSize: pageData?.page.pageSize,
                  pageIndex: pageData?.page.pageIndex,
                  totalElements: pageData?.page.totalElements,
                  search: searchModel,
                } as FetchDataType);
              }}
            />
          </TableNavBar.SearchAndFilter>
          <TableNavBar.Pagination>
            {pageData && (
              <Pagination page={pageData.page} fetchData={fetchData} />
            )}
          </TableNavBar.Pagination>
          <TableNavBar.TableFormat>
            {pageData && (
              <TableFormat
                columns={columns}
                data={pageData?.data}
                onRowClicked={(value: ProductModel) =>
                  value && handleProductClick(value)
                }
                _query={useGetProductsByPaginationQuery} fetchInactive={fetchInactive}
              />
            )}
          </TableNavBar.TableFormat>
        </TableNavBar>
        <section className="w-full md:w-[calc(100%-1rem)] lg:w-1/4 lg:mt-2 md:mx-2 lg:ml-0 mt-2 md:mt-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 h-fit gap-y-2 mr-2 mb-2 gap-x-2">
          <div className="col-span-1">
            <PackProductPrint />
          </div>
          <div className="bg-white p-5 rounded-lg col-span-1">
            <span className="font-bold text-lg ">{t("uploadFile")}</span>
            <div className="px-2 mt-3">
              <form id="productsForm">
                <input
                  accept=".xlsx, .xls, .csv"
                  type="file"
                  placeholder={t(`uploadFile`)}
                  id="fileUpload"
                  className="border-0 text-sm
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-lg file:border-TT-blue
                                    file:text-sm file:font-semibold
                                    file:bg-white
                                    hover:file:bg-TT-blue hover:file:text-white"
                  onChange={() => Upload()}
                />
              </form>
              <div className="h-4" />
              {fileProcessing && <>
                <div className="flex-row flex items-center gap-x-2">
                  <div className="rounded-full w-3 h-3 bg-red-600"></div>
                  <span className="gap-x-2">{t("error")}</span>
                  <span className="gap-x-2 font-bold">{failedCount}</span>
                </div>
                <div className="flex-row flex items-center gap-x-2">
                  <div className="rounded-full w-3 h-3 bg-yellow-300"></div>
                  <span className="gap-x-2">{t("processed")}</span>
                  <span className="gap-x-2 font-bold">{processCount}</span>
                </div>
                <div className="flex-row flex items-center gap-x-2">
                  <div className="rounded-full w-3 h-3 bg-green-600"></div>
                  <span className="gap-x-2">{t("success")}</span>
                  <span className="gap-x-2 font-bold">{successCount}</span>
                </div>
              </>}
            </div>
          </div>
          {showProductDesc && (
            <div className="bg-white p-5 rounded-lg col-span-1 md:col-span-2 lg:col-span-1">
              <div className="flex">
                <span className="font-bold text-lg ">
                  {t("productDescription")}
                </span>
              </div>
              <div className="px-2 h-48 overflow-y-auto">
                {productDesc?.images && (
                  <img
                    src={productDesc?.images[0]?.imageURL}
                    alt={productDesc?.product_name}
                    className="rounded-lg my-3 mx-auto w-full md:w-1/2 lg:w-full h-44"
                  />
                )}
                <div>{productDesc?.productDescription}</div>
              </div>
            </div>
          )}
        </section>
      </div>
      {productDesc && (
        <Modal
          isOpen={showPrintModal}
          setIsOpen={setShowPrintModal}
          title={"Print QR"}
        >
          <PrintQR product={productDesc} />
        </Modal>
      )}
    </>
  );
}

export default Products;
