import { yupResolver } from '@hookform/resolvers/yup';
import { CodeTypes } from './../models/codeTypes.model';
import { useEffect, useState } from "react";
import { useCreateCodeTypesMutation, useGetCodeTypesByIdQuery, useGetCodeTypesByPaginationQuery, useUpdateCodeTypesByIdMutation } from "../Services/codeTypeApi";
import { useFetchDataHook } from "./FetchData";
import { useForm } from 'react-hook-form';
import { addCodesValidationSchema } from '../Utils/validation';

interface _Props {
    id: string | undefined
}

export const useCodeTypeHook = (props: _Props) => {
    const { id } = props
    const { pageData, loading, fetchData, model, refetching } = useFetchDataHook(useGetCodeTypesByPaginationQuery);
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(addCodesValidationSchema) })
    const { data: codeTypesData } = useGetCodeTypesByIdQuery(id, { skip: !id })
    const [updateCodeTypesData, { error: updateCodeTypesDataError }] = useUpdateCodeTypesByIdMutation()
    const [createCodeTypesData, { error: createCodeTypesDataError }] = useCreateCodeTypesMutation()
    const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();
    const [createSuccess, setCreateSuccess] = useState<boolean | undefined>();
    const [navigateTo, setNavigateTo] = useState<boolean>()
    const onSubmit = (value: any) => {
        let updateCodeTypesDetails: CodeTypes = codeTypesData
            ? Object.assign({}, codeTypesData)
            : ({} as any);

        if (updateCodeTypesDetails) {
            updateCodeTypesDetails.code = value?.codeName
            updateCodeTypesDetails.pattern = value?.codePattern
            updateCodeTypesDetails.codeExample = value?.codeExample
            if (id) {
                updateCodeTypesData(updateCodeTypesDetails).then((res: any) => {
                    if (res) {
                        setNavigateTo(true)
                        setUpdateSuccess(true)
                        console.log("update success: ", res.data)
                    }
                    else {
                        setNavigateTo(false)
                        setUpdateSuccess(false)
                        console.log("update errors: ", res.data)
                    }
                })
            }
            else {
                createCodeTypesData(updateCodeTypesDetails).then((res: any) => {
                    if (res) {
                        setNavigateTo(true)
                        setCreateSuccess(true)
                        console.log("create success: ", res.data)
                    }
                    else {
                        setNavigateTo(false)
                        setCreateSuccess(false)
                        console.log("create errors: ", res.data)
                    }
                })
            }
        }
    }

    useEffect(() => {
        if (codeTypesData) {
            reset(codeTypesData)
        }
    }, [codeTypesData])


    return {
        pageData,
        loading,
        fetchData,
        model,
        refetching,
        codeTypesData,
        updateCodeTypesData,
        setNavigateTo,
        navigateTo,
        createCodeTypesData,
        register,
        handleSubmit,
        errors,
        onSubmit,
        createCodeTypesDataError,
        updateCodeTypesDataError,
        createSuccess,
        updateSuccess,
    };
}