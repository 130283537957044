import { allApis } from './allApi';
import { Login } from '../models/login.model';
import { PagedData } from '../models/pagedData.model';

export const apiTokenConfigApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAPITokenConfig: builder.query<Login[], void>({
            query: () => "api/apitoken/getAll/page",
            providesTags: ["APITokenConfig"],
        }),
        getAPITokenConfigByPagination: builder.query<PagedData<Login[]>, any>({
            query: search => {
                return {
                    url: "api/apitoken/getAll/page",
                    params: search
                }
            },
            providesTags: ["APITokenConfig"],
        }),
    }),
});

export const { useGetAPITokenConfigQuery, useGetAPITokenConfigByPaginationQuery } = apiTokenConfigApi;
