import { useFetchDataHook } from "./FetchData";
import { useCreateEmailConfigMutation, useDefaultEmailIdUpdateMutation, useDeleteEmailByIdMutation, useGetEmailConfigByIdQuery, useGetEmailConfigByPaginationQuery, useUpdateEmailConfigByIdMutation } from "../Services/EmailConfigApi";
import { useForm } from "react-hook-form";
import { useEffect, useState } from 'react';
import { Email } from '../models/email.model';


interface _Props {
    id: string | undefined
}
export const useEmailConfigHook = (props: _Props) => {
    const { id } = props
    const { pageData, loading, fetchData, model, refetching, isEmpty, isError } = useFetchDataHook(useGetEmailConfigByPaginationQuery);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [template, setTemplate] = useState<string>("");
    const [preview, setPreview] = useState<boolean>(false);
    const [deleteEmail] = useDeleteEmailByIdMutation();
    const [updateEmail] = useUpdateEmailConfigByIdMutation();
    const [createEmail] = useCreateEmailConfigMutation();
    const [defaultEmail] = useDefaultEmailIdUpdateMutation();
    const { data: getEmailDataById } = useGetEmailConfigByIdQuery(id, { skip: !id })
    const [navigateTo, setNavigateTo] = useState<boolean>()
    const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();

    const onSubmit = (value: any) => {
        let updateEmailDetails: Email = getEmailDataById
            ? Object.assign({}, getEmailDataById)
            : ({} as any);

        updateEmailDetails.templateName = value.name;
        updateEmailDetails.description = value.description;
        updateEmailDetails.template = template;
        if (id) {
            updateEmail(updateEmailDetails).then((res: any) => {
                if (res.data) {
                    setNavigateTo(true)
                    setUpdateSuccess(true)
                    console.log("update success: ", res.data)
                }
                else {
                    setNavigateTo(false)
                    setUpdateSuccess(false)
                    console.log("update errors: ", res.data)
                }
            })
        }
        else if (!id) {
            createEmail(updateEmailDetails).then((res: any) => {
                if (res.data) {
                    setNavigateTo(true)
                    setUpdateSuccess(true)
                    console.log("create success: ", res.data)
                }
                else {
                    setNavigateTo(false)
                    setUpdateSuccess(false)
                    console.log("create errors: ", res.data)
                }
            })
        }
    }

    useEffect(() => {
        if (getEmailDataById) {
            reset(getEmailDataById);
        }
    }, [getEmailDataById])

    return {
        pageData, loading, fetchData, model, refetching, deleteEmail, isEmpty, isError,
        register, handleSubmit, reset, errors, preview, setPreview, template, setTemplate,
        getEmailDataById, onSubmit, setNavigateTo, navigateTo, updateSuccess, defaultEmail
    };
}