import { ReactNode } from 'react'
import { Dialog } from '@headlessui/react'
import Icon from './base/icon/icon'
import { useTranslation } from 'react-i18next'

interface ModalProp {
  isOpen: boolean
  setIsOpen: Function
  title?: string,
  children: ReactNode
  onDismiss?: Function
  className?: string
}

const Modal = ({ isOpen, setIsOpen, title, children, onDismiss, className }: ModalProp) => {

  const { t } = useTranslation();
  
  function closeModal() {
    setIsOpen(false);
    onDismiss && onDismiss()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => { closeModal() }}
      as="div"
      className={
        `fixed inset-0 z-10 flex items-center justify-center overflow-y-auto ${isOpen && "bg-gray-900 bg-opacity-30"}`
      }
    >
      <div className={`flex flex-col rounded-md bg-white md:w-1/2 lg:w-1/4 p-6 ${className}`}>
        <Dialog.Overlay />
        <div className='flex flex-row justify-between'>
          <Dialog.Title className="text-2xl font-semibold mb-4">
            {t(`${title}`)}
          </Dialog.Title>
          <Icon icon="CLOSE" onClick={() => closeModal()} className="cursor-pointer" />
        </div>
        <Dialog.Panel>
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>

  )
}

export default Modal;