import { PagedData } from '../models/pagedData.model';
import { Transaction } from '../models/transaction.model';
import { allApis } from './allApi';

export const transactionApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllTransactions: builder.query<PagedData<Transaction[]>, any>({
            query: (search) => {
                return {
                    url: "api/transaction/getAll/page",
                    params: search
                };
            },
            providesTags: ['Transaction']
        }),
    }),

});

export const { useGetAllTransactionsQuery } = transactionApi;
