import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../Store";


interface UtilsStateType {
  clearFilters: boolean;
  navBarTitle: string;
  navBarPrevPage?: string;
  navBarUserAvatar?: boolean;
  showInActiveList?: boolean
}

const initialStateValue: UtilsStateType = {
  clearFilters: false,
  navBarTitle: "",
  navBarPrevPage: "",
  navBarUserAvatar: true,
  showInActiveList: false
};

export const utilSlice = createSlice({
  name: "utils",
  initialState: {
    value: initialStateValue
  },
  reducers: {
    setClearFilters: (state, action) => {
      state.value.clearFilters = action.payload;
    },
    setNavbarTitle: (state, action) => {
      state.value.navBarTitle = action.payload;
    },
    setNavbarPrevPage: (state, action) => {
      state.value.navBarPrevPage = action.payload;
    },
    setNavbareUserAvatar: (state, action) => {
      state.value.navBarUserAvatar = action.payload;
    },
    setNavbarProps: (state, action) => {
      state.value.navBarTitle = action.payload.navBarTitle;
      state.value.navBarPrevPage = action.payload.navBarPrevPage;
      state.value.navBarUserAvatar = action.payload.navBarUserAvatar;
    },
    setShowInActiveList: (state, action) => {
      state.value.showInActiveList = action.payload
    }
  }
});

export const getClearFilters = (state: RootState): boolean => state.utils?.value.clearFilters;
export const getNavbarTitle = (state: RootState): string => state.utils?.value.navBarTitle;
export const getNavbarPrevPage = (state: RootState): string => state.utils?.value.navBarPrevPage;
export const getNavbarUserAvatar = (state: RootState): boolean => state.utils?.value.navBarUserAvatar;
export const getShowInActiveList = (state: RootState): boolean => state.utils?.value.showInActiveList;

export const { setClearFilters, setNavbarTitle, setNavbarPrevPage, setNavbareUserAvatar, setNavbarProps, setShowInActiveList } = utilSlice.actions;
export default utilSlice.reducer;