import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../Components/base/button/button';
import Field from '../../../Components/base/field/field';
import { toastError, toastSuccess } from '../../../Components/toast';
import { useCompanyTypesHook } from '../../../Hooks/CompanyTypesHooks';

function AddCompanyType() {
  const { id } = useParams()
  const { t } = useTranslation();
  const navigate = useNavigate()
  const {
    register, handleSubmit, errors, onSubmit, companyTypesData, navigateTo, setNavigateTo, createSuccess, updateSuccess, createCompanyTypesDataError, updateCompanyTypesDataError
  } = useCompanyTypesHook({ id })

  useEffect(() => {
    if (createCompanyTypesDataError as any) {
      toastError((createCompanyTypesDataError as any)?.data?.message);
    }
    if (updateCompanyTypesDataError as any) {
      toastError((updateCompanyTypesDataError as any)?.data?.message);
    }
  }, [createCompanyTypesDataError, updateCompanyTypesDataError])

  useEffect(() => {
    if (navigateTo && createSuccess === true) {
      toastSuccess(t("createdSuccessfully"));
      setNavigateTo(false);
      navigate('/types/companyType');
    } else if (!navigateTo && createSuccess === false) {
      toastSuccess(t("failed"));
    }
  }, [navigateTo, createSuccess]);

  useEffect(() => {
    if (navigateTo && updateSuccess === true) {
      toastSuccess(t('updatedSuccessfully'));
      setNavigateTo(false);
      navigate('/types/companyType')
    }
    else if (!navigateTo && updateSuccess === false) {
      toastSuccess(t('failed'));
    }
  }, [navigateTo, updateSuccess]);

  return (
    <div className="md:md:h-[calc(100%-4.3rem)]  md:m-2 rounded-lg shadow bg-white w-screen md:w-auto overflow-auto">
      <div className="w-full lg:w-1/4 p-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
            <div className="col-span-12 flex flex-col gap-2">
              <label htmlFor="companyName" className='text-xs uppercase font-semibold'>{t(`companyType`)}</label>
              <Field
                {...register('companyType')} error={errors?.companyType && `${errors?.companyType?.message}`} type='text' defaultValue={companyTypesData?.companyType}
                placeholder={t(`companyType`)} id="companyName" className='px-2 h-12'
              />
          <div className='flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-5 justify-center'>
                <Button color='primary' className="py-1 md:w-fit text-white h-12 md:h-fit w-full mx-0" submit>{id ? t('update') : t('addCompanyType')}</Button>
          </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddCompanyType