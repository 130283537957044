import { allApis } from './allApi';
import { DeliveryPath } from '../models/deliveryPath.model';
import { PagedData } from '../models/pagedData.model';

export const deliveryPathApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getDeliveryPaths: builder.query<DeliveryPath[], void>({
            query: () => "api/deliveryPath",
            providesTags: ["DeliveryPath"],
        }),
        getDeliveryPathByPagination: builder.query<PagedData<DeliveryPath[]>, any>({
            query: search => {
                return {
                    url: "api/deliveryPath/getAll/page",
                    params: search
                }
            },
            providesTags: ["DeliveryPath"],
        }),
        getDeliveryPathById: builder.query<DeliveryPath, any>({
            query: (id: string) => `api/deliveryPath/${id}`,
            providesTags: ["DeliveryPath"],
        }),
        updateDeliveryPathById: builder.mutation<DeliveryPath, Partial<DeliveryPath>>({
            query: (deliveryPath) => {
                return {
                    url: `api/deliveryPath/${deliveryPath.id}`,
                    method: "PUT",
                    body: deliveryPath,
                }
            },
            invalidatesTags: ['DeliveryPath']
        }),
        createDeliveryPath: builder.mutation<DeliveryPath, Partial<DeliveryPath>>({
            query: (deliveryPath) => {
                return {
                    url: `api/deliveryPath`,
                    method: "POST",
                    body: deliveryPath,
                }
            },
            invalidatesTags: ['DeliveryPath']
        }),
        deleteDeliveryPathById: builder.mutation<boolean, any>({
            query: (id) => {
                return {
                    url: `api/deliveryPath/${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ['DeliveryPath']
        }),
        restoreDeliveryPathById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `api/deliveryPath/restore/${id}`,
                }
            },
            providesTags: ['DeliveryPath']
        }),
        defaultIdUpdate: builder.mutation<DeliveryPath, Partial<DeliveryPath>>({
            query: (id) => {
                return {
                    url: `api/deliveryPath/default/${id}`,
                    method: "PUT",
                }
            },
            invalidatesTags: ['DeliveryPath']
        }),
    }),

});

export const { useGetDeliveryPathsQuery,
    useGetDeliveryPathByPaginationQuery,
    useDeleteDeliveryPathByIdMutation,
    useGetDeliveryPathByIdQuery,
    useUpdateDeliveryPathByIdMutation,
    useCreateDeliveryPathMutation,
    useDefaultIdUpdateMutation,
    useRestoreDeliveryPathByIdQuery,
} = deliveryPathApi;
