import { allApis } from "./allApi";
import { PagedData } from "../models/pagedData.model";
import { CompanyType } from "../models/companyType.model";

export const companyTypesApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        companyTypes: builder.query<CompanyType[], void>({
            query: () => "api/companyType",
            providesTags: ['CompanyType']
        }),
        getCompanyTypesByPagination: builder.query<PagedData<CompanyType[]>, any>({
            query: (args) => {
                return {
                    url: "api/companyType/getAll/page/",
                    params: args
                }
            },
            providesTags: ['CompanyType']
        }),
        getCompanyTypesById: builder.query<CompanyType, any>({
            query: (id: string) => `api/companyType/${id}`,
        }),
        updateCompanyTypesById: builder.mutation<CompanyType[], any>({
            query: (companyType) => {
                return {
                    url: `api/companyType/${companyType.id}`,
                    method: "PUT",
                    body: companyType,
                }
            },
            invalidatesTags: ['CompanyType']
        }),
        createCompanyTypes: builder.mutation<CompanyType[], Partial<CompanyType>>({
            query: (companyType) => {
                return {
                    url: `api/companyType`,
                    method: "POST",
                    body: companyType,
                }
            },
            invalidatesTags: ['CompanyType']
        }),
        deleteCompanyTypesById: builder.mutation<any, any>({
            query: (id) => {
                return {
                    url: `api/companyType/${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ['CompanyType']
        }),
        restoreCompanyTypesById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `api/companyType/restore/${id}`,
                }
            },
            providesTags: ['CompanyType']
        }),
    })
});

export const { useCompanyTypesQuery, useRestoreCompanyTypesByIdQuery, useGetCompanyTypesByPaginationQuery, useGetCompanyTypesByIdQuery, useUpdateCompanyTypesByIdMutation, useCreateCompanyTypesMutation, useDeleteCompanyTypesByIdMutation } = companyTypesApi;