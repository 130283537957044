import { yupResolver } from '@hookform/resolvers/yup';
import { DeliveryPath } from './../models/deliveryPath.model';
import { useEffect, useState } from 'react';
import { useFetchDataHook } from "./FetchData";
import { useCreateDeliveryPathMutation, useDefaultIdUpdateMutation, useDeleteDeliveryPathByIdMutation, useGetDeliveryPathByIdQuery, useGetDeliveryPathByPaginationQuery, useUpdateDeliveryPathByIdMutation } from "../Services/DeliveryPathApi";
import { useLocationTypesQuery } from '../Services/locationTypesApi';
import { useForm } from 'react-hook-form';
import { addConfigDeliveryPathValidationSchema } from '../Utils/validation';


interface _Props {
    id?: string | undefined
}
export const useDeliveryPathHook = (props: _Props) => {
    const { id } = props
    const { pageData, loading, fetchData, model, refetching, isEmpty, isError } = useFetchDataHook(useGetDeliveryPathByPaginationQuery);
    const { data: locationTypeData, isSuccess: locationTypeSuccess } = useLocationTypesQuery({ id })
    const [deleteDeliveryPath] = useDeleteDeliveryPathByIdMutation();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(addConfigDeliveryPathValidationSchema) });
    const { data: deliveryPathData } = useGetDeliveryPathByIdQuery(id, { skip: !id })
    const [updateDeliveryPath, { error: updateDeliveryPathError }] = useUpdateDeliveryPathByIdMutation()
    const [createDeliveryPath, { error: createDeliveryPathError }] = useCreateDeliveryPathMutation()
    const [defaultDeliveryPath] = useDefaultIdUpdateMutation()
    const [newRadioSelect, setNewRadioSelect] = useState()
    const [locationTypeOption, setLocationTypeOption] = useState<string[]>([])
    const [locationType, setLocationType] = useState<string[]>([]);
    const [result, setResult] = useState<any | undefined>([]);
    const [locationTypeQuery, setLocationTypeQuery] = useState('')
    const [navigateTo, setNavigateTo] = useState<boolean>()
    const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();
    const [createSuccess, setCreateSuccess] = useState<boolean | undefined>();

    const addLocationType = (item: any) => {
        if (item && !locationType.includes(item)) {
            setLocationType([...locationType, item]);
            setLocationTypeOption(locationTypeOption.filter(c => c !== item));
        }
    }

    const removeLocationType = (location: any) => {
        setLocationType(locationType.filter(c => c !== location));
        setLocationTypeOption([...locationTypeOption, location]);
    }

    const onSubmit = (values: any) => {

        let updateDeliveryPathDetails: DeliveryPath = deliveryPathData
            ? Object.assign({}, deliveryPathData)
            : ({} as any);

        updateDeliveryPathDetails.pathName = values.pathName;
        updateDeliveryPathDetails.pathCode = values.pathCode;
        updateDeliveryPathDetails.order = values.order;
        updateDeliveryPathDetails.status = values.inActiveUserLogin;
        updateDeliveryPathDetails.path = result;
        if (id) {
            updateDeliveryPath(updateDeliveryPathDetails).then((res: any) => {
                if (res) {
                    setNavigateTo(true)
                    setUpdateSuccess(true)
                    console.log("update success: ", res.data)
                }
                else {
                    setNavigateTo(false)
                    setUpdateSuccess(false)
                    console.log("update errors: ", res.data)
                }
            })
        }
        else if (!id) {
            createDeliveryPath(updateDeliveryPathDetails).then((res: any) => {
                if (res) {
                    setNavigateTo(true)
                    setCreateSuccess(true)
                    console.log("create success: ", res.data)
                }
                else {
                    setNavigateTo(false)
                    setCreateSuccess(false)
                    console.log("create errors: ", res.data)
                }
            })
        }
    }
    useEffect(() => {
        if (deliveryPathData) {
            reset(deliveryPathData)
            setLocationType([])
            deliveryPathData?.path?.map((values) => {
                setLocationType((value) => [...value, values.locationType]);
            })
        }
    }, [deliveryPathData])

    useEffect(() => {
        if (locationTypeData && locationTypeSuccess) {
            locationTypeData?.map((e) => {
                setLocationTypeOption((value) => [...value, e.locationType]);
            })
        }
    }, [locationTypeSuccess, locationTypeData])

    useEffect(() => {
        setResult([])
        if (locationType && locationTypeData) {
            locationType.forEach((value) =>
                setResult((val1: any) => [...val1, locationTypeData.find((val) =>
                    value === val.locationType
                )])
            )
        }
    }, [locationTypeSuccess, locationType, locationTypeData])

    return {
        pageData, loading, fetchData, model, isEmpty,
        refetching, deleteDeliveryPath, newRadioSelect, isError,
        setNewRadioSelect, deliveryPathData, id, locationTypeData,
        onSubmit, register, handleSubmit, reset, errors, locationTypeOption,
        setLocationTypeOption, locationType, setLocationType, addLocationType, removeLocationType,
        locationTypeQuery, setLocationTypeQuery, setNavigateTo, navigateTo, defaultDeliveryPath,
        createSuccess, setCreateSuccess, createDeliveryPathError, updateDeliveryPathError, updateSuccess, setUpdateSuccess,
    };
}