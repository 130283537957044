import { useState } from "react";
import { v4 } from "uuid";
import { Login } from "../models/login.model";
import { useCreateTokenMutation, useGetAllApiTokensQuery, useUpdateTokenMutation, useUpdateValidityMutation } from "../Services/apiTokenApi";
import { useFetchDataHook } from "./FetchData";

export const useApiTokenHook = () => {
    const [toastMessage, setToastMessage] = useState<string>("");

    const { pageData, loading, fetchData, isError, isEmpty } = useFetchDataHook(useGetAllApiTokensQuery);
    const [deleteApiToken] = useUpdateTokenMutation();
    const [updateApiTokenValidity] = useUpdateValidityMutation();
    const [createToken] = useCreateTokenMutation()

    const create = () => {
        const apiToken: Login = {} as Login;
        apiToken.token = v4();
        apiToken.manual = true;

        createToken(apiToken).then((res: any) => {
            if (res.data) {
                console.log("createdSuccessfully");
            }
        });
    }

    return {
        toastMessage, setToastMessage, create, pageData, loading,
        deleteApiToken, updateApiTokenValidity, fetchData, isEmpty, isError,
    };
}