import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";

interface RoleSelectorProp {
  data: any[];
  onSelected: Function;
  defaultRole: any[];
}

const RoleSelector = (props: RoleSelectorProp) => {
  const { data, onSelected, defaultRole } = props;
  const [role, setRole] = useState(
    defaultRole.length > 0 ? defaultRole[0] : undefined
  ); // {role: string}

  useEffect(() => {
    if (role) {
      onSelected(role);
    }
  }, [role]);

  const selectedStyle =
    "mr-1 mb-1 p-2 rounded-md bg-blue-500 border-2 text-white border-TT-blue";
  const unselectedStyle =
    "mr-1 mb-1 p-2 rounded-md border-TT-blue border-2 text-black";

  return (
    <RadioGroup value={role?.role} onChange={(r) => setRole({role: r})}>
      <div className="flex flex-row flex-wrap cursor-default">
        {data.map((item, index) => {
          return (
            <RadioGroup.Option value={item.role} key={index}>
              {({ checked }) => (
                <div className={checked ? selectedStyle : unselectedStyle}>
                  {item.role_name}
                </div>
              )}
            </RadioGroup.Option>
          );
        })}
      </div>
    </RadioGroup>
  );
};

export default React.memo(RoleSelector);
