import { useCallback, useEffect, useState } from "react";

export interface FetchDataType {
    pageSize: number,
    pageIndex: number,
    totalElements: number,
    search: any,
    status: boolean
}

export const useFetchDataHook = <T>(useQuery: any) => {
    const [model, setModel] = useState<any>({
        pageIndex: 0,
        pageSize: 15,
        totalElements: 0,
        totalPages: 0,
    } as any);

    const { data: pageData, isLoading: loading, isFetching: refetching, isError } = useQuery(model);

    useEffect(() => {
        if (pageData?.page) {
            const totalPages = Math.ceil(pageData?.page?.totalElements / pageData?.page?.pageSize);
            setTimeout(() => {
                setModel({ ...model, totalPages, totalElements: pageData?.page?.totalElements });
            }, 50);
        }
    }, [pageData?.data]);

    const fetchData = useCallback(({ pageSize, pageIndex, totalElements, search, status }: FetchDataType) => {

        if (totalElements) {
            const newPageTotal = Math.ceil(totalElements / pageSize) - 1;
            if (newPageTotal < pageIndex) {
                pageIndex = newPageTotal;
            }
        }
        setModel({ ...model, ...search, pageSize, pageIndex, status });
    }, [])

    const isEmpty = pageData && (pageData?.data?.length === 0 || pageData?.length === 0)

    return { pageData, loading, fetchData, model, refetching, isError, isEmpty };
}