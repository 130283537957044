import { useEffect } from "react"

export function useTitle(title:string) {
    useEffect(() => {
        const prevTitle = document.title
        document.title = "T&T | " + title;

        return () => {
            document.title = prevTitle
        }
    })
}