import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editCompanyImageValidationSchema } from "../../Utils/validation";
import Field from "../../Components/base/field/field";
import { useTranslation } from "react-i18next";
import Icon from "../../Components/base/icon/icon";
import { useDropzone } from "react-dropzone";
import imagePlaceholder from "../../Assets/images/image.svg";
import { useParams } from "react-router-dom";
import {
  useDeleteProductImageMutation,
  useGetProductByIdQuery,
  useUploadProductImagefromFileMutation,
  useUploadProductImagefromUrlMutation,
} from "../../Services/productApi";
import { useEffect, useState } from "react";
import Button from "../../Components/base/button/button";
import { Config } from "../../Config";
import { toastError, toastSuccess } from "../../Components/toast";

function Images() {
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(editCompanyImageValidationSchema) });
  const { data: imageData, refetch } = useGetProductByIdQuery(id);

  const [file, setFile] = useState<File[] | undefined>([]);
  const [imageUrl, setImageUrl] = useState<string>('');

  const [uploadImageFile] = useUploadProductImagefromFileMutation();
  const [uploadImageURL] = useUploadProductImagefromUrlMutation();
  const [deleteProductImage] = useDeleteProductImageMutation();

  const onSubmit = (values: any) => {
    if (values.imageUrl) {
      uploadImageURL({ imageUrl: values.imageUrl, id: id }).then(
        (result: any) => {
          if (result.data) {
            reset();
            refetch();
          } else {
            console.log("Image upload by URL failed", result.error);
          }
        }
      );
    }
  };

  function uploadImageByFile() {
    if (file) {
      try {
        file?.forEach(async (item: any) => {
          const result = await uploadImageFile({
            id: id,
            file: item,
            type: "undefined",
          }).unwrap();
          if (result) {
            reset();
            setFile(undefined);
            refetch();
            toastSuccess(t("updatedSuccessfully"));
          }
        });
      } catch (error) {
        toastError("failed");
        console.log("upload failed", error);
      }
    }
  }

  function deleteImage(imageId: string) {
    deleteProductImage({
      productId: id,
      imageId: imageId,
    }).then(() => {
      refetch();
    });
  }

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
  });

  useEffect(() => {
    if (acceptedFiles?.length) {
      acceptedFiles.forEach((values) => {
        setFile((value) => value && [...value, values]);
      });
    }
  }, [acceptedFiles]);

  const remove = (item: any) => {
    setFile(file?.filter((value) => value !== item));
  };

  const AcceptedImage = (item: any) => (
    <li
      key={item?.item.path}
      className="p-2 shadow-md rounded-md grid grid-cols-12"
    >
      <div className="col-span-2 flex justify-center items-center">
        <img src={imagePlaceholder} alt="img" />
      </div>
      <div className="col-span-9"> {item?.item.name}</div>
      <div className="col-span-1 flex justify-center items-center rotate-45 hover:text-red-500">
        <Icon
          icon="ADD_BLACK"
          onClick={() => {
            remove(item.item);
          }}
        />
      </div>
    </li>
  );

  return (
    <section className="md:h-[calc(100%-3.5rem)] rounded-lg shadow bg-white w-screen lg:w-auto overflow-hidden md:p-5">
      <div className="grid lg:grid-cols-12 h-full">
        <div className="grid col-span-6 md:border-r-2 p-5 ">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full flex flex-col items-center gap-5"
            id="Images"
          >
            <div className="w-3/4 flex flex-col gap-2">
              <label
                htmlFor="imageUrl"
                className="text-xs uppercase font-semibold"
              >
                {t(`imageUrl`)}
              </label>
              <Field
                {...register("imageUrl")}
                error={imageUrl != '' && errors?.imageUrl && t(`${errors?.imageUrl?.message}`)}
                type="text"
                placeholder={t(`enterImageURL`)}
                id="imageUrl"
                className="px-2 h-12"
                onChange={(e: any) => { console.log(e.target.value); setImageUrl(e.target.value); }}
              />
              <div className="flex flex-col gap-2 justify-center items-center">
                <Button
                  color="primary"
                  className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
                  form="Images"
                  submit
                >
                  {t("add")}
                </Button>
              </div>
            </div>
            <div className="border-dashed border-2 rounded-lg h-1/4 w-3/4 flex flex-col items-center justify-center">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <span className="text-TT-blue flex justify-center items-center h-full">
                  <Icon icon="UPLOAD" size="large" />
                </span>
                <span className="text-lg">{t("dragAndDropImage")}</span>
              </div>
            </div>
            <aside className="w-3/4">
              {file && file.length ? (
                <>
                  {file.map((item: any, index: number) => (
                    <AcceptedImage item={item} key={index} />
                  ))}
                </>
              ) : null}
            </aside>
            <div className="flex flex-col gap-2 justify-center items-center">
              <Button
                color="primary"
                className="py-1 md:w-fit h-fit w-full mx-0 uppercase"
                onClick={() => {
                  uploadImageByFile();
                }}
                id="Images"
              >
                {t("upload")}
              </Button>
            </div>
          </form>
        </div>
        <div className="col-span-6 md:p-5 overflow-auto">
          <div className="grid grid-cols-2 gap-5 p-5">
            {imageData?.images &&
              imageData?.images.map((images: any, index: number) => {
                return (
                  <span className="relative inline-block group" key={index}>
                    <img
                      src={
                        images?.imageURL
                          ? images?.imageURL
                          : Config.API_URL +
                            "/api/products/file/" +
                            images?.image_name
                      }
                      alt="TT"
                      className="border rounded-lg"
                    />
                    <div className="w-6 h-6 bg-TT-blue text-white flex justify-center items-center rounded-full font-semibold absolute -top-2 -right-2 opacity-0 group-hover:opacity-100 rotate-45">
                      <Icon
                        icon="ADD_BLACK"
                        onClick={() => {
                          deleteImage(images.id);
                        }}
                      />{" "}
                    </div>
                  </span>
                );
              })}
            {/* <span className="relative inline-block group">
                            <img src={img2} alt="TT" className='border rounded-lg' />
                            <div className="w-6 h-6 bg-TT-blue text-white flex justify-center items-center rounded-full font-semibold absolute -top-2 -right-2 opacity-0 group-hover:opacity-100 rotate-45"><Icon icon="ADD_BLACK" /> </div>
                        </span>
                        <span className="relative inline-block group">
                            <img src={img1} alt="TT" className='border rounded-lg' />
                            <div className="w-6 h-6 bg-TT-blue text-white flex justify-center items-center rounded-full font-semibold absolute -top-2 -right-2 opacity-0 group-hover:opacity-100 rotate-45"><Icon icon="ADD_BLACK" /> </div>
                        </span>
                        <span className="relative inline-block group">
                            <img src={img1} alt="TT" className='border rounded-lg' />
                            <div className="w-6 h-6 bg-TT-blue text-white flex justify-center items-center rounded-full font-semibold absolute -top-2 -right-2 opacity-0 group-hover:opacity-100 rotate-45"><Icon icon="ADD_BLACK" /> </div>
                        </span>
                        <span className="relative inline-block group">
                            <img src={img1} alt="TT" className='border rounded-lg' />
                            <div className="w-6 h-6 bg-TT-blue text-white flex justify-center items-center rounded-full font-semibold absolute -top-2 -right-2 opacity-0 group-hover:opacity-100 rotate-45"><Icon icon="ADD_BLACK" /> </div>
                        </span> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Images;
