import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    useGetPricingByPaginationQuery,
    useGetPricingByIdQuery,
    useCreatePricingMutation,
    useUpdatePricingByIdMutation,
} from "../Services/PricingApi";
import { addPricingValidationSchema } from "../Utils/validation";
import { useFetchDataHook } from "./FetchData";
import { CostStructure } from "../models/costStructure.model";
import { Company } from "../models/company.model";
import { useCompaniesHook } from "./CompaniesHooks";
import { useEffect, useState } from "react";
import { useGetBlockLifeSpanByPaginationQuery } from "../Services/blockLifespanTypesApi";

type PricingProps = {
    id?: string;
};

export const usePricingHook = (props: PricingProps) => {
    const { id } = props;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({ resolver: yupResolver(addPricingValidationSchema) });

    const { pageData, loading, fetchData, model, refetching } = useFetchDataHook(
        useGetPricingByPaginationQuery
    );
    const { pageData: blockLifeSpanType } = useFetchDataHook(
        useGetBlockLifeSpanByPaginationQuery
    );
    const { data: pricing } = useGetPricingByIdQuery(id, { skip: !id });

    useEffect(() => {
        if (pricing) {
            reset(pricing);
        }
    }, [pricing]);

    const [addPricing, { error: addPricingError }] = useCreatePricingMutation();
    const [updatePricing, { error: updatePricingError }] = useUpdatePricingByIdMutation();
    const { pageData: companiesList, loading: companiesListLoading } =
        useCompaniesHook();
    const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();
    const [createSuccess, setCreateSuccess] = useState<boolean | undefined>();
    const [navigateTo, setNavigateTo] = useState<boolean>();

    let cost: CostStructure = {} as any;

    const onSubmit = (values: any) => {
        cost.blockLifeSpanType = values.blockLifeSpan;
        cost.discount = Number(values.discount);
        cost.tax = Number(values.tax);
        cost.price = Number(values.price);
        companiesList?.forEach((company: Company) => {
            if (values.company === company.company_name) {
                cost.company = company;
            }
        });
        if (!id) {
            addPricing(cost).then((res: any) => {
                if (res) {
                    setNavigateTo(true)
                    setCreateSuccess(true)
                    console.log("create success: ", res.data)
                }
                else {
                    setNavigateTo(false)
                    setCreateSuccess(false)
                    console.log("create errors: ", res.data)
                }
            });
        } else {
            cost.id = pricing?.id ?? "";
            updatePricing(cost).then((res: any) => {
                if (res) {
                    setNavigateTo(true)
                    setUpdateSuccess(true)
                    console.log("update success: ", res.data)
                }
                else {
                    setNavigateTo(false)
                    setUpdateSuccess(false)
                    console.log("update errors: ", res.data)
                }
            });
        }
    };

    return {
        pricing,
        pageData,
        loading,
        fetchData,
        model,
        refetching,
        id,
        register,
        handleSubmit,
        errors,
        onSubmit,
        companiesList,
        companiesListLoading,
        blockLifeSpanType,
        addPricingError,
        updatePricingError,
        createSuccess,
        setCreateSuccess,
        updateSuccess,
        setUpdateSuccess,
        navigateTo,
        setNavigateTo,
    };
};
