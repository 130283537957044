import { useTranslation } from 'react-i18next';
import NoData from "../../Assets/images/empty-state-image.svg";

function NoDataAvailable({ errorString }: { errorString?: string }) {

    const { t } = useTranslation()

    return (
        <div className='h-[65vh] md:h-full flex flex-col flex-1 bg-white justify-start md:justify-center items-center rounded-lg'>
            <img
                src={NoData}
                className={`flex h-1/5 w-1/5`}
                alt="TT"
            />
            <span>
                {errorString ? t(errorString) : t("noDataAvailable")}
            </span>
        </div>
    )
}

export default NoDataAvailable