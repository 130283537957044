import { PagedData } from "../models/pagedData.model";
import { allApis } from "./allApi";
import { Login } from '../models/login.model';

export const apiTokenApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllApiTokens: builder.query<PagedData<Login>, any>({
            query: search => {
                return {
                    url: "api/apitoken/getAll/page",
                    params: search
                };
            },
            providesTags: ['ApiToken']
        }),
        updateValidity: builder.mutation<Login, Partial<Login>>({
            query: login => ({
                url: "api/apitoken/updateValidity/" + login.id,
                method: 'PUT',
                body: login
            }),
            invalidatesTags: ['ApiToken']
        }),
        updateToken: builder.mutation<Login, Partial<Login>>({
            query: login => ({
                url: "api/apitoken/updateToken/" + login.id,
                method: 'PUT',
                body: login
            }),
            invalidatesTags: ['ApiToken']
        }),
        createToken: builder.mutation<Login, Partial<Login>>({
            query: login => ({
                url: "api/apitoken",
                method: 'POST',
                body: login
            }),
            invalidatesTags: ['ApiToken']
        })
    })
});

export const { useGetAllApiTokensQuery, useUpdateValidityMutation, useUpdateTokenMutation, useCreateTokenMutation } = apiTokenApi;