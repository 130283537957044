import { allApis } from './allApi';
import { CostStructure } from '../models/costStructure.model';
import { PagedData } from '../models/pagedData.model';

export const pricingApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getPricing: builder.query<CostStructure[], void>({
            query: () => "api/pricing",
            providesTags: ["Pricing"],
        }),
        getPricingById: builder.query<CostStructure, any>({
            query: (id) => ({
                url: `api/pricing/${id}`,
                method: 'GET',
                params: id
            }),
            providesTags: ["Pricing"],
        }),
        getPricingByPagination: builder.query<PagedData<CostStructure[]>, any>({
            query: search => {
                return {
                    url: "api/pricing/getAll/page",
                    params: search
                }
            },
            providesTags: ["Pricing"],
        }),
        createPricing: builder.mutation<any, any>({
            query: (pricing) => ({
                url: `api/pricing`,
                method: 'POST',
                body: pricing
            }),
            invalidatesTags: ["Pricing"],
        }),
        deletePricingById: builder.mutation<boolean, any>({
            query: (id) => {
                return {
                    url: `api/pricing/${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ['Pricing']
        }),
        restorePricingById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `api/pricing/restore/${id}`,
                }
            },
            providesTags: ['Pricing']
        }),
        updatePricingById: builder.mutation<CostStructure, any>({
            query: (pricing) => {
                console.log('pricing', pricing)
                return {
                    url: `api/pricing/${pricing.id}`,
                    method: "PUT",
                    body: pricing,
                }
            },
            invalidatesTags: ['Pricing']
        }),
    }),
});

export const { useGetPricingQuery, useRestorePricingByIdQuery, useGetPricingByPaginationQuery, useCreatePricingMutation, useGetPricingByIdQuery, useDeletePricingByIdMutation, useUpdatePricingByIdMutation } = pricingApi;