import { CodeTypes } from '../models/codeTypes.model';
import { PagedData } from '../models/pagedData.model';
import { allApis } from './allApi';

export const codeTypeApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllCodeTypes: builder.query<CodeTypes[], any>({
            query: () => "api/codeType",
            providesTags: ['CodeType']
        }),
        getCodeTypesByPagination: builder.query<PagedData<CodeTypes[]>, any>({
            query: (args) => {
                return {
                    url: "api/codeType/getAll/page/",
                    params: args
                }
            },
            providesTags: ['CodeType']
        }),
        getCodeTypesById: builder.query<CodeTypes, any>({
            query: (id: string) => `api/codeType/${id}`,
        }),
        updateCodeTypesById: builder.mutation<CodeTypes[], any>({
            query: (codeTypes) => {
                return {
                    url: `api/codeType/${codeTypes.id}`,
                    method: "PUT",
                    body: codeTypes,
                }
            },
            invalidatesTags: ['CodeType']
        }),
        createCodeTypes: builder.mutation<CodeTypes[], Partial<CodeTypes>>({
            query: (codeType) => {
                return {
                    url: `api/codeType`,
                    method: "POST",
                    body: codeType,
                }
            },
            invalidatesTags: ['CodeType']
        }),
        deleteCodeTypesById: builder.mutation<any, any>({
            query: (id) => {
                return {
                    url: `api/codeType/${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ['CodeType']
        }),
        restoreCodeTypesById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `api/codeType/restore/${id}`,
                }
            },
            providesTags: ['CodeType']
        }),
    }),

});

export const { useGetAllCodeTypesQuery, useRestoreCodeTypesByIdQuery, useGetCodeTypesByIdQuery, useGetCodeTypesByPaginationQuery, useUpdateCodeTypesByIdMutation, useCreateCodeTypesMutation, useDeleteCodeTypesByIdMutation } = codeTypeApi;
