import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Field from "../../../Components/base/field/field";
import Button from "../../../Components/base/button/button";
import Icon from "../../../Components/base/icon/icon";
import { Combobox, Transition } from "@headlessui/react";
import { useCodeConfigHook } from "../../../Hooks/CodeConfigHooks";
import { toastError, toastSuccess } from "../../../Components/toast";
import Spinner from "../../../Components/Spinner";

function AddCode() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    errors,
    codeConfifData,
    onSubmit,
    codeTypeOptions,
    codeTypeQuery,
    setCodeTypeQuery,
    addCode,
    removeCode,
    codeTypes,
    setCodeTypes,
    codeTypeData,
    setNavigateTo,
    navigateTo,
    updateSuccess,
    createCodeConfigError,
    updateCodeConfigError,
    createSuccess,
    setCreateSuccess,
  } = useCodeConfigHook({ id });

  let filteredCodes;
  if (codeTypeOptions) {
    filteredCodes =
      codeTypeQuery === ""
        ? codeTypeOptions
        : codeTypeOptions.filter((codeTypes: any) =>
          codeTypes
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(codeTypeQuery.toLowerCase().replace(/\s+/g, ""))
        );
  }

  useEffect(() => {
    if (createCodeConfigError as any) {
      toastError((createCodeConfigError as any)?.data?.message);
    }
    if (updateCodeConfigError as any) {
      toastError((updateCodeConfigError as any)?.data?.message);
    }
  }, [createCodeConfigError, updateCodeConfigError]);

  useEffect(() => {
    if (navigateTo && createSuccess === true) {
      toastSuccess(t("createdSuccessfully"));
      setNavigateTo(false);
      navigate(-1);
    } else if (!navigateTo && createSuccess === false) {
      toastSuccess(t("failed"));
    }
  }, [navigateTo, createSuccess]);

  useEffect(() => {
    if (navigateTo && updateSuccess === true) {
      toastSuccess(t("updatedSuccessfully"));
      setNavigateTo(false);
      navigate(-1);
    } else if (!navigateTo && updateSuccess === false) {
      toastSuccess(t("failed"));
    }
  }, [navigateTo, updateSuccess]);

  if (id && !codeConfifData) {
    return (
      <div
        className={`md:h-full h-screen flex justify-center items-center bg-white`}
      >
        <Spinner className="h-8 w-8" color="#2984FF" />
      </div>
    );
  }

  return (
    <section className="md:md:h-[calc(100%-4.3rem)]  md:m-2 rounded-lg shadow bg-white w-screen md:w-auto overflow-auto">
      <div className="px-5 pb-5 pt-4  bg-TT-background md:bg-white h-full w-screen md:w-full rounded-lg overflow-auto">
        <form
          id="addDeliveryPath"
          className="w-full lg:w-3/5"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
            <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
              <label
                htmlFor="codeName"
                className="text-xs font-semibold text-TT-gray uppercase"
              >
                {t(`codeName`)}
              </label>
              <Field
                {...register("codeName")}
                error={errors?.codeName && `${errors?.codeName?.message}`}
                type="text"
                defaultValue={codeConfifData?.packName}
                placeholder={t(`enterCodeName`)}
                id="codeName"
                className="px-2 h-12"
              />
            </div>
            <div className="col-span-12 md:col-span-6 flex flex-col gap-2" />
            <div className="col-span-12 flex flex-col gap-2">
              <label
                htmlFor="codes"
                className="text-xs font-semibold text-TT-gray uppercase"
              >
                {t(`codes`)}
              </label>
              <div className="flex gap-x-2">
                {codeTypes.map((item, index) => (
                  <div
                    className="flex gap-x-2 bg-TT-blue rounded-lg text-white px-2 items-center"
                    key={index}
                  >
                    <div>{item}</div>
                    <div
                      className="hover:text-red-500 rounded-full p-0.5"
                      onClick={() => removeCode(item)}
                    >
                      <Icon icon="CLOSE" size="x-small" />
                    </div>
                  </div>
                ))}
              </div>
              {
                <div className="z-30 my-2 md:my-0 md:w-1/2 pr-4">
                  <Combobox value={""} onChange={() => { }}>
                    <div className="relative z-30">
                      <div>
                        <Combobox.Input
                          className="leading-5 relative flex flex-1 w-full rounded-lg p-input bg-white text-gray-700 md:text-base placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-TT-blue focus:border-transparent border border-gray-300 h-12 pl-2.5"
                          onChange={() => { }}
                          onSelect={(event: any) => {
                            setCodeTypeQuery(event.target.value);
                          }}
                          placeholder={t("CodeType")}
                          autoComplete="off"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center"></Combobox.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setCodeTypeQuery("")}
                      >
                        <Combobox.Options className="focus:outline-none absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
                          {filteredCodes && filteredCodes?.length === 0 ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                              Nothing found.
                            </div>
                          ) : (
                            filteredCodes?.map((codeTypeList) => (
                              <Combobox.Option
                                key={codeTypeList}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-5 pr-4 mx-1 rounded-lg ${active
                                    ? "bg-TT-blue text-white"
                                    : "text-gray-900"
                                  }`
                                }
                                value={codeTypeList}
                                onClick={() => addCode(codeTypeList)}
                              >
                                {({ selected, active }) => (
                                  <span
                                    id="txt_selectedlocationType"
                                    className={`block truncate ${selected ? "font-medium" : "font-normal"
                                      }`}
                                  >
                                    {codeTypeList}
                                  </span>
                                )}
                              </Combobox.Option>
                            ))
                          )}
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </Combobox>
                </div>
              }
            </div>
            <div className="md:col-span-6 col-span-12 flex flex-col gap-2">
              <label
                htmlFor="displayOrder"
                className="text-xs font-semibold text-TT-gray uppercase"
              >
                {t(`displayOrder`)}
              </label>
              <Field
                {...register("order")}
                error={errors?.order && `${errors?.order?.message}`}
                type="text"
                defaultValue={codeConfifData?.order}
                placeholder={t(`enterDisplayOrder`)}
                id="displayOrder"
                className="px-2 h-12"
              />
            </div>
            <div className="col-span-6 flex flex-col gap-2"></div>
            <div className="md:col-span-6 col-span-12 flex gap-2 justify-between flex-row items-center">
              <label
                htmlFor="status"
                className="text-xs font-semibold text-TT-gray uppercase"
              >
                {t(`status`)}
              </label>
              <div className="flex gap-x-2 items-center">
                <Field
                  type="checkbox"
                  id="status"
                  {...register("inActiveUserLogin")}
                  error={
                    errors?.inActiveUserLogin &&
                    `${errors?.inActiveUserLogin?.message}`
                  }
                  defaultChecked={codeConfifData?.status}
                />
                <span>{t(`inactiveUserCan’tLogin`)}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-2 justify-center">
            <Button
              color="primary"
              className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
              form="addDeliveryPath"
              submit
            >
              {!id ? t("addCode") : t("update")}
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default AddCode;
