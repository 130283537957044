import { Login } from "../models/login.model";
import { allApis } from "./allApi";

export const loginApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        login: builder.mutation<Login, any>({
            query: (loginParam) => ({
                url: `api/auth/login`,
                method: 'POST',
                body: loginParam
            })
        })
    })
});

export const { useLoginMutation } = loginApi;