import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addProductInfoValidationSchema } from "../../Utils/validation";
import Field from "../../Components/base/field/field";
import { useTranslation } from "react-i18next";
import Button from "../../Components/base/button/button";
import {
    useCreateProductsMutation,
    useGetProductByIdQuery,
    useUpdateProductByIdMutation,
} from "../../Services/productApi";
import { useNavigate, useParams } from "react-router-dom";
import { Products } from "../../models/products.model";
import { toastError, toastSuccess } from "../../Components/toast";
import Spinner from "../../Components/Spinner";

function ProductInfo() {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(addProductInfoValidationSchema) });
    const { data: productData } = useGetProductByIdQuery(id, { skip: !id });
    const [updateProductData, { error: updateProductDataError }] =
        useUpdateProductByIdMutation();
    const [createProductData, { error: createProductDataError }] =
        useCreateProductsMutation();
    const [navigateTo, setNavigateTo] = useState<boolean>();
    const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();
    const [createSuccess, setCreateSuccess] = useState<boolean | undefined>();

    const onSubmit = (value: any) => {
        let updateProduct: Products = productData
            ? Object.assign({}, productData)
            : ({} as any);

      if (updateProduct) {
          updateProduct.gtin = value.GTIN;
          updateProduct.preferred_sno = value.additionalNo;
          updateProduct.product_name = value.productName;
          updateProduct.productDescription = value.productDescription;
          updateProduct.company_info = value.companyInformation;
          if (id) {
              updateProductData(updateProduct).then((res: any) => {
                  if (res.data) {
                      setNavigateTo(true);
                      setUpdateSuccess(true);
                      console.log("update success: ", res.data);
                  } else {
                      setNavigateTo(false);
                      setUpdateSuccess(false);
                      console.log("update errors: ", res.data);
                  }
              });
          } else if (!id) {
              createProductData(updateProduct).then((res: any) => {
                  if (res.data) {
                      setNavigateTo(true);
              setCreateSuccess(true);
              console.log("create success: ", res.data);
          } else {
              setNavigateTo(false);
              setCreateSuccess(false);
              console.log("create errors: ", res.error);
          }
        });
      }
    }
  };

    useEffect(() => {
        if (createProductDataError as any) {
            toastError((createProductDataError as any)?.data?.message);
        }
        if (updateProductDataError as any) {
            toastError((updateProductDataError as any)?.data?.message);
        }
    }, [createProductDataError, updateProductDataError]);

    useEffect(() => {
        if (productData && !id) {
            reset();
        } else {
            reset(productData);
        }
    }, [productData, id]);

    useEffect(() => {
        if (navigateTo && updateSuccess === true) {
            toastSuccess(t("updatedSuccessfully"));
            setNavigateTo(false);
            navigate(-1);
        } else if (!navigateTo && updateSuccess === false) {
            toastError(t("failed"));
        }
    }, [navigateTo, updateSuccess]);
    useEffect(() => {
        if (navigateTo && createSuccess === true) {
            toastSuccess(t("createdSuccessfully"));
            setNavigateTo(false);
            navigate(-1);
        } else if (!navigateTo && createSuccess === false) {
            toastError(t("failed"));
        }
    }, [navigateTo, createSuccess]);

    if (!productData && id) {
        return (
            <div
              className={`md:h-full h-screen flex justify-center items-center bg-white`}
            >
              <Spinner className="h-8 w-8" color="#2984FF" />
            </div>
          );
    }

    return (
        <section className="md:h-[calc(100%-3.5rem)] h-screen rounded-lg shadow bg-white w-screen md:w-auto overflow-auto">
            <div className="w-screen md:w-full lg:w-3/5 p-5 md:pb-24 lg:pb-0 md:mb-20 overflow-auto">
                <form id="addProductInfo" onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
                        <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                            <label htmlFor="GTIN" className="text-xs uppercase font-semibold">
                                {t(`GTIN`)}
                            </label>
                            <Field
                                {...register("GTIN")}
                                error={errors?.GTIN && `${errors?.GTIN?.message}`}
                                type="text"
                                defaultValue={productData?.gtin}
                                placeholder={t(`EnterUniqueGTINWithCompanyPrefix`)}
                                id="GTIN"
                                className="px-2 h-12"
                            />
                        </div>
                        <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                            <label
                                htmlFor="additionalNo"
                                className="text-xs uppercase font-semibold"
                            >
                                {t(`additionalNo`)} ({t(`usedForInternalReferencePurpose`)})
                            </label>
                            <Field
                                {...register("additionalNo")}
                              error={
                                  errors?.additionalNo && `${errors?.additionalNo?.message}`
                              }
                              type="text"
                              defaultValue={productData?.preferred_sno}
                              placeholder={t(`additionalNo`)}
                              id="additionalNo"
                              className="px-2 h-12"
                          />
                      </div>
                      <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                          <label
                              htmlFor="productName"
                              className="text-xs uppercase font-semibold"
                          >
                              {t(`productName`)}
                          </label>
                          <Field
                              {...register("productName")}
                              error={errors?.productName && `${errors?.productName?.message}`}
                              type="text"
                              defaultValue={productData?.product_name}
                              placeholder={t(`productName`)}
                              id="productName"
                              className="px-2 h-12"
                          />
                      </div>
                      <div className="col-span-12 md:col-span-6 flex flex-col gap-2"></div>

                      <div className="col-span-12 flex flex-col gap-2">
                          <label
                              htmlFor="productDescription"
                              className="text-xs uppercase font-semibold"
                          >
                              {t(`productDescription`)}
                          </label>
                          <Field
                              {...register("productDescription")}
                              error={
                                  errors?.productDescription &&
                                  `${errors?.productDescription?.message}`
                              }
                              type="textarea"
                              rows={3}
                              defaultValue={productData?.productDescription}
                              placeholder={t(`enterProductDescription`)}
                              id="productDescription"
                              className="px-2 h-12"
                          />
                      </div>
                      <div className="col-span-12 flex flex-col gap-2">
                          <label
                              htmlFor="companyInformation"
                              className="text-xs uppercase font-semibold"
                          >
                              {t(`companyInformation`)}
                          </label>
                          <Field
                              {...register("companyInformation")}
                              error={
                                  errors?.companyInformation &&
                                  `${errors?.companyInformation?.message}`
                              }
                              type="textarea"
                              rows={3}
                              defaultValue={productData?.company_info}
                              placeholder={t(`enterCompanyInformation`)}
                              id="companyInformation"
                              className="px-2 h-12"
                          />
                      </div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-2 justify-center">
                        <Button
                          color="primary"
                          className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
                          form="addProductInfo"
                          submit
                      >
                            {!id ? t("addProduct") : t("update")}
                      </Button>
                  </div>
              </form>
          </div>
      </section>
  );
}

export default ProductInfo;
