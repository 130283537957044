import React from "react";
import { useTranslation } from "react-i18next";
import Field from "../../Components/base/field/field";
import Icon from "../../Components/base/icon/icon";
import Select from "../../Components/base/Select";
import Pagination from "../../Components/Pagination";
import TableFormat from "../../Components/TableFormat";
import { Pagination as pagination } from "../../models/pagination.model";

function ChainCodes() {
  const { t } = useTranslation();
  const numbers = [5, 10, 50, 100, 200, 500, 1000];

  const pageData = {
    data: [
      {
        id: 1,
        chaincodeName: "ttSnowData",
        channelName: "a202011",
        path: "-",
        transactionCount: "0",
        version: 1,
      },
      {
        id: 2,
        chaincodeName: "ttSnowData",
        channelName: "a202011",
        path: "-",
        transactionCount: "0",
        version: 1,
      },
      {
        id: 3,
        chaincodeName: "ttSnowData",
        channelName: "a202011",
        path: "-",
        transactionCount: "0",
        version: 1,
      },
      {
        id: 4,
        chaincodeName: "ttSnowData",
        channelName: "a202011",
        path: "-",
        transactionCount: "0",
        version: 0,
      },
      {
        id: 5,
        chaincodeName: "ttSnowData",
        channelName: "a202011",
        path: "-",
        transactionCount: "0",
        version: 1,
      },
    ],
    page: {} as pagination
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("chaincodeName"),
        accessor: "chaincodeName",
      },
      {
        Header: t("channelName"),
        accessor: "channelName",
      },
      {
        Header: t("path"),
        accessor: "path",
      },
      {
        Header: t("transactionCount"),
        accessor: "transactionCount",
      },
      {
        Header: t("version"),
        accessor: "version",
      },
    ], [t]);

  return (
    <section className="rounded-lg shadow bg-white h-full md:h-[calc(100%-3.3rem)]">
      <div className="flex justify-between p-3 border-b md:pb-5 flex-wrap">
        <div className="flex justify-between w-full md:justify-start md:w-auto gap-x-2">
          <div className="relative w-full">
            <Field
              type="text"
              placeholder="Search"
              name="search"
              className="w-full h-8 px-2 pr-7 rounded-lg z-0 focus:outline-none border-TT-ash focus:border-TT-blue border text-sm"
            />
            <div className="absolute top-1.5 right-2  cursor-pointer">
              <Icon icon="SEARCH" />
            </div>
          </div>
          <div className="h-8 w-fit px-2 border border-TT-ash rounded-lg flex gap-x-2 items-center">
            <div className="flex flex-none">
              <Icon icon="FILTER" />
            </div>
            <span className="font-semibold">{t("filter")}</span>
          </div>
        </div>
        <div className="flex gap-x-3">
          <div className="flex justify-center md:justify-start mt-2 md:mt-0 flex-wrap gap-y-2 md:gap-y-0 items-center gap-x-3">
            <div className="flex items-center gap-x-3 flex-none">
              <span className="text-xs md:text-sm">{t("show")}</span>
              <Select items={numbers} />
            </div>
            {pageData && <Pagination page={pageData.page} fetchData={()=>{}}/>}
          </div>
        </div>
      </div>
      <section className="overflow-y-auto">
        {pageData && <TableFormat columns={columns} data={pageData?.data} />}
      </section>
    </section>
  );
}

export default ChainCodes;
