import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Field from '../../../Components/base/field/field';
import Button from '../../../Components/base/button/button';
import { useEmailConfigHook } from '../../../Hooks/EmailConfigHooks';
import { toastSuccess } from '../../../Components/toast';
import { useEffect } from 'react';

function AddEmail() {

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { register, handleSubmit, reset, errors, preview, setPreview, template, setTemplate, getEmailDataById, onSubmit, navigateTo, setNavigateTo, updateSuccess } = useEmailConfigHook({ id })

  useEffect(() => {
    if (navigateTo && updateSuccess === true) {
      toastSuccess(t('updatedSuccessfully'));
      setNavigateTo(false);
      navigate(-1)
    }
    else if (!navigateTo && updateSuccess === false) {
      toastSuccess(t('failed'));
    }
  }, [navigateTo, updateSuccess]);

  return (
    <section className="md:md:h-[calc(100%-4.3rem)]  md:m-2 rounded-lg shadow bg-white w-screen md:w-auto overflow-auto">
      <div className="w-screen md:w-full lg:w-3/5 p-5 md:pb-24 lg:pb-0">
        <form id="addDeliveryPath" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
            <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
              <label htmlFor="name" className='text-xs font-semibold text-TT-gray uppercase'>{t(`name`)}</label>
              <Field
                {...register('name')} error={errors?.name && `${errors?.name?.message}`} type="text" defaultValue={getEmailDataById?.templateName}
                placeholder={t(`entername`)} id="name" className='px-2 h-12'
              />
            </div>
            <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
              <label htmlFor="description" className='text-xs font-semibold text-TT-gray uppercase'>{t(`description`)}</label>
              <Field
                {...register('description')} error={errors?.description && `${errors?.description?.message}`} type="text" defaultValue={getEmailDataById?.description}
                placeholder={t(`enterDescription`)} id="description" className='px-2 h-12'
              />
            </div>
            <div className="col-span-12">
              <Field type='textarea' rows={10} name="sample" onInput={(e: any) => { setTemplate(e.target.value) }} defaultValue={getEmailDataById?.template}>
              </Field>
            </div>
          </div>
          <div className='flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-2 justify-center'>
            <Button color='primary' className="py-1 md:w-fit h-12 md:h-fit w-full mx-0" onClick={() => {setPreview(!preview)}}>{t('preview')}</Button>
            <Button color='primary' className="py-1 md:w-fit h-12 md:h-fit w-full mx-0" form='addDeliveryPath' submit>{id ? `${t("update")}` : `${t("addEmail")}`}</Button>
          </div>
        </form>
        
      </div>
      <div className="flex-1 p-3">
        {preview &&
          <>
            <span className='text-xl font-semibold'>{t('preview')}</span>
          <div className='px-5' dangerouslySetInnerHTML={{ __html: template }} />
          </>
        }
      </div>
    </section>
  )
}

export default AddEmail