import { PagedData } from '../models/pagedData.model';
import { Products } from './../models/products.model';
import { allApis } from './allApi';

export const productApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getAllProducts: builder.query<Products[], void>({
            query: () => "api/products",
            providesTags: ['Products'],
        }),
        getProductByGtin: builder.query<Products, any>({
            query: (Gtin: string) => `api/products/gtin/${Gtin}`,
        }),
        getProductById: builder.query<Products, any>({
            query: (id: string) => `api/products/${id}`,
            providesTags: ['Products'],
        }),
        getProductsByPagination: builder.query<PagedData<Products[]>, any>({
            query: (search) => {
                return {
                    url: "api/products/getAll/page",
                    params: search
                };
            },
            providesTags: ['Products'],
        }),
        createProducts: builder.mutation<Products, Partial<Products>>({
            query: (product) => {
                return {
                    url: `api/products`,
                    method: "POST",
                    body: product,
                }
            },
            invalidatesTags: ['Products']
        }),
        updateProductById: builder.mutation<Products, Partial<Products>>({
            query: (product) => {
                return {
                    url: `api/products/${product.id}`,
                    method: "PUT",
                    body: product,
                }
            },
            invalidatesTags: ['Products']
        }),
        deleteProductById: builder.mutation<any, any>({
            query: (id) => {
                return {
                    url: `api/products/${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ['Products']
        }),
        restoreProductById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `api/products/restore/${id}`,
                }
            },
            providesTags: ['Products']
        }),
        uploadProductImagefromUrl: builder.mutation<any, any>({
            query: (args) => {
                const { imageUrl, id } = args;
                return {
                    url: `api/products/uploadURL/${id}`,
                    method: "POST",
                    body: { imageURL: imageUrl }
                }
            },
            invalidatesTags: ['Products']
        }),
        uploadProductImagefromFile: builder.mutation<any, Object>({
            query: ({ id, file, type }: { id: string, file: File, type: string }) => {
                let fileData: FormData = new FormData();
                fileData.append('file', file, file.name);
                return {
                    url: `api/products/file/upload/${id}/${type}`,
                    method: "POST",
                    body: fileData
                }
            },
            invalidatesTags: ['Products']
        }),
        deleteProductImage: builder.mutation<any, any>({
            query: (args) => {
                const { productId, imageId } = args;
                return {
                    url: `api/products/file/delete/${productId}/${imageId}`,
                    method: "GET",
                }
            },
            invalidatesTags: ['Products']
        }),
        updateProductFromUpload: builder.mutation<Products, Partial<Products>>({
            query: (product) => {
                return {
                    url: `api/products/createOrUpdate`,
                    method: "POST",
                    body: product,
                }
            },
            invalidatesTags: ['Products']
        }),
    }),

});

export const { useUpdateProductByIdMutation, useDeleteProductByIdMutation, useGetAllProductsQuery, useGetProductByGtinQuery,
    useGetProductByIdQuery, useGetProductsByPaginationQuery, useDeleteProductImageMutation, useUploadProductImagefromFileMutation,
    useUploadProductImagefromUrlMutation, useCreateProductsMutation, useUpdateProductFromUploadMutation, useRestoreProductByIdQuery } = productApi;
