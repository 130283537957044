import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import { useGetProductByGtinQuery } from "../../Services/productApi";
import { useAppDispatch } from "../../Store/hooks";
import { setNavbarProps } from "../../Services/utilsReducer";

function Scan() {
  const style = {
    navLink: `w-fit p-1 px-2 font-semibold border-transparent hover:border-TT-blue border-b-2 focus:outline-none`,
    active: `w-fit p-1 px-2 font-bold border-b-2 border-TT-blue`,
  };

  const exploreOn = [
    { title: "individual", url: "scan/individual" },
    { title: "group", url: "scan/group" },
  ];

  const { t } = useTranslation();
  const [productCode, setProductCode] = useState<string | undefined>();
  const dispatch = useAppDispatch();

  const { data: product, refetch } = useGetProductByGtinQuery(productCode, {
    skip: !productCode,
  });

  if (product) {
    setProductCode(undefined);
  }

  useEffect(() => {
    dispatch(
      setNavbarProps({
        navBarTitle: "scan",
      })
    );
  }, []);

  useEffect(() => {
    if (productCode) {
      refetch();
    }
  }, [productCode]);

  return (
    <div className="flex flex-col flex-1 h-full">
      <div className="flex justify-start lg:px-3 px-2 py-1 lg:py-2 gap-x-3 items-center no-scrollbar overflow-x-auto mt-[7.6rem] md:mt-0">
        {exploreOn.map((item, index) => (
          <NavLink to={`/${item.url}`} key={index} className={(navData: any) => navData.isActive ? style.active : style.navLink }>
            {t(item.title)}
          </NavLink>
        ))}
      </div>
      <section className="bg-white md:mx-2 md:mt-2 rounded-lg overflow-auto my-2 h-full">
        <div className="flex h-full">
          <Outlet />
        </div>
      </section>
    </div>
  );
}

export default Scan;
