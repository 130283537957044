import { BlockLifespanTypes } from './../models/blockLifespanTypes.model';
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateBlockLifespanTypesMutation, useGetBlockLifeSpanByPaginationQuery, useGetBlockLifespanTypesByIdQuery, useUpdateBlockLifespanTypesByIdMutation } from "../Services/blockLifespanTypesApi";
import { useFetchDataHook } from "./FetchData";
import { addBlockLifespanValidationSchema } from '../Utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';

interface _Props {
    id: string | undefined
}
export const useBlockLifespanTypesHook = (props: _Props) => {
    const { id } = props
    const { pageData, loading, fetchData, model, refetching } = useFetchDataHook(useGetBlockLifeSpanByPaginationQuery);
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(addBlockLifespanValidationSchema) })
    const { data: blockLifeSpanTypesData } = useGetBlockLifespanTypesByIdQuery(id, { skip: !id })
    const [updateBlockLifespanTypesData, { error: updateBlockLifespanTypesError }] = useUpdateBlockLifespanTypesByIdMutation()
    const [createBlockLifespanTypesData, { error: createBlockLifespanTypesError }] = useCreateBlockLifespanTypesMutation()
    const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();
    const [createSuccess, setCreateSuccess] = useState<boolean | undefined>();
    const [navigateTo, setNavigateTo] = useState<boolean>()

    const onSubmit = (value: any) => {
        let updateBlockLifeSpanTypesDetails: BlockLifespanTypes = blockLifeSpanTypesData
            ? Object.assign({}, blockLifeSpanTypesData)
            : ({} as any);

        if (updateBlockLifeSpanTypesDetails) {
            updateBlockLifeSpanTypesDetails.type = value.type
            updateBlockLifeSpanTypesDetails.lifespan = value.lifeSpan
            updateBlockLifeSpanTypesDetails.prefix = value.prefix
            if (id) {
                updateBlockLifespanTypesData(updateBlockLifeSpanTypesDetails).then((res: any) => {
                    if (res) {
                        setNavigateTo(true)
                        setUpdateSuccess(true)
                        console.log("update success: ", res.data)
                    }
                    else {
                        setNavigateTo(false)
                        setUpdateSuccess(false)
                        console.log("update errors: ", res.data)
                    }
                })
            }
            else {
                createBlockLifespanTypesData(updateBlockLifeSpanTypesDetails).then((res: any) => {
                    if (res) {
                        setNavigateTo(true)
                        setCreateSuccess(true)
                        console.log("create success: ", res.data)
                    }
                    else {
                        setNavigateTo(false)
                        setCreateSuccess(false)
                        console.log("create errors: ", res.data)
                    }
                })
            }
        }
    }

    useEffect(() => {
        if (blockLifeSpanTypesData) {
            reset(blockLifeSpanTypesData)
        }
    }, [blockLifeSpanTypesData])

    return { pageData, loading, fetchData, model, refetching, register, handleSubmit, errors, onSubmit, blockLifeSpanTypesData, updateBlockLifespanTypesData, createBlockLifespanTypesData, navigateTo, setNavigateTo, createBlockLifespanTypesError, updateBlockLifespanTypesError, createSuccess, updateSuccess };
}