import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Icon from "../../Components/base/icon/icon";
import Spinner from "../../Components/Spinner";
import { useProfileLocationHook } from "../../Hooks/ProfileLocationHook";

const ProfileLocation = () => {

  const { t } = useTranslation()

  const { locationData, locationDataLoading } = useProfileLocationHook();

  if (!locationData || locationDataLoading) {
    return (
      <div
        className={`md:h-full h-screen flex justify-center items-center bg-white`}
      >
        <Spinner className="h-8 w-8" color="#2984FF" />
      </div>
    );
  }

  return (
    <>
      <section>
        <div className="font-semibold flex gap-x-3 items-center">
          <Icon icon="LOCATION" />
          <span className='text-lg font-semibold'>{t('locationDetails')}</span>
          <NavLink to={`/locations/${locationData?.id}`}>
            <span className='text-TT-blue hover:cursor-pointer'><Icon icon="EDIT" /></span>
          </NavLink>
        </div>
      </section>
      <section className='mt-5'>
        <div className="grid grid-cols-3 lg:w-1/2 mt-5 gap-y-2">
          <div className="col-span-1">{t(`locationKey`)}</div>
          <div className="col-span-2">{locationData?.locationKey}</div>
          <div className="col-span-1">{t(`location`)}</div>
          <div className="col-span-2">{locationData?.location}</div>
          <div className="col-span-1">{t(`locationInfo`)}</div>
          <div className="col-span-2">{locationData?.locationInfo}</div>
          <div className="col-span-1">{t(`locationType`)}</div>
          <div className="col-span-2">{locationData?.locationType?.locationType}</div>
          <div className="col-span-1">{t(`locationToken`)}</div>
          <div className="col-span-2">{locationData?.locationToken}</div>
        </div>
      </section>
    </>
  )
}

export default ProfileLocation;