import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Trace } from "../../models/trace.model";
import { productApi } from "../../Services/productApi";
import { useCreateScanPreferenceDataMutation, useCreateTraceDataMutation, useGetScanPreferenceDataQuery } from "../../Services/TraceApi";
import { useGetCurrentUserQuery } from "../../Services/userApi";
import { yupResolver } from "@hookform/resolvers/yup";
import { groupScanValidationSchema } from "../../Utils/validation";
import ScanForm from "./ScanForm";
import QRScanner from "./QRScanner";
import { useAppDispatch } from "../../Store/hooks";
import { formatDate2, formatTime2 } from "../../Utils/datetime";
import { toastError, toastSuccess } from "../../Components/toast";
import Button from "../../Components/base/button/button";
import { ScanPreference } from "../../models/scanPreference.model";
import ScanPreferenceComponent from "./ScanPreferenceComponent";
import ScanPreferenceHeaderComponent from "./ScanPreferenceHeaderComponent";

function Individual() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: scanPreference } = useGetScanPreferenceDataQuery();
  const [createScanPreference] = useCreateScanPreferenceDataMutation();

  let trace: Trace = {} as any;
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch
  } = useForm({ resolver: yupResolver(groupScanValidationSchema) });

  const [sendTrace] = useCreateTraceDataMutation();

  const [product, setProduct] = useState<any>();
  const [newProductCode, setNewProductCode] = useState<string | undefined>();
  const [error, setError] = useState<boolean>();
  const [metaData, setMetaData] = useState<any>();
  const [scanCode, setScanCode] = useState<any>();

  const handleUpdate = (code: any) => {
    if (code) {
      setScanCode(JSON.parse(code.data).c);
      setMetaData(JSON.parse(code.data).met);
      const gtin = JSON.parse(code.data).pc;
      setNewProductCode(JSON.parse(code.data).c);
      if (gtin) {
        dispatch(productApi.endpoints.getProductByGtin.initiate(gtin)).then(
          (value: any) => {
            setProduct(value.data);
          }
        );
      }
    }
  };

  useEffect(() => {
    if (newProductCode) {
      trace.code = newProductCode;
      trace.metadata = metaData;
      trace.productCode = product?.gtin ?? "";
      trace.locationToken = currentUser?.location?.locationToken ?? "";
      trace.traceStatus = getValues().traceStatus;
      trace.others = getValues().others;
      trace.subProductType = getValues().subProductType;
      trace.qrCodeType = getValues().qrType;
      trace.maskType = getValues().maskType;
      trace.scanType = getValues().scanType;
      trace.blockChain_lifespan = Number(getValues().lifeSpan);
      sendTrace(trace).then((res: any) => {
        if (res) {
          if (res.data) {
            setError(false);
            toastSuccess(t("traceCreatedSuccessfully"));
            setNewProductCode(res.data.productCode);
          }
          if (res.error) {
            setError(true);
            toastError(res.error.data.message);
          }
        }
      });
    }
  }, [product]);

  function getDuplicate(jsonString: string) {
    let duplicateCount: number = 0;
    if (scanPreference?.length && scanPreference?.length > 0) {
      scanPreference?.forEach((pref: ScanPreference) => {
        if (jsonString === pref.scanPreference) {
          duplicateCount++;
        }
      });
      if (duplicateCount >= 1) {
        return false;
      } else {
        return true;
      }
    }
  }

  async function scanPreferenceData() {
    if (currentUser && (getValues().traceStatus || getValues().qrType || getValues().maskType || getValues().scanType || getValues().lifeSpan)) {
      let jsonString = JSON.stringify(getValues());
      if (scanPreference?.length && scanPreference?.length > 0) {
        if (getDuplicate(jsonString)) {
          let preference: ScanPreference = {} as any;
          preference.scanPreference = jsonString;
          preference.user = currentUser;
          await createScanPreference(preference);
        }
      } else if (!scanPreference?.length) {
        let preference: ScanPreference = {} as any;
        preference.scanPreference = jsonString;
        preference.user = currentUser;
        await createScanPreference(preference);
      }
    }
  }

  return (
    <div className="flex flex-1">
      <div className="flex flex-col" style={{ flex: 2 }}>
        <div className="flex flex-1">
          <div className="w-screen md:w-full p-5 md:pb-24 lg:pb-0 order-2 md:order-1">
            <ScanForm
              watch={watch}
              errors={errors}
              register={register}
              handleSubmit={handleSubmit}
              onSubmit={() => { }}
            />
            <div className="flex flex-row-reverse mt-4">
              <Button color='primary' className="py-1 md:w-fit text-white h-8 md:h-fit w-full mx-0" onClick={() => { scanPreferenceData(); }}>{t('savePreference')}</Button>
            </div>
          </div>
        </div>
        <div className="flex flex-1 min-w-full">
          {scanPreference?.length && scanPreference?.length > 0 ?
            <div className="mx-4 min-w-full">
              <ScanPreferenceHeaderComponent />
              <ScanPreferenceComponent setValue={setValue} />
            </div>
            : <></>}
        </div>
      </div>
      <div className="w-full lg:w-2/6 bg-TT-blue/10 rounded-lg md:m-2 p-5 flex flex-1 items-center flex-col gap-5 order-1 md:order-2">
        <QRScanner handleUpdate={handleUpdate} />
        <div className="h-4" />
        {!error && error === false && (
          <>
            <div className="font-semibold">{t(`scanned`)}</div>
            {product && (
              <div className="w-full p-2 px-5 shadow rounded-lg flex gap-x-4">
                <img
                  src={
                    product?.images?.length && product?.images?.length > 0
                      ? product?.images[0].imageURL
                      : ""
                  }
                  alt="img1"
                  className="rounded-full w-16 h-16"
                />
                <div className="flex flex-col font-semibold">
                  <span>{product?.product_name}</span>
                  <div className="flex gap-x-3 text-xs ">
                    <span>{formatDate2(new Date().toISOString())}</span>
                    <span>{formatTime2(new Date().toISOString())}</span>
                  </div>
                  <span className="text-gray-500">{scanCode}</span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Individual;
