import toast from "react-hot-toast";
import Icon from "./base/icon/icon";

export function toastSuccess(message) {
  toast.success(message, {
    id: message ? message.toString() : undefined,
    duration: 4000,
    position: "top-right",
    style: { backgroundColor: "#008000", color: "#FFFFFF" },
  });
}

export function toastError(message) {
  toast.error(message, {
    id: message ? message.toString() : undefined,
    duration: 4000,
    position: "top-right",
    style: { backgroundColor: "#FF0000", color: "#FFFFFF" },
  });
}

export function toastInfo(message, icon) {
  toast(message, {
    id: message ? message.toString() : undefined,
    duration: 4000,
    icon: icon ? <Icon icon={icon} size={"large"} /> : undefined,
    position: "top-right",
    style: { backgroundColor: "#2E96B4", color: "white" },
  });
}
