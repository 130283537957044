import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import TrialVersion from "../../Components/base/TrialVersion";
import { useAppPermission } from "../../Hooks/AppPermissionHook";
import { Role } from "../../models/userRole.model";
import { setNavbarProps } from "../../Services/utilsReducer";
import { useAppDispatch } from "../../Store/hooks";

function Explorer() {
  const isTrial = true;

  const style = {
    navLink: `w-fit p-1 px-2 font-semibold border-transparent hover:border-TT-blue border-b-2 focus:outline-none`,
    active: `w-fit p-1 px-2 font-bold border-b-2 border-TT-blue`,
  };

  const { hasPermission } = useAppPermission();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setNavbarProps({
      navBarTitle: "explorer",
      navBarPrevPage: "",
      navBarUserAvatar: true,
    }));
  }, [])

  const exploreOn = hasPermission([Role.SuperAdmin])
    ? [
      { title: "status", url: "explorer/status" },
      { title: "network", url: "explorer/network" },
      { title: "chainCodes", url: "explorer/chaincode" },
      { title: "channels", url: "explorer/channels" },
      { title: "blocks", url: "explorer/blocks" },
    ]
    : [
      { title: "status", url: "explorer/status" },
      { title: "channels", url: "explorer/channels" },
      { title: "blocks", url: "explorer/blocks" },
    ];

  if (isTrial) {
    return <TrialVersion />
  }
  return (
    <>
      <section className={`h-screen mt-[7.6rem] md:mt-0 md:m-2 md:w-auto w-screen ${location.pathname.includes('status') ? 'overflow-auto' : 'overflow-hidden'} `}>
        <div className="flex justify-start lg:px-3 px-2 py-1 lg:py-2 gap-x-3 items-center no-scrollbar w-full overflow-x-auto">
          {exploreOn.map((item, index) => (
            <NavLink
              to={`/${item.url}`}
              key={index}
              className={(navData: any) =>
                navData.isActive ? style.active : style.navLink
              }
            >
              <div className="flex gap-x-2 items-center">{t(item.title)}</div>
            </NavLink>
          ))}
        </div>
        <Outlet />
      </section>
    </>
  );
}

export default Explorer;
