import Field from '../../Components/base/field/field';
import { useTranslation } from 'react-i18next';
import useProfileHooks from '../../Hooks/ProfileHooks';
import { useGetCurrentUserQuery } from '../../Services/userApi';
import ProfileCompany from './ProfileCompany';
import { useAppPermission } from '../../Hooks/AppPermissionHook';
import { Role } from '../../models/userRole.model';
import { NavLink } from 'react-router-dom';
import Button from '../../Components/base/button/button';
import ProfileLocation from './ProfileLocation';
import { useAppDispatch } from "../../Store/hooks";
import { setNavbarProps } from "../../Services/utilsReducer";
import { useEffect } from 'react';
import Spinner from '../../Components/Spinner';
function Profile() {

  const { t } = useTranslation();
  const {
    register,
    errors,
    handleSubmit,
    updateProfile,
  } = useProfileHooks();

  const { hasPermission } = useAppPermission()
  const dispatch = useAppDispatch();
  const { data } = useGetCurrentUserQuery()

  useEffect(() => {
    dispatch(
      setNavbarProps({
        navBarTitle: "profile",
        navBarUserAvatar: true,
      })
    );
  }, []);

  if(!data) {
    return (
      <div className={`md:h-full h-screen flex justify-center items-center bg-white`}>
        <Spinner className="h-8 w-8" color="#2984FF" />
      </div>
    )
  }

  return (
    <div className='h-screen w-full md:bg-white'>
      <section className="md:px-5 mt-[7.6rem] md:mt-2 h-full overflow-auto">
        <div className="px-2 md:px-0 bg-TT-background md:bg-white h-full w-screen md:w-full">
          <div className="pt-2 flex gap-x-5 justify-between md:justify-start">
            <span className='flex gap-x-2'>{t(`username`)}<span className='hidden md:flex'>:</span></span> <span className="font-semibold">{data?.username}</span>
          </div>
          <div className="w-full lg:w-3/5 mt-5">
            <form id="profileForm" onSubmit={handleSubmit(updateProfile)}>
              <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
                <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                  <label htmlFor="firstName" className='text-xs uppercase font-semibold'>{t(`firstName`)}</label>
                  <Field
                    {...register('firstName')} error={errors?.firstName && `${errors?.firstName?.message}`} type="text"
                    placeholder={t(`firstName`)} id="firstName" className='px-2 h-12'
                  />
                </div>
                <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                  <label htmlFor="lastName" className='text-xs uppercase font-semibold'>{t(`lastName`)}</label>
                  <Field
                    {...register('lastName')} error={errors?.lastName && `${errors?.lastName?.message}`} type="text"
                    placeholder={t(`lastName`)} id="lastName" className='px-2 h-12'
                  />
                </div>
                <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                  <label htmlFor="email" className='text-xs uppercase font-semibold'>{t(`email`)}</label>
                  <Field
                    {...register('email')} error={errors?.email && `${errors?.email?.message}`} type="email"
                    placeholder={t(`email`)} id="email" className='px-2 h-12'
                  />
                </div>
                <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                  <label htmlFor="phone" className='text-xs uppercase font-semibold'>{t(`phone`)}</label>
                  <Field
                    {...register('phone')} error={errors?.phone && `${errors?.phone?.message}`} type="text"
                    placeholder={t(`phone`)} id="phone" className='px-2 h-12'
                  />
                </div>
                <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                  <label htmlFor="other" className='text-xs uppercase font-semibold'>{t(`others`)}</label>
                  <Field
                    {...register('others')} error={errors?.others && `${errors?.others?.message}`} type="text"
                    placeholder={t(`others`)} id="other" className='px-2 h-12'
                  />
                </div>
              </div>
            </form>
          </div>
          <div className='flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 my-5 justify-center'>
            <NavLink to='/changePassword'><Button color='plain' className="py-1 md:w-fit h-fit w-full mx-0">{t('changePassword')}</Button></NavLink>
            <Button color='primary' className="py-1 md:w-fit h-fit w-full mx-0" form='profileForm' submit>{t('update')}</Button>
          </div>
          {hasPermission([Role.CompanyAdmin]) ?
            <>
              <div className="border-b-2 my-3"></div>
              <ProfileCompany />
            </>
            : null}
          {hasPermission([Role.CompanyLocationAdmin]) ?
            <>
              <div className="border-b-2 my-3"></div>
              <ProfileLocation />
            </>
            : null}
        </div>
      </section>
    </div>
  )
}

export default Profile