import { useState, useEffect } from 'react';
import { Products } from './../models/products.model';
import { useForm } from "react-hook-form";
import { useGetProductByIdQuery, useGetProductsByPaginationQuery, useUpdateProductByIdMutation } from "../Services/productApi";
import { useFetchDataHook } from "./FetchData";

type _Props = {
    id: string | undefined
}

export const useProductsHook = (props: _Props) => {
    const { id } = props
    const { pageData, loading, fetchData, model, refetching } = useFetchDataHook(useGetProductsByPaginationQuery);
    const { data: productData } = useGetProductByIdQuery(id);
    const [UpdateMaskingData, { error: UpdateMaskingDataError }] = useUpdateProductByIdMutation()
    const { register, handleSubmit, reset, watch, setValue } = useForm();
    const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();
    const [navigateTo, setNavigateTo] = useState<boolean>();

    useEffect(() => {
      reset(productData);
    }, [productData, reset])
    
    const onSubmit = (value: any) => {
        let updateMaskingDetails: Products = productData
            ? Object.assign({}, productData)
            : ({} as any);

        if (updateMaskingDetails) {
            updateMaskingDetails.view_company = value.view_company;
            updateMaskingDetails.view_metadata = value.view_metadata;
            updateMaskingDetails.view_items = value.view_items;
            updateMaskingDetails.view_location = value.view_location;
            updateMaskingDetails.access_navigate = value.access_navigate;
            if (id) {
                UpdateMaskingData(updateMaskingDetails).then((res: any) => {
                    if (res) {
                        setNavigateTo(true)
                        setUpdateSuccess(true)
                        console.log("update success: ", res.data)
                    }
                    else {
                        setNavigateTo(false)
                        setUpdateSuccess(false)
                        console.log("update errors: ", res.data)
                    }
                })
            }
        }

    }
    return { pageData, loading, fetchData, model, refetching, register, handleSubmit, watch, setValue, reset, productData, onSubmit, navigateTo, setNavigateTo, UpdateMaskingDataError, updateSuccess, setUpdateSuccess };
}