import { getLogin } from './../Services/userReducer';
import { useAppSelector } from './../Store/hooks';
import { useGetLocationByUserIdQuery } from "../Services/locationApi"

export const useProfileLocationHook = () => {
  const currentUser = useAppSelector(getLogin)?.user
  const { data: locationData, isLoading: locationDataLoading } = useGetLocationByUserIdQuery(currentUser?.id, { skip: !currentUser || !currentUser?.id })

  return {
    locationData,
    locationDataLoading,
  }
}