import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom'
import Button from '../Components/base/button/button';
import Icon from '../Components/base/icon/icon';
import { Login } from '../models/login.model';
import TableFormat from './TableFormat';
import { toastError, toastSuccess } from '../Components/toast';
import { useGetAllApiTokensQuery, useUpdateTokenMutation, useUpdateValidityMutation } from '../Services/apiTokenApi';
import { useApiTokenHook } from '../Hooks/ApiToken';
import { useFetchDataHook } from '../Hooks/FetchData';

function Action({values}:{values: Login}) {
  const {t} = useTranslation();
  const [updateToken] = useUpdateTokenMutation();

  const deleteItem = (login: Login) => {
    updateToken(login).then((res: any) => {
      if (res.data) {
        toastSuccess(t('updatedSuccessfully'));
      } else {
        toastError(t('notUpdated'));
      }
    });
  }

  return (<Button className="text-xs py-1" size='sm' onClick={() => deleteItem(values)}><Icon icon="DELETE" /></Button>);
};

function Expire({values}:{values: Login}) {
  const {t} = useTranslation();
  const [updateValidity] = useUpdateValidityMutation();

  const updateItem = (login: Login) => {
    updateValidity(login).then((res: any) => {
      if (res.data) {
        toastSuccess(t('updatedSuccessfully'));
      } else {
        toastError(t('notUpdated'));
      }
    });
  }

  return (
    <>
      {values.expire} <Button className="text-xs py-1" size='sm' onClick={() => updateItem(values)}>{t('extendValidity')}</Button>
    </>
  );
};

function ApiToken() {
  const { toastMessage, setToastMessage, create } = useApiTokenHook();
  const { pageData, loading, fetchData, model } = useFetchDataHook(useGetAllApiTokensQuery);

  useEffect(() => {
    if (toastMessage) {
      toastSuccess(t(toastMessage));
      setToastMessage('');
    }
  } , [toastMessage]);

  const {t} = useTranslation();

  const defaultColumn = React.useMemo(() => ({}), []);

  const columns = React.useMemo(() => [{
    Header: t('token'),
    accessor: 'token',
    width: 200,
  },
  {
    Header: t('createdAt'),
    accessor: 'createdAt',
    width: 200,
  }, {
    Header: t('expire'),
    accessor: 'expire',
    width: 400,
    Cell: ({ cell: { value }}: any) => <Expire values={value} />
  }, {
    Header: t('action'),
    accessor: 'action',
    Cell: ({ cell: { value }}: any) => <Action values={value} />
  }], [t]);

  const data = useMemo(() => {
    const displayData: any[] = [];
    
    pageData?.data?.forEach((loginItem:any) => {
      const tempData = {...loginItem};

      displayData.push({
        token: tempData.token,
        createdAt: tempData.createdAt,
        expire: tempData,
        action: tempData
      });
    });

    return displayData;
  }, [pageData]);

  return (
    <div className='flex flex-col h-screen'>
      <div className='text-xl'>
        {t('APITokens')}
        <Button className="py-1" onClick={() => create()}>{t('createToken')}</Button>
      </div>
      <div 
        className="grid grid-cols-12 flex-row lg:flex-col justify-center w-full mb-4 rounded-lg bg-white h-auto lg:overflow-hidden"
      >
        <div className="lg:col-span-10 col-span-12 border-r">
          {pageData && (
            <TableFormat 
              columns={columns} data={data} defaultColumn={defaultColumn} loading={loading} fetchData={fetchData}
              page={model}
            />
          )}
        </div>
        <div className="lg:col-span-5 col-span-12 p-3"><Outlet /></div>
      </div>
    </div>
  )
}

export default ApiToken