import { useTranslation } from 'react-i18next';
import Field from '../../Components/base/field/field';
import { BlockLifespanTypes } from '../../models/blockLifespanTypes.model';
import { useBlockLifeSpanConfigHook } from '../../Hooks/BlockLifeSpanConfigHooks';
import { useEffect, useState } from 'react';

interface IScanForm {
    errors: any;
    register: any;
    handleSubmit: any;
    onSubmit: any;
    watch: any;
}

function ScanForm({ errors, register, handleSubmit, onSubmit, watch }: IScanForm) {

    const { t } = useTranslation();
    const { pageData: blockLifeSpan } = useBlockLifeSpanConfigHook();
    const [blockLifeSpanTypes, setBlockLifeSpanTypes] = useState<BlockLifespanTypes[]>([]);

    useEffect(() => {
        if (blockLifeSpan && blockLifeSpan.data.length) {
            const temp = Object.assign([], blockLifeSpan.data);
            setBlockLifeSpanTypes(temp.sort((type1: BlockLifespanTypes, type2: BlockLifespanTypes) => type1.lifespan - type2.lifespan));
        }
    }, [blockLifeSpan])

    return (
        <form id="scanForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 md:gap-x-8 gap-y-4">
                <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                    <label htmlFor="traceStatus" className="text-sm font-semibold" > {t(`traceStatus`)} </label>
                    <Field type="select" error={errors?.traceStatus?.message} {...register("traceStatus")}>
                        <option value="">{t(`select`)}</option>
                        <option value="Shipped">{t(`shipped`)}</option>
                        <option value="InTransit">{t(`inTransit`)}</option>
                        <option value="delivered">{t(`delivered`)}</option>
                        <option value="cancelled">{t(`cancelled`)}</option>
                        <option value="others">{t(`others`)}</option>
                    </Field>
                </div>
                <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                    {watch('traceStatus') === 'others' &&
                        <>
                            <label htmlFor="traceStatus" className="text-sm font-semibold" > {t(`others`)} </label>
                            <Field
                                {...register('others')}
                                type="text"
                                placeholder={t(`others`)}
                                className="px-2 h-[2.4rem]"
                            />
                        </>}
                </div>
                <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                    <label htmlFor="qrType" className="text-sm font-semibold"> {t(`qrType`)} </label>
                    <Field type="select" error={errors?.qrType?.message} {...register("qrType")} >
                        <option value="">{t(`select`)}</option>
                        <option value="container">{t(`container`)}</option>
                        <option value="box">{t(`box`)}</option>
                        <option value="pack">{t(`pack`)}</option>
                        <option value="product">{t(`product`)}</option>
                        <option value="ingredient">{t(`ingredient`)}</option>
                    </Field>
                </div>
                {/* <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                    <label htmlFor="maskType" className="text-sm font-semibold"> {t(`maskType`)} </label>
                    <Field type="select" error={errors?.maskType?.message} {...register("maskType")} >
                        <option value="">{t(`select`)}</option>
                        <option value="companies">{t(`companies`)}</option>
                        <option value="locations">{t(`locations`)}</option>
                        <option value="all">{t(`all`)}</option>
                        <option value="none">{t(`none`)}</option>
                    </Field>
                </div> */}
                <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                    <label htmlFor="traceStatus" className="text-sm font-semibold" > SubProduct Type </label>
                    <Field
                        {...register('subProductType')}
                        type="text"
                        placeholder='SubProduct Type'
                        className="px-2 h-[2.4rem]"
                    />
                </div>
                <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                    <label htmlFor="scanType" className="text-sm font-semibold"> {t(`scanType`)} </label>
                    <Field type="select" error={errors?.scanType?.message} {...register("scanType")} >
                        <option value="">{t(`select`)}</option>
                        <option value="IN">{t(`in`)}</option>
                        <option value="OUT">{t(`out`)}</option>
                        <option value="Transfer">{t(`transfer`)}</option>
                    </Field>
                </div>
                <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                    <label htmlFor="lifeSpan" className="text-sm font-semibold"> {t(`lifeSpan`)} </label>
                    <Field type="select" error={errors?.lifeSpan?.message} {...register("lifeSpan")} >
                        <option value="">{t(`select`)}</option>
                        {blockLifeSpanTypes?.sort((type1: BlockLifespanTypes, type2: BlockLifespanTypes) => type1.lifespan - type2.lifespan).map((item: BlockLifespanTypes, index: number) => (
                            <option value={item.lifespan} key={index}>{t(item.type)}</option>
                        ))}
                    </Field>
                </div>
            </div>
        </form>
    )
}

export default ScanForm