import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithInterceptor } from './api';

export const allApis = createApi({
        reducerPath: "allApi",
        baseQuery: baseQueryWithInterceptor,
        tagTypes: [
                'ApiToken', 'Transaction', 'LocationType', 'CompanyType', 'Companies',
                'CodeType', 'BlockLifespanTypes', 'Customer', 'VerificationCompany', 'Products',
                'DeliveryPath', 'CodeConfig', 'BlockConfig', 'EmailConfig', 'APITokenConfig', 'Pricing',
                'User', 'CurrentUser', 'QRCode', 'Location', 'LocationType', 'Company', 'BlockLifeSpan', 'Version', 'ScanPreference'],
        endpoints: (builder) => ({})
});