import { allApis } from './allApi';

export const rolesApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAllRoles: builder.query<any[], void>({
      query: () => "api/UserRole/all",
    }),
  }),
});

export const { useGetAllRolesQuery } = rolesApi;