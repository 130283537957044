import { BlockLifeSpan } from './../models/blockLifeSpan.model';
import { allApis } from "./allApi";

export const blockLifeSpanApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getBlockLifeSpan: builder.query<BlockLifeSpan[], void>({
            query: () => "api/blockLifespan",
            providesTags: ['BlockLifeSpan']
        }),
        defaultIdCodesUpdate: builder.mutation<BlockLifeSpan, Object>({
            query: (blockLifespan) => {
                return {
                    url: "api/blockLifespan/default/",
                    method: "POST",
                    body: blockLifespan
                }
            },
            invalidatesTags: ['BlockLifeSpan']
        }),
        getDefaultIdCodes: builder.query<BlockLifeSpan, void>({
            query: () => "api/blockLifespan/default",
            providesTags: ['BlockLifeSpan']
        }),
    }),

});

export const { useGetBlockLifeSpanQuery, useDefaultIdCodesUpdateMutation, useGetDefaultIdCodesQuery } = blockLifeSpanApi;