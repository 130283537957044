import { QRCode } from "../models/qrcode.model";
import { allApis } from "./allApi";

export const qrCodeApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getItemCodes: builder.mutation<QRCode[], any>({
      query: (args) => {
        const { id, total, met } = args;
        return ({
          url: `api/qrCode/item/${id}/${total}`,
          method: 'POST',
          body: { met }
        })
      },
    }),
    getPackCodes: builder.query<QRCode[], number>({
      query: (total) => {
        return `api/qrCode/pack/${total}`;
      },
      providesTags: ["QRCode"],
    }),
  }),

});

export const { useGetItemCodesMutation, useGetPackCodesQuery } = qrCodeApi;