// eslint-disable-next-line import/no-anonymous-default-export
export default {
  '(clickOrDropImage)': '（画像をクリックまたはドロップ）',
  about: 'ヘルプ',
  action: 'アクション',
  activate: 'アクティブ',
  add: '追加',
  addBlock: 'ブロックを追加',
  addBlockConfig: 'ブロック構成を追加',
  addBlockLifespan: 'ブロックの有効期限を追加',
  addCode: 'コードを追加',
  addCodes: 'コードを追加',
  addCodeTypes: 'コードを追加',
  addCompany: '会社情報を追加',
  addCompanyType: '会社タイプを追加',
  addCustomer: '顧客を追加',
  addDeliveryPath: '配送ルートを追加',
  addEmail: 'メールを追加',
  addedBy: '登録者',
  'addedBy :': '登録者 :',
  addInfo: '情報を追加',
  addPath: 'ルートを追加',
  addedLocation: '場所を追加',
  addedUser: 'ユーザー情報を追加',
  addImageFromLocal: 'ローカルから画像を追加',
  addImageFromURL: 'URLから画像を追加',
  additionalNo: '番号を追加',
  additionalUniqueNumber: 'ユニークな番号を追加',
  addLocation: '場所を追加',
  addLocationTypes: '場所の種類を追加',
  addNew: '新規追加',
  addNewLocation: '新しい場所を追加',
  addPack: 'パックを追加',
  addProduct: '製品情報を追加',
  address: '住所',
  address1: '住所 1',
  address2: '住所 2',
  addressAndDetails: '住所と詳細',
  addressDetails: '住所詳細',
  addUser: 'ユーザー情報を追加',
  advancedTrack: '高度な追跡',
  all: 'すべて',
  allergicTo: 'アレルギー成分',
  allergy: 'アレルギー',
  APP_NAME: 'Trace & Track',
  areYouSure: '実施してもよろしいですか？',
  aroundMultipleLocations: '複数の場所',
  attachments: '添付',
  back: '戻る',
  block: 'ブロック',
  blockConfig: 'ブロック構成',
  blockContentDetails: 'ブロックコンテンツの詳細',
  blockDetails: 'ブロックの詳細',
  blockId: 'ブロックID',
  blockLifespan: 'ブロック寿命',
  blockLifeSpan: 'ブロック寿命',
  blockLifespanList: 'ブロック寿命一覧',
  blockLifeSpanList: 'ブロック寿命一覧',
  blockLifespanType: 'ブロックの寿命タイプ',
  blockNo: 'ブロック番号',
  bottleCode: 'ボトルコード',
  box: 'ボックス',
  cancel: '取消',
  cancelAll: '全てをキャンセル',
  cancelled: '取消',
  channel: 'チャネル',
  city: '市',
  clear: 'クリア',
  code: 'コード',
  codeExample: '例',
  codeExample_help: 'ユーザーの理解のためのコード例',
  codeName: 'コード名',
  codeName_help: 'コード名 ex. GTIN, JAN, MD等',
  codeNames: 'コード',
  codePattern: 'パターン',
  codePattern_help: 'パターン、桁数',
  codes: 'コード',
  codesList: 'コード一覧',
  CodeType: 'コードの種類',
  companies: '会社',
  companiesList: '会社一覧',
  company: '会社',
  company_help: 'ユーザーが所属する会社を選択する',
  'company_name_local(Japanese)': '会社名（表示名）',
  companyDetails: '会社情報',
  companyInfo: '会社概要',
  companyInformation: '企業情報',
  companyLocation: '会社の場所',
  companyLogo: '会社ロゴ ',
  companyName: '会社名 ',
  companyProduct: '会社の製品一覧',
  companyProfile: '会社のプロフィール',
  companyType: 'ボトラーの種類',
  companyTypeList: '会社タイプ一覧',
  companyTypeLlist: '会社タイプ一覧',
  companyTypes: 'ボトラーの種類',
  companyUser: '会社のユーザー',
  config: '設定',
  confirmPassword: 'パスワードを確認する',
  contact: '連絡先',
  contactPerson: '連絡窓口',
  contactPerson1: '連絡窓口 1',
  contactPerson2: '連絡窓口 2',
  contacts: '連絡先',
  container: 'コンテナ',
  contentsOfBox: 'ボックスの内容',
  contentsOfContainer: 'コンテナの内容',
  contentsOfPack: 'パックの内容',
  country: '国',
  create: '作成',
  createBlockAtAllLocations: 'すべての場所にブロックを作成する',
  createdAt: '作成日',
  createdSuccessfully: '正常に作成されました',
  customer: '顧客',
  'Customer Company name or individual customer name.': '顧客会社名または個々の顧客名',
  'Customer identification code for internal reference.': '内部参照用の顧客識別コード',
  'Location Type used to specify interlinks': 'インターリンクを指定するために使用されるロケーションタイプ',
  'Location identification key (GLN, ID etc).': 'ロケーション識別キー（GLN、IDなど）',
  customer_code: '顧客コード',
  customerInfo: '顧客情報',
  customerList: '顧客一覧',
  customerName: '顧客名',
  customers: '顧客',
  printQR: 'QRを印刷',
  totalBlocks: '合計ブロック',
  totalQRCodes: '合計QRコード',
  customersList: '顧客一覧',
  dashboard: 'ダッシュボード',
  data: 'データ',
  date: '日にち',
  dragAndDropImage: '画像をドラッグアンドドロップ',
  data_hash: 'データハッシュ',
  default: 'デフォルト',
  deletedSuccessfully: '正常に削除されました',
  restoredSuccessfully: '正常に復元されました',
  restoreFailed: '復元に失敗しました',
  deleteFailed: '削除に失敗しました',
  deleteCompany: '会社情報を削除',
  deleteCustomer: '顧客を削除',
  deleteDeliveryPath: '配送ルートを削除',
  deletedLocation: '場所を削除',
  deletedUser: '削除了ユーザー',
  deleteLocation: '場所を削除',
  deleteLocationType: 'ロケーションタイプを削除',
  deletePack: 'パックを削除',
  deleteProduct: '製品情報を削除',
  deleteUser: 'ユーザー情報を削除',
  delivery: '配送',
  deliveryPath: '配送ルート',
  deliveryPathList: '配送ルート一覧',
  description: '説明',
  details: '詳細',
  download: 'ダウンロード',
  edit: '編集',
  editBlockLifeSpanType: 'ブロックの寿命タイプを編集',
  editCodeType: 'コードを編集',
  editCompanies: '会社情報を編集',
  editCompany: '会社情報を編集',
  editCompanyType: '会社タイプを編集',
  editCustomer: '顧客を編集',
  editCustomers: '得意先情報を編集 ',
  editDeliveryPath: '配送ルートを編集',
  editedLocation: '場所情報を編集しました',
  editedUser: 'ユーザー情報を編集',
  editEmail: 'メールを編集',
  editIssue: 'インシデントを編集',
  editIssues: 'インシデントを編集 ',
  editLocation: '場所を編集',
  editLocations: '場所を編集',
  editLocationType: '場所タイプを編集',
  editPack: 'パックを編集',
  editProduct: '製品情報を編集',
  editProducts: '製品情報を編集',
  editUser: 'ユーザー情報を編集',
  email: 'Eメール',
  email1: 'Eメール 1',
  email2: 'Eメール 2',
  enterAddress1: '住所 1を入力',
  enterAddress2: '住所 2を入力',
  enterBlockLifespanType: 'ブロックの寿命タイプを入力',
  enterBlock: 'ブロックを入力',
  enterCodeName: 'コード名を入力',
  enterDisplayOrder: '表示順を入力',
  enterEmail1: 'Eメール 1を入力',
  enterEmail2: 'Eメール 2を入力',
  enterType: 'タイプを入力',
  enterLifespan: '寿命を入力',
  enterPrefix: 'プレフィックスを入力',
  'enterPathName(reference name)': 'パス名（参照名）を入力',
  'enterPathCode(Reference ID code)': 'パスコード（参照IDコード）を入力',
  enterNewPassword: '新しいパスワードを入力',
  enterphone: '電話番号を入力',
  enterPreferredDeliveryPath: '配送ルートを入力',
  enterProductDescription: '製品の説明を入力',
  enterCity: '市を入力',
  enterCode: 'コードを入力',
  enterCompanyInformation: '会社情報を入力',
  enterCountry: '国を入力',
  enterCompanyName: '会社名を入力',
  enterImageURL: '画像URLを入力',
  EnterUniqueGTINWithCompanyPrefix: 'ユニークなGTINを入力してください',
  enterPrefecture: '都道府県を入力',
  enterContactPerson1: '連絡先担当者 1を入力',
  enterContactPerson2: '連絡先担当者 2を入力',
  enterDescription: '説明を入力',
  entername: '名前を入力',
  error404: '404 エラー',
  fromDate: '日付から',
  filter: 'フィルター',
  filterByAddedBy: '名前で絞り込む',
  filterByCity: '都市で絞り込む',
  filterByCode: 'コードで絞り込む',
  filterByCompany: '会社で絞込',
  filterByCountry: '国で絞り込む',
  filterByCustomerCode: '得意先コードで絞り込む',
  filterByFirstName: '名でフィルター ',
  filterByGTIN: 'GTINで絞り込む',
  filterByLastName: '姓でフィルター',
  filterByLocationKey: '場所キーで絞り込む',
  filterByLocationName: '場所で絞り込む',
  filterByName: '名前で絞り込む',
  filterByNumber: '番号で絞り込む',
  filterByPrefecture: '都道府県で絞り込む',
  filterByProductName: '品目名称で絞り込む',
  filterByProductNumber: '品目で絞り込む',
  filterByTitle: 'タイトルで絞り込む',
  filterCompanyCode: '会社コードで絞り込む',
  filterCompanyName: '会社名前で絞り込む',
  filterIssueById: 'IDで絞り込む',
  filterIssueByReporter: '登録者で絞り込む',
  filterIssueByRepoter: '登録者で絞り込む',
  filterUserByCompany: '会社名前で絞り込む',
  firstName: '名前',
  generate: '作成',
  goBack: '戻る',
  previous_hash: '前のハッシュ',
  blockDetail: 'ブロックの詳細',
  changePassword: 'パスワードを変更する',
  track: '追跡',
  group: 'グループ',
  gs1_prefix: 'GS1先頭文字',
  enterGs1_prefix: 'GS1先頭文字を入力',
  gtin: 'GTIN',
  home: 'ホーム',
  high: '高',
  imageDrop: 'ドロップイメージ',
  images: '画像',
  imageURLFromLocalStorage: 'ローカルストレージから画像URLを入力',
  imageFromURL: 'URLから画像を追加',
  in: 'の',
  individual: '個人',
  'inactiveUserCan’tLogin': 'アクティブでないユーザーはログインできません',
  info: '情報',
  ingredient: '成分',
  ingredients: '成分',
  ingredientsAdded: '追加された成分',
  inMonths: '数か月後',
  issue: 'インシデント一覧',
  issueCode: 'インシデント番号',
  issues: 'インシデント',
  item: '項目',
  items: '項目',
  itemsNavigate: '項目をナビゲート',
  key: 'コード',
  language: '言語',
  lastName: '苗字',
  latitude: '緯度',
  lifeSpan: '寿命',
  location: '場所',
  location_help: 'ユーザーがロケーションマネージャである場合は、場所を選択してください',
  locationDetails: '場所の詳細',
  locationInfo: '場所情報',
  locationKey: '場所キー',
  locationList: '場所一覧',
  locationMetaCode: '場所コード',
  locationName: '正式名称',
  locationProfile: '場所の情報',
  locations: '場所',
  locationToken: 'APIのロケーショントークン',
  locationType: '場所の種類',
  locationTypeKey: '場所の種類キー',
  locationTypeList: '場所タイプ一覧',
  locationTypes: '場所の種類',
  longitude: '経度',
  low: '低',
  masking: 'マスキング',
  mainMenu: 'メインメニュー',
  maskType: '保護タイプ',
  mayTheForce: '力があります',
  metadata: 'メタデータ',
  metaCode: 'メタコード',
  msg_invalid: '無効',
  msg_required: '必須項目',
  manufacturingDate: '製造日',
  name: '名前',
  new: '新着',
  noCameraFound: 'カメラが見つかりません',
  none: '無',
  noBlockData: 'ブロックデータなし',
  public: '公衆',
  client: 'クライアント',
  noOfCodes: 'コード数',
  notUpdated: '更新できません',
  nutrient: '栄養素',
  nutrients: '栄養素',
  nutritions: '栄養素',
  offlineMessage: '接続できないため、更新できません！',
  options: 'オプション',
  order: '注文',
  others: 'その他',
  others_help: '追加情報',
  out: 'アウト',
  pack: 'パック',
  packName: 'パック名',
  packagedOn: 'パッケージされた日',
  packagedLocation: 'パッケージ化された場所',
  packagedDate: 'パッケージ日付',
  packs: 'パック',
  packType: 'パックタイプ',
  password: 'パスワード',
  password_help: 'ユーザーのパスワードをリセットする',
  path: '道',
  pathCode: 'パスコード',
  pathName: 'パス名',
  payLoadList: 'ブロックチェーンペイロード一覧',
  peers: 'ピア',
  peerType: 'ピアタイプ',
  phone: '電話',
  pincode: 'PINコード',
  prefix: '先頭文字',
  prefecture: '県',
  preferred_sno: 'シリアル 番号',
  preferredDeliveryPath: '優先配送ルート',
  preview: '下見',
  print: '印刷する',
  printPackCode: 'プリントパックコード',
  product: '製品',
  packScannedSuccessfully: 'パックスキャン成功',
  productScannedSuccessfully: '製品スキャン成功',
  productCode: '製品コード',
  productDescription: '製品説明',
  productUpdatedSuccessfully: '製品が正常に更新されました',
  productUpdateFailed: '製品の更新に失敗しました',
  productList: '製品一覧',
  productName: '品目名称',
  products: '製品',
  profile: 'プロフィール',
  progress: '進捗状況',
  publicKey: '公開キー',
  qrCodePackPrint: 'QRコード作成',
  qrType: 'QRタイプ',
  qrScan: 'QRスキャン',
  qrCodesGeneratedSuccessfully: "QRコードが正常に生成されました",
  referenceName: '参照名',
  remove: '移動',
  removeAll: '全て削除',
  requestUrl: 'リクエストURL',
  reporter: '顧客情報',
  reenterNewPassword: '新しいパスワードを再入力してください',
  reset: 'リセット',
  result: '結果',
  revert_btn: '元に戻す',
  role: '役割',
  roles_help: '管理者またはロケーション管理者のいずれかを選択します。',
  show: '表示',
  save: '保存',
  save_btn: '保存',
  saveAndScan: '保存してスキャン',
  savePassword: 'パスワードを保存する',
  saved: '保存成功',
  scan: 'スキャン',
  scanned: 'スキャン済み',
  savePreference: '設定を保存',
  select: '選択する',
  savedScanPreferences: '保存されたスキャン設定',
  scanType: 'スキャンの種類',
  ScanAsAPack: 'パックとしてスキャン',
  searchForTrace: 'トレースを検索',
  scanTrace: 'トレースをスキャン',
  SearchOrScanTrace: 'トレースを検索またはスキャン',
  selectCamera: 'カメラを選択',
  selectColumns: '列を選択',
  selectDeliveryPaths: '配送ルートを選択',
  send: '送信',
  sfftApp: 'Trace & Track',
  snapshot: 'スナップショット',
  status: '状態',
  currentStatus: '現在のステータス',
  latlong: '緯度と経度',
  yetToBeUpdated: 'まだ更新されていません',
  status_help: 'アクティブでないユーザーはログインできません',
  success: '成功',
  template: 'テンプレート',
  templateList: 'テンプレート一覧',
  templateName: 'テンプレート名',
  theSelectedCustomerIs: '選択した顧客は',
  timestamp: 'タイムスタンプ',
  toDate: '終了日',
  title: '製品名',
  trace: 'トレース',
  traceAndTrack: 'Trace & Track',
  traceBack: 'トレースバック',
  traceCreatedSuccessfully: 'トレースが正常に作成されました',
  traceStatus: 'トレースステータス',
  track01: '追跡01',
  trackBox: '追跡ボックス',
  trackCode: '追跡コード',
  trackContainer: '追跡コンテナ',
  trackNow: '今すぐ追跡',
  trackPack: '追跡パック',
  trackProduct: '製品を追跡',
  trackProductsWithQRCodes: 'QRコードで商品を追跡する',
  transaction: '取引',
  transfer: '転送',
  shipped: '発送済み',
  inTransit: '輸送中',
  delivered:'配達済み',
  type: 'タイプ',
  types: 'タイプ',
  'Unique GTIN with company prefix.': '会社先頭文字の一意GTIN',
  unpack: '梱包',
  updateBrowser: '古い情報を表示しています。更新してページを再読み込みしてください。',
  updatedAt: '更新日',
  updatedSuccessfully: '更新に成功',
  updateFailed: 'アップデートに失敗しました',
  upload: 'アップロード',
  uploadAll: '全データのアップロード',
  uploadFile: 'ファイルをアップロード',
  uploaded: 'アップロード済み',
  user: 'ユーザー',
  userCompany: 'ユーザーの会社情報',
  userList: 'ユーザー一覧',
  username: 'ユーザー名',
  username_help: 'ログインするためのユーザー名',
  users: 'ユーザーを登録',
  unsigned: '未署名',
  verificationCompaniesList: '検証会社一覧',
  verificationList: '検証リスト',
  verify: '確認',
  version: 'バージョン',
  viewActiveList: 'アクティブなリストを表示',
  viewInactiveList: '非アクティブリストの表示',
  viewLocation: 'ロケーションを表示',
  wagyuBeef: 'ワギュビー',
  yes: 'はい',
  yourProfile: 'あなたのプロフィール',
  referenceIdentificationCode: '参照識別コード',
  displayOrder: '表示順',
  'Delivery Path - Location Types.': '配信パス - ロケーションタイプ',
  logout: 'ログアウト',
  login: 'ログインする',
  rememberMe: '私を覚えてますか',
  traceCode: '追跡コード',
  trackView: 'トラックビュー',
  ProductAlreadyAdded: '製品はすでに追加されています',
  productDetails: '製品詳細',
  APITokens: 'APIトークン',
  APITokenList: 'APIトークンリスト',
  token: 'トークン',
  createToken: 'トークンを作成する',
  expire: '期限切れ',
  explorer: 'エクスプローラー',
  extendValidity: '有効期間を延長する',
  noDataAvailable: 'データなし',
  noImage: '画像なし',
  codeList: 'コードリスト',
  codeTypes: 'コードタイプ',
  blocksStatus: 'ブロックステータス',
  blockChainStatus: 'ブロックチェーンステータス',
  blocksStatistics: 'ブロック統計',
  selectChannel: 'チャンネルを選択',
  blocks: 'ブロック',
  transactions: 'トランザクション',
  nodes: 'ノード',
  chainCodes: 'チェーンコード',
  peerName: 'ピア名',
  channels: 'チャンネル',
  id: 'Id',
  channelName: 'チャネル名',
  timeStamp: 'タイムスタンプ',
  network: '通信網',
  from: 'から',
  to: 'に',
  search: '探す',
  noOfTx: 'Txの数',
  dataHash: 'データハッシュ',
  blockHash: 'ブロックハッシュ',
  prevoiusHash: '前のハッシュ',
  size: 'サイズ（KB）',
  creator: 'クリエイター',
  txId: 'Tx Id',
  chainCode: 'チェーンコード',
  packedAt: 'で梱包',
  packedOn: 'に詰め込まれています',
  costStructure: 'コスト構造',
  price: '価格',
  tax: '税金',
  discount: 'ディスカウント',
  pricing: '価格設定',
  pricingList: '価格表',
  editPricing: '価格を編集する',
  deletePrice: '価格を削除',
  channelType: 'チャネルタイプ',
  pleaseCheckAt: 'で確認してください :',
  usernameOrEmailIsRequired: 'ユーザー名またはメールアドレスが必要です',
  incorrectUsernameOrPassword: 'ユーザーネームまたはパスワードが違います',
  signIn: 'サインイン',
  usernameOrEmail: 'ユーザー名または電子メール',
  somethingWentWrong: "何かがうまくいかなかった",
  required: '必要',
  enterValidEmail: '有効な電子メールを入力してください',
  emailCanBeMax50CharactersLong: 'Mēru no naga-sa wa saidai 50 mojidesu',
  enterCompanyProfile: '会社概要を入力してください',
  failed: '失敗した',
  enteredPasswordsAreDifferent: '入力したパスワードが異なります',
  urlMustBeLessThan250Characters: 'URLは250文字未満である必要があります',
  companyInformationMustBeLessThan250Characters: '会社情報は 250 文字未満にする必要があります',
  sessionExpired: 'セッションの有効期限が切れ',
  error: 'エラー',
  processed: '処理済み',
  thisBrowserDoesNotSupportHTML5: 'このブラウザはHTML5をサポートしていません。',
  pleaseUploadAValidExcelFile: '有効なExcelファイルをアップロードしてください。',
  loginToYourAccount: 'あなたのアカウントにログイン',
  welcomeBack: 'お帰りなさい',
  pleaseEnterYourDetails: '詳細を入力してください',
  traceNotFound: 'トレースが見つかりません',
  contentDetails: 'コンテンツの詳細',
  months: '月',
  addPricing: '価格を追加',
  searchFromDate: '日付から検索',
  searchToDate: '現在までの検索',
  greaterThanOrEqualTo8Characters: "8文字以上",
  lessThanOrEqualTo25Characters: "25文字以下",
  blankSpacesAreNotAllowed: "空白は使用できません",
  atleastOneNumber: "少なくとも1つの番号",
  atleastOneLowerCaseLetter: "少なくとも1つの小文字",
  atleastOneSpecialSymbol: "少なくとも1つの特別なシンボル",
  atleastOneUpperCaseLetter: "少なくとも1つの大文字",
  of: "の",
  fileSizeIsTooLargeUploadUnder1MB: "ファイル サイズが大きすぎます 1 MB 未満のアップロード",
  unsupportedFileFormat: 'サポートされていないファイル形式',
  onlyJpegJpgAndPngImagesWillBeAccepted: '*.jpeg、*.jpg、*.png の画像のみ受け付けます。',
  enterValidCountryCodeAndPhoneNo: "有効な国コードと電話番号を入力してください",
  usedForInternalReferencePurpose: "内部参照目的で使用",
  enterPincode: "暗証番号を入力",
  noLocationAvailable: "利用可能な場所がありません",
}
