import React from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../../Components/Pagination";
import TableFormat from "../../../Components/TableFormat";
import { Action, ActionType, ComponentType } from "../../Action";
import { useDeliveryPathHook } from "../../../Hooks/DeliveryPathHook";
import { TableNavBar } from "../../../Components/TableNavBar/TableNavBar";
import SearchAndFilter from "../../../Components/TableNavBar/SearchAndFilter";
import { deliveryPathApi, useGetDeliveryPathByPaginationQuery } from "../../../Services/DeliveryPathApi";
import { useAppDispatch } from "../../../Store/hooks";

function DeliveryPath() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    pageData,
    loading,
    deleteDeliveryPath,
    defaultDeliveryPath,
    fetchData,
    isError,
    isEmpty
  } = useDeliveryPathHook({ id: "" });

  const columns = React.useMemo(
    () => [
      {
        Header: t("code"),
        accessor: "pathCode",
      },
      {
        Header: t("name"),
        accessor: "pathName",
      },
      {
        Header: t("path"),
        accessor: "path",
        Cell: ({ row: { original } }: any) => (
          <div>
            {" "}
            {original.path.map((item: any) => (
              <span className=" bg-TT-blue rounded-xl text-white mx-1 px-2 py-1.5 ca">
                {t(item.locationType)}
              </span>
            ))}{" "}
          </div>
        ),
      },
      {
        Header: t("default"),
        accessor: "default",
        Cell: ({ row: { original } }: any) => (
          <div
            className="w-5 h-5 rounded-full relative border-2 border-TT-blue"
            onClick={() => {
              defaultDeliveryPath(original.id);
            }}
          >
            <div
              className={`absolute w-3 h-3 rounded-full  inset-0.5 ${
                original.default && "bg-TT-blue"
              }`}
            ></div>
          </div>
        ),
      },
      {
        Header: t("action"),
        accessor: "",
        Cell: ({ row: { original } }: any) => (
          <Action
            id={original.id}
            types={[ActionType.View, original.status ? ActionType.Delete : ActionType.Restore]}
            deleteFunc={deleteDeliveryPath}
            restoreFunc={()=>dispatch(deliveryPathApi.endpoints.restoreDeliveryPathById.initiate(original.id)).unwrap()}
            invalidateFunc={()=>dispatch(deliveryPathApi.util.invalidateTags(['DeliveryPath']))}
            navigation={`${original.id}`}
            title="view History"
            componentType={ComponentType.Button}
          />
        ),
      },
    ], [t]);

  return (
    <TableNavBar
      tableTitle={"config"}
      navLink={"add"}
      buttonTitle={"addDeliveryPath"}
      pageData={pageData}
      fetchData={fetchData}
      isError={isError}
      isLoading={loading}
      isEmpty={isEmpty}
    >
      <TableNavBar.SearchAndFilter>
        <SearchAndFilter />
      </TableNavBar.SearchAndFilter>
      <TableNavBar.Pagination>
        {pageData && <Pagination page={pageData.page} fetchData={fetchData} />}
      </TableNavBar.Pagination>
      <TableNavBar.TableFormat>
        {pageData && <TableFormat columns={columns} data={pageData?.data} _query={useGetDeliveryPathByPaginationQuery} />}
      </TableNavBar.TableFormat>
    </TableNavBar>
  );
}

export default DeliveryPath;
