import imagePlaceholder from "../../Assets/images/image.svg";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../Components/base/button/button";
import Field from "../../Components/base/field/field";
import Icon from "../../Components/base/icon/icon";
import Modal from "../../Components/Modal";
import { toastError, toastSuccess } from "../../Components/toast";
import { Config } from "../../Config";
import { useEditCompanyHook } from "../../Hooks/EditCompanyHook";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editCompanyImageValidationSchema } from "../../Utils/validation";
import {
  useDeleteCompanyImageMutation,
  useUploadCompanyImagefromFileMutation,
  useUploadCompanyImagefromUrlMutation,
} from "../../Services/companyApi";
import { Company } from "../../models/company.model";
import { PhoneNumberField } from "../../Components/base/PhoneInput/PhoneNumberInput";
import { setNavbarProps } from "../../Services/utilsReducer";
import { useAppDispatch } from "../../Store/hooks";
import Spinner from "../../Components/Spinner";
function EditCompany() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    errors,
    companyDetails,
    companyDetailsLoading,
    companyTypesLoading,
    companyTypes,
    setCompanyType,
    onSubmit,
    reset,
    setValue,
    navigateBack,
    createSuccess,
    updateSuccess,
    companyType,
    refetch,
    control,
  } = useEditCompanyHook({ id: id });

  useEffect(() => {
    if (updateSuccess === true && navigateBack) {
      toastSuccess(t("updatedSuccessfully"));
      navigate(-1);
    } else if (updateSuccess === false && !navigateBack) {
      toastError(t("updateFailed"));
    }
  }, [navigateBack, updateSuccess, t]);

  useEffect(() => {
    if (createSuccess === true && navigateBack) {
      toastSuccess(t("createdSuccessfully"));
      navigate(-1);
    } else if (createSuccess === false && !navigateBack) {
      toastError("failed");
    }
  }, [navigateBack, createSuccess, t]);

  useEffect(() => {
    dispatch(
      setNavbarProps({
        navBarTitle: id ? "editCompany" : "addCompany",
        navBarPrevPage: "/profile",
        navBarUserAvatar: true,
      })
    );
  }, []);

  if (companyDetailsLoading || (id && !companyDetails) || companyTypesLoading) {
    return (
      <div
        className={`md:h-full h-screen flex justify-center items-center bg-white`}
      >
        <Spinner className="h-8 w-8" color="#2984FF" />
      </div>
    );
  }

  return (
    <>
      <section className="md:px-2 mt-[7.6rem] md:mt-2 h-full md:pb-20">
        <div className="px-5 pb-5 pt-4  bg-TT-background md:bg-white h-full w-screen md:w-full rounded-lg overflow-auto">
          <div className="w-full">
            {id && companyDetails && (
              <CompanyAvatar company={companyDetails} refetch={refetch} />
            )}
            <form onSubmit={handleSubmit(onSubmit)} id="editCompanyForm">
              <div className="font-bold uppercase">{t("companyInfo")}</div>
              <div className="grid grid-cols-4 md:mx-4 md:gap-5 mt-3">
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="other"
                    className="text-xs uppercase font-semibold text-TT-gray"
                  >
                    {t(`company_name_local(Japanese)`)}
                  </label>
                  <Field
                    {...register("companyNameJapanese")}
                    error={
                      errors?.companyNameJapanese &&
                      `${errors?.companyNameJapanese?.message}`
                    }
                    type="text"
                    defaultValue={companyDetails?.company_name_local}
                    placeholder={t(`enterCompanyName(Japanese)`)}
                    id="companyNameJapanese"
                    className="px-2 h-12 mt-1"
                  />
                </div>
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="companyName"
                    className="text-xs uppercase font-semibold text-TT-gray"
                  >
                    {t(`companyName`)}
                  </label>
                  <Field
                    {...register("companyName")}
                    error={
                      errors?.companyName && `${errors?.companyName?.message}`
                    }
                    type="text"
                    defaultValue={companyDetails?.company_name}
                    placeholder={t(`enterCompanyName`)}
                    id="companyName"
                    className="px-2 h-12 mt-1"
                  />
                </div>
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="gs1_prefix"
                    className="text-xs uppercase font-semibold text-TT-gray"
                  >
                    {t(`gs1_prefix`)}
                  </label>
                  <Field
                    {...register("gs1_prefix")}
                    error={
                      errors?.gs1_prefix && `${errors?.gs1_prefix?.message}`
                    }
                    type="text"
                    defaultValue={companyDetails?.gs1_prefix}
                    placeholder={t(`enterGs1_prefix`)}
                    id="gs1_prefix"
                    className="px-2 h-12 mt-1"
                  />
                </div>
              </div>
              <hr className="mt-5 mb-2" />
              <div className="font-bold uppercase">{t("address")}</div>
              <div className="grid grid-cols-4 md:mx-4 md:gap-5 mt-3">
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="address1"
                    className="text-xs uppercase font-semibold text-TT-gray"
                  >
                    {t(`address1`)}
                  </label>
                  <Field
                    {...register("address1")}
                    error={errors?.address1 && `${errors?.address1?.message}`}
                    type="text"
                    defaultValue={companyDetails?.address1}
                    placeholder={t(`enterAddress1`)}
                    id="address1"
                    className="px-2 h-12 mt-1"
                  />
                </div>
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="address2"
                    className="text-xs uppercase font-semibold text-TT-gray"
                  >
                    {t(`address2`)}
                  </label>
                  <Field
                    {...register("address2")}
                    error={errors?.address2 && `${errors?.address2?.message}`}
                    type="text"
                    defaultValue={companyDetails?.address2}
                    placeholder={t(`enterAddress2`)}
                    id="address2"
                    className="px-2 h-12 mt-1"
                  />
                </div>
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="pincode"
                    className="text-xs uppercase font-semibold text-TT-gray"
                  >
                    {t(`pincode`)}
                  </label>
                  <Field
                    {...register("pincode")}
                    error={errors?.pincode && `${errors?.pincode?.message}`}
                    type="text"
                    defaultValue={companyDetails?.pincode}
                    placeholder={t(`enterPincode`)}
                    id="pincode"
                    className="px-2 h-12 mt-1"
                  />
                </div>
                <div className="lg:col-span-1 hidden lg:block"></div>
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="city"
                    className="text-xs uppercase font-semibold text-TT-gray"
                  >
                    {t(`city`)}
                  </label>
                  <Field
                    {...register("city")}
                    error={errors?.city && `${errors?.city?.message}`}
                    type="text"
                    defaultValue={companyDetails?.city}
                    placeholder={t(`enterCity`)}
                    id="city"
                    className="px-2 h-12 mt-1"
                  />
                </div>
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="prefecture"
                    className="text-xs uppercase font-semibold text-TT-gray"
                  >
                    {t(`prefecture`)}
                  </label>
                  <Field
                    {...register("prefecture")}
                    error={
                      errors?.prefecture && `${errors?.prefecture?.message}`
                    }
                    type="text"
                    defaultValue={companyDetails?.prefecture}
                    placeholder={t(`enterPrefecture`)}
                    id="prefecture"
                    className="px-2 h-12 mt-1"
                  />
                </div>
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="country"
                    className="text-xs uppercase font-semibold text-TT-gray"
                  >
                    {t(`country`)}
                  </label>
                  <Field
                    {...register("country")}
                    error={errors?.country && `${errors?.country?.message}`}
                    type="text"
                    defaultValue={companyDetails?.country}
                    placeholder={t(`enterCountry`)}
                    id="country"
                    className="px-2 h-12 mt-1"
                  />
                </div>
              </div>
              <hr className="mt-5 mb-2" />
              <div className="font-bold uppercase">{t("contacts")}</div>
              <div className="grid grid-cols-4 md:mx-4 md:gap-5 mt-3">
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="contactPerson1"
                    className="text-xs uppercase font-semibold text-TT-gray"
                  >
                    {t(`contactPerson1`)}
                  </label>
                  <Field
                    {...register("contactPerson1")}
                    error={
                      errors?.contactPerson1 &&
                      `${errors?.contactPerson1?.message}`
                    }
                    type="text"
                    defaultValue={companyDetails?.contactPerson1}
                    placeholder={t(`enterContactPerson1`)}
                    id="contactPerson1"
                    className="px-2 h-12 mt-1"
                  />
                </div>
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="email1"
                    className="text-xs uppercase font-semibold text-TT-gray"
                  >
                    {t(`email1`)}
                  </label>
                  <Field
                    {...register("email1")}
                    error={errors?.email1 && `${errors?.email1?.message}`}
                    type="text"
                    defaultValue={companyDetails?.email1}
                    placeholder={t(`enterEmail1`)}
                    id="email1"
                    className="px-2 h-12 mt-1"
                  />
                </div>
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="phone"
                    className="text-xs uppercase font-semibold text-TT-gray"
                  >
                    {t(`phone`)}
                  </label>
                  <div className="h-1" />
                  <Controller
                    control={control}
                    name="phone"
                    render={({ field: { onChange } }) => (
                      <PhoneNumberField
                        value={companyDetails?.phone}
                        placeholder={t("enterphone")}
                        onChange={onChange}
                        error={errors?.phone?.message}
                      />
                    )}
                  />
                  {(errors?.phone as any)?.message && (
                    <span className="text-sm text-red-500 mt-2">
                      {t((errors?.phone as any)?.message)}
                    </span>
                  )}
                </div>
                <div className="lg:col-span-1 hidden lg:block"></div>
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="contactPerson2"
                    className="text-xs uppercase font-semibold text-TT-gray"
                  >
                    {t(`contactPerson2`)}
                  </label>
                  <Field
                    {...register("contactPerson2")}
                    error={
                      errors?.contactPerson2 &&
                      `${errors?.contactPerson2?.message}`
                    }
                    type="text"
                    defaultValue={companyDetails?.contactPerson2}
                    placeholder={t(`enterContactPerson2`)}
                    id="contactPerson2"
                    className="px-2 h-12 mt-1"
                  />
                </div>
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <label
                    htmlFor="email2"
                    className="text-xs uppercase font-semibold text-TT-gray"
                  >
                    {t(`email2`)}
                  </label>
                  <Field
                    {...register("email2")}
                    error={errors?.email2 && `${errors?.email2?.message}`}
                    type="text"
                    defaultValue={companyDetails?.email2}
                    placeholder={t(`enterEmail2`)}
                    id="email2"
                    className="px-2 h-12 mt-1"
                  />
                </div>
              </div>
              <hr className="mt-5 mb-2" />
              <div className="font-bold uppercase">{t("companyProfile")}</div>
              <div className="grid lg:grid-cols-2 md:mx-4 md:gap-5 mt-3">
                <Field
                  {...register("profile")}
                  error={
                    errors?.locationdetails &&
                    `${errors?.locationdetails?.message}`
                  }
                  type="textarea"
                  rows={3}
                  defaultValue={companyDetails?.profile}
                  placeholder={t(`enterCompanyProfile`)}
                  id="profile"
                  className="px-2 h-12 md:ml-1"
                />
              </div>
              <hr className="mt-5 mb-2" />
              <div className="font-bold uppercase">{t("companyType")}</div>
              <div className="grid grid-cols-4 md:mx-4 md:gap-5 mt-3">
                <div className="col-span-4 md:col-span-2 lg:col-span-1">
                  <div className="mb-1">
                    <label
                      htmlFor="companyType"
                      className="text-xs uppercase font-semibold text-TT-gray"
                    >
                      {t(`companyType`)}
                    </label>
                  </div>
                  <Field
                    {...register("companyType")}
                    error={
                      errors?.companyType && `${errors?.companyType?.message}`
                    }
                    type="select"
                    placeholder={t(`chooseCompanyType`)}
                    id="companyType"
                    className="px-2 h-12 mt-1"
                    value={companyType}
                    defaultValue={companyType}
                    onChange={(event: any) => {
                      setValue("companyType", event.target.value);
                      setCompanyType(event.target.value);
                    }}
                  >
                    {companyTypes?.map((type, index) => {
                      return (
                        <option key={index.toString()} value={type?.companyType}>
                          {type?.companyType}
                        </option>
                      );
                    })}
                  </Field>
                </div>
              </div>
              <div className="flex flex-row gap-x-10 gap-y-2 md:mt-10 mt-5 justify-center">
                <Button
                  color="primary"
                  className="py-1 md:w-fit h-fit w-full mx-0"
                  form="editCompanyForm"
                  submit
                >
                  {id ? `${t("update")}` : `${t("add")}`}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default EditCompany;

const CompanyAvatar = ({
  company,
  refetch,
}: {
  company: Company;
  refetch: Function;
}) => {
  const { t } = useTranslation();
  const companyFileUrl = "/api/company/file/";

  const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(editCompanyImageValidationSchema) });

  const [uploadByImageURL] = useUploadCompanyImagefromUrlMutation();
  const [deleteCompanyImage] = useDeleteCompanyImageMutation();
  const [uploadByFile] = useUploadCompanyImagefromFileMutation();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/png": [".png", ".jpeg", ".jpg"],
    },
  });

  const [file, setFile] = useState<any>();

  useEffect(() => {
    if (acceptedFiles?.length) setFile(acceptedFiles[0]);
  }, [acceptedFiles]);

  const remove = () => {
    setFile(undefined);
  };

  const AcceptedImage = () => (
    <div
      key={file?.path}
      className="p-2 shadow-md rounded-md grid grid-cols-12"
    >
      <div className="col-span-2 flex justify-center items-center truncate">
        <img src={imagePlaceholder} alt="img" />
      </div>
      <div className="col-span-9 truncate"> {file?.name}</div>
      <div className="col-span-1 flex justify-center items-center rotate-45 hover:text-red-500 cursor-pointer">
        <Icon
          icon="ADD_BLACK"
          onClick={() => {
            remove();
          }}
        />
      </div>
    </div>
  );

  function onSubmit(values: any) {
    if (values.imageUrl) {
      uploadByImageURL({ imageUrl: values.imageUrl, id: company.id }).then(
        (result: any) => {
          if (result.data) {
            reset();
            setImageModalOpen(false);
            refetch();
          } else {
            console.log("Image upload by URL failed", result.error);
          }
        }
      );
    }
  }

  async function uploadImageFile() {
    if (file) {
      try {
        const result = await uploadByFile({ id: company.id, file: file });

        if (file.size > 1000000) {
          toastError(t("fileSizeIsTooLargeUploadUnder1MB"));
        } else if (result) {
          reset();
          setImageModalOpen(false);
          refetch();
          setFile(undefined);
          toastSuccess(t("updatedSuccessfully"));
        }
      } catch (error) {
        toastError("failed");
        console.log("upload failed", error);
      }
    }
  }

  function deleteImage() {
    deleteCompanyImage({
      companyId: company?.id,
      imageId: company?.images?.id,
    }).then(() => {
      refetch();
    });
  }

  return (
    <>
      <div className="font-bold">{t("companyLogo")}</div>
      <div className="w-32 h-32 rounded-full shadow border m-5 relative flex justify-center items-center">
        <div className="absolute bottom-1 right-0 p-1.5 rounded-full shadow-md text-TT-blue z-10 cursor-pointer bg-white">
          {company.images ? (
            <>
              <div
                className="w-6 h-6 absolute bottom-1 right-1 opacity-0"
                onClick={() => {
                  deleteImage();
                }}
              />
              <Icon icon="DELETE" />
            </>
          ) : (
            <>
              <div
                className="w-6 h-6 absolute bottom-1 right-1 opacity-0"
                onClick={() => {
                  setImageModalOpen(true);
                }}
              />
              <Icon icon="EDIT" />
            </>
          )}
        </div>
        {company?.images ? (
          company.images?.imageURL ? (
            <img
              src={`${company.images?.imageURL}`}
              className="h-full w-full rounded-full"
              alt=""
            />
          ) : company.images.image_name ? (
            <img
              src={`${Config.API_URL}${companyFileUrl}${company.images?.image_name}`}
              className="h-full w-full rounded-full"
              alt=""
            />
          ) : (<></>)
        ) : (
          <div className="font-semibold">{t("noImage")}</div>
        )}
      </div>
      <Modal
        isOpen={imageModalOpen}
        setIsOpen={setImageModalOpen}
        title={t("companyLogo")}
        className={"lg:w-1/2 md:w-3/4 sm:w-3/4 "}
      >
        <div className="p-5 flex lg:flex-row flex-col gap-5">
          <div className="w-full lg:h-80 flex flex-col items-center gap-5">
            <div className="border-dashed border-2 rounded-lg h-full w-full flex flex-col items-center justify-center py-10">
              <div
                {...getRootProps({ className: "dropzone" })}
                tabIndex={-1}
                className="flex flex-col items-center gap-2"
              >
                <input
                  {...getInputProps()}
                  multiple={false}
                  accept="image/png, image/gif, image/jpeg"
                />
                <span className="text-TT-blue flex justify-center items-center h-full my-2">
                  <Icon icon="UPLOAD" size="large" />
                </span>
                <span className="text-lg">{t("dragAndDropImage")}</span>
                <span className="text-xl">{t("or")}</span>
                <Button
                  color="primary"
                  className="py-1 md:w-fit h-12 md:h-fit w-full mx-0 uppercase"
                >
                  {t("browse")}
                </Button>
                <div className="h-2" />
                <span className="text-xs text-gray-500 text-center">
                  {t("onlyJpegJpgAndPngImagesWillBeAccepted")}
                </span>
              </div>
            </div>
          </div>
          <div className="ml-5 mr-2 bg-gray-100" style={{ width: 2 }} />
          <div className="w-full lg:h-8 flex flex-col gap-2">
            <form onSubmit={handleSubmit(onSubmit)} id="imageUrl">
              <label
                htmlFor="imageUrl"
                className="text-xs uppercase font-semibold"
              >
                {t(`imageUrl`)}
              </label>
              <Field
                {...register("imageUrl")}
                error={errors?.imageUrl && `${errors?.imageUrl?.message}`}
                type="text"
                placeholder={t(`enterImageURL`)}
                id="imageUrl"
                className="px-2 h-12 md:ml-1"
              />
            </form>
            <aside className="w-full">
              {file ? (
                <div className="flex flex-col gap-2 justify-center items-center">
                  <AcceptedImage />
                  <Button
                    onClick={() => {
                      uploadImageFile();
                    }}
                    color="primary"
                    className="py-1 md:w-fit h-12 md:h-fit w-full mx-0 uppercase"
                  >
                    {t("upload")}
                  </Button>
                </div>
              ) : null}
            </aside>
          </div>
        </div>
      </Modal>
    </>
  );
};
