import { PagedData } from "../models/pagedData.model";
import { User } from "./../models/user.model";
import { allApis } from "./allApi";

export const userApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getUsers: builder.query<User[], void>({
            query: () => "api/user",
            providesTags: ["User"],
        }),

        createUser: builder.mutation<User, User>({
            query: (user) => {
                return ({
                    url: `api/user`,
                    method: 'POST',
                    body: user
                })
            },
            invalidatesTags: ["User"],
        }),

        getCurrentUser: builder.query<User, void>({
            query: () => "api/user/currentuser",
            providesTags: ["CurrentUser"],
        }),

        updateCurrentUser: builder.mutation<User, Partial<User>>({
            query: (user) => {
                return {
                    url: `api/user/${user.id}`,
                    method: "PUT",
                    body: user,
                };
            },
            invalidatesTags: ["CurrentUser"],
        }),

        updateUser: builder.mutation<User, Partial<User>>({
            query: (user) => {
                return {
                    url: `api/user/${user.id}`,
                    method: "PUT",
                    body: user,
                };
            },
            invalidatesTags: ["User"],
        }),

        getUsersByPagination: builder.query<PagedData<User[]>, any>({
            query: search => {
                return {
                    url: "api/user/getAll/page",
                    params: search
                };
            },
            providesTags: ["User"]
        }),

        getUserById: builder.query<User, string>({
            query: (id: string) => `api/user/${id}`,
            providesTags: ["User"]
        }),

        deleteUserById: builder.mutation<boolean, string>({
            query: (id) => {
                return {
                    url: `api/user/${id}`,
                    method: "DELETE"
                }
            },
            invalidatesTags: ["User"],
        }),

        restoreUserById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `api/user/restore/${id}`,
                }
            },
            providesTags: ['User']
        }),
    }),
});

export const { useGetUsersQuery, useRestoreUserByIdQuery, useUpdateUserMutation, useGetCurrentUserQuery, useGetUsersByPaginationQuery, useUpdateCurrentUserMutation, useGetUserByIdQuery, useCreateUserMutation, useDeleteUserByIdMutation } = userApi;
