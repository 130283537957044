import { useTranslation } from 'react-i18next';
import Icon from './icon/icon'
import * as XLSX from "xlsx";
import { useFetchDataHook } from '../../Hooks/FetchData';
import { saveAs } from 'file-saver';
import { toastError } from '../toast';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { getClearFilters, getShowInActiveList, setClearFilters, setShowInActiveList } from '../../Services/utilsReducer';
import { useLocation } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from './MultipleDropDown/DropDown'
import { useEffect, useState } from 'react';


export default function CustomPopover({ _query, fetchInactive, headerColumn, }: { _query: any, fetchInactive?: (active: boolean) => void, headerColumn?: any[] }) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const clearFilters = useAppSelector(getClearFilters);
    const showInActiveList = useAppSelector(getShowInActiveList);
    const location = useLocation();
    const style = {
        menu: `absolute border border-gray-300 left-20 bg-white rounded-lg lg:w-40 xs:w-52 mb-0 mx-0 bg-clip-padding w-auto`,
        item: `block py-1 px-8 mb-2  w-min-full text-sm font-normal clear-both whitespace-nowrap border-0 hover:bg-TT-blue hover:text-white hover:rounded-md cursor-pointer text-black flex gap-x-4 `,
    }
    const { pageData } = useFetchDataHook(_query);

    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const downloadXLSFile = () => {
        if (pageData?.data?.length > 0) {
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(pageData?.data);
            const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, location.pathname.replace('/', ''));
            console.log(excelBuffer);
        } else {
            toastError(t('noDataAvailable'))
        }
    }

    const saveAsExcelFile = (buffer: any, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        saveAs(data, fileName + '_' + new Date().getTime() + '.xlsx');
    }
    const handleListChange = (e: any) => {
        dispatch(setClearFilters(!clearFilters));
        dispatch(setShowInActiveList(!showInActiveList));
        fetchInactive && fetchInactive(!e.target.checked);
    }
    useEffect(() => {
        if (location) {
            dispatch(setShowInActiveList(false));
        }
    }, [location])


    return (
        <div className="w-full max-w-sm ">
            <div className="relative focus:outline-none">
                <Dropdown>
                    <DropdownToggle>
                        <Icon icon="MORE" />
                    </DropdownToggle>
                    <DropdownMenu>
                        <div className="absolute right-1 mt-1 select-none">
                            <div className="shadow-lg ring-1 ring-black ring-opacity-10 rounded-md">
                                <div className="relative w-fit rounded-lg p-1 bg-white">
                                    <DropdownItem><div className='flex gap-x-4' onClick={() => downloadXLSFile()}><Icon icon="DOWNLOAD" />{t('download')}</div></DropdownItem>
                                    <DropdownItem>
                                        <div className='flex flex-row items-center gap-x-4'>
                                            <input type="checkbox" className='w-5 self-center' defaultChecked={showInActiveList} onChange={handleListChange} />
                                            {t('viewInactiveList')}
                                        </div>
                                    </DropdownItem>
                                    <Dropdown>
                                        <DropdownToggle>
                                            <div className={style.item}>
                                                <Icon icon="COLUMNS" className='flex-none' />
                                                {t('selectColumns')}
                                                <Icon icon='RIGHT' size='x-small' className='flex self-center flex-none' />
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {headerColumn?.map((column, index) => (
                                                <div key={column.id} className="mx-2">
                                                    {column.Header != "Action" &&
                                                        <DropdownItem key={index}> <input type="checkbox"  {...column.getToggleHiddenProps()} className='w-5 self-center' /> {t(column.Header)}</DropdownItem>
                                                    }
                                                </div>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    )
}
