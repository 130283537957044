import { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import CustomPopover from './base/CustomPopover';

interface TableFormatProps {
    columns: any,
    data: any,
    onRowClicked?: Function,
    _query?: any,
    fetchInactive?: (active: boolean) => void,
}

function TableFormat({ columns, data, onRowClicked, _query, fetchInactive }: TableFormatProps) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        allColumns,
    } = useTable({
        columns,
        data,
    })
    const [isQuery, setIsQuery] = useState<boolean | undefined>(false)

    useEffect(() => {
        if (_query) {
            setIsQuery(true)
        }
    }, [_query])


    return (
        <div className="h-fit min-h-[15rem] overflow-auto no-scrollbar">
            <table {...getTableProps()} className="w-full" key={data?.id}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr  {...headerGroup.getHeaderGroupProps()} className="h-12 border-b font-bold text-sm px-3 ">
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="text-left first:pl-4 last:pr-4 px-3 lg:px-0 whitespace-nowrap" >{column.render('Header')}</th>
                            ))}
                            {isQuery &&
                                <th className='py-4'>
                                    <CustomPopover _query={_query} fetchInactive={fetchInactive} headerColumn={allColumns} />
                                </th>
                            }
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className={`h-[2.6rem] items-center font-semibold text-sm px-3 gap-2 hover:shadow-md ${onRowClicked && "hover:cursor-pointer"}`} onClick={() => {
                                onRowClicked && onRowClicked(data?.[index])
                            }}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()} className="truncate capitalize first:pl-4 last:pr-4  px-3 lg:px-0 justify-start">{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default TableFormat;
