import { useTitle } from './UseTitle';
import { useNavigate } from 'react-router-dom';
import Icon from '../Components/base/icon/icon';
import Button from '../Components/base/button/button';
import { useEffect, useState } from 'react';
import Field from '../Components/base/field/field';
import { useSignInHook } from '../Hooks/SignIn';
import { useTranslation } from 'react-i18next';
import logo from '../Assets/images/tt-logo.svg'
import SignInSearch from './SignInSearch';
import LanguageDropdown from '../Components/base/LanguageDropdown';

function SignIn() {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    const {
        navigateScreen, handleSubmit, onSubmit, serverError, register, errors, isLoggingIn
    } = useSignInHook();

    useTitle(t('signIn'));
    const navigate = useNavigate()

    useEffect(() => {
        if (navigateScreen) {
            navigate(navigateScreen);
        }
    }, [navigateScreen]);

    return (
        <div className="bg-[url('Assets/images/login-2in1-bg.svg')] lg:bg-cover md:bg-contain sm:bg-contain bg-center bg-no-repeat">
            <div className="flex flex-row  absolute right-1 md:right-10 top-5">
                <SignInSearch />
            </div>
            <section className="flex items-center justify-center h-screen">
                <div className="w-11/12 md:w-7/12 lg:w-[30%] h-fit p-4 md:p-16 bg-white flex flex-col shadow-md rounded-md  relative">
                    <div className=" absolute right-5 top-5">
                        <LanguageDropdown />
                    </div>
                    <div><img src={logo} alt="TT" className='w-20 h-20 mx-auto mb-2' /></div>
                    <div className="text-lg font-semibold text-center mb-2">{t('loginToYourAccount')}</div>
                    <div className="text-xs text-center text-TT-gray">{`${t('welcomeBack')}, ${t('pleaseEnterYourDetails')}`}</div>
                    <form className='mt-7 text-TT-gray' onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                            <label htmlFor="input_email" className='text-sm capitalize font-semibold'>
                                {t('email')}
                            </label>
                            <Field
                                {...register('email')} error={errors?.email && t(`${errors?.email?.message}`)} type="text"
                                placeholder={t('usernameOrEmail') + ' *'} id="input_email" className='px-2 h-12 mt-1.5'
                            />
                        </div>
                        <div>
                            <label htmlFor="input_password" className='text-sm capitalize font-semibold'>
                                {t('password')}
                            </label>
                            <div className="relative">
                                <Field
                                    {...register('password')} error={((errors?.password && t(`${errors?.password?.message}`)) || (serverError))} type={showPassword ? 'text' : 'password'}
                                    placeholder={t('password') + ' *'} id="input_password" className='px-2 h-12 mt-1.5'
                                />
                                {!errors?.password && <span className='absolute top-4 right-4  cursor-pointer text-TT-gray-light  ' onClick={() => { setShowPassword(!showPassword) }}>{!showPassword ? <Icon icon="EYE_SLASH" /> : <Icon icon="EYE" />}</span>}</div>
                        </div>

                        <div className="flex justify-center mt-10">
                            <Button className="w-full py-3 text-white" color="primary" submit disabled={isLoggingIn}>{t(`login`)}</Button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
}

export default SignIn;