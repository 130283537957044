import { useAppPermission } from './AppPermissionHook';
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { User } from "../models/user.model";
import { Role } from "../models/userRole.model";
import { useGetCompaniesQuery } from "../Services/companiesApi";
import {
  useGetLocationByUserQuery,
  useGetLocationsByCompanyQuery,
} from "../Services/locationApi";
import { useGetAllRolesQuery } from "../Services/rolesApi";
import {
  useCreateUserMutation,
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from "../Services/userApi";
import {
  addUserValidationSchema,
  editUserValidationSchema,
} from "../Utils/validation";
import { Location as LocationModel } from '../models/location.model';

export const useAddUsersHook = ({ id }: { id?: string }) => {
  const [company, setCompany] = useState<any>("");
  const [defaultRoleIndex, setDefaultRoleIndex] = useState<number>();
  const [selectedRole, setSelectedRole] = useState<string>();
  const [navigateBack, setNavigateBack] = useState<boolean>(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>();
  const [createSuccess, setCreateSuccess] = useState<boolean | undefined>();
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>();
  const [companyQuery, setCompanyQuery] = useState("");
  const [companyOption, setCompanyOption] = useState<string[]>([]);
  const [locationId, setLocationId] = useState<string>();
  const { hasPermission } = useAppPermission()

  const { data: userData, isLoading: userLoading } = useGetUserByIdQuery(
    id ? id : "",
    { skip: !id }
  );
  const { data: roles, isLoading: rolesLoading } = useGetAllRolesQuery();
  const { data: locations } = useGetLocationByUserQuery();
  const { data: companies } = useGetCompaniesQuery();
  const { data: locationsByCompany } = useGetLocationsByCompanyQuery(company, {
    skip: !company || company === "",
  });

  const [createUser, { error: createUserError }] = useCreateUserMutation();
  const [updateUser, { error: updateUserError }] = useUpdateUserMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(
      id ? editUserValidationSchema : addUserValidationSchema
    ),
  });

  const findDefaultRole = () => {
    roles?.forEach((role, index) => {
      if (userData?.roles[0].role === role.role) {
        setDefaultRoleIndex(index);
      }
    });
  };

  const setCompanyState = () => {
    if (companies) {
      if (userData?.company?.id) {
        const company = companies.find((company) => userData.company.id === company.id)
        setCompany(company);
      } else {
        setCompany(companies[0]);
      }
    }
  };

  useEffect(() => {
    if (userData && roles) {
      findDefaultRole();
    }
    if (userData?.roles && userData?.roles.length > 0) {
      setSelectedRole(userData?.roles[0]?.role?.toString());
    }

    setCompanyState();

  }, [userData, roles, companies]);

  const formatLocation = (locations: LocationModel) => {
    return `${locations?.locationKey} : ${locations?.locationType?.locationType}`;
  };

  function onSubmit(values: any) {
    let updateUserData: User = userData
      ? Object.assign({}, userData)
      : ({} as User);

    if (updateUserData) {
      updateUserData.firstName = values?.firstName;
      updateUserData.lastName = values?.lastName;
      updateUserData.username = values?.userName;
      updateUserData.email = values?.email;
      updateUserData.password = values?.password;
      updateUserData.phone = values?.phone;
      updateUserData.others = values?.others;
      if (values?.roles?.length) {
        updateUserData.roles = values.roles;
      }
      if (company) {
        updateUserData.company = { id: company.id } as any;
      }
      if (hasPermission([Role.CompanyAdmin])) {
        if (values.location) {
          locations?.forEach(item => {
            if (item.id === values.location) {
              updateUserData.location = { id: item.id } as any;
            }
          })
        }
      }
      if (hasPermission([Role.SuperAdmin])) {
        if (values.location) {
          locationsByCompany?.forEach(item => {
            if (item.id === values.location) {
              updateUserData.location = { id: item.id } as any;
            }
          })
        }
      }
      if (id) {
        updateUser(updateUserData).then((result: any) => {
          if (result.data) {
            setUpdateSuccess(true);
            setNavigateBack(true);
          } else {
            setUpdateSuccess(false);
            setNavigateBack(false);
            console.log("User update failed", result?.error);
          }
        });
      } else {
        createUser(updateUserData).then((result: any) => {
          if (result.data) {
            setCreateSuccess(true);
            setNavigateBack(true);
          } else {
            setCreateSuccess(false);
            setNavigateBack(false);
            console.log("User update failed", result?.error);
          }
        });
      }
    }
  }

  function handleStatus() {
    let updateUserData: User = userData
      ? Object.assign({}, userData)
      : ({} as User);

    updateUserData.status = !updateUserData.status;
    if (id) {
      updateUser(updateUserData).then((result: any) => {
        if (result.data) {
          console.log(`User status changed to ${!updateUserData.status}`);
        } else {
          console.log("User status update failed", result?.error);
        }
      });
    }
  }

  let companyData;
  if (companyOption) {
    companyData =
      companyQuery === ""
        ? companyOption
        : companyOption.filter((locationType: any) =>
          locationType
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(companyQuery.toLowerCase().replace(/\s+/g, ""))
        );
  }

  function addCompany(companyName: string) {
    companies?.forEach(item => {
      if (companyName.trim() === item.company_name) {
        setCompany(item);
      }
    })
    setSelectedCompany(companyName);
    setValue("company", companyName);
  }

  useEffect(() => {
    if (companies) {
      companies?.map((e) => {
        setCompanyOption((value) => [...value, e.company_name]);
      });
    }
  }, [companies]);

  useEffect(() => {
    if (userData) {
      reset(userData);
      if (userData.location) {
        setValue('location', userData.location.id);
        setLocationId(userData.location.id);
      }
      if (userData.company) {
        setCompany(userData.company);
        setSelectedCompany(userData.company.company_name);
      }
    }
  }, [userData])

  return {
    register,
    handleSubmit,
    reset,
    errors,
    userData,
    userLoading,
    onSubmit,
    roles,
    rolesLoading,
    locations,
    companies,
    control,
    setValue,
    setCompany,
    defaultRoleIndex,
    locationsByCompany,
    createUserError,
    updateUserError,
    setSelectedRole,
    selectedRole,
    handleStatus,
    updateSuccess,
    navigateBack,
    createSuccess,
    setCreateSuccess,
    companyData,
    selectedCompany,
    setCompanyQuery,
    addCompany,
    locationId,
    setLocationId,
    formatLocation
  };
};
