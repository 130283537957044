
import { logoutToken } from '../Services/tokenReducer';
import { changeToInitial } from '../Services/userReducer';
import { useAppDispatch } from '../Store/hooks';

export const useSignOutHook = () => {

    const dispatch = useAppDispatch();
    const signOutCurrentUser = () => {
        dispatch(changeToInitial());
        dispatch(logoutToken());
    };
    return { signOutCurrentUser }
}