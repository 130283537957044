import { LocationType } from "../models/locationType.model";
import { PagedData } from "../models/pagedData.model";
import { allApis } from "./allApi";

export const locationTypesApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        locationTypes: builder.query<LocationType[], any>({
            query: () => "api/locationType",
            providesTags: ['LocationType']
        }),
        getLocationTypesByPagination: builder.query<PagedData<LocationType[]>, any>({
            query: (args) => {
                return {
                    url: "api/locationType/getAll/page/",
                    params: args
                }
            },
            providesTags: ['LocationType']
        }),
        getLocationTypesById: builder.query<LocationType, any>({
            query: (args) => {
                const { id } = args
                // const { id, sessionId } = args
                
                return `api/locationType/${id}`
            },
            providesTags: ['LocationType']
        }),
        updateLocationTypesById: builder.mutation<LocationType[], Partial<LocationType>>({
            query: (locationType) => {
                return {
                    url: `api/locationType/${locationType.id}`,
                    method: "PUT",
                    body: locationType,
                }
            },
            invalidatesTags: ['LocationType']
        }),
        createLocationTypes: builder.mutation<LocationType[], Partial<LocationType>>({
            query: (locationTypes) => {
                return {
                    url: `api/locationType`,
                    method: "POST",
                    body: locationTypes,
                }
            },
            invalidatesTags: ['LocationType']
        }),
        deleteLocationTypesById: builder.mutation<any, any>({
            query: (id) => {
                return {
                    url: `api/locationType/${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ['LocationType']
        }),
        restoreLocationTypesById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `api/locationType/restore/${id}`,
                }
            },
            providesTags: ['LocationType']
        }),
    })
});

export const { useLocationTypesQuery, useRestoreLocationTypesByIdQuery, useGetLocationTypesByPaginationQuery, useGetLocationTypesByIdQuery, useUpdateLocationTypesByIdMutation, useCreateLocationTypesMutation, useDeleteLocationTypesByIdMutation } = locationTypesApi;