import { allApis } from './allApi';
import { Location } from '../models/location.model';
import { PagedData } from '../models/pagedData.model';
import { Company } from '../models/company.model';

export const locationApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getLocationByUser: builder.query<Location[], void>({
            query: () => "api/location",
            providesTags: ["Location"]
        }),
        getLocationById: builder.query<Location, any>({
            query: (id: string) => `api/location/${id}`,
            providesTags: ["Location"]
        }),
        getLocationByPagination: builder.query<PagedData<Location[]>, any>({
            query: (search) => {
                return {
                    url: "api/location/getAll/page",
                    params: search
                };
            },
            providesTags: ["Location"]
        }),
        updateLocationById: builder.mutation<Location, Partial<Location>>({
            query: (location) => {
                return {
                    url: `api/location/${location.id}`,
                    method: "PUT",
                    body: location,
                }
            },
            invalidatesTags: ['Location']
        }),
        createLocations: builder.mutation<Location, Partial<Location>>({
            query: (location) => {
                return {
                    url: 'api/location',
                    method: "POST",
                    body: location,
                }
            },
            invalidatesTags: ['Location']
        }),
        deleteLocationById: builder.mutation<any, any>({
            query: (id) => {
                return {
                    url: `api/location/${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ['Location']
        }),
        restoreLocationById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `api/location/restore/${id}`,
                }
            },
            providesTags: ['Location']
        }),
        getLocationsByCompany: builder.query<Location[], Company>({
            query: (comapany) => `api/location/company/${comapany.id}`,
            providesTags: ["Location"]
        }),
        getLocationByUserId: builder.query<Location, string>({
            query: (userId) => `api/location/user/${userId}`,
        }),
    }),

});

export const { useGetLocationByUserQuery, useRestoreLocationByIdQuery, useGetLocationByIdQuery, useGetLocationByPaginationQuery, useCreateLocationsMutation, useUpdateLocationByIdMutation, useDeleteLocationByIdMutation, useGetLocationsByCompanyQuery, useGetLocationByUserIdQuery } = locationApi;
