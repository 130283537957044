import { useEffect, useState } from 'react';
import { useAppDispatch } from './../Store/hooks';
import { useForm } from "react-hook-form";
import { Trace } from "../models/trace.model";
import { locationApi, useGetLocationByIdQuery } from "../Services/locationApi";
import { useGetProductByGtinQuery } from "../Services/productApi";
import { useGetTraceDataQuery } from "../Services/TraceApi";

export interface Coordinates {
    id: string,
    name: string,
    position: { lat: string, lng: string }
}

export const useTraceHook = ({ code, trace }: { code?: string, trace?: Trace }) => {
    const { control, register, getValues } = useForm();

    const { data: traceData, isLoading: traceDataLoading, isError: traceDataError, isSuccess: traceSuccess, refetch: traceRefetch } = useGetTraceDataQuery(code, { skip: !code });
    const { data: locationData, isLoading: locationDataLoading, isSuccess: locationSuccess, isError, error } = useGetLocationByIdQuery(trace?.locationId, { skip: !trace?.locationId });
    const { data: productData, isLoading: productLoading, isSuccess: productSuccess } = useGetProductByGtinQuery(trace?.productCode);

    return {
        control, register, getValues,
        traceData, traceDataLoading, traceSuccess, traceDataError, traceRefetch,
        locationData, productData, locationDataLoading, productLoading,
        locationSuccess, isError, error, productSuccess
    }
}

export const useTraceLocationsHook = ({ trace }: { trace?: Trace }) => {

    const dispatch = useAppDispatch();

    const [coordinates, setCoordinates] = useState<Coordinates[]>([])
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        if (trace) {
            getLocationData()
        }
    }, [trace])

    async function getLocationData() {
        if (trace) {
            setIsSuccess(false);
            await findCoordinates(trace.locationId);
            if (trace.travels)
                await Promise.all(trace.travels.map((element) => {
                    return findCoordinates(element.locationId);
                }))
            setIsSuccess(true);
            return;
        }
    }

    async function findCoordinates(locationId: string) {
        await dispatch(locationApi.endpoints.getLocationById.initiate(locationId)).unwrap().then((location) => {
            if (location?.latitude && location?.longitude) {
                setCoordinates((values) => {
                    if (values.find(value => (value.position.lat === location.latitude) && (value.position.lng === location.longitude))) {
                        return values;
                    } else {
                        return [...values, { id: (location.locationKey), name: location.location, position: { lat: (location.latitude), lng: (location.longitude) } }]
                    }
                });
            }
        });
        return;
    }

    return { isSuccess, coordinates }
}
