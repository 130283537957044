import { TraceBlock } from './../models/traceBlock.model';
import { allApis } from "./allApi";

export const fabricApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getBlockData: builder.query<TraceBlock, any>({
      query: (args) => {
        const { blockId, channel, address } = args;
        return {
          url: `api/fabric/${blockId}/${channel}/${address}`,
          method: "GET"
        }
      },
    }),
  }),

});

export const { useGetBlockDataQuery } = fabricApi;