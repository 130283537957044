import Field from "../../Components/base/field/field";
import { useTranslation } from "react-i18next";
import Button from "../../Components/base/button/button";
import { useCustomerHook } from "../../Hooks/CustomerHooks";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { toastError, toastSuccess } from "../../Components/toast";
import { setNavbarProps } from "../../Services/utilsReducer";
import { useAppDispatch } from "../../Store/hooks";
import { PhoneNumberField } from "../../Components/base/PhoneInput/PhoneNumberInput";
import { Controller } from "react-hook-form";
import Spinner from "../../Components/Spinner";

function AddCustomer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    customersData,
    register,
    handleSubmit,
    reset,
    errors,
    onSubmit,
    newDeliveryPath,
    setNewDeliveryPath,
    deliveryPathData,
    deliveryPath,
    createCustomerDataError,
    updateCustomerDataError,
    createSuccess,
    updateSuccess,
    navigateTo,
    setNavigateTo,
    control,
  } = useCustomerHook({ id });

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setNavbarProps({
      navBarTitle: id ? "editCustomer" : "addCustomer",
      navBarPrevPage: "/customers",
      navBarUserAvatar: true,
    }));
  }, [])

  useEffect(() => {
    if (createCustomerDataError as any) {
      toastError((createCustomerDataError as any)?.data?.message);
    }
    if (updateCustomerDataError as any) {
      toastError((updateCustomerDataError as any)?.data?.message);
    }
  }, [createCustomerDataError, updateCustomerDataError])
  useEffect(() => {
    if (navigateTo && updateSuccess === true) {
      toastSuccess(t("updatedSuccessfully"));
      setNavigateTo(false);
      navigate(-1);
    } else if (!navigateTo && updateSuccess === false) {
      toastSuccess(t("failed"));
    }
  }, [navigateTo, updateSuccess]);
  useEffect(() => {
    if (navigateTo && createSuccess === true) {
      toastSuccess(t("createdSuccessfully"));
      setNavigateTo(false);
      navigate(-1);
    } else if (!navigateTo && createSuccess === false) {
      toastSuccess(t("failed"));
    }
  }, [navigateTo, createSuccess]);

  if(id && !customersData) {
    return (
      <div
        className={`md:h-full h-screen flex justify-center items-center bg-white`}
      >
        <Spinner className="h-8 w-8" color="#2984FF" />
      </div>
    );
  }

  return (
    <>
      <section className="bg-white mt-[7.6rem] m-0 md:m-2 md:mt-2 rounded-lg h-full overflow-auto ">
        <div className="w-full h-fit lg:w-3/5 p-5 md:pb-24 lg:pb-0">
          <form id="addCustomer" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="customerName"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`customerName`)}
                </label>
                <Field
                  {...register("customerName")}
                  error={errors?.customerName && `${errors?.customerName?.message}`}
                  type="text"
                  defaultValue={customersData?.customer_name}
                  placeholder={t(`customerName`)}
                  id="customerName"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="customer_code"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`customer_code`)}
                </label>
                <Field
                  {...register("customer_code")}
                  error={errors?.customer_code && `${errors?.customer_code?.message}`}
                  type="text"
                  defaultValue={customersData?.customer_code}
                  placeholder={t(`customer_code`)}
                  id="customer_code"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="address1"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`address1`)}
                </label>
                <Field
                  {...register("address1")}
                  error={errors?.address1 && `${errors?.address1?.message}`}
                  type="text"
                  defaultValue={customersData?.address1}
                  placeholder={t(`address1`)}
                  id="address1"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="address2"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`address2`)}
                </label>
                <Field
                  {...register("address2")}
                  error={errors?.address2 && `${errors?.address2?.message}`}
                  type="text"
                  defaultValue={customersData?.address2}
                  placeholder={t(`address2`)}
                  id="address2"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-4 flex flex-col gap-2">
                <label
                  htmlFor="city"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`city`)}
                </label>
                <Field
                  {...register("city")}
                  error={errors?.city && `${errors?.city?.message}`}
                  type="text"
                  defaultValue={customersData?.city}
                  placeholder={t(`city`)}
                  id="city"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-4 flex flex-col gap-2">
                <label
                  htmlFor="prefecture"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`prefecture`)}
                </label>
                <Field
                  {...register("prefecture")}
                  error={errors?.prefecture && `${errors?.prefecture?.message}`}
                  type="text"
                  defaultValue={customersData?.prefecture}
                  placeholder={t(`prefecture`)}
                  id="prefecture"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-4 flex flex-col gap-2">
                <label
                  htmlFor="country"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`country`)}
                </label>
                <Field
                  {...register("country")}
                  error={errors?.country && `${errors?.country?.message}`}
                  type="text"
                  defaultValue={customersData?.country}
                  placeholder={t(`country`)}
                  id="country"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-4 flex flex-col gap-2">
                <label
                  htmlFor="contactPerson1"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`contactPerson1`)}
                </label>
                <Field
                  {...register("contactPerson1")}
                  error={errors?.contactPerson1 && `${errors?.contactPerson1?.message}`}
                  type="text"
                  defaultValue={customersData?.contactPerson1}
                  placeholder={t(`contactPerson1`)}
                  id="contactPerson1"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-4 flex flex-col gap-2">
                <label
                  htmlFor="email1"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`email1`)}
                </label>
                <Field
                  {...register("email1")}
                  error={errors?.email1 && `${errors?.email1?.message}`}
                  type="text"
                  defaultValue={customersData?.email1}
                  placeholder={t(`email1`)}
                  id="email1"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-4 flex flex-col gap-2">
                <label
                  htmlFor="phone"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`phone`)}
                </label>
                <Controller
                  name='phone'
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <PhoneNumberField
                      value={customersData?.phone} placeholder={t('enterphone')} onChange={onChange}
                      error={errors?.phone?.message}
                  />
                  )}
                />
                {((errors?.phone as any)?.message) &&
                  <span className="text-sm text-red-500 mt-2">{t((errors?.phone as any)?.message)}</span>
                }
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="contactPerson2"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`contactPerson2`)}
                </label>
                <Field
                  {...register("contactPerson2")}
                  error={errors?.contactPerson2 && `${errors?.contactPerson2?.message}`}
                  type="text"
                  defaultValue={customersData?.contactPerson2}
                  placeholder={t(`contactPerson2`)}
                  id="contactPerson2"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
                <label
                  htmlFor="email2"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`email2`)}
                </label>
                <Field
                  {...register("email2")}
                  error={errors?.email2 && `${errors?.email2?.message}`}
                  type="text"
                  defaultValue={customersData?.email2}
                  placeholder={t(`email2`)}
                  id="email2"
                  className="px-2 h-12"
                />
              </div>
              <div className="col-span-12 flex flex-col gap-2">
                <label
                  htmlFor="deliveryPath"
                  className="text-xs uppercase font-semibold"
                >
                  {t(`deliveryPath`)}
                </label>
                <Field
                  {...register("deliveryPaths")}
                  type="select"
                  name="deliveryPath"
                  onChange={(e: any) => {
                    setNewDeliveryPath(e.target.value);
                  }}
                  error={errors?.deliveryPaths && `${errors?.deliveryPaths?.message}`}
                >
                  <option value="">{t("select")}</option>
                  {id && customersData?.deliveryPath?.path && (
                    <option selected>
                      {customersData?.deliveryPath?.pathName}
                    </option>
                  )}
                  {deliveryPathData?.data?.map((path: any) => (
                    <>
                      {customersData?.deliveryPath?.pathName !==
                        path?.pathName && (
                          <option id={path.id}>{path?.pathName}</option>
                        )}
                    </>
                  ))}
                </Field>
                <div className="flex gap-3 flex-wrap">
                  {!newDeliveryPath &&
                    customersData?.deliveryPath?.path &&
                    id &&
                    customersData?.deliveryPath?.path?.map(
                      (locationTypes: any) => (
                        <>
                          <span className="p-2 border-TT-blue border flex">
                            {locationTypes?.locationType}
                          </span>
                        </>
                      )
                    )}
                  {!id &&
                    deliveryPath &&
                    deliveryPath?.path?.map((locationTypes: any) => (
                      <>
                        <span className="p-2 border-TT-blue border flex">
                          {locationTypes?.locationType}
                        </span>
                      </>
                    ))}
                </div>
                {newDeliveryPath !== undefined &&
                  deliveryPathData?.data.map(
                    (p: any) =>
                      p.pathName === newDeliveryPath && (
                        <div className="flex gap-3 flex-wrap">
                          {p?.path &&
                            id &&
                            p?.path?.map((value: any) => (
                              <span className="p-2 border-TT-blue border flex">
                                {" "}
                                {value?.locationType}
                              </span>
                            ))}
                        </div>
                      )
                  )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-2 justify-center">
              <Button
                  color="primary"
                  className="py-1 md:w-fit h-12 md:h-fit w-full mx-0"
                  form="addCustomer"
                  submit
                >
                {id ? `${t("update")}` : `${t("addCustomer")}`}
                </Button>

            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default AddCustomer;
