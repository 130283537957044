import { useTranslation } from "react-i18next";
import SearchBar from "./SearchBar";
import { useAsyncDebounce } from "react-table";
import { useEffect, useState } from "react";
import { getClearFilters } from "../../Services/utilsReducer";
import { useAppSelector } from "../../Store/hooks";
import { useAppPermission } from "../../Hooks/AppPermissionHook";
import { Role } from "../../models/userRole.model";

interface SearchAndFilterProp {
  onSearch?: (searchModel: Object | undefined) => void;
  type?: "Users" | "Companies" | "Locations" | "Customers" | "Products"; //TODO check and add other screens too
}

export default function SearchAndFilter({
  onSearch,
  type,
}: SearchAndFilterProp) {
  const { t } = useTranslation();

  const { hasPermission } = useAppPermission();

  const clearFilters = useAppSelector(getClearFilters);

  const [searchModel, setSearchModel] = useState<any>({});

  let component;

  const handleFilter = useAsyncDebounce((search: any) => {
    setSearchModel((value: any) => ({ ...value, ...search }));
  }, 400);

  useEffect(() => {
    if (searchModel) {
      onSearch && onSearch(searchModel);
    }
  }, [searchModel]);

  switch (type) {
    case "Users":
      component = (
        <>
          <SearchBar
            placeHolder={t("filterByFirstName")}
            onSearch={(firstName) => {
              handleFilter({ firstName: firstName ? firstName : undefined });
            }}
            clearFilters={clearFilters}
          />
          <SearchBar
            placeHolder={t("filterByLastName")}
            onSearch={(lastName) => {
              handleFilter({ lastName: lastName ? lastName : undefined });
            }}
            clearFilters={clearFilters}
          />
          {hasPermission([Role.SuperAdmin]) ? (
            <SearchBar
              placeHolder={t("filterByCompany")}
              onSearch={(companyName) => {
                handleFilter({
                  "company.companyName": companyName ? companyName : undefined,
                });
              }}
              clearFilters={clearFilters}
            />
          ) : (
            <SearchBar
              placeHolder={t("filterByLocationName")}
              onSearch={(locationName) => {
                handleFilter({
                  "location.location": locationName ? locationName : undefined,
                });
              }}
              clearFilters={clearFilters}
            />
          )}
        </>
      );
      break;

    case "Companies":
      component = (
        <SearchBar
          placeHolder={t("filterCompanyName")}
          onSearch={(companyName) => {
            handleFilter({
              company_name: companyName ? companyName : undefined,
            });
          }}
          clearFilters={clearFilters}
        />
      );
      break;

    case "Locations":
      component = (
        <>
          <SearchBar
            placeHolder={t("filterByLocationKey")}
            onSearch={(locationKey) => {
              handleFilter({
                locationKey: locationKey ? locationKey : undefined,
              });
            }}
            clearFilters={clearFilters}
          />
          <SearchBar
            placeHolder={t("filterByLocationName")}
            onSearch={(locationName) => {
              handleFilter({
                location: locationName ? locationName : undefined,
              });
            }}
            clearFilters={clearFilters}
          />
        </>
      );
      break;

    case "Customers":
      component = (
        <>
          <SearchBar
            placeHolder={t("filterByCity")}
            onSearch={(city) => {
              handleFilter({ city: city ? city : undefined });
            }}
            clearFilters={clearFilters}
          />
          <SearchBar
            placeHolder={t("filterByCountry")}
            onSearch={(country) => {
              handleFilter({ country: country ? country : undefined });
            }}
            clearFilters={clearFilters}
          />
        </>
      );
      break;

    case "Products":
      component = (
        <>
          <SearchBar
            placeHolder={t("filterByGTIN")}
            onSearch={(gtin) => {
              handleFilter({ gtin: gtin ? gtin : undefined });
            }}
            clearFilters={clearFilters}
          />
          <SearchBar
            placeHolder={t("filterByProductName")}
            onSearch={(productName: any) => {
              handleFilter({
                product_name: productName ? productName : undefined,
              });
            }}
            clearFilters={clearFilters}
          />
        </>
      );
      break;

    default:
      component = <></>;
      break;
  }

  return (
    <div className="flex flex-col md:flex-row justify-center w-full md:justify-start md:w-auto md:gap-x-2 gap-y-2">
      {component}
    </div>
  );
}
