import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import Icon from "../../Components/base/icon/icon";
import { useGetTraceDataQuery } from "../../Services/TraceApi";
import { productApi } from "../../Services/productApi";
import { locationApi } from "../../Services/locationApi";
import { useAppDispatch } from "../../Store/hooks";
import { Products } from "../../models/products.model";
import { Location } from "../../models/location.model";
import { formatDateTimeFull } from "../../Utils/datetime";
import TraceListItem from "./TraceListItem";
import ItemsDown from "./ItemsDown";
import { useAppPermission } from "../../Hooks/AppPermissionHook";
import NoDataAvailable from "../../Components/base/NoDataAvailable";
import Spinner from "../../Components/Spinner";
import { setNavbarProps } from "../../Services/utilsReducer";
import { QRCodeType } from "../../models/trace.model";
import TracePack from '../../Assets/images/trace-pack.svg';

function Trace() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { isUserLoggedIn } = useAppPermission();
  const dispatch = useAppDispatch();

  const {
    data: trace,
    isLoading: traceLoading,
    isFetching,
    isError: traceError,
  } = useGetTraceDataQuery(id, { skip: !id });
  const [product, setProduct] = React.useState<Products>();
  const [location, setLocation] = React.useState<Location>();
  const [ascending, setAscending] = React.useState<boolean>(true);

  var [imageIndex, setImageIndex] = React.useState(0);

  useEffect(() => {
    if (trace) {
      dispatch(
        productApi.endpoints.getProductByGtin.initiate(trace[0]?.productCode)
      ).then((value: any) => {
        setProduct(value.data);
        dispatch(
          locationApi.endpoints.getLocationById.initiate(trace[0]?.locationId)
        ).then((value: any) => {
          setLocation(value.data);
        });
      });
    }
  }, [trace, dispatch]);

  const canShow = (key: string | undefined): boolean => {
    if (key === "none") {
      return false;
    }
    if (key === "client") {
      return isUserLoggedIn();
    }
    return true;
  };

  useEffect(() => {
    dispatch(
      setNavbarProps({
        navBarTitle: "trace",
        navBarUserAvatar: true,
        navBarPrevPage: "/trace",
      })
    );
  }, [dispatch]);

  if (isFetching || traceLoading) {
    return <div className="h-screen flex flex-1 justify-center items-center md:m-2 rounded-lg shadow md:w-auto w-screen p-5 bg-white">
      <Spinner className="h-8 w-8" color="#2984FF" />
    </div>
  }

  return (
    <div className="h-screen flex flex-col flex-1 md:overflow-hidden">
      {traceLoading && !trace && !product && !location && (
        <div className="h-screen flex flex-1 justify-center items-center md:m-2 rounded-lg shadow md:w-auto w-screen p-5 bg-white">
          <Spinner className="h-8 w-8" color="#2984FF" />
        </div>
      )}
      {traceError && !trace && (
        <div className="h-screen flex flex-1 justify-center items-center md:m-2 rounded-lg shadow md:w-auto w-screen p-5 bg-white">
          <NoDataAvailable errorString="traceNotFound" />
        </div>
      )}
      {trace && !traceError && (
        <section
          className={
            !isUserLoggedIn()
              ? `h-full shadow bg-white md:w-auto w-screen py-5 px-3 lg:px-7 mt-0.5`
              : `h-full mt-[7.6rem] md:m-2 rounded-lg shadow bg-white md:w-auto w-screen p-2 lg:p-5 overflow-auto no-scrollbar lg:overflow-hidden`
          }
        >
          <div className="grid grid-cols-2  gap-x-3 text-xs md:text-base">
            <div className="col-span-2 lg:col-span-1 gap-y-3 lg:h-screen lg:pb-[15%] lg:overflow-auto">
              <>
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-col">
                    {trace[0].qrCodeType === QRCodeType.pack ?
                      <div className="flex flex-row">
                        <span className="pr-2 capitalize font-semibold">{trace[0].qrCodeType}</span>
                        <span className="pr-2 font-semibold">Details </span>
                      </div> : <div className="flex flex-row">
                        <span className="pr-2 capitalize font-semibold">{trace[0].qrCodeType}</span>
                        <span className="pr-2 font-semibold">Details </span>
                      </div>}
                    <div className="text-2xl font-semibold">
                      {product?.product_name}
                    </div>
                  </div>
                  {(trace[0].qrCodeType === QRCodeType.product) && <img src={TracePack} alt={product?.product_name} className='w-12 h-12 ' />}
                  {(trace[0].qrCodeType === QRCodeType.pack) && <Icon icon="ITEM_CART" className="w-12 h-12" />}
                  {(trace[0].qrCodeType === QRCodeType.container) && <Icon icon="HANGER" className="w-12 h-12" />}
                  {(trace[0].qrCodeType === QRCodeType.box) && <Icon icon="CONTAINER" className="w-12 h-12" />}
                  {(trace[0].qrCodeType === QRCodeType.ingredient) && <Icon icon="INGREDIENTS" className="w-12 h-12" />}
                </div>
                {trace[0].productCode && (
                  <>
                    <div className="relative">
                      <button
                        className="cursor-pointer hover:bg-white/80 rounded-full flex justify-center items-center h-14 w-14  disabled:opacity-0 disabled:cursor-default focus:outline-none absolute left-10 inset-y-1/2"
                        disabled={imageIndex > 0 ? false : true}
                        onClick={() => setImageIndex(--imageIndex)}
                      >
                        <Icon icon="LEFT" size="medium" />
                      </button>
                      {product?.images.length ? <img
                        src={((imageIndex === 0 || imageIndex > 0) && (product?.images.length && product?.images[imageIndex].imageURL !== undefined)) ? product?.images[imageIndex].imageURL : ""}
                        alt={product?.product_name}
                        className="rounded-lg flex justify-center my-5 w-full min-h-min lg:h-96 object-cover border"
                      /> : <img src={TracePack} alt={product?.product_name} className='rounded-lg flex justify-center my-5 w-full min-h-min lg:h-96 object-contain border' />}
                      <button
                        className="cursor-pointer hover:bg-white/80 rounded-full flex justify-center items-center h-14 w-14 disabled:opacity-0 disabled:cursor-default focus:outline-none absolute right-10 inset-y-1/2"
                        disabled={
                          product && product?.images?.length > imageIndex + 1
                            ? false
                            : true
                        }
                        onClick={() => setImageIndex(++imageIndex)}
                      >
                        <Icon icon="RIGHT" size="medium" />
                      </button>
                    </div>
                    <div className="font-semibold text-base">
                      {t("description")}
                    </div>
                    <p className="text-TT-gray-light  text-justify indent-10">
                      {product?.productDescription}
                    </p>
                    <div className="grid grid-cols-4 mt-3 gap-y-2 items-center">
                      <div className="flex flex-col col-span-2">
                        <div className="font-semibold">{t("code")}</div>
                        <div className="text-TT-gray-light">
                          {trace[0]?.productCode}
                        </div>
                      </div>
                      {canShow(product?.view_company) && (
                        <div className="flex flex-col col-span-2">
                          <div className="font-semibold">
                            {t("companyName")}
                          </div>
                          <div className="text-TT-gray-light">
                            {product?.company.company_name}
                          </div>
                        </div>
                      )}
                      {canShow(product?.view_location) && (
                        <div className="flex flex-col col-span-2">
                          <div className="font-semibold">
                            {t("packagedLocation")}
                          </div>
                          <div className="text-TT-gray-light">
                            {location?.location}
                          </div>
                        </div>
                      )}
                      <div className="flex flex-col col-span-2">
                        <div className="font-semibold">{t("packagedOn")}</div>
                        <div className="text-TT-gray-light">
                          {formatDateTimeFull(trace[0]?.created_at.toString())}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* {trace[0]?.items_up?.length ? <div className="text-lg pt-8 font-semibold">{trace[0].subProductType}</div> : <></>} */}
                {trace[0]?.items_up?.length ? <div className="text-lg pt-8 font-semibold">Extract</div> : <></>}
                <div className="flex gap-2 mr-5 pb-5 mt-5 overflow-x-auto w-full">
                  {canShow(product?.view_items) &&
                    trace &&
                    trace[0]?.items_up?.map((item, index) => (
                      <ItemsDown
                        key={index}
                        productCode={item.productCode}
                        traceCode={item.code}
                        isNavigable={canShow(product?.access_navigate)}
                        createdAt={item.created_at}
                      />
                    ))}
                </div>
                {/* {trace[0]?.items_down?.length ? <div className="text-lg pt-8 font-semibold">{trace[0].subProductType}</div> : <></>} */}
                {trace[0]?.items_down?.length ? <div className="text-lg pt-8 font-semibold">Extracted From</div> : <></>}
                <div className="flex gap-2 mr-5 pb-5 mt-5 overflow-x-auto w-full">
                  {canShow(product?.view_items) &&
                    trace &&
                    trace[0]?.items_down?.map((item, index) => (
                      <ItemsDown
                        key={index}
                        productCode={item.productCode}
                        traceCode={item.code}
                        isNavigable={canShow(product?.access_navigate)}
                        createdAt={item.created_at}
                      />
                    ))}
                </div>
              </>
            </div>
            <div className="col-span-2 lg:col-span-1 lg:border-l-2 lg:pl-2 no-scrollbar overflow-auto h-screen pb-[15%]">
              <div className="flex gap-x-3 items-center mb-2">
                <div
                  className={`bg-TT-blue w-5 h-5 rounded-full text-white flex items-center justify-center ${!ascending && "rotate-180"
                    }`}
                  onClick={() => {
                    setAscending(!ascending);
                  }}
                >
                  <Icon icon="DOWN2" size="medium" />{" "}
                </div>
                <div className="w-full px-2 py-3 md:p-5 shadow-md rounded-lg border">
                  <div className="flex justify-between items-center">
                    <div className="flex gap-x-3 items-center">
                      <Icon icon="TRUCK" className="w-12 h-12" />
                      <span className="font-semibold">{t("currentStatus")}</span>
                      <span className="font-semibold">:</span>
                      {trace[0]?.traceCurrentStatus ?
                        <span className="font-semibold text-green-500">
                          {trace[0]?.traceCurrentStatus !== 'others' ? t(trace[0]?.traceCurrentStatus) : (trace[0]?.others ? trace[0]?.others : t(trace[0]?.traceCurrentStatus))}
                        </span> : <span className="font-semibold text-green-500">
                          {t('yetToBeUpdated')}
                        </span>}
                    </div>

                    <NavLink
                      to={`/trace/locations/${id}`}
                      className="flex gap-x-3 items-center"
                    >
                      <Icon icon="LOCATION_NEW" size="medium" />
                      <div className="text-TT-blue underline underline-offset-1 font-semibold">
                        {t(`viewLocation`)}
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
              {ascending && (
                <>
                  <TraceListItem
                    id={trace[0].locationId}
                    travel={trace[0]}
                    accId="-1"
                    showLocation={canShow(product?.view_location)}
                    showMetaData={canShow(product?.view_metadata)}
                    trace={trace[0]}
                    ascending={ascending}
                    arrow={(trace[0]?.travels?.length !== undefined && trace[0]?.travels?.length) <= 0}
                  />
                  {trace[0]?.travels?.map((travel, index) => (
                    <TraceListItem
                      id={travel.locationId}
                      travel={travel}
                      accId={`${index}`}
                      key={index}
                      showLocation={canShow(product?.view_location)}
                      showMetaData={canShow(product?.view_metadata)}
                      trace={trace[0]}
                      ascending={ascending}
                      arrow={trace[0]?.travels?.length === index + 1}
                    />
                  ))}
                </>
              )}
              {!ascending && (
                <>
                  {trace[0]?.travels
                    ?.slice(0)
                    .reverse()
                    .map((travel, index) => (
                      <TraceListItem
                        id={travel.locationId}
                        travel={travel}
                        accId={`${index}`}
                        key={index}
                        showLocation={canShow(product?.view_location)}
                        showMetaData={canShow(product?.view_metadata)}
                        trace={trace[0]}
                        ascending={ascending}
                        arrow={false}
                      />
                    ))}
                  <TraceListItem
                    id={trace[0].locationId}
                    travel={trace[0]}
                    accId="-1"
                    showLocation={canShow(product?.view_location)}
                    showMetaData={canShow(product?.view_metadata)}
                    trace={trace[0]}
                    ascending={ascending}
                    arrow={true}
                  />
                </>
              )}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Trace;
