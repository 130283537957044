import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../Components/base/button/button';
import Field from '../../../Components/base/field/field';
import Spinner from '../../../Components/Spinner';
import { toastError, toastSuccess } from '../../../Components/toast';
import { useCodeTypeHook } from '../../../Hooks/CodeTypeHooks';

function AddCodes() {
  const { id } = useParams()
  const { t } = useTranslation();
  const navigate = useNavigate()
  const {
    codeTypesData,
    setNavigateTo,
    navigateTo,
    register,
    handleSubmit,
    errors,
    createCodeTypesDataError,
    updateCodeTypesDataError,
    createSuccess,
    updateSuccess,
    onSubmit } = useCodeTypeHook({ id })

  useEffect(() => {
      if (createCodeTypesDataError as any) {
        toastError((createCodeTypesDataError as any)?.data?.message);
      }
      if (updateCodeTypesDataError as any) {
        toastError((updateCodeTypesDataError as any)?.data?.message);
      }
    }, [createCodeTypesDataError, updateCodeTypesDataError])

  useEffect(() => {
    if (navigateTo && createSuccess === true) {
      toastSuccess(t("createdSuccessfully"));
      setNavigateTo(false);
      navigate('/types/codes');
    } else if (!navigateTo && createSuccess === false) {
      toastSuccess(t("failed"));
    }
  }, [navigateTo, createSuccess]);

  useEffect(() => {
    if (navigateTo && updateSuccess === true) {
      toastSuccess(t('updatedSuccessfully'));
      setNavigateTo(false);
      navigate('/types/codes')
    }
      else if (!navigateTo && updateSuccess === false) {
        toastSuccess(t('failed'));
      }
  }, [navigateTo, updateSuccess]);

  if (id && !codeTypesData) {
    return (
      <div
        className={`md:h-full h-screen flex justify-center items-center bg-white`}
      >
        <Spinner className="h-8 w-8" color="#2984FF" />
      </div>
    );
  }

  return (
    <div className="md:md:h-[calc(100%-4.3rem)]  md:m-2 rounded-lg shadow bg-white w-screen md:w-auto overflow-auto">
      <div className="w-full lg:w-1/4 p-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-12 text-TT-gray md:gap-x-8 gap-y-4">
            <div className="col-span-12 flex flex-col gap-2">
              <label htmlFor="codeName" className='text-xs uppercase font-semibold'>{t(`codeName`)}</label>
              <Field
                {...register('codeName')} error={errors?.codeName && `${errors?.codeName?.message}`} type='text' defaultValue={codeTypesData?.code}
                placeholder={t(`codeName`)} id="codeName" className='px-2 h-12'
              />
              <p className="text-xs text-TT-gray">{t(`codeName_help`)}</p>
            </div>
            <div className="col-span-12 flex flex-col gap-2">
              <label htmlFor="codePattern" className='text-xs uppercase font-semibold'>{t(`codePattern`)}</label>
              <Field
                {...register('codePattern')} error={errors?.codePattern && `${errors?.codePattern?.message}`} type="text" defaultValue={codeTypesData?.pattern}
                placeholder={t(`codePattern`)} id="codePattern" className='px-2 h-12'
              />
              <p className="text-xs text-TT-gray">{t(`codePattern_help`)}</p>
            </div>
            <div className="col-span-12 flex flex-col gap-2">
              <label htmlFor="codeExample" className='text-xs uppercase font-semibold'>{t(`codeExample`)}</label>
              <Field
                {...register('codeExample')} error={errors?.codeExample && `${errors?.codeExample?.message}`} type="text" defaultValue={codeTypesData?.codeExample} 
                placeholder={t(`codeExample`)} id="codeExample" className='px-2 h-12'
              />
              <p className="text-xs text-TT-gray">{t(`codeExample_help`)}</p>
          <div className='flex flex-col md:flex-row gap-x-10 gap-y-2 md:mt-10 mt-5 justify-center'>
                <Button color='primary' className="py-1 md:w-fit text-white h-12 md:h-fit w-full mx-0" submit>{id ? t('update') : t('addCodes')}</Button>
          </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddCodes