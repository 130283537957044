import { allApis } from './allApi';
import { Pack } from '../models/pack.model';
import { PagedData } from '../models/pagedData.model';

export const codeConfigApi = allApis.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getCodeConfig: builder.query<Pack[], void>({
            query: () => "api/pack",
            providesTags: ["CodeConfig"],
        }),
        getCodeConfigByPagination: builder.query<PagedData<Pack[]>, any>({
            query: search => {
                return {
                    url: "api/pack/getAll/page",
                    params: search
                }
            },
            providesTags: ["CodeConfig"],
        }),
        getCodeConfigById: builder.query<Pack, any>({
            query: (id: string) => `api/pack/${id}`,
            providesTags: ["CodeConfig"],
        }),
        updateCodeConfigById: builder.mutation<Pack, Partial<Pack>>({
            query: (codes) => {
                return {
                    url: `api/pack/${codes.id}`,
                    method: "PUT",
                    body: codes,
                }
            },
            invalidatesTags: ['CodeConfig']
        }),
        createCodeConfig: builder.mutation<Pack, Partial<Pack>>({
            query: (codes) => {
                return {
                    url: `api/pack`,
                    method: "POST",
                    body: codes,
                }
            },
            invalidatesTags: ['CodeConfig']
        }),
        deleteCodeById: builder.mutation<boolean, any>({
            query: (id) => {
                return {
                    url: `api/pack/${id}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ['CodeConfig']
        }),
        restoreCodeById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `api/pack/restore/${id}`,
                }
            },
            providesTags: ['CodeConfig']
        }),
        defaultIdUpdate: builder.mutation<Pack, Partial<Pack>>({
            query: (id) => {
                return {
                    url: `api/pack/default/${id}`,
                    method: "PUT",
                }
            },
            invalidatesTags: ['CodeConfig']
        }),
    }),
});

export const { useGetCodeConfigQuery,
    useGetCodeConfigByPaginationQuery,
    useDeleteCodeByIdMutation,
    useGetCodeConfigByIdQuery,
    useCreateCodeConfigMutation,
    useUpdateCodeConfigByIdMutation,
    useDefaultIdUpdateMutation,
    useRestoreCodeByIdQuery,
} = codeConfigApi;
