import { useTranslation } from "react-i18next";
import {
    useDeleteScanPreferenceByIdMutation,
    useGetScanPreferenceDataQuery,
} from "../../Services/TraceApi";
import { ScanPreference } from "../../models/scanPreference.model";
import { useState } from "react";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import Icon from "../../Components/base/icon/icon";

const ScanPreferenceComponent = ({ setValue }: { setValue: UseFormSetValue<FieldValues> }) => {
    const { t } = useTranslation();
    const { data: scanPreference } = useGetScanPreferenceDataQuery();
    const [deleteScanPreference] = useDeleteScanPreferenceByIdMutation();
    const [selectedIndex, setSelectedIndex] = useState<number>();

    return (
        <div className='my-2'>
            {scanPreference?.length && scanPreference?.length > 0 ?
                <div className="overflow-y-auto overflow-x-hidden h-96">
                    {scanPreference.map((preference: ScanPreference, index: number) => (
                        <div className='w-full' key={index}>
                            <div className="flex flex-row mt-2 items-center">
                                <span className="flex flex-1 text-left">{t(JSON.parse(preference?.scanPreference).traceStatus?.toLowerCase())}</span>
                                <span className="flex flex-1 text-left">{t(JSON.parse(preference?.scanPreference).qrType?.toLowerCase())}</span>
                                {/* <span className="flex flex-1 text-left">{t(JSON.parse(preference?.scanPreference).maskType?.toLowerCase())}</span> */}
                                <span className="flex flex-1 text-left">{t(JSON.parse(preference?.scanPreference).scanType?.toLowerCase())}</span>
                                <span className="flex flex-1">{t(JSON.parse(preference?.scanPreference).lifeSpan)} Month(s)</span>
                                <div className="flex flex-1">
                                    <div
                                        className="w-5 h-5 rounded-full relative border-2 border-TT-blue"
                                        onClick={() => {
                                            setSelectedIndex(index);
                                            if (preference?.scanPreference) {
                                                setValue('traceStatus', JSON.parse(preference?.scanPreference).traceStatus)
                                                setValue('qrType', JSON.parse(preference?.scanPreference).qrType)
                                                setValue('maskType', JSON.parse(preference?.scanPreference).maskType)
                                                setValue('scanType', JSON.parse(preference?.scanPreference).scanType)
                                                setValue('lifeSpan', JSON.parse(preference?.scanPreference).lifeSpan)
                                            }
                                        }}
                                    >
                                        <div className={`absolute w-3 h-3 rounded-full  inset-0.5 ${selectedIndex === index && "bg-TT-blue"}`} />
                                    </div>
                                </div>
                                <div className="flex flex-1">
                                    <Icon icon="CLOSE" className="text-red-500" onClick={() => { deleteScanPreference(preference?.id); }} />
                                </div>
                            </div>
                        </div>))}
                </div>
                : <></>}
        </div>
    );
};

export default ScanPreferenceComponent;
